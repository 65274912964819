import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Skeleton,
} from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Link from "@mui/material/Link";
import { Link as LinkR } from "react-router-dom";
import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import AppSelectList from "components/AppSelectList";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import agent from "api/agent";
import AppDateTime from "../../../components/AppDateTime";

interface Props {
    modalOpen: any;
    setModalOpen: any;
}

const validationSchema = yup.object({
    firstName: yup
        .string()
        .trim()
        .max(50, "Die inhoud vir die veld moet minder as 50 karakters wees")
        .required("Naam is 'n noodsaaklike veld."),
    lastName: yup
        .string()
        .trim()
        .max(50, "Die inhoud vir die veld moet minder as 50 karakters wees")
        .required("Van is 'n noodsaaklike veld."),
    email: yup
        .string()
        .trim()
        .email("Die eposadres moet geldig wees")
        .required("E-posadres is 'n noodsaaklike veld."),
    phone: yup
        .string()
        .trim()
        .matches(
            /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/,
            "Verskaf asb 'n geldige telefoonnommer"
        )
        .required("Telefoonnommer is 'n noodsaaklike veld."),
    birthday: yup
        .string()
        .required("Verjaarsdag is 'n noodsaaklike veld."),
    idNumber: yup
        .string()
        .trim()
        .max(20, "Die ID nommer moet minder as 20 karakters wees")
        .required("ID Nommer is 'n noodsaaklike veld."),
    ShippingAddress_City: yup
        .string()
        .trim()
        .max(50, "Die dorp/stad moet minder as 50 karakters wees")
        .required("Dorp/Stad is 'n noodsaaklike veld."),
    ShippingAddress_PostalCode: yup
        .string()
        .trim()
        .max(10, "Die poskode moet minder as 10 karakters wees")
        .required("Poskode is 'n noodsaaklike veld."),
    ShippingAddress_Province: yup
        .string()
        .trim()
        .max(30, "Die provinsie moet minder as 30 karakters wees")
        .required("Provinsie is 'n noodsaaklike veld."),
    ShippingAddress_Address1: yup
        .string()
        .trim()
        .max(200, "Die adres moet minder as 200 karakters wees")
        .required("Adres Lyn 1 is 'n noodsaaklike veld."),
    bankName: yup
        .string()
        .trim()
        .max(200, "Die bank naam moet minder as 200 karakters wees")
        .required("Bank naam is 'n noodsaaklike veld."),
    bankAccountHolderName: yup
        .string()
        .trim()
        .max(200, "Die rekeninghour naam moet minder as 200 karakters wees")
        .required("Rekeninghour naam is 'n noodsaaklike veld."),
    bankBranch: yup
        .string()
        .trim()
        .max(50, "Die tak naam of kode moet minder as 50 karakters wees")
        .required("Tak naam of kode is 'n noodsaaklike veld."),
    bankAccountNumber: yup
        .string()
        .trim()
        .max(20, "Die rekening nommer moet minder as 20 karakters wees")
        .required("Rekening nommer is 'n noodsaaklike veld."),
    subscriptionConfirmation: yup
        .string()
        .trim()
        .equals(["Ja, baie dankie"], "Bevestiging is nodig om te kan voortgaan"),
    debitOrderMandate: yup
        .string()
        .trim()
        .equals(
            ["Ja, verseker en ek sien baie uit na my eerste boks!"],
            "Bevestiging is nodig om te kan voortgaan"
        ),
    answer1: yup
        .string()
        .trim()
        .max(50, "Die inhoud vir die veld moet minder as 50 karakters wees")
        .required("Naam is 'n noodsaaklike veld."),
    answer2: yup
        .string()
        .trim()
        .notOneOf(["Kies..."], "Kies asb. Seun of Dogter"),
    answer3: yup
         .string()
         .required("Verjaarsdag is 'n noodsaaklike veld."),
    answer4: yup
        .string()
        .trim()
        .max(50, "Die inhoud vir die veld moet minder as 50 karakters wees")
        .required("Gunsteling kleur is 'n noodsaaklike veld."),
});

export function MembershipForm({ modalOpen, setModalOpen }: Props) {
    const [step, setStep] = useState(1);
    const [submitVisible, setSubmitVisible] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        formState: { errors, isSubmitting },
    } = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {

        //In case user was referred by an agent.
        setValue("referredBy", localStorage.getItem("agentkey") ?? "");

        console.log('setting value');

    }, [setValue]);


    //Store the value when recaptcha is checked.
    function onChange(value) {
        setValue("captcha", value);

        if (value === null) {
            setSubmitVisible(false);
        } else {
            setSubmitVisible(true);
        }
    }

    async function handleSubmitData(data: FieldValues) {
        try {

            //Validation:
            var captcha = getValues("captcha");

            if (captcha === null || captcha === "") {
                toast.error(
                    "The reCaptcha validation must be completed, please confirm the reCaptcha then try again to submit. "
                );
                console.error("captcha not valid", captcha);
                return;
            }

            await agent.Subscriptions.membershipCreate(data);

            //console.log('Submitting the following:',data);

            setStep(2);
        } catch (error) {
            if (error.Captcha) {
                toast.warning(
                    "Captcha validasie was nie suksesvol, probeer asb weer om die validasie te doen en dan die vorm te stuur."
                );
                setSubmitVisible(false);
            }
        }
    }

    return (
        <Dialog
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"md"}
            fullWidth
            sx={{ margin: 5 }}
        >
            <DialogTitle
                sx={{
                    m: 0,
                    p: 2,
                    backgroundColor: (theme) => theme.palette.primary.main,
                }}
            >
                <Typography
                    fontWeight={700}
                    sx={{ color: (theme) => theme.palette.primary.contrastText }}
                >
                    Kleintjies Maandboks - Debietorder Registrasievorm
                </Typography>

                <IconButton
                    aria-label="close"
                    onClick={() => setModalOpen(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.primary.contrastText,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ marginTop: 2 }}>
                {step === 2 && (
                    <Box>
                        <Typography
                            variant={"body1"}
                            color={"textSecondary"}
                            align={"center"}
                        >
                            Baie dankie, jou aansoek is ontvang en ons sal binne 1 - 2 dae in
                            kontak wees.
                        </Typography>
                    </Box>
                )}

                {isSubmitting && step !== 2 && (
                    <Box>
                        <Typography
                            variant={"body1"}
                            color={"textSecondary"}
                            align={"center"}
                            sx={{ paddingBottom: 3 }}
                        >
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="rectangular" height={200} />
                        </Typography>
                    </Box>
                )}

                {!isSubmitting && step !== 2 && (
                    <Box>


                        <Box
                            component={Grid}
                            marginBottom={{ xs: 10, sm: 0 }}
                            container
                            spacing={4}
                        >
                            <Grid item xs={12} sm={12}>
                                <Typography>
                                    Hiermee gee ek, die ondergetekende, toestemming dat my
                                    bankrekening soos gegee in hierdie vorm, elke maand
                                    gedebiteur word deur Klein Bederf met die bedrag van R395.00 (vir die volgende 12 maande):
                                </Typography>

                                <input
                                    type="hidden"
                                    name="membershipTypeId"
                                    value={4}
                                    {...register("membershipTypeId")}
                                />



                                <AppSelectList
                                    control={control}
                                    name="debitOrderMandate"
                                    label=""
                                    defaultValue="Ja, verseker en ek sien baie uit na my eerste boks!"
                                    items={[
                                        "Ja, verseker en ek sien baie uit na my eerste boks!",
                                        "Nee",
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography>Ouer/Voog se besonderhede:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Naam"
                                    variant="outlined"
                                    name={"firstName"}
                                    fullWidth
                                    {...register("firstName")}
                                    error={errors?.firstName != null}
                                    // @ts-ignore
                                    helperText={errors?.firstName?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Van"
                                    variant="outlined"
                                    name={"lastName"}
                                    fullWidth
                                    {...register("lastName")}
                                    error={errors?.lastName != null}
                                    // @ts-ignore
                                    helperText={errors?.lastName?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="E-Posadres"
                                    variant="outlined"
                                    name={"email"}
                                    fullWidth
                                    {...register("email")}
                                    error={errors?.email != null}
                                    // @ts-ignore
                                    helperText={errors?.email?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Telefoonnommer (persoon wat die pakkie ontvang)"
                                    variant="outlined"
                                    name={"phone"}
                                    fullWidth
                                    {...register("phone")}
                                    error={errors?.phone != null}
                                    // @ts-ignore
                                    helperText={errors?.phone?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AppDateTime
                                    control={control}
                                    name="birthday"
                                    label="Wanneer is jou volgende verjaarsdag?"
                                    format="DD MMMM YYYY"
                                />

                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Wat is jou ID nommer?"
                                    variant="outlined"
                                    name={"idNumber"}
                                    fullWidth
                                    {...register("idNumber")}
                                    error={errors?.idNumber != null}
                                    // @ts-ignore
                                    helperText={errors?.idNumber?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography>Ons Klub-Maatjie se besonderhede:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Naam"
                                    variant="outlined"
                                    name={"answer1"}
                                    fullWidth
                                    {...register("answer1")}
                                    error={errors?.answer1 != null}
                                    // @ts-ignore
                                    helperText={errors?.answer1?.message}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <AppSelectList
                                    control={control}
                                    name="answer2"
                                    label="Ons Klub-maatjie is 'n: "
                                    defaultValue="Kies..."
                                    items={["Kies...", "Seun", "Dogter"]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <AppDateTime
                                    control={control}
                                    name="answer3"
                                    label="Geboorte Datum"
                                    format="DD MMMM YYYY"
                                />

                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Gunsteling Kleur"
                                    variant="outlined"
                                    name={"answer4"}
                                    fullWidth
                                    {...register("answer4")}
                                    error={errors?.answer4 != null}
                                    // @ts-ignore
                                    helperText={errors?.answer4?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography>
                                    Adres! Waar moet ons elke maand hierdie Bederfboks vir jou
                                    aflewer? (Onthou asseblief ook die poskode)
                                </Typography>{" "}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Adres Lyn 1"
                                    variant="outlined"
                                    name={"ShippingAddress_Address1"}
                                    fullWidth
                                    {...register("ShippingAddress_Address1")}
                                    error={errors?.ShippingAddress_Address1 != null}
                                    // @ts-ignore
                                    helperText={errors?.ShippingAddress_Address1?.message}
                                />{" "}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Adres Lyn 2 (opsioneel)"
                                    variant="outlined"
                                    name={"ShippingAddress_Address2"}
                                    fullWidth
                                    {...register("ShippingAddress_Address2")}
                                    error={errors?.ShippingAddress_Address2 != null}
                                    // @ts-ignore
                                    helperText={errors?.ShippingAddress_Address2?.message}
                                />{" "}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Dorp/Stad"
                                    variant="outlined"
                                    name={"ShippingAddress_City"}
                                    fullWidth
                                    {...register("ShippingAddress_City")}
                                    error={errors?.ShippingAddress_City != null}
                                    // @ts-ignore
                                    helperText={errors?.ShippingAddress_City?.message}
                                />{" "}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Provinsie"
                                    variant="outlined"
                                    name={"ShippingAddress_Province"}
                                    fullWidth
                                    {...register("ShippingAddress_Province")}
                                    error={errors?.ShippingAddress_Province != null}
                                    // @ts-ignore
                                    helperText={errors?.ShippingAddress_Province?.message}
                                />{" "}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Poskode"
                                    variant="outlined"
                                    name={"ShippingAddress_PostalCode"}
                                    fullWidth
                                    {...register("ShippingAddress_PostalCode")}
                                    error={errors?.ShippingAddress_PostalCode != null}
                                    // @ts-ignore
                                    helperText={errors?.ShippingAddress_PostalCode?.message}
                                />{" "}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Land"
                                    variant="outlined"
                                    name={"ShippingAddress_Country"}
                                    fullWidth
                                    disabled
                                    defaultValue={"South Africa"}
                                    {...register("ShippingAddress_Country")}
                                    error={errors?.ShippingAddress_Country != null}
                                    // @ts-ignore
                                    helperText={errors?.ShippingAddress_Country?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography>
                                    Is daar 'n Klein Agent wat jou verwys of registreer
                                    hiervoor, indien wel, wat is die Agent se e-posadres?{" "}
                                </Typography>
                                <TextField
                                    label=""
                                    variant="outlined"
                                    name={"referredBy"}
                                    fullWidth
                                    {...register("referredBy")}
                                    defaultValue={localStorage.getItem("agentkey") ?? ""}
                                    disabled={
                                        (localStorage.getItem("agentkey") ?? "") === "" ? false : true
                                    }
                                    error={errors?.referredBy != null}
                                    // @ts-ignore
                                    helperText={errors?.referredBy?.message}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Typography>Bank Besonderhede:</Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Banknaam"
                                    variant="outlined"
                                    name={"bankName"}
                                    fullWidth
                                    {...register("bankName")}
                                    error={errors?.bankName != null}
                                    // @ts-ignore
                                    helperText={errors?.bankName?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Naam van Rekeninghouer"
                                    variant="outlined"
                                    name={"bankAccountHolderName"}
                                    fullWidth
                                    {...register("bankAccountHolderName")}
                                    error={errors?.bankAccountHolderName != null}
                                    // @ts-ignore
                                    helperText={errors?.bankAccountHolderName?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Tak Naam of Kode"
                                    variant="outlined"
                                    name={"bankBranch"}
                                    fullWidth
                                    {...register("bankBranch")}
                                    error={errors?.bankBranch != null}
                                    // @ts-ignore
                                    helperText={errors?.bankBranch?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AppSelectList
                                    control={control}
                                    name="bankAccountType"
                                    label="Tipe Rekening"
                                    defaultValue="Tjekrekening"
                                    items={[
                                        "Tjekrekening",
                                        "Spaarrekening",
                                        "Besigheidsrekening",
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Rekeningnommer"
                                    variant="outlined"
                                    name={"bankAccountNumber"}
                                    fullWidth
                                    {...register("bankAccountNumber")}
                                    error={errors?.bankAccountNumber != null}
                                    // @ts-ignore
                                    helperText={errors?.bankAccountNumber?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AppSelectList
                                    control={control}
                                    name="debitOrderDate"
                                    label="Watter datum kies jy vir die Debietorder om af te gaan?"
                                    defaultValue="Die 25ste van elke maand"
                                    items={[
                                        "Die 1ste dag van elke maand",
                                        "Die 25ste van elke maand",
                                        "Die 28ste van elke maand",
                                    ]}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Typography>
                                    Hiermee bevestig ek dat al my inligting soos bo gegee waar
                                    is en gee ek hiermee ook toestemming dat Klein die
                                    debietorder vir die volgende 12 maande aftrek waarvan die
                                    eerste maand s'n binne die volgende dag of 2 sal wees vir my
                                    eerste boks! Deur te registreer, stem jy in tot ons{" "}
                                    <Link
                                        component={LinkR}
                                        color={"primary"}
                                        to={"/privaatheidsbeleid"}
                                        underline={"none"}
                                    >
                                        Privaatheidsbeleid
                                    </Link>{" "}
                                    en{" "}
                                    <Link
                                        component={LinkR}
                                        color={"primary"}
                                        to={"/bepalingsenvoorwaardes"}
                                        underline={"none"}
                                    >
                                        Bepalings en Voorwaardes
                                    </Link>
                                    .
                                </Typography>

                                <AppSelectList
                                    control={control}
                                    name="subscriptionConfirmation"
                                    label=""
                                    defaultValue="Ja, baie dankie"
                                    items={["Ja, baie dankie", "Nee"]}
                                />
                            </Grid>

                            <Grid item xs={1} sm={4}></Grid>
                            <Grid item xs={10} sm={4}>
                                <input
                                    type="hidden"
                                    name={"captcha"}
                                    {...register("captcha")}
                                />

                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item xs={1} sm={4}></Grid>



                        </Box>

                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => setModalOpen(false)}>
                    Keer Terug
                </Button>
                {step !== 2 &&
                    <LoadingButton
                        size={"large"}
                        variant={"contained"}
                        //disabled={!submitVisible}
                        onClick={handleSubmit(handleSubmitData)}
                        loading={isSubmitting}
                        disabled={!submitVisible}
                    >
                        Sluit Aan
                    </LoadingButton>}
            </DialogActions>
        </Dialog>
    );
}
