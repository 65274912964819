import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import Items from "../Cart/Items";
import CartSummary from "views/Cart/CartSummary";
import { useFormContext } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store/configureStore";
import { useEffect } from "react";
import { fetchBasketAsync } from "store/basketSlice";

export default function Review() {

  const dispatch = useAppDispatch();

  const { status, basket } = useAppSelector((state) => state.basket);

  const {
   setError,
   clearErrors,
   trigger,
  } = useFormContext();

  useEffect(() => {
    if(basket.items.filter(x => x.errorStockQuantity).length > 0){
      setError('stock', { type: 'custom', message: 'insufficient stock' });
    }else{
      clearErrors('stock');
      trigger();
      console.log('clearing stock error');
    }
  }, [basket,clearErrors, setError, trigger]);

 
  useEffect(() => {
    dispatch(fetchBasketAsync());

  }, [dispatch]);
  
  if (status.includes("pending")) {
    return (
      <Box sx={{ textAlign: "left" }}>
        <CircularProgress />
        <Typography>Besig om jou mandjie te laai...</Typography>
      </Box>
    );
  }


  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Items />
        </Grid>
        <Grid item xs={12} md={4}>
          <CartSummary />
        </Grid>
      </Grid>
    </Box>
  );
}
