import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Page from "./Page";
import Main from "layouts/Main";
import {
  Divider,
  useTheme,
  Grid,
} from "@mui/material";
import { useAppSelector } from "store/configureStore";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function AgentWelcome() {
  const { user } = useAppSelector((state) => state.account);

  const theme = useTheme();

  return (
    <Main>
      <Page>
        <Box>
          <Typography variant="h6" gutterBottom fontWeight={700}>
            Welkom {user.firstName}!
          </Typography>

          <Box paddingY={4}>
            <Divider />
          </Box>
          <Typography>
            Liefste Agent, Baie welkom by die Klein Bederf familie. Jy sal nog
            sien, hier is die bederf eintlik GROOT!
            <br />
            <br />
            Ek (Hanna) gaan jou op ‘n WhatsApp groep plaas. Op die groepe deel
            ons al ons advertensies en nuutste informasie, daar gaan jy ook
            lekker leer oor ons verskillende produkte en ander agente se
            ervarings.
            <br />
            <br />
            Volg ook asb die skakel na ons agente facebookblad. Gaan kuier gerus
            op die blad en kom so ophoogte van ons nuutste nuus, advertensies en
            kompetisies. Agent facebookblad:{" "}
            <a
              href="https://www.facebook.com/groups/477305612884977/"
              target="_blank"
              rel="noreferrer"
            >
              Facebook Skakel
            </a>
            <br />
            <br />
            Ons agent sitkamer, waar ons agente logo’s, voorbeelde van
            besigheidskaartjies en ander oulike advertensies en hulpmiddels te
            vind is sal binnekort op ons webblad beskikbaar wees onder 'Agent Sitkamer'. Tydelik kan die inligting op ons Dropbox skakel gevind word:
            <a
              href="https://www.dropbox.com/sh/dty96htivtr548a/AADkzKmx4fk7h90lgYeakFcga?dl=0"
              target="_blank"
              rel="noreferrer"
            >
              Dropbox Skakel
            </a>
            <br />
            <br />
            Ons het 'n baie oulike handleiding vir nuwe agente wat jy hierso kan
            aflaai:{" "}
            <a
              href="https://kleinbederf.com/uploads/Agente%20Handleiding.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Handleiding (14MB)
            </a>
            <br />
            <br />
            Geniet die reis saam met ons!
            <br />
            <br />
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
             
                <Box
                  component={LazyLoadImage}
                  width={1}
                  borderRadius={5}
                  src={"/images/besigheidskaart-Chane.jpeg"}
                  alt="..."
                  effect="blur"
                  sx={{
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0.6)"
                        : "none",
                  }}
                />
              
            </Grid>
            <Grid item xs={12} md={6}>
             
                <Box
                  component={LazyLoadImage}
                  width={1}
                  borderRadius={5}
                  src={"/images/besigheidskaart-Hanna.jpg"}
                  alt="..."
                  effect="blur"
                  sx={{
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0.6)"
                        : "none",
                  }}
                />
             
            </Grid>
          </Grid>
        </Box>
      </Page>
    </Main>
  );
}
