import React from 'react';

import {
  BepalingsEnVoorwaardes as BepalingsEnVoorwaardesView,
  Privaatheidsbeleid as PrivaatheidsbeleidView,
  Kontak as KontakView,
  Agente as AgenteView,
  Handelshoek as HandelshoekView,
  Registreer as RegistreerView,
  BevestigRegistrasie as BevestigRegistrasieView,
  TekenIn as TekenInView,
  WagwoordVergeet as WagwoordVergeetView,
  Tuisblad as TuisbladView,

  Checkout as CheckoutView,
  Cart as CartView,
  Wishlist as WishlistView,
  Winkel as WinkelView,
  Produk as ProdukView,

  Fout404 as Fout404View,
  Fout500 as Fout500View
  
} from 'views';
import Groothandelaar from './Groothandelaars/Groothandelaar';
import KatalogusPage from './Katalogus/Katalogus';
import MeerOorKleinBladsy from './MeerOorKlein/MeerOorKlein';
import OnsHandelshoeke from './OnsHandelshoeke/OnsHandelshoeke';
import OnsWinkels from './OnsWinkels/OnsWinkels';
import MembershipBaba from './Membership/Baba/Maandboks';
import MembershipTween from './Membership/Tween/Maandboks';
import MembershipOlies from './Membership/Olies/Maandboks';
import MembershipKleintjies from './Membership/Kleintjies/Maandboks';
import MembershipDames from './Membership/Dames/Maandboks';

const routes = [
 
  {
    path: '/',
    renderer: (params = {}): JSX.Element => <TuisbladView {...params} />,
  },
  {
    path: '/bepalingsenvoorwaardes',
    renderer: (params = {}): JSX.Element => <BepalingsEnVoorwaardesView {...params} />,
  },
  {
    path: '/privaatheidsbeleid',
    renderer: (params = {}): JSX.Element => <PrivaatheidsbeleidView {...params} />,
  },
  {
    path: '/kontak',
    renderer: (params = {}): JSX.Element => <KontakView {...params} />,
  },
  {
    path: '/ons-handelshoeke',
    renderer: (params = {}): JSX.Element => <OnsHandelshoeke {...params} />,
  },
  {
    path: '/ons-winkels',
    renderer: (params = {}): JSX.Element => <OnsWinkels {...params} />,
  },
  {
    path: '/kontak',
    renderer: (params = {}): JSX.Element => <KontakView {...params} />,
  },
  {
    path: '/meeroorklein',
    renderer: (params = {}): JSX.Element => <MeerOorKleinBladsy {...params} />,
  },
  {
    path: '/katalogus',
    renderer: (params = {}): JSX.Element => <KatalogusPage {...params} />,
  },
  {
    path: '/agente',
    renderer: (params = {}): JSX.Element => <AgenteView {...params} />,
  },
  {
    path: '/groothandelaar',
    renderer: (params = {}): JSX.Element => <Groothandelaar {...params} />,
  },
  {
    path: '/handelshoek',
    renderer: (params = {}): JSX.Element => <HandelshoekView {...params} />,
  },
  {
    path: '/maandboks-dames',
    renderer: (params = {}): JSX.Element => <MembershipDames   {...params} />,
  },
  {
    path: '/maandboks-kleintjies',
    renderer: (params = {}): JSX.Element => <MembershipKleintjies {...params} />,
  },
  {
    path: '/maandboks-olies',
    renderer: (params = {}): JSX.Element => <MembershipOlies {...params} />,
  },
  {
    path: '/maandboks-baba',
    renderer: (params = {}): JSX.Element => <MembershipBaba {...params} />,
  },
  {
    path: '/maandboks-tween',
    renderer: (params = {}): JSX.Element => <MembershipTween {...params} />,
  },
  {
    path: '/registreer',
    renderer: (params = {}): JSX.Element => <RegistreerView {...params} />,
  },
  {
    path: '/bevestigregistrasie',
    renderer: (params = {}): JSX.Element => <BevestigRegistrasieView {...params} />,
  },
  {
    path: '/tekenin',
    renderer: (params = {}): JSX.Element => <TekenInView {...params} />,
  },
  {
    path: '/wagwoordvergeet',
    renderer: (params = {}): JSX.Element => <WagwoordVergeetView {...params} />,
  },
  {
    path: '/mandjie',
    renderer: (params = {}): JSX.Element => <CartView {...params} />,
  },
  {
    path: '/wenslys',
    renderer: (params = {}): JSX.Element => <WishlistView {...params} />,
  },
  {
    path: '/bestel',
    renderer: (params = {}): JSX.Element => <CheckoutView {...params} />,
  },
  {
    path: '/winkel',
    renderer: (params = {}): JSX.Element => <WinkelView {...params} />,
  },

  {
    path: '/produk/:slug',
    renderer: (params = {}): JSX.Element => <ProdukView {...params} />,
  },
  {
    path: '/fout404',
    renderer: (params = {}): JSX.Element => <Fout404View {...params} />,
  },
  {
    path: '/fout500',
    renderer: (params = {}): JSX.Element => <Fout500View {...params} />,
  },
];

export default routes;
