import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Page from "./Page";
import Main from "layouts/Main";
import {
  Tabs,
  Tab,
} from "@mui/material";
import React from "react";
import CommissionNetwork from "./Commission_Network";
import CommissionListPage from "./Commission_List";
import CommissionNameListPage from "./Commission_Namelist";
import CommissionPayoutsPage from "./CommissionPayout/Commission_Payouts";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Commission() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Main>
      <Page>
        <Box>
          <Typography variant="h6" gutterBottom fontWeight={700}>
            Kommissie Netwerk
          </Typography>
          {/* <Typography variant={"subtitle2"} color={"text.secondary"}>
            Ons werk nog aan hierdie bladsy, sal hopelik binnekort hiermee klaar wees.
          </Typography> */}
          {/* <Box paddingY={4}>
            <Divider />
          </Box> */}
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Jou Netwerk" {...a11yProps(0)} />
                <Tab label="Naamlys" {...a11yProps(1)} />
                <Tab label="Kommissie Ontvang" {...a11yProps(2)} />
                <Tab label="Balans en Uitbetalings" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <CommissionNetwork/>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CommissionNameListPage/>
             
            </TabPanel>
            <TabPanel value={value} index={2}>
             <CommissionListPage/>
            </TabPanel>
            <TabPanel value={value} index={3}>
             <CommissionPayoutsPage/>
            </TabPanel>
          </Box>
        </Box>
      </Page>
    </Main>
  );
}
