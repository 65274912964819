import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import agent from "../../api/agent";
import { useState } from "react";
import { toast } from "react-toastify";
import AppSelectList from "components/AppSelectList";
import React from "react";
import { LoadingButton } from "@mui/lab";
import { Membership } from "models/membership";
import AppDateTime from "../../components/AppDateTime";

interface Props {
  closeModal: (reload: boolean) => void;
  id: number;
}

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .max(50, "Die inhoud vir die veld moet minder as 50 karakters wees")
    .required("Naam is 'n noodsaaklike veld."),
  lastName: yup
    .string()
    .trim()
    .max(50, "Die inhoud vir die veld moet minder as 50 karakters wees")
    .required("Van is 'n noodsaaklike veld."),
  email: yup
    .string()
    .trim()
    .email("Die eposadres moet geldig wees")
    .required("E-posadres is 'n noodsaaklike veld."),
  // phone: yup
  //   .string()
  //   .trim()
  //   .matches(
  //     /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/,
  //     "Verskaf asb 'n geldige telefoonnommer"
  //   )
  //   .required("Telefoonnommer is 'n noodsaaklike veld."),
  // idNumber: yup
  //   .string()
  //   .trim()
  //   .max(20, "Die ID nommer moet minder as 20 karakters wees")
  //   .required("ID Nommer is 'n noodsaaklike veld."),
  // birthday: yup
  //   .date()
  //   .typeError("Geboorte datum moet geldig wees")
  //   .required("Geboorte datum is 'n noodsaaklike veld."),
  // ShippingAddress_City: yup
  //   .string()
  //   .trim()
  //   .max(50, "Die dorp/stad moet minder as 50 karakters wees")
  //   .required("Dorp/Stad is 'n noodsaaklike veld."),
  // ShippingAddress_PostalCode: yup
  //   .string()
  //   .trim()
  //   .max(10, "Die poskode moet minder as 10 karakters wees")
  //   .required("Poskode is 'n noodsaaklike veld."),
  // ShippingAddress_Province: yup
  //   .string()
  //   .trim()
  //   .max(30, "Die provinsie moet minder as 30 karakters wees")
  //   .required("Provinsie is 'n noodsaaklike veld."),
  // ShippingAddress_Address1: yup
  //   .string()
  //   .trim()
  //   .max(200, "Die adres moet minder as 200 karakters wees")
  //   .required("Adres Lyn 1 is 'n noodsaaklike veld."),
  // bankName: yup
  //   .string()
  //   .trim()
  //   .max(200, "Die bank naam moet minder as 200 karakters wees")
  //   .required("Bank naam is 'n noodsaaklike veld."),
  // bankAccountHolderName: yup
  //   .string()
  //   .trim()
  //   .max(200, "Die rekeninghour naam moet minder as 200 karakters wees")
  //   .required("Rekeninghour naam is 'n noodsaaklike veld."),
  // bankBranch: yup
  //   .string()
  //   .trim()
  //   .max(50, "Die tak naam of kode moet minder as 50 karakters wees")
  //   .required("Tak naam of kode is 'n noodsaaklike veld."),
  // bankAccountNumber: yup
  //   .string()
  //   .trim()
  //   .max(20, "Die rekening nommer moet minder as 20 karakters wees")
  //   .required("Rekening nommer is 'n noodsaaklike veld."),
  // subscriptionConfirmation: yup
  //   .string()
  //   .trim()
  //   .equals(["Ja, baie dankie"], "Bevestiging is nodig om te kan voortgaan"),
  // debitOrderMandate: yup
  //   .string()
  //   .trim()
  //   .equals(
  //     ["Ja, verseker en ek sien baie uit na my eerste boks!"],
  //     "Bevestiging is nodig om te kan voortgaan"
  //   ),
});


export function MembershipForm({ closeModal, id }: Props) {
  const [loading, setLoading] = useState(true);
  const [formdetails, setFormDetails] = useState<Membership>(null);

  React.useEffect(() => {
    let active = true;

    (async () => {
      if (active) {
        agent.Admin.membershipDetails(id)
          .then((c: Membership) => {
            setFormDetails(c);

            //console.log('details from system', c);
          })
          .catch(() => {
            toast.error(
              "Aggenee, die sisteem kon nie die subskripsie besonderhede laai nie, probeer asb weer"
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }
    })();

    return () => {
      active = false;
    };
  }, [id]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  async function handleSubmitData(data: FieldValues) {
    try {
      await agent.Admin.membershipUpdate({ ...data, id: id });

      closeModal(true);

      toast.success("Suksesvol opgedateer");
    } catch (error) {
      //Do nothing, API will raise error message.
    }
  }

  return (
    <Dialog
      open
      onClose={() => closeModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        <Typography
          fontWeight={700}
          sx={{ color: (theme) => theme.palette.primary.contrastText }}
        >
          {" "}
          Verwysing No {id}
        </Typography>

        <IconButton
          aria-label="close"
          onClick={() => closeModal(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ marginTop: 2 }}>
        {(isSubmitting || loading) && (
          <Box sx={{ textAlign: "center", paddingY: 10 }}>
            <CircularProgress color="secondary" />
          </Box>
        )}

        {!isSubmitting && !loading && formdetails && (
          <Box sx={{ width: "100%" }}>
            <Typography fontWeight={800} sx={{ margin: 2 }}>
              Besonderhede:
            </Typography>

            <Box>
              <Typography
                align={"center"}
                color={"text.secondary"}
                sx={{ textTransform: "uppercase", paddingBottom: 2 }}
                variant={"subtitle2"}
                fontWeight={600}
              >
                {formdetails.membershipTypeTitle.toUpperCase()} - DEBIETORDER
                REGISTRASIEVORM
              </Typography>
              <Box
                component={Grid}
                marginBottom={{ xs: 10, sm: 0 }}
                container
                spacing={4}
              >
                <Grid item xs={12} sm={12}>
                  <Typography>
                    Hiermee gee ek, die ondergetekende, toestemming dat my
                    bankrekening soos gegee in hierdie vorm, elke maand
                    gedebiteur word deur Klein Bederf vir die volgende 12
                    maande:
                  </Typography>
                  <AppSelectList
                    control={control}
                    name="debitOrderMandate"
                    label=""
                    defaultValue={formdetails.debitOrderMandate}
                    items={[
                      "Ja, verseker en ek sien baie uit na my eerste boks!",
                      "Nee",
                    ]}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Naam"
                    variant="outlined"
                    name={"firstName"}
                    fullWidth
                    defaultValue={formdetails.firstName}
                    {...register("firstName")}
                    error={errors?.firstName != null}
                    // @ts-ignore
                    helperText={errors?.firstName?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Van"
                    variant="outlined"
                    name={"lastName"}
                    fullWidth
                    defaultValue={formdetails.lastName}
                    {...register("lastName")}
                    error={errors?.lastName != null}
                    // @ts-ignore
                    helperText={errors?.lastName?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="E-Posadres"
                    variant="outlined"
                    name={"email"}
                    fullWidth
                    defaultValue={formdetails.email}
                    {...register("email")}
                    error={errors?.email != null}
                    // @ts-ignore
                    helperText={errors?.email?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Telefoonnommer"
                    variant="outlined"
                    name={"phone"}
                    fullWidth
                    defaultValue={formdetails.phone}
                    {...register("phone")}
                    error={errors?.phone != null}
                    // @ts-ignore
                    helperText={errors?.phone?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Wat is jou ID nommer?"
                    variant="outlined"
                    name={"idNumber"}
                    fullWidth
                    defaultValue={formdetails.idNumber}
                    {...register("idNumber")}
                    error={errors?.idNumber != null}
                    // @ts-ignore
                    helperText={errors?.idNumber?.message}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  {/*<TextField*/}
                  {/*  name="birthday"*/}
                  {/*  label="Wanneer is jou volgende verjaarsdag?"*/}
                  {/*  type="date"*/}

                  {/*  sx={{ width: "100%" }}*/}
                  {/*  InputLabelProps={{*/}
                  {/*    shrink: true,*/}
                  {/*  }}*/}
                  {/*  defaultValue={*/}
                  {/*    new Date(formdetails.birthday)*/}
                  {/*      .toISOString()*/}
                  {/*      .split("T")[0]*/}
                  {/*  }*/}
                  {/*  {...register("birthday")}*/}
                  {/*  error={errors?.birthday != null}*/}
                  {/*  // @ts-ignore*/}
                  {/*  helperText={errors?.birthday?.message}*/}
                  {/*                    />*/}

                  <AppDateTime
                    control={control}
                    name="birthday"
                    label="Wanneer is jou volgende verjaarsdag?"
                    format="DD MMMM YYYY"
                    defaultValue={formdetails.birthday}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography>
                    Adres! Waar moet ons elke maand vir jou aflewer? (Onthou
                    asseblief ook die poskode)
                  </Typography>{" "}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Adres Lyn 1"
                    variant="outlined"
                    name={"ShippingAddress_Address1"}
                    fullWidth
                    defaultValue={formdetails.shippingAddress_Address1}
                    {...register("ShippingAddress_Address1")}
                    error={errors?.ShippingAddress_Address1 != null}
                    // @ts-ignore
                    helperText={errors?.ShippingAddress_Address1?.message}
                  />{" "}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Adres Lyn 2 (opsioneel)"
                    variant="outlined"
                    name={"ShippingAddress_Address2"}
                    fullWidth
                    defaultValue={formdetails.shippingAddress_Address2}
                    {...register("ShippingAddress_Address2")}
                    error={errors?.ShippingAddress_Address2 != null}
                    // @ts-ignore
                    helperText={errors?.ShippingAddress_Address2?.message}
                  />{" "}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Dorp/Stad"
                    variant="outlined"
                    name={"ShippingAddress_City"}
                    fullWidth
                    defaultValue={formdetails.shippingAddress_City}
                    {...register("ShippingAddress_City")}
                    error={errors?.ShippingAddress_City != null}
                    // @ts-ignore
                    helperText={errors?.ShippingAddress_City?.message}
                  />{" "}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Provinsie"
                    variant="outlined"
                    name={"ShippingAddress_Province"}
                    fullWidth
                    defaultValue={formdetails.shippingAddress_Province}
                    {...register("ShippingAddress_Province")}
                    error={errors?.ShippingAddress_Province != null}
                    // @ts-ignore
                    helperText={errors?.ShippingAddress_Province?.message}
                  />{" "}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Poskode"
                    variant="outlined"
                    name={"ShippingAddress_PostalCode"}
                    fullWidth
                    defaultValue={formdetails.shippingAddress_PostalCode}
                    {...register("ShippingAddress_PostalCode")}
                    error={errors?.ShippingAddress_PostalCode != null}
                    // @ts-ignore
                    helperText={errors?.ShippingAddress_PostalCode?.message}
                  />{" "}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <AppSelectList
                    control={control}
                    name="ShippingAddress_Country"
                    label="Land"
                    defaultValue={
                      formdetails.shippingAddress_Country ?? "Suid-Afrika"
                    }
                    items={["Suid-Afrika", "South Africa"]}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography>
                    Is daar 'n Klein Agent wat jou verwys of registreer
                    hiervoor, indien wel, wat is die Agent se e-posadres?{" "}
                  </Typography>
                  <TextField
                    label=""
                    variant="outlined"
                    name={"referredBy"}
                    fullWidth
                    {...register("referredBy")}
                    defaultValue={formdetails.referredBy}
                    error={errors?.referredBy != null}
                    // @ts-ignore
                    helperText={errors?.referredBy?.message}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography>Ander Inligting:</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Antwoord 1"
                    variant="outlined"
                    name={"answer1"}
                    fullWidth
                    defaultValue={formdetails.answer1}
                    {...register("answer1")}
                    error={errors?.answer1 != null}
                    // @ts-ignore
                    helperText={errors?.answer1?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Antwoord 2"
                    variant="outlined"
                    name={"answer2"}
                    fullWidth
                    defaultValue={formdetails.answer2}
                    {...register("answer2")}
                    error={errors?.answer2 != null}
                    // @ts-ignore
                    helperText={errors?.answer2?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Antwoord 3"
                    variant="outlined"
                    name={"answer3"}
                    fullWidth
                    defaultValue={formdetails.answer3}
                    {...register("answer3")}
                    error={errors?.answer3 != null}
                    // @ts-ignore
                    helperText={errors?.answer3?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Antwoord 4"
                    variant="outlined"
                    name={"answer4"}
                    fullWidth
                    defaultValue={formdetails.answer4}
                    {...register("answer4")}
                    error={errors?.answer4 != null}
                    // @ts-ignore
                    helperText={errors?.answer4?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Antwoord 5"
                    variant="outlined"
                    name={"answer5"}
                    fullWidth
                    defaultValue={formdetails.answer5}
                    {...register("answer5")}
                    error={errors?.answer5 != null}
                    // @ts-ignore
                    helperText={errors?.answer5?.message}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Antwoord 6"
                    variant="outlined"
                    name={"answer6"}
                    fullWidth
                    defaultValue={formdetails.answer6}
                    {...register("answer6")}
                    error={errors?.answer6 != null}
                    // @ts-ignore
                    helperText={errors?.answer6?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Antwoord 7"
                    variant="outlined"
                    name={"answer7"}
                    fullWidth
                    defaultValue={formdetails.answer7}
                    {...register("answer7")}
                    error={errors?.answer7 != null}
                    // @ts-ignore
                    helperText={errors?.answer7?.message}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography>Bank Besonderhede:</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Banknaam"
                    variant="outlined"
                    name={"bankName"}
                    fullWidth
                    defaultValue={formdetails.bankName}
                    {...register("bankName")}
                    error={errors?.bankName != null}
                    // @ts-ignore
                    helperText={errors?.bankName?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Naam van Rekeninghouer"
                    variant="outlined"
                    name={"bankAccountHolderName"}
                    fullWidth
                    defaultValue={formdetails.bankAccountHolderName}
                    {...register("bankAccountHolderName")}
                    error={errors?.bankAccountHolderName != null}
                    // @ts-ignore
                    helperText={errors?.bankAccountHolderName?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Tak Naam of Kode"
                    variant="outlined"
                    name={"bankBranch"}
                    fullWidth
                    defaultValue={formdetails.bankBranch}
                    {...register("bankBranch")}
                    error={errors?.bankBranch != null}
                    // @ts-ignore
                    helperText={errors?.bankBranch?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppSelectList
                    control={control}
                    name="bankAccountType"
                    label="Tipe Rekening"
                    defaultValue={formdetails.bankAccountType}
                    items={[
                      "Tjekrekening",
                      "Spaarrekening",
                      "Besigheidsrekening",
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Rekeningnommer"
                    variant="outlined"
                    name={"bankAccountNumber"}
                    fullWidth
                    defaultValue={formdetails.bankAccountNumber}
                    {...register("bankAccountNumber")}
                    error={errors?.bankAccountNumber != null}
                    // @ts-ignore
                    helperText={errors?.bankAccountNumber?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppSelectList
                    control={control}
                    name="debitOrderDate"
                    label="Watter datum kies jy vir die Debietorder om af te gaan?"
                    defaultValue={formdetails.debitOrderDate}
                    items={[
                      "Die 1ste dag van elke maand",
                      "Die 25ste van elke maand",
                      "Die 28ste van elke maand",
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography>
                    Hiermee bevestig ek dat al my inligting soos bo gegee waar
                    is en gee ek hiermee ook toestemming dat Klein die
                    debietorder vir die volgende 12 maande aftrek waarvan die
                    eerste maand s'n binne die volgende dag of 2 sal wees vir my
                    eerste boks!
                  </Typography>
                  <AppSelectList
                    control={control}
                    name="subscriptionConfirmation"
                    label=""
                    defaultValue={formdetails.subscriptionConfirmation}
                    items={["Ja, baie dankie", "Nee"]}
                  />
                </Grid>
              </Box>
            </Box>

            <Divider aria-hidden="true" sx={{ margin: 2 }} />
            <Typography fontWeight={800}>Admin Opsies:</Typography>
            <Box
              component={Grid}
              marginBottom={{ xs: 10, sm: 0 }}
              container
              spacing={4}
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Notas"
                  variant="outlined"
                  name={"notes"}
                  fullWidth
                  defaultValue={formdetails.notes}
                  {...register("notes")}
                  error={errors?.notes != null}
                  // @ts-ignore
                  helperText={errors?.notes?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Maandelikse Koste"
                  variant="outlined"
                  name={"monthlyFeePerItem"}
                  fullWidth
                  type={"number"}
                  defaultValue={formdetails.monthlyFeePerItem}
                  {...register("monthlyFeePerItem")}
                  error={errors?.monthlyFeePerItem != null}
                  // @ts-ignore
                  helperText={errors?.monthlyFeePerItem?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>

              <AppDateTime
                    control={control}
                    name="startDate"
                    label="Aansluiting"
                    format="DD MMMM YYYY"
                    defaultValue={formdetails.startDate}
                  />

                {/* <TextField
                  label="Aansluiting"
                  variant="outlined"
                  name={"startDate"}
                  fullWidth
                  type={"date"}
                  defaultValue={
                    formdetails.startDate === null
                      ? ""
                      : new Date(formdetails.startDate)
                          .toISOString()
                          .split("T")[0]
                  }
                  {...register("startDate")}
                  error={errors?.startDate != null}
                  // @ts-ignore
                  helperText={errors?.startDate?.message}
                /> */}
              </Grid>
              <Grid item xs={12} sm={6}>

              <AppDateTime
                    control={control}
                    name="endDate"
                    label="Subskripsie Eindig"
                    format="DD MMMM YYYY"
                    defaultValue={formdetails.endDate}
                  />

                
                {/* <TextField
                  label="Subskripsie Eindig"
                  variant="outlined"
                  name={"endDate"}
                  fullWidth
                  type={"date"}
                  defaultValue={
                    formdetails.endDate === null
                      ? ""
                      : new Date(formdetails.endDate)
                          .toISOString()
                          .split("T")[0]
                  }
                  {...register("endDate")}
                  error={errors?.endDate != null}
                  // @ts-ignore
                  helperText={errors?.endDate?.message}
                /> */}
              </Grid>

              <Grid item xs={12} sm={12}>
                <AppSelectList
                  control={control}
                  name="status"
                  label="Status"
                  defaultValue={formdetails.status}
                  items={[
                    "Nuwe Aansoek",
                    "Aktief",
                    "Verval",
                    "Gekanselleer",
                    "Debiet Order Fout",
                  ]}
                />
              </Grid>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => closeModal(false)}>
          Keer Terug
        </Button>
        <LoadingButton
          size={"large"}
          variant={"contained"}
          //disabled={!submitVisible}
          onClick={handleSubmit(handleSubmitData)}
          loading={isSubmitting}
          disabled={loading}
        >
          Stoor Veranderinge
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
