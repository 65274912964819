import { Backdrop, Box } from "@mui/material";

interface Props {
    message?: string;
}

export default function LoadingComponent({message = 'Loading...'}: Props) {
    return (
        <Backdrop open={true}  invisible={true}  sx={{ backgroundColor: '#F9F3EC', zIndex: 9999 }}>
            <Box display='flex' justifyContent='center' alignItems='center' height='100vh' width='100vw'>
                {/* <CircularProgress size={100} color='secondary'/>
                <Typography variant='h4' sx={{justifyContent: 'center', position: 'fixed', top: '60%'}}>
                    {message}
                </Typography> */}
                  <Box sx={{textAlign: 'center'}}>
            <img src="/images/Klein-logo.svg" width="200" alt="Klein logo"/>
            <p>{message}</p>
            </Box>
          
            </Box>
        </Backdrop>
    );
}