import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import {Link as LinkR} from 'react-router-dom';
import { ProductCategory } from 'models/product';

interface Props {
  category: ProductCategory
}

const Headline = ({category}: Props): JSX.Element => {
  return (
    <Box
      display={'flex'}
      flexDirection={{ xs: 'column', sm: 'row' }}
      justifyContent={'space-between'}
      alignItems={{ xs: 'flex-start', sm: 'center' }}
    >
      <Typography marginBottom={{ xs: 1, sm: 0 }}>Handeroom</Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="primary" component={LinkR} to="/">
          Tuisblad
        </Link>
        <Link underline="hover" color="primary" component={LinkR} to="/winkel">
          Winkel
        </Link>
        <Link underline="hover" color="primary" component={LinkR} to={`/winkel?kategorie=${category.slug}`}>
        {category.title}
        </Link>
        {/* <Typography color="text.primary">Hand en Lyf Bederf</Typography> */}
      </Breadcrumbs>
    </Box>
  );
};

export default Headline;
