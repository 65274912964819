import { useEffect, useState } from "react";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { Link as LinkR } from "react-router-dom";
import { priceFormatv2 } from "../../../util/util";

import ProductQuickViewDialog from "./ProductQuickViewDialog";

import { useAppDispatch, useAppSelector } from "../../../store/configureStore";
import { productSelectors } from "../../../store/catalogSlice";
import { Skeleton } from "@mui/material";
import { addWishlistItemAsync, removeItemAsync } from "store/wishlistSlice";
import { toast } from "react-toastify";
import { Product } from "models/product";

interface Props {
    type?: string;
}

const Products = ({ type = "winkel" }: Props): JSX.Element => {
    const theme = useTheme();
    const [openId, setOpenId] = useState(null);

    const [products, setProducts] = useState<Product[]>([]);

    const dispatch = useAppDispatch();

    const { loadingProducts, productParams } = useAppSelector(
        (state) => state.catalog
    );
    const { wishlistItems } = useAppSelector((state) => state.wishlist);
    const allproducts = useAppSelector(productSelectors.selectAll);

    useEffect(() => {

        if (type === "winkel") {
            var filteredproducts = allproducts
                .filter(
                    (p) =>
                        productParams.flavours.length === 0 ||
                        p.productVariations.some(
                            (x) =>
                                x.type === "Geur" && productParams.flavours.includes(x.title)
                        )
                )
                .filter(
                    (p) =>
                        productParams.brands.length === 0 ||
                        productParams.brands.includes(p.productBrandId)
                )
                .filter(
                    (p) =>
                        productParams.categories.length === 0 ||
                        productParams.categories.includes(p.productCategoryId)
                )
                .filter((p) =>
                    p.name.toLowerCase().includes(productParams.filterTitle.toLowerCase())
                )
                .filter(
                    (p) =>
                        p.price >= productParams.priceRange[0] &&
                        p.price <= productParams.priceRange[1]
                );

            //               <MenuItem value={'A-Z'}>A-Z</MenuItem>
            // <MenuItem value={'Z-A'}>Z-A</MenuItem>
            // {/* <MenuItem value={''}>Beste Verkoper</MenuItem>
            // <MenuItem value={4}>Beste Resensie</MenuItem> */}
            // <MenuItem value={'PriceLow'}>Prys: laag tot hoog</MenuItem>
            // <MenuItem value={'PriceHigh'}>Prys: hoog tot laag</MenuItem>

            switch (productParams.orderBy) {
                case "A-Z": filteredproducts.sort();
                    break;

                case "Z-A": filteredproducts.sort().reverse();
                    break;

                case "PriceLow": filteredproducts.sort((a, b) => a.price - b.price);
                    break;

                case "PriceHigh": filteredproducts.sort((a, b) => b.price - a.price);
                    break;

                default:
                    break;
            }

            setProducts(filteredproducts);
        }

        if (type === "featured") {
            setProducts(allproducts.filter((x) => x.isFeatured === true));
        }
    }, [allproducts, type, productParams]);

    async function handleAddToWishlist(productId: number) {
        try {
            if (!localStorage.getItem("user")) {
                toast.warning("Teken in om die wenslys funksies te gebruik");
                return;
            }

            if (wishlistItems.filter((x) => x.product.id === productId).length > 0) {
                await dispatch(removeItemAsync({ productId: productId }));
                toast.success("Produk uit wenslys uitgehaal");
            } else {
                await dispatch(addWishlistItemAsync({ productId: productId }));
                toast.success("Produk in wenslys bygevoeg");
            }
        } catch (error) {
            toast.error(error);
        }
    }

    if (loadingProducts)
        return (
            <Grid container spacing={{ xs: 4, md: 2 }}>
                {Array(6)
                    .fill("a")
                    .map((i, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Box display={"block"} width={1} height={1}>
                                <Card
                                    sx={{
                                        width: 1,
                                        height: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        boxShadow: "none",
                                        bgcolor: "transparent",
                                        backgroundImage: "none",
                                    }}
                                >
                                    <Skeleton
                                        sx={{ bgcolor: "#F9F3EC" }}
                                        animation="wave"
                                        variant="rectangular"
                                        height={270}
                                    />

                                    {/* For variant="text", adjust the height via font-size */}
                                    <Skeleton
                                        sx={{ bgcolor: "#F9F3EC", fontSize: "1rem" }}
                                        animation="wave"
                                        variant="text"
                                    />
                                    <Skeleton
                                        sx={{ bgcolor: "#F9F3EC", fontSize: "1rem" }}
                                        animation="wave"
                                        variant="text"
                                    />

                                    <Stack marginTop={2} spacing={1} direction={"row"}>
                                        <Skeleton
                                            sx={{ bgcolor: "#F9F3EC" }}
                                            animation="wave"
                                            variant="rectangular"
                                            width={100}
                                            height={40}
                                        />
                                        <Skeleton
                                            sx={{ bgcolor: "#F9F3EC" }}
                                            animation="wave"
                                            variant="rectangular"
                                            width={100}
                                            height={40}
                                        />
                                    </Stack>
                                    {/* <Skeleton variant="circular" width={40} height={40} /> */}
                                </Card>
                            </Box>
                        </Grid>
                    ))}
            </Grid>
        );

    if (products.length === 0) {
        return (
            <Typography>Geen produkte gevind met die toegepaste filters. </Typography>
        );
    }

    return (
        <Grid container spacing={{ xs: 4, md: 2 }}>
            {products.map((item) => (
                <Grid
                    item
                    xs={type === "featured" ? 12 : 12}
                    sm={type === "featured" ? 4 : 6}
                    md={type === "featured" ? 3 : 4}
                    key={item.id}
                >
                    <Box display={"block"} width={1} height={1}>
                        <Card
                            sx={{
                                width: 1,
                                height: 1,
                                display: "flex",
                                flexDirection: "column",
                                boxShadow: "none",
                                bgcolor: "transparent",
                                backgroundImage: "none",
                            }}
                        >
                            <Box
                                sx={{
                                    position: "relative",
                                }}
                            >
                                <CardMedia
                                    title={item.name}
                                    image={"/uploads/" + item.mainImageUrlThumbnail}
                                    onClick={() => setOpenId(item.id)}
                                    sx={{
                                        position: "relative",
                                        height: 320,
                                        overflow: "hidden",
                                        borderRadius: 2,
                                        cursor: "pointer",
                                        filter:
                                            theme.palette.mode === "dark"
                                                ? "brightness(0.7)"
                                                : "none",
                                    }}
                                ></CardMedia>

                                <Box
                                    padding={2}
                                    bgcolor={"background.paper"}
                                    boxShadow={2}
                                    borderRadius={2}
                                    position={"absolute"}
                                    top={16}
                                    right={16}
                                    display={"flex"}
                                    alignItems={"center"}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => handleAddToWishlist(item.id)}
                                >
                                    <Box
                                        component={"svg"}
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill={
                                            wishlistItems?.filter((x) => x.product.id === item.id)
                                                .length > 0
                                                ? "red"
                                                : "none"
                                        }
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        width={20}
                                        height={20}
                                        color={"text.primary"}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                                        />
                                    </Box>
                                </Box>
                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    sx={{
                                        position: "absolute",
                                        top: "auto",
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        padding: 2,
                                    }}
                                >
                                    {item.onSale && (
                                        <Box
                                            sx={{
                                                bgcolor: theme.palette.error.light,
                                                paddingY: "4px",
                                                paddingX: "8px",
                                                borderRadius: 1,
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography
                                                variant={"caption"}
                                                fontWeight={700}
                                                sx={{
                                                    color: theme.palette.common.white,
                                                    textTransform: "uppercase",
                                                    lineHeight: 1,
                                                }}
                                            >
                                                Promosie
                                            </Typography>
                                        </Box>
                                    )}
                                    {false && (
                                        <Box
                                            sx={{
                                                bgcolor: theme.palette.success.light,
                                                paddingY: "4px",
                                                paddingX: "8px",
                                                borderRadius: 1,
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography
                                                variant={"caption"}
                                                fontWeight={700}
                                                sx={{
                                                    color: theme.palette.common.white,
                                                    textTransform: "uppercase",
                                                    lineHeight: 1,
                                                }}
                                            >
                                                Nuut
                                            </Typography>
                                        </Box>
                                    )}
                                </Stack>
                            </Box>

                            <Box
                                marginTop={2}
                                display={"flex"}
                                alignItems={"left"}
                                justifyContent={"space-between"}
                            >
                                <Typography
                                    fontWeight={700}
                                    noWrap
                                    sx={{ textTransform: "uppercase" }}
                                >
                                    {item.name}
                                </Typography>

                            </Box>
                            <Box
                                marginTop={1}
                                //display={"flex"}
                                textAlign={"right"}
                            //justifyContent={"space-between"}
                            >

                                <Typography fontWeight={500}>
                                    {priceFormatv2(
                                        item, true
                                    )}

                                </Typography>
                            </Box>

                            <Box marginTop={0.5} display={"flex"} alignItems={"center"}>
                                <Box display={"flex"} alignItems={"center"}>
                                    {[1, 2, 3, 4, 5].map((r) => (
                                        <Box
                                            key={r}
                                            component={"svg"}
                                            color={
                                                r <= (item.reviewScore / 10)
                                                    ? theme.palette.secondary.main
                                                    : theme.palette.divider
                                            }
                                            width={16}
                                            height={16}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                        </Box>
                                    ))}
                                </Box>
                                <Typography
                                    variant={"caption"}
                                    color={"text.secondary"}
                                    marginLeft={0.5}
                                >
                                    {item.reviewCount} {item.reviewCount === 1 ? "resensie" : "resensies"}
                                </Typography>

                                
                            </Box>
                            <Box marginTop={0.5} display={"flex"} alignItems={"center"}>
                              
                                {item.showStock &&
                                    <Typography
                                        variant={"caption"}
                                        color={"text.secondary"}
                                        marginLeft={0.5}
                                    >
                                        {item.quantityInStock} in voorraad.
                                    </Typography>}
                            </Box>
                            <Stack marginTop={2} spacing={1} direction={"row"}>
                                <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    size={"large"}
                                    fullWidth
                                    onClick={() => setOpenId(item.id)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        width={20}
                                        height={20}
                                    >
                                        <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                                    </svg>
                                </Button>
                                <Button
                                    color={"primary"}
                                    size={"large"}
                                    fullWidth
                                    sx={{ bgcolor: alpha(theme.palette.primary.light, 0.1) }}
                                    component={LinkR}
                                    to={`/produk/${item.slug}`}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        width={20}
                                        height={20}
                                    >
                                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                        <path
                                            fillRule="evenodd"
                                            d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </Button>
                            </Stack>
                            {/* <Button
                component={Link}
                href={item.href}
                size={'large'}
                sx={{
                  color: theme.palette.text.primary,
                  marginTop: 1,
                  justifyContent: 'space-between',
                }}
                fullWidth
                endIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    width={20}
                    height={20}
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                }
              >
                See the details
              </Button> */}
                        </Card>
                        <ProductQuickViewDialog
                            open={openId === item.id}
                            onClose={() => setOpenId(null)}
                            product={item}
                        />
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};

export default Products;
