import { Divider, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import AppCheckbox from "components/AppCheckbox";
import { useFormContext } from "react-hook-form";
import { Link as LinkR } from "react-router-dom";
import { useAppSelector } from "store/configureStore";
import TextField from "@mui/material/TextField";

export default function CustomerDetailsForm() {
  const { user } = useAppSelector((state) => state.account);

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Typography variant="subtitle1">Persoonlike Inligting</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Naam"
          variant="outlined"
          name={"customerFirstName"}
          defaultValue={user?.firstName ?? ''}
          fullWidth
          {...register("customerFirstName")}
          error={errors?.customerFirstName != null}
          // @ts-ignore
          helperText={errors?.customerFirstName?.message}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
      <TextField
          label="Van"
          variant="outlined"
          name={"customerLastName"}
          defaultValue={user?.lastName ?? ''}
          fullWidth
          {...register("customerLastName")}
          error={errors?.customerLastName != null}
          // @ts-ignore
          helperText={errors?.customerLastName?.message}
        />
       
      </Grid>
      <Grid item xs={12} sm={6}>
      <TextField
          label="Kontak Nommer"
          variant="outlined"
          name={"customerPhoneNumber"}
          defaultValue={user?.contactNumber ?? ''}
          fullWidth
          {...register("customerPhoneNumber")}
          error={errors?.customerPhoneNumber != null}
          // @ts-ignore
          helperText={errors?.customerPhoneNumber?.message}
        />
      
      </Grid>
      <Grid item xs={12} sm={6}>
      <TextField
          label="E-Posadres"
          variant="outlined"
          name={"customerEmail"}
          type='email'
          defaultValue={user?.email ?? ''}
          fullWidth
          {...register("customerEmail")}
          error={errors?.customerEmail != null}
          // @ts-ignore
          helperText={errors?.customerEmail?.message}
        />
      
      </Grid>
      {!user && (
        <Grid item xs={12} sm={12}>
          <Typography variant={"subtitle2"}>
            Is reeds geregistreer?{" "}
            <Link
              component={LinkR}
              color={"primary"}
              to={"/tekenin"}
              underline={"none"}
            >
              Teken in{" "}
            </Link>
            of{" "}
            <Link
              component={LinkR}
              color={"primary"}
              to={"/registreer"}
              underline={"none"}
            >
              registreer.
            </Link>
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="subtitle1">
          Besigheid Inligting (Opsioneel)
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
      <TextField
          label="Besigheid Naam (opsioneel)"
          variant="outlined"
          name={"businessName"}
          defaultValue={user?.businessName ?? ''}
          fullWidth
          {...register("businessName")}
          error={errors?.businessName != null}
          // @ts-ignore
          helperText={errors?.businessName?.message}
        />
       
      </Grid>
      <Grid item xs={12} sm={6}>
      <TextField
          label="Besigheid Registrasie Nommer (opsioneel)"
          variant="outlined"
          name={"businessNumber"}
          defaultValue={user?.businessNumber ?? ''}
          fullWidth
          {...register("businessNumber")}
          error={errors?.businessNumber != null}
          // @ts-ignore
          helperText={errors?.businessNumber?.message}
        />
       
      </Grid>
      <Grid item xs={12} sm={6}>
      <TextField
          label="Besigheid VAT Nommer (opsioneel)"
          variant="outlined"
          name={"businessVatNumber"}
          defaultValue={user?.businessVatNumber ?? ''}
          fullWidth
          {...register("businessVatNumber")}
          error={errors?.businessVatNumber != null}
          // @ts-ignore
          helperText={errors?.businessVatNumber?.message}
        />
       
      </Grid>
      <Grid item xs={12} sm={12}>
        <Divider />
      </Grid>
      {/* <Grid item xs={12} sm={12}>
        <Typography variant="subtitle1">Bepalings en Voorwaardes / Privaatheidsbeleid</Typography>
      </Grid> */}
      <Grid item xs={12} sm={12}>
        <AppCheckbox
          control={control}
          name="acceptTermsAndConditions"
          label="Ek het die Privaatheidsbeleid en Bepalings en Voorwaardes van Klein gelees en aanvaar die inhoud."
          required
        />

        <Typography component="p" variant="body2" align="left" paddingTop={2}>
          Jou persoonlike data sal gebruik word om jou bestelling te verwerk,
          jou ervaring op hierdie webwerf te ondersteun, en vir ander doeleindes
          soos beskryf in ons{" "}
          <Link
            component={LinkR}
            color={"primary"}
            to={"/privaatheidsbeleid"}
            underline={"none"}
          >
            Privaatheidsbeleid
          </Link>{" "}
          en  
          <Link
            component={LinkR}
            color={"primary"}
            to={"/bepalingsenvoorwaardes"}
            underline={"none"}
          >
            Bepalings en Voorwaardes
          </Link>
          .
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}
