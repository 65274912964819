import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
const Footer = (): JSX.Element => {

  return (
    <>
      <Box
        sx={{
          display: { xs: "flex", sm: "none" },
          p: 0,
          m: 0,
          bgcolor: "background.paper",
          borderRadius: 1,
        }}
      >
        <Typography
          align={"center"}
          variant={"subtitle2"}
          color="text.secondary"
          gutterBottom
        >
          &copy; Kopiereg 2024. Klein Bederf (Pty) Ltd. Alle regte voorbehou.
          Foute en weglatings uitgesluit. Weergawe {process.env.REACT_APP_VERSION}. Die webwerf is met
          passie gemaak deur Web App Developers (Pty) Ltd.
        </Typography>
      </Box>

      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          p: 0,
          m: 0,
          bgcolor: "background.paper",
          borderRadius: 1,
        }}
      >
        <Box>
          <Typography
            align={"left"}
            variant={"subtitle2"}
            color="text.secondary"
            gutterBottom
          >
            &copy; 2024 Klein Bederf (Pty) Ltd. Alle regte voorbehou. Foute en weglatings uitgesluit.
          </Typography>
          {/* <Typography
            align={"left"}
            variant={"subtitle2"}
            fontSize="0.8em"
            color="text.secondary"
            gutterBottom
          >
            
          </Typography> */}
        </Box>

        <Box>
          {/* <Typography
            align={"right"}
            variant={"subtitle2"}
            color="text.secondary"
            gutterBottom
          >
           Weergawe {process.env.REACT_APP_VERSION}
          </Typography> */}
          <Typography
            align={"right"}
            variant={"subtitle2"}
            fontSize="0.7em"
            color="text.secondary"
            gutterBottom
          >
             Die webwerf is met passie gemaak deur Web App Developers (Pty) Ltd. (Weergawe {process.env.REACT_APP_VERSION})
          </Typography>
        </Box>

       
      </Box>
    </>
  );
};

export default Footer;
