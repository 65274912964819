import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

interface Props {
    requiresReload: any;
    setRequiresReload: any;
}

export default function AlertDialog({requiresReload, setRequiresReload}: Props) {

  const handleClose = () => {
    window.location.reload(); 
    setRequiresReload(false);
  };


  return (
    <div>
     
      <Dialog
        open={requiresReload}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      ><DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: (theme) => theme.palette.primary.main,
      }}
    >
      <Typography
        fontWeight={700}
        sx={{ color: (theme) => theme.palette.primary.contrastText }}
      >
        Nuwe Weergawe
      </Typography>
    </DialogTitle>
        <DialogContent sx={{ marginTop: 2 }}>
          <DialogContentText id="alert-dialog-description">
            'n nuwe weergawe van ons webwerf is beskikbaar, druk op herlaai om die nuwe weergawe te laai.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
          <Button variant={"contained"} onClick={handleClose} autoFocus>
            Herlaai
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
