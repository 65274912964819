import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Wishlist } from "models/wishlist";
import agent from "../api/agent";

interface WishlistState {
  wishlistItems: Wishlist[] | null;
}

const initialState: WishlistState = {
  wishlistItems: null,
};

//Create async method
export const addWishlistItemAsync = createAsyncThunk<Wishlist[],{productId: number;}>(
  "wishlist/addWishlistItemAsync",
  async ({ productId }, thunkAPI) => {
    try {
      return await agent.Wishlist.addItem(productId);
    } catch (error: any) {
      thunkAPI.rejectWithValue(error);
      throw error;
    }
  },
  {
    condition: () => {
      if (!localStorage.getItem("user")) return false;
    },
  }
);

export const fetchWishlistAsync = createAsyncThunk<Wishlist[]>(
  "wishlist/fetchWishlistAsync",
  async (_, thunkAPI) => {
    try {
      return await agent.Wishlist.list();
    } catch (error: any) {
      thunkAPI.rejectWithValue(error);
    }
  },
  {
    condition: () => {
      if (!localStorage.getItem("user")) return false;
    },
  }
);

export const removeItemAsync = createAsyncThunk<Wishlist[],{ productId: number }>(
  "wishlist/removeWishlistItemAsync",
  async ({ productId }, thunkAPI) => {
    try {
      return await agent.Wishlist.removeItem(productId);
    } catch (error: any) {
      thunkAPI.rejectWithValue(error);
    }
  },
  {
    condition: () => {
      if (!localStorage.getItem("user")) return false;
    },
  }
);

export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addWishlistItemAsync.fulfilled, (state, action) => {
      state.wishlistItems = action.payload;
    });

    builder.addCase(removeItemAsync.fulfilled, (state, action) => {
      state.wishlistItems = action.payload;
    });

    builder.addCase(fetchWishlistAsync.fulfilled, (state, action) => {
      state.wishlistItems = action.payload;
    });

    builder.addCase(addWishlistItemAsync.rejected, (state, action) => {
      throw(action.payload);
    });

    builder.addCase(removeItemAsync.rejected, (state, action) => {
      throw(action.payload);
    });

    builder.addCase(fetchWishlistAsync.rejected, (state, action) => {
      throw(action.payload);
    });
  },
});

//export const {} = wishlistSlice.actions;
