import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";

import Details from "../../Produk/components/Details/Details";
import Image from "../../Produk/components/Image";
import { Product } from "models/product";
import { DialogContent, DialogTitle, IconButton } from "@mui/material";

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClose: () => void;
  open: boolean;
  product: Product;
}

const ProductQuickViewDialog = ({
  onClose,
  open,
  product,
}: Props): JSX.Element => {
  return (
    <Dialog onClose={onClose} open={open} maxWidth={"lg"}>
       <DialogTitle
        sx={{
          m: 0,
          p: 2,
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        <Typography
          fontWeight={700}
          sx={{ color: (theme) => theme.palette.primary.contrastText }}
        >
          {product.name}
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
      <Box paddingY={{ xs: 1, sm: 2 }} paddingX={{ xs: 2, sm: 4 }}>
        
        <Box paddingY={2}>
          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid item xs={12} md={6}>
            <Image
                images={product.productImages}
                title={product.name}
              />
            </Grid>
            <Grid item container xs={12} md={6} alignItems={"center"}>
              <Box width={1}>
                <Details product={product} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProductQuickViewDialog;
