import { useCallback, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Main from "layouts/Main";
import Container from "components/Container";
import { FormatErrors } from "layouts/FormatErrors";
import { Grid, Typography } from "@mui/material";
import { confirmEmailAndSignInUser } from "store/accountSlice";
import { useAppDispatch, useAppSelector } from "store/configureStore";
import { useSearchParams } from "react-router-dom";

const BevestigRegistrasie = (): JSX.Element => {
  
  const [searchParams] = useSearchParams();

  const kode = searchParams.get('kode');
  const epos = searchParams.get('epos');

  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [processing, setProcessing] = useState(true);
  const [registerErrors, setRegisterErrors] = useState<string[]>([]);

  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.account);

  const check = useCallback(async (epos: string, kode: string) => {
    if (
      kode === null ||
      kode === undefined ||
      epos === null ||
      epos === undefined
    ) {
      setRegisterErrors([
        "Skakel is nie korrek, probeer asb weer die skakel wat in die bevestiging epos gestuur was.",
      ]);
      setProcessing(false);
      return;
    }

    try {
      await dispatch(confirmEmailAndSignInUser({ epos: epos, kode: kode }));

      setEmailConfirmed(true);
    } catch (error) {
      const errors: string[] = [];

     
      if (error.error.title) {
        setRegisterErrors([error.error.title]);
      }

      if (error.data && Object.keys(error.data.errors).length > 0) {
        Object.values(error.data.errors)
          .flat()
          .forEach((err: any, i) => {
            errors.push(err);
          });
          setRegisterErrors(errors);
      }
    } finally {
      setProcessing(false);
    }
  },[dispatch]);

  
  useEffect(() => {

    
    
    check(epos, kode);
  }, [check, epos, kode]);

  const theme = useTheme();

  return (
    <Main>
      <Box
        sx={{
          width: 1,
          height: 1,
          overflow: "hidden",
        }}
      >
        <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
          <Box
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            position={"relative"}
          >
            <Box
              width={1}
              order={{ xs: 2, md: 1 }}
              display={"flex"}
              alignItems={"center"}
            >
              <Container>
                {processing && (
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    </Grid>
                    <Grid item container xs={12}>
                      <Box
                        display="flex"
                        flexDirection={{ xs: "column", sm: "row" }}
                        alignItems={{ xs: "stretched", sm: "center" }}
                        justifyContent={"space-between"}
                        width={1}
                        maxWidth={600}
                        margin={"0 auto"}
                      >
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "1rem",
                            width: "90%",
                            paddingRight: 5,
                            marginRight: 5,
                          }}
                        />
                        <Skeleton
                          variant="rectangular"
                          width={210}
                          height={60}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                )}

                {registerErrors.length > 0 && (
                  <FormatErrors listoferrors={registerErrors} />
                )}

                {emailConfirmed && (
                  <Box>
                    <Box marginBottom={4}>
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 700,
                        }}
                      >
                        Eposadres Bevestig
                      </Typography>
                      <Typography color="text.secondary">
                        Wonderlik {user.firstName}! Jou e-posadres is bevestig en jy is ingeteken op ons webblad. Gaan gerus na die Produkte blad om te sien watse oulike produkte ons verkoop. 
                      </Typography>
                      
                    </Box>
                  </Box>
                )}
              </Container>
            </Box>
            <Box
              sx={{
                flex: { xs: "0 0 100%", md: "0 0 50%" },
                position: "relative",
                maxWidth: { xs: "100%", md: "50%" },
                order: { xs: 1, md: 2 },
                minHeight: { xs: "auto", md: "calc(100vh - 58px)" },
              }}
            >
              <Box
                sx={{
                  width: { xs: 1, md: "50vw" },
                  height: "100%",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      overflow: "hidden",
                      left: "0%",
                      width: 1,
                      height: 1,
                      position: { xs: "relative", md: "absolute" },
                      clipPath: {
                        xs: "none",
                        md: "polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)",
                      },
                      shapeOutside: {
                        xs: "none",
                        md: "polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        height: { xs: "auto", md: 1 },
                        "& img": {
                          objectFit: "cover",
                        },
                        "& .lazy-load-image-loaded": {
                          height: 1,
                          width: 1,
                        },
                      }}
                    >
                      <Box
                        component={LazyLoadImage}
                        effect="blur"
                        src={"/images/winkel-hartenbos.jpeg"}
                        height={{ xs: "auto", md: 1 }}
                        maxHeight={{ xs: 300, md: 1 }}
                        width={1}
                        maxWidth={1}
                        sx={{
                          filter:
                            theme.palette.mode === "dark"
                              ? "brightness(0.7)"
                              : "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Main>
  );
};

export default BevestigRegistrasie;
