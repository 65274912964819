import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, Skeleton } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import agent from "../../api/agent";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "store/configureStore";
import { fetchCurrentUser } from "store/accountSlice";
import AppCheckbox from "components/AppCheckbox";
import { Link as LinkR } from "react-router-dom";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, "'n Geldige naam is nodig")
    .max(70, "'n Geldige naam is nodig")
    .required("Naam is 'n noodsaaklike veld. "),
  lastName: yup
    .string()
    .trim()
    .min(2, "'n Geldige van is nodig")
    .max(50, "'n Geldige van is nodig")
    .required("Van is 'n noodsaaklike veld. "),
    addressLine1: yup
    .string()
    .trim()
    .min(2, "'n Geldige adres is nodig")
    .max(200, "'n Geldige adres is nodig")
    .required("Hierdie is 'n noodsaaklike veld. "),
    addressCity: yup
    .string()
    .trim()
    .min(5, "'n Geldige dorp/stad is nodig")
    .max(100, "'n Geldige dorp/stad is nodig")
    .required("Hierdie is 'n noodsaaklike veld. "),
    addressProvince: yup
    .string()
    .trim()
    .min(5, "'n Geldige provinsie is nodig")
    .max(100, "'n Geldige provinsie is nodig")
    .required("Hierdie is 'n noodsaaklike veld. "),
    addressCode: yup
    .string()
    .trim()
    .min(2, "'n Geldige kode is nodig")
    .max(10, "'n Geldige kode is nodig")
    .required("Hierdie is 'n noodsaaklike veld. "),
    addressCountry: yup
    .string()
    .trim()
    .min(2, "'n Geldige land is nodig")
    .max(50, "'n Geldige land is nodig")
    .required("Hierdie is 'n noodsaaklike veld. "),
  email: yup
    .string()
    .trim()
    .email("'n Geldige e-posadres is nodig'")
    .required("E-posadres is 'n noodsaaklike veld. "),
  phone: yup
    .string()
    .trim()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/,
      "Please enter a valid phone number."
    ),
  idNumber: yup
    .string()
    .trim()
    .min(2, "ID Nommer is te kort, 'n Geldige ID Nommer is nodig. ")
    .max(20, "ID Nommer is te lank, 'n Geldige ID Nommer is nodig.")
    .required("ID Nommer is 'n noodsaaklike veld"),
  ageConfirmation: yup
  .boolean()
  .isTrue("Jy moet die veld bevestig om te kan voort gaan met registrasie."),
});

const Form = (): JSX.Element => {
  //const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("Geen Aansoek");

  // const recaptchaRef = React.createRef();

  const { user } = useAppSelector((state) => state.account);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    agent.Admin.formRetailerStatus()
      .then((result: string) => {
        setStatus(result);
        console.log("status", result);
      })
      .catch(() => {
        toast.error("Aggenee, kon nie die status laai nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  }, []);

  async function handleSubmitData(data: FieldValues) {
    try {
      await agent.Forms.formRetailerRegistrationSubmit(data);

      //setStep(2);
      setStatus("Nuwe Aansoek");

      await dispatch(fetchCurrentUser());
    } catch (error) {
      toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
    }
  }

  if (isSubmitting || loading) {
    return (
      <Box>
        <Box marginBottom={7}>
          <Typography
            align={"center"}
            color={"text.secondary"}
            sx={{ textTransform: "uppercase" }}
            variant={"subtitle2"}
            fontWeight={600}
          >
            SLUIT AAN
          </Typography>
          <Typography fontWeight={700} variant={"h4"} align={"center"}>
            Voltooi asseblief die registrasievorm:
          </Typography>
        </Box>
        <Typography
          variant={"body1"}
          color={"textSecondary"}
          align={"center"}
          sx={{ paddingBottom: 3 }}
        >
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="rectangular" height={200} />
        </Typography>
      </Box>
    );
  }

  if (user.roles.includes("Agent") || user.roles.includes("Admin") || user.roles.includes("Bulk") ) {
    return (
      <Box>
        <Box marginBottom={4}>
          <Typography
            align={"center"}
            color={"text.secondary"}
            sx={{ textTransform: "uppercase" }}
            variant={"subtitle2"}
            fontWeight={600}
          >
            Jy is reeds geregistreer as 'n {user.roles?.join(',')}. As jy jou huidige profiel wil verander na 'n Retailer, kontak ons by info@kleinbederf.co.za vir meer inligting en hulp.  Alternatief kan jy uitteken en 'n nuwe Groothandelaar (Retailer) profiel vir jou besigheid skep op die <Link
                      component={LinkR}
                      color={"primary"}
                      to={"/registreer"}
                      underline={"none"}
                    >
                      registrasie 
                    </Link> bladsy.
          </Typography>
        </Box>
      </Box>
    );
  }

  if (status === "Afgehandel: Retailer") {
    return (
      <Box>
        <Box marginBottom={4}>
          <Typography
            align={"center"}
            color={"text.secondary"}
            sx={{ textTransform: "uppercase" }}
            variant={"subtitle2"}
            fontWeight={600}
          >
            Baie dankie! Jou registrasie is reeds afgehandel.
          </Typography>
        </Box>
      </Box>
    );
  }

  if (status === "Nuwe Aansoek: Retailer") {
    return (
      <Box>
        <Box marginBottom={4}>
          <Typography
            align={"center"}
            color={"text.secondary"}
            sx={{ textTransform: "uppercase" }}
            variant={"subtitle2"}
            fontWeight={600}
          >
            Baie dankie! Jou registrasie is ontvang.
          </Typography>
        </Box>
        <Typography
          variant={"body1"}
          color={"textSecondary"}
          align={"center"}
          sx={{ paddingBottom: 3 }}
        >
          Onthou om jou registrasiefooi van R480.00 in ons rekening te betaal
          (jy kry die bedrag weer terug in die vorm van ‘n geskenkbewys), en om
          jou bewys van betaling aan hanna@kleinbederf.co.za te stuur.
          <br />
          <br />
          Ons bank rekening vir EFT betalings: FNB, 250655, Tjek Rekening, 62814053972
          (Gebruik jou naam en van as verwysing)
        </Typography>
      </Box>
    );
  }

  if (status === "Nuwe Aansoek: Agent") {
    return (
      <Box>
        <Box marginBottom={4}>
          <Typography
            align={"center"}
            color={"text.secondary"}
            sx={{ textTransform: "uppercase" }}
            variant={"subtitle2"}
            fontWeight={600}
          >
            Jy het reeds 'n registrasie vir Agent wat in verwerking is. Kontak ons by info@kleinbederf.co.za as jy hulp nodig het. 
          </Typography>
        </Box>
        <Typography
          variant={"body1"}
          color={"textSecondary"}
          align={"center"}
          sx={{ paddingBottom: 3 }}
        >
          Onthou om jou Agent registrasiefooi van R480.00 in ons rekening te betaal
          (jy kry die bedrag weer terug in die vorm van ‘n geskenkbewys), en om
          jou bewys van betaling aan hanna@kleinbederf.co.za te stuur.
          <br />
          <br />
          Ons bank rekening vir EFT betalings: FNB, 250655, Tjek Rekening, 62814053972
          (Gebruik jou naam en van as verwysing)
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box marginBottom={7}>
        <Typography
          align={"center"}
          color={"text.secondary"}
          sx={{ textTransform: "uppercase" }}
          variant={"subtitle2"}
          fontWeight={600}
        >
          SLUIT AAN
        </Typography>
        <Typography fontWeight={700} variant={"h4"} align={"center"}>
          Groothandelaar (Retailer) Registrasievorm
        </Typography>
        <Typography paddingTop={2}>
          Dankie vir jou belangstelling om 'n groothandelaar (retailer) te word {user.firstName}!
          Ons sien sommer klaar daarna uit om jou as deel van ons familie te
          verwelkom. Jy sal nog sien, hier is die bederf eintlik GROOT! <br />
          <br />
          Bevestig asseblief jou besonderhede en voltooi enige uitstaande
          inligting om jou registrasie te voltooi. Enige veranderinge of
          byvoeging van inligting hieronder sal op jou gebruikers profiel
          opgedateer of bygevoeg word.
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <Box
          component={Grid}
          marginBottom={{ xs: 10, sm: 0 }}
          container
          spacing={4}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              label="Naam"
              variant="outlined"
              name={"firstName"}
              fullWidth
              defaultValue={user.firstName ?? ""}
              {...register("firstName")}
              error={errors?.firstName != null}
              // @ts-ignore
              helperText={errors?.firstName?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Van"
              variant="outlined"
              name={"lastName"}
              fullWidth
              defaultValue={user.lastName ?? ""}
              {...register("lastName")}
              error={errors?.lastName != null}
              // @ts-ignore
              helperText={errors?.lastName?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="E-Posadres"
              variant="outlined"
              name={"email"}
              fullWidth
              defaultValue={user.email ?? ""}
              {...register("email")}
              error={errors?.email != null}
              // @ts-ignore
              helperText={errors?.email?.message}
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Selfoonnommer"
              variant="outlined"
              name={"phone"}
              fullWidth
              defaultValue={user.contactNumber ?? ""}
              {...register("phone")}
              error={errors?.phone != null}
              // @ts-ignore
              helperText={errors?.phone?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="ID Nommer"
              variant="outlined"
              name={"idNumber"}
              fullWidth
              defaultValue={user.idNumber ?? ""}
              {...register("idNumber")}
              error={errors?.idNumber != null}
              // @ts-ignore
              helperText={errors?.idNumber?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Besigheid Naam (opsioneel)"
              variant="outlined"
              name={"companyName"}
              fullWidth
              defaultValue={user.businessName ?? ""}
              {...register("companyName")}
              error={errors?.companyName != null}
              // @ts-ignore
              helperText={errors?.companyName?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Besigheid Registrasie No (opsioneel)"
              variant="outlined"
              name={"companyNumber"}
              fullWidth
              defaultValue={user.businessNumber ?? ""}
              {...register("companyNumber")}
              error={errors?.companyNumber != null}
              // @ts-ignore
              helperText={errors?.companyNumber?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Besigheid VAT No (opsioneel)"
              variant="outlined"
              name={"businessVatNumber"}
              fullWidth
              defaultValue={user.businessVatNumber ?? ""}
              {...register("businessVatNumber")}
              error={errors?.businessVatNumber != null}
              // @ts-ignore
              helperText={errors?.businessVatNumber?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Adres Lyn 1"
              variant="outlined"
              name={"addressLine1"}
              fullWidth
              defaultValue={user.addressLine1 ?? ""}
              {...register("addressLine1")}
              error={errors?.addressLine1 != null}
              // @ts-ignore
              helperText={errors?.addressLine1?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Adres Lyn 2"
              variant="outlined"
              name={"addressLine2"}
              fullWidth
              defaultValue={user.addressLine2 ?? ""}
              {...register("addressLine2")}
              error={errors?.addressLine2 != null}
              // @ts-ignore
              helperText={errors?.addressLine2?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Dorp / Stad"
              variant="outlined"
              name={"addressCity"}
              fullWidth
              defaultValue={user.addressCity ?? ""}
              {...register("addressCity")}
              error={errors?.addressCity != null}
              // @ts-ignore
              helperText={errors?.addressCity?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Provinsie"
              variant="outlined"
              name={"addressProvince"}
              fullWidth
              defaultValue={user.addressProvince ?? ""}
              {...register("addressProvince")}
              error={errors?.addressProvince != null}
              // @ts-ignore
              helperText={errors?.addressProvince?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Poskode"
              variant="outlined"
              name={"addressCode"}
              fullWidth
              defaultValue={user.addressCode ?? ""}
              {...register("addressCode")}
              error={errors?.addressCode != null}
              // @ts-ignore
              helperText={errors?.addressCode?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Land"
              variant="outlined"
              name={"addressCountry"}
              fullWidth
              defaultValue={user.addressCountry ?? ""}
              {...register("addressCountry")}
              error={errors?.addressCountry != null}
              // @ts-ignore
              helperText={errors?.addressCountry?.message}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <AppCheckbox
              control={control}
              name="ageConfirmation"
              label="Ek bevestig hiermee dat ek ouer as 18 is. Wanneer jy as agent/retailer aansluit gee jy toestemming dat jou naam, van, en e-posadres gedeel word en sigbaar is aan die ander agente in die kommissie netwerk. "
            />
          </Grid>

          {/* <Grid item xs={1} sm={4}></Grid>
          <Grid item xs={10} sm={4}>
            <input type="hidden" name={"captcha"} {...register("captcha")} />

            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={1} sm={4}></Grid> */}

          <Grid
            item
            container
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Button
              size={"large"}
              variant={"contained"}
              type={"submit"}
              disabled={!isValid}
            >
              Registreer
            </Button>
            {/* <Typography
              variant={"subtitle2"}
              color={"textSecondary"}
              sx={{ marginTop: 2 }}
              align={"center"}
            >
              Ons sal binne 1-2 werksdae in kontak wees.
            </Typography> */}
          </Grid>
          {/* <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item container justifyContent={"center"} xs={12}>
            <Box>
              <Typography component="p" variant="body2" align="left">
                Deur te registreer, stem jy in tot ons{" "}
                <Link
                  component={LinkR}
                  color={"primary"}
                  to={"/privaatheidsbeleid"}
                  underline={"none"}
                >
                  Privaatheidsbeleid
                </Link>{" "}
                en{" "}
                <Link
                  component={LinkR}
                  color={"primary"}
                  to={"/bepalingsenvoorwaardes"}
                  underline={"none"}
                >
                  Bepalings en Voorwaardes
                </Link>
                .
              </Typography>
            </Box>
          </Grid> */}
        </Box>
      </form>
    </Box>
  );
};

export default Form;
