import {useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { setProductParams } from "store/catalogSlice";
import { useAppSelector, useAppDispatch } from "store/configureStore";

const FilterFlavour = (): JSX.Element => {
  const [open, setOpen] = useState(true);

  const { productParams, flavours } = useAppSelector(
    (state) => state.catalog
  );


  const dispatch = useAppDispatch();

  const handleClick = (): void => {
    setOpen(!open);
  };

  const [checkedItems, setCheckedItems] = useState(productParams.flavours || []);

  function handleSelectAll(){
    setCheckedItems([]); 
    dispatch(setProductParams({ flavours: [] }));
  }

  function handleChecked(value: string) {
    const curentIndex = checkedItems.findIndex((item) => item === value);

    let newChecked: string[] = [];
    if (curentIndex === -1) newChecked = [...checkedItems, value];
    else newChecked = checkedItems.filter((item) => item !== value);
    setCheckedItems(newChecked);
    dispatch(setProductParams({ flavours: newChecked }));

  }


  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ cursor: "pointer" }}
        marginBottom={1}
        onClick={() => handleClick()}
      >
        <Typography fontWeight={700}>Geur</Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          marginBottom={1}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0 !important",
            },
            "& .MuiOutlinedInput-input": {
              padding: 1,
            },
          }}
        >
        
        </Box>
        <Stack spacing={1}>
        <FormControlLabel
                sx={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    color="primary"
                    checked={productParams.flavours.length === 0}
                    onChange={handleSelectAll}
                    sx={{
                      padding: 0,
                      marginRight: 1,
                    }}
                  />
                }
                label={'Alles'}
              />

          {flavours.map((flavour, i) => (
            <Box key={i}>
              <FormControlLabel
                sx={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      productParams.flavours.indexOf(flavour) !== -1
                    }
                    onChange={() => handleChecked(flavour)}
                    sx={{
                      padding: 0,
                      marginRight: 1,
                    }}
                  />
                }
                label={flavour}
              />
            </Box>
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
};

export default FilterFlavour;
