import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import agent from "api/agent";
import { toast } from "react-toastify";

const validationSchema = yup.object({
  comments: yup
    .string()
    .min(10, "Maak boodskap 'n bietjie langer asb.")
    .max(500, "Maak boodskap 'n bietjie korter asb.")
    .required("Boodskap is noodsaaklik."),
});

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClose: () => void;
  open: boolean;
  productid: number;
}

const FeedbackForm = ({ onClose, open, productid }: Props): JSX.Element => {
  const theme = useTheme();
  const [currentScore, setCurrentScore] = useState(5);
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  async function handleSubmitData(data: FieldValues) {
    try {

      await agent.Reviews.reviewCreate({productId: productid, comments: data.comments, rating: currentScore * 10});

      toast.success("Baie dankie, resensie suksesvol ontvang.");

      onClose();

      setSubmittedSuccessfully(true);

    } catch (error) {
      toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
    }
  }

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth={"sm"}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 2,
        },
      }}
    >
      <Box paddingY={2} paddingX={4}>
        <Box paddingY={2} display={"flex"} justifyContent={"space-between"}>
          <Typography variant={"h5"} fontWeight={700}>
            Skryf 'n Resensie
          </Typography>
          <Box
            component={"svg"}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={24}
            height={24}
            onClick={onClose}
            sx={{ cursor: "pointer" }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </Box>
        </Box>

        {!submittedSuccessfully &&
        <Box paddingY={2}>
          <form onSubmit={handleSubmit(handleSubmitData)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant={"subtitle2"} sx={{ marginBottom: 1 }}>
                  Punte
                </Typography>
                <Box display={"flex"} alignItems={"center"}>
                  {[1, 2, 3, 4, 5].map((r) => (
                    <Box
                      key={r}
                      component={"svg"}
                      color={
                        r <= currentScore
                          ? theme.palette.primary.main
                          : theme.palette.divider
                      }
                      width={28}
                      height={28}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      sx={{ cursor: "pointer" }}
                      onClick={() => setCurrentScore(r)}
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"subtitle2"} sx={{ marginBottom: 1 }}>
                  Beskrywing
                </Typography>
                <TextField
                  variant="outlined"
                  name={"comments"}
                  fullWidth
                  multiline
                  rows={5}
                  {...register("comments")}
                  error={errors?.comments != null}
                  // @ts-ignore
                  helperText={errors?.comments?.message}
                />
              </Grid>
              <Grid item container xs={12}>
                <LoadingButton
                  size={"large"}
                  variant={"contained"}
                  type={"submit"}
                  loading={isSubmitting}
                >
                  Stuur
                </LoadingButton>
               
              </Grid>
              <Grid item container xs={12}>
              <Typography
              variant={"subtitle2"}
              color={"textSecondary"}
              sx={{ marginTop: 2, marginBottom: 5 }}
              align={"center"}
            >
              NB. Ons sal die resensie nagaan voor dit gepubliseer word. 
            </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
}

    {submittedSuccessfully &&
    <Typography
    variant={"subtitle2"}
    color={"textSecondary"}
    sx={{ marginTop: 2, marginBottom: 5 }}
    align={"center"}
  >
   Baie dankie, ons het jou resensie vir die produk ontvang.
  </Typography>
    }

      </Box>
    </Dialog>
  );
};

export default FeedbackForm;
