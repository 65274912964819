import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useAppSelector, useAppDispatch } from "store/configureStore";
import { setProductParams } from "store/catalogSlice";
import { useSearchParams } from "react-router-dom";

export default function FilterCategory() {
  const [open, setOpen] = useState(true);

  const [searchParams] = useSearchParams();
  const kategorieParams = searchParams.get("kategorie");

  const { productParams, categories, filtersLoaded } = useAppSelector(
    (state) => state.catalog
  );

  const dispatch = useAppDispatch();

  const handleClick = (): void => {
    setOpen(!open);
  };

  const [checkedItems, setCheckedItems] = useState(
    productParams.categories || []
  );

  function handleChecked(value: number) {
    const curentIndex = checkedItems.findIndex((item) => item === value);

    let newChecked: number[] = [];
    if (curentIndex === -1) newChecked = [...checkedItems, value];
    else newChecked = checkedItems.filter((item) => item !== value);
    setCheckedItems(newChecked);
    dispatch(setProductParams({ categories: newChecked }));
    //dispatch(fetchProductsAsync());
  }

  useEffect(() => {
    if (filtersLoaded && kategorieParams) {
      try {
        if (kategorieParams === "alles") {
          setCheckedItems([]);
          dispatch(setProductParams({ categories: [] }));
        } else {
          var catId = categories.find((x) => x.slug === kategorieParams).id;

          setCheckedItems([catId]);
          dispatch(setProductParams({ categories: [catId] }));
        }
        // dispatch(fetchProductsAsync());
      } catch (error) {
        console.error(error);
      }
    }
  }, [kategorieParams, categories, dispatch, filtersLoaded]);

  function handleSelectAll() {
    setCheckedItems([]);
    dispatch(setProductParams({ categories: [] }));
  }

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ cursor: "pointer" }}
        marginBottom={1}
        onClick={() => handleClick()}
      >
        <Typography fontWeight={700}>Kategoriee</Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Stack spacing={1}>
          <FormControlLabel
            sx={{ marginLeft: 0 }}
            control={
              <Checkbox
                color="primary"
                checked={productParams.categories.length === 0}
                onChange={handleSelectAll}
                sx={{
                  padding: 0,
                  marginRight: 1,
                }}
              />
            }
            label={"Alles"}
          />
          {categories.map((category) => (
            <Box key={category.id}>
              <FormControlLabel
                sx={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      productParams.categories.indexOf(category.id) !== -1
                    }
                    onChange={() => handleChecked(category.id)}
                    sx={{
                      padding: 0,
                      marginRight: 1,
                    }}
                  />
                }
                label={category.title}
              />
            </Box>
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
}
