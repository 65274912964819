import { useScrollTrigger, Fade, Box, Fab } from "@mui/material";

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export function ScrollToTop() {

    const trigger = useScrollTrigger({
      target: window ? window : undefined,
      disableHysteresis: true,
      threshold: 100,
    });
  
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      const anchor = (
        (event.target as HTMLDivElement).ownerDocument || document
      ).querySelector('#back-to-top-anchor');
  
      if (anchor) {
        anchor.scrollIntoView({
          block: 'center',
        });
      }
    };
  
    return (
      <Fade in={trigger}>
        <Box
          onClick={handleClick}
          role="presentation"
          sx={{ position: 'fixed', bottom: 65, right: 30 }}
        >
           <Fab size="small" aria-label="scroll back to top" sx={{backgroundColor: '#75b784', color: 'white'}}>
            <KeyboardArrowUpIcon />
          </Fab>
        </Box>
      </Fade>
    );
  }
  
  