import FixedLayout from "layouts/Fixed";
import {
  Typography,
  Box,
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  Tabs,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddTaskIcon from "@mui/icons-material/AddTask";
import UndoIcon from "@mui/icons-material/Undo";
import {
  DataGrid,
  GridRowsProp,
  GridColumns,
  GridActionsCellItem,
  GridRowId,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { FormRetailer } from "models/forms";
import React from "react";

export default function GroothandelaarForm() {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);
  const [openDeleteConfirmationId, setOpenDeleteConfirmationId] =
    React.useState<GridRowId>("0");
  const [openDetailsModal, setOpenDetailsModal] = React.useState(false);
  const [openDetailsModalRow, setOpenDetailsModalRow] = React.useState<any>({});
  const [tabStatus, setTabStatus] = React.useState(0);

  const handleStatusChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabStatus(newValue);

  };

  useEffect(() => {
    let filter = "";

    switch (tabStatus) {
      case 0:
        filter = "Nuwe Aansoek";
        break;
      case 1:
        filter = "Afgehandel";
        break;
    }

    setLoading(true);

    agent.Admin.formRetailerList(filter)
      .then((c: FormRetailer[]) => {
      
        const submissions: GridRowsProp = c.map((s) => ({
          id: s.id,
          firstName: s.firstName,
          lastName: s.lastName,
          email: s.email,
          phone: s.phone,
          companyName: s.companyName,
          companyNumber: s.companyNumber,
          addressLine1: s.addressLine1,
          addressLine2: s.addressLine2,
          addressCity: s.addressCity,
          addressCode: s.addressCode,
          addressCountry: s.addressCountry,
          message: s.message,
          createDate: s.createDate,
          processed: s.processed,
          referredBy: s.referredBy,
        }));
        setRows(submissions);
      })
      .catch((error) => {
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
        console.log(error);
      })
      .finally(() => setLoading(false));
  }, [tabStatus]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    setOpenDeleteConfirmation(true);
    setOpenDeleteConfirmationId(id);
  };

  const handleDeleteClickConfirmed = () => {
    setLoading(true);

    setOpenDeleteConfirmation(false);
    const id = openDeleteConfirmationId;

    agent.Admin.formRetailerDelete(parseInt(id.toString()))
      .then((c) => setRows(rows.filter((row) => row.id !== id)))
      .catch((error) => {
        toast.warning("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  };

  const handleOpenClick = (id: GridRowId) => () => {
    setOpenDetailsModal(true);
    setOpenDetailsModalRow(rows.find((row) => row.id === id));
  };

  const handleMarkAsProcessedClick = (id: GridRowId) => () => {
    setLoading(true);

    agent.Admin.formRetailerMarkAsProcessed(parseInt(id.toString()))
      .then((c) => {
        const newrows = rows.filter((row) => row.id !== id);
        setRows(newrows);
      })
      .catch((error) => {
        toast.warning("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  };

  const columns: GridColumns = [
    { field: "id", headerName: "#", width: 50 },
    {
      field: "name",
      headerName: "Naam",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
      },
    },

    { field: "email", headerName: "E-Posadres", flex: 1 },
    { field: "phone", headerName: "Kontak Nommer", flex: 1 },
    { field: "companyName", headerName: "Besigheid Naam", flex: 1 },
    { field: "referredBy", headerName: "Verwys Deur", flex: 1 },
    {
      field: "createDate",
      headerName: "Datum",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }

        const date = new Date(params.value);

        const valueFormatted = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        });
        return valueFormatted;
      },
    },
    {
      field: "processed",
      headerName: "Status",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Boolean>) => {
        if (params.value === true) {
          return (
            <Chip
              label="Afgehandel"
              color="success"
              variant="outlined"
              size="small"
            />
          );
        } else {
          return (
            <Chip
              label="Nuwe Aansoek"
              color="error"
              variant="outlined"
              size="small"
            />
          );
        }
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Opsies",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={
              rows.find((x) => x.id === id)?.processed ? (
                <UndoIcon />
              ) : (
                <AddTaskIcon />
              )
            }
            label={
              rows.find((x) => x.id === id)?.processed
                ? "Merk as Afgehandel"
                : "Merk as Nuwe Aansoek"
            }
            onClick={handleMarkAsProcessedClick(id)}
            disabled={loading}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="Read"
            onClick={handleOpenClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
            showInMenu
          />,
        ];
      },
    },
  ];

  return (
    <FixedLayout>
      <Box
        width={1}
        margin={"0 auto"}
        paddingX={2}
        paddingY={{ xs: 2, sm: 2, md: 2 }}
      >
        <div>
          <Dialog
            open={openDetailsModal}
            onClose={() => setOpenDetailsModal(false)}
          >
            <Box sx={{ width: 500 }}></Box>
            <DialogTitle id="alert-dialog-title">
              {"Vorm Inligting"}
            </DialogTitle>
            <DialogContent>
              <Typography sx={{ mt: 2 }}>
                <strong>Naam: </strong> <br />
                {openDetailsModalRow?.firstName ?? ""}
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <strong>Van: </strong> <br />
                {openDetailsModalRow?.lastName ?? ""}
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <strong>E-Posadres: </strong> <br />
                {openDetailsModalRow?.email ?? ""}
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <strong>Kontak Nommer: </strong> <br />
                {openDetailsModalRow?.phone ?? ""}
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <strong>Besigheid Naam: </strong> <br />
                {openDetailsModalRow?.companyName ?? ""}
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <strong>Besigheid Nommer: </strong> <br />
                {openDetailsModalRow?.companyNumber ?? ""}
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <strong>Besigheid VAT Nommer: </strong> <br />
                {openDetailsModalRow?.businessVATNumber ?? ""}
              </Typography>

              <Typography sx={{ mt: 2 }}>
                <strong>Adres: </strong> <br />
                {openDetailsModalRow?.addressLine1 ?? ""} <br />
                {openDetailsModalRow?.addressLine2 ?? ""} <br />
                {openDetailsModalRow?.addressCity ?? ""} <br />
                {openDetailsModalRow?.addressProvince ?? ""} <br />
                {openDetailsModalRow?.addressCode ?? ""} <br />
                {openDetailsModalRow?.addressCountry ?? ""}
              </Typography>
             
              <Typography sx={{ mt: 2 }}>
                <strong>Datum: </strong>
                <br />{" "}
                {openDetailsModalRow?.createDate &&
                  new Date(openDetailsModalRow?.createDate).toLocaleDateString(
                    "en-ZA",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    }
                  )}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDetailsModal(false)}>
                Gaan Terug
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div>
          <Dialog
            open={openDeleteConfirmation}
            onClose={() => setOpenDeleteConfirmation(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Bevestig"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Is jy seker jy wil die aansoek verwyder?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDeleteConfirmation(false)}>
                Gaan Terug
              </Button>
              <Button
                color="error"
                onClick={handleDeleteClickConfirmed}
                autoFocus
              >
                Verwyder
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ p: 2 }} variant="h5">
            Groothandelaar Aansoeke
          </Typography>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", padding: 1 }}>
            <Tabs
              value={tabStatus}
              onChange={handleStatusChange}
              aria-label="tabs"
            >
              <Tab label="Nuwe Aansoek" id={"Nuwe Aansoek"} />
              <Tab label="Afgehandel" id={"Afgehandel"} />
            </Tabs>
          </Box>
        </Box>

        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
              LoadingOverlay: LinearProgress,
            }}
            loading={loading}
          />
        </Box>
      </Box>
    </FixedLayout>
  );
}
