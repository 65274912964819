import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Main from "layouts/Main";
import Container from "components/Container";
import {
  About,
  Handelshoeke,
  Hero,
  Newsletter,
  PromoGrid,
} from "./components";
import { Typography } from "@mui/material";
import MeerOorKlein from "./components/MeerOorKlein";
import Products from "views/Winkel/Products";
import { useAppSelector } from "store/configureStore";
import { productSelectors } from "store/catalogSlice";

const Tuisblad = (): JSX.Element => {

  const allproducts = useAppSelector(productSelectors.selectAll);

  const theme = useTheme();
  return (
    <Main>
      <Box
        sx={{
          backgroundColor: theme.palette.alternate.main,
          backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
          position: "relative",
          marginTop: -13,
          paddingTop: 13,
        }}
      >
        <Container position={"relative"} zIndex={3} sx={{ marginTop: -3 }}>
          <Hero />
          <br />
          <About />
        </Container>
        {/* <Box
          component={"svg"}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1921 273"
          sx={{
            position: "absolute",
            width: "100%",
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 1,
            height: "35%",
          }}
        >
          <polygon
            fill={theme.palette.background.paper}
            points="0,273 1921,273 1921,0 "
          />
        </Box> */}
      </Box>

      <Container>
        <Container maxWidth="md">
          <Box marginBottom={3}>
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              sx={{ paddingBottom: 2, textAlign: "center" }}
            >
              Klein is ‘n jare lange droom wat waar geword het! Dit was reeds
              van ‘n baie jong ouderdom af Chané se passie om mense te bederf,
              hetsy met haar eie skeppings of ‘n mooi opgemaakte geskenk!
            </Typography>

            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              sx={{ paddingBottom: 2, textAlign: "center" }}
            >
              Die liefde waarmee elke Klein produk gemaak word is duidelik
              sigbaar en daar is niks lekkerder as om goeie terugvoer van ons
              kosbare kliënte te kry nie.
            </Typography>
          </Box>
        </Container>
      </Container>

      <Box bgcolor={"alternate.main"}>
        <Container>
          <PromoGrid />
        </Container>
      </Box>

      {allproducts.filter(x => x.isFeatured === true).length > 0? 
      <Container>
        <Box marginBottom={4}>
          <Typography variant={"h5"} fontWeight={700}>
            Voorskou Produkte
          </Typography>
        </Box>
        <Products type={"featured"} />
      </Container>
      : ""}

      <Box
        sx={{
          backgroundImage: `linear-gradient(180deg, ${theme.palette.alternate.main} 50%, ${theme.palette.background.paper} 0%)`,
        }}
      >
        <Container>
          <MeerOorKlein />
        </Container>
      </Box>
      <Container>
        <Handelshoeke />
      </Container>
      <Box bgcolor={"alternate.main"} marginTop={5}>
        <Container>
          <Newsletter />
        </Container>
      </Box>
    </Main>
  );
};

export default Tuisblad;
