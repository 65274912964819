import FixedLayout from "layouts/Fixed";
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  Tabs,
  IconButton,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  DataGrid,
  GridRowsProp,
  GridColumns,
  GridActionsCellItem,
  GridRowId,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import React from "react";
import { Membership } from "models/membership";
import { MembershipForm } from "./MembershipForm";

export default function SubscriptionsDamesbokse() {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);
  const [openDeleteConfirmationId, setOpenDeleteConfirmationId] =
    React.useState<GridRowId>("0");
  const [openDetailsModal, setOpenDetailsModal] = React.useState(false);
  const [openDetailsModalId, setOpenDetailsModalId] = React.useState<number>(0);
  const [tabStatus, setTabStatus] = React.useState(0);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const handleStatusChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabStatus(newValue);

    let filter = "";

    switch (newValue) {
      case 0:
        filter = "Nuwe Aansoek";
        break;
      case 1:
        filter = "Aktief";
        break;
      case 2:
        filter = "Verval";
        break;
      case 3:
        filter = "Gekanselleer";
        break;
        case 4:
          filter = "Debiet Order Fout";
          break;
        case 5:
            filter = "Alles";
            break;
    }

    //Load from database filtered values
    setLoading(true);

    agent.Admin.membershipList(filter, 0)
      .then((c: Membership[]) => {
        const memberships: GridRowsProp = c.map((s) => s);
        setRows(memberships);
      })
      .catch((error) => {
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  };

  function closeModal(reload: Boolean) {
    setOpenDetailsModal(false);

    if (reload) {
      let filter = "";

      switch (tabStatus) {
        case 0:
          filter = "Nuwe Aansoek";
          break;
        case 1:
          filter = "Aktief";
          break;
        case 2:
          filter = "Verval";
          break;
        case 3:
          filter = "Gekanselleer";
          break;
          case 4:
            filter = "Debiet Order Fout";
            break;
          case 5:
              filter = "Alles";
              break;
      }

      setLoading(true);

      agent.Admin.membershipList(filter, 0)
        .then((c: Membership[]) => {
          const memberships: GridRowsProp = c.map((s) => s);
          setRows(memberships);
        })
        .catch((error) => {
          toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
        })
        .finally(() => setLoading(false));
    }
  }

  useEffect(() => {
    agent.Admin.membershipList("Nuwe Aansoek",0)
      .then((c: Membership[]) => {
        const memberships: GridRowsProp = c.map((s) => s);
        setRows(memberships);
      })
      .catch((error) => {
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  }, []);

  const handleDeleteClick = (id: GridRowId) => () => {
    setOpenDeleteConfirmation(true);
    setOpenDeleteConfirmationId(id);
  };

  const handleDeleteClickConfirmed = () => {
    setLoading(true);

    setOpenDeleteConfirmation(false);
    const id = openDeleteConfirmationId;

    agent.Admin.membershipDelete(parseInt(id.toString()))
      .then((c) => setRows(rows.filter((row) => row.id !== id)))
      .catch((error) => {
        toast.warning("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  };

  const handleOpenClick = (id: GridRowId) => () => {
    setOpenDetailsModal(true);
    setOpenDetailsModalId(parseInt(id.toString()));
  };

  const columns: GridColumns = [
    { field: "id", headerName: "#", width: 80 },
    { field: "membershipTypeTitle", headerName: "Tiepe", width: 80 },
    {
      field: "Naam",
      headerName: "Naam",
      width: 200,
      valueGetter: (params) => {
        return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
      },
    },
    { field: "notes", headerName: "Notas", width: 200 },
    { field: "email", headerName: "E-Posadres", width: 200 },
      { field: "phone", headerName: "Kontak Nommer", width: 150 },
      { field: "birthday", headerName: "Verjaarsdag", width: 250 },
    //{
    //  field: "birthday",
    //  headerName: "Verjaarsdag",
    //  width: 150,
    //  valueFormatter: (params: GridValueFormatterParams<Date>) => {
    //    if (params.value == null) {
    //      return "";
    //    }

    //    const date = new Date(params.value);

    //    const valueFormatted = date.toLocaleDateString("en-ZA", {
    //      year: "numeric",
    //      month: "short",
    //      day: "numeric",
    //    });
    //    return valueFormatted;
    //  },
    //},
    { field: "idNumber", headerName: "ID Nommer", width: 150 },
      { field: "monthlyFeeTotal", headerName: "Bedrag", width: 100 },
      { field: "startDate", headerName: "Aangesluit", width: 250 },
      { field: "endDate", headerName: "Subskripsie Eindig", width: 250 },
    //{
    //  field: "startDate",
    //  headerName: "Aangesluit",
    //  width: 150,
    //  valueFormatter: (params: GridValueFormatterParams<Date>) => {
    //    if (params.value == null) {
    //      return "";
    //    }

    //    const date = new Date(params.value);

    //    const valueFormatted = date.toLocaleDateString("en-ZA", {
    //      year: "numeric",
    //      month: "short",
    //      day: "numeric",
    //    });
    //    return valueFormatted;
    //  },
    //},
    //{
    //  field: "endDate",
    //  headerName: "Subskripsie Eindig",
    //  width: 150,
    //  valueFormatter: (params: GridValueFormatterParams<Date>) => {
    //    if (params.value == null) {
    //      return "";
    //    }

    //    const date = new Date(params.value);

    //    const valueFormatted = date.toLocaleDateString("en-ZA", {
    //      year: "numeric",
    //      month: "short",
    //      day: "numeric",
    //    });
    //    return valueFormatted;
    //  },
    //},
    {
      field: "shippingAddress_Address1",
      headerName: "Adres Lyn 1",
      width: 200,
    },
    {
      field: "shippingAddress_Address2",
      headerName: "Adres Lyn 2",
      width: 200,
    },
    {
      field: "shippingAddress_City",
      headerName: "Adres Dorp/Stad",
      width: 200,
    },
    {
      field: "shippingAddress_Province",
      headerName: "Adres Provinsie",
      width: 200,
    },
    {
      field: "shippingAddress_PostalCode",
      headerName: "Adres Kode",
      width: 200,
    },
    { field: "shippingAddress_Country", headerName: "Adres Land", width: 200 },
    { field: "bankName", headerName: "Bank Naam", width: 200 },
    {
      field: "bankAccountHolderName",
      headerName: "Bank Rekeninghouer",
      width: 200,
    },
    { field: "bankBranch", headerName: "Bank Tak", width: 200 },
    { field: "bankAccountType", headerName: "Bank Rekening Tiepe", width: 200 },
    { field: "bankAccountNumber", headerName: "Bank Rekening No", width: 200 },
    { field: "debitOrderDate", headerName: "Debit Order Datum", width: 200 },
    { field: "referredBy", headerName: "Verwys Deur", width: 200 },
    {
      field: "actions",
      type: "actions",
      headerName: "Opsies",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="Read"
            onClick={handleOpenClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <FixedLayout>
      <Box
        width={1}
        margin={"0 auto"}
        paddingX={2}
        paddingY={{ xs: 2, sm: 2, md: 2 }}
      >
        <div>
          {openDetailsModal && (
            <MembershipForm closeModal={closeModal} id={openDetailsModalId} />
          )}
        </div>

        <div>
          <Dialog
            open={openDeleteConfirmation}
            onClose={() => setOpenDeleteConfirmation(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              sx={{
                m: 0,
                p: 2,
                backgroundColor: (theme) => theme.palette.error.main,
              }}
            >
              <Typography
                fontWeight={700}
                sx={{ color: (theme) => theme.palette.primary.contrastText }}
              >
                {" "}
                Bevestig
              </Typography>

              <IconButton
                aria-label="close"
                onClick={() => closeModal(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.primary.contrastText,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent sx={{ marginTop: 2 }}>
              <DialogContentText id="alert-dialog-description">
                Is jy seker jy wil die subskripsie verwyder?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={() => setOpenDeleteConfirmation(false)}
              >
                Gaan Terug
              </Button>
              <Button
                color="error"
                onClick={handleDeleteClickConfirmed}
                autoFocus
                variant="contained"
              >
                Verwyder
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ p: 2 }} variant="h5">
            Maandbokse
          </Typography>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", padding: 1 }}>
            <Tabs
              value={tabStatus}
              onChange={handleStatusChange}
              aria-label="tabs"
            >
              <Tab label="Nuwe Aansoek" id={"Nuwe Aansoek"} />
              <Tab label="Aktief" id={"Aktief"} />
              <Tab label="Verval" id={"Verval"} />
              <Tab label="Gekanselleer" id={"Gekanselleer"} />
              <Tab label="Debiet Order Fout" id={"Debiet Order Fout"} />
              <Tab label="Alles" id={"Alles"} />
            </Tabs>
          </Box>
        </Box>

        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  birthday: false,
                  favouriteColour: false,
                  idNumber: false,
                  shippingAddress_Address1: false,
                  shippingAddress_Address2: false,
                  shippingAddress_City: false,
                  shippingAddress_Province: false,
                  shippingAddress_PostalCode: false,
                  shippingAddress_Country: false,
                  referredBy: false,
                  bankName: false,
                  bankAccountHolderName: false,
                  bankBranch: false,
                  bankAccountType: false,
                  bankAccountNumber: false,
                  debitOrderDate: false,
                },
              },
            }}
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
              LoadingOverlay: LinearProgress,
            }}
            loading={loading}
          />
        </Box>
      </Box>
    </FixedLayout>
  );
}
