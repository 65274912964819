import { Suspense, useCallback, useEffect, useState, useSyncExternalStore } from "react";
import Page from "./components/Page";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-lazy-load-image-component/src/effects/blur.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css";
import "aos/dist/aos.css";
import { useAppDispatch } from "./store/configureStore";
import { fetchCurrentUser } from "./store/accountSlice";
import { fetchBasketAsync } from "./store/basketSlice";
import { fetchFilters, fetchProductsAsync } from "store/catalogSlice";
import { startLoading, stopLoading } from "store/systemSlice";
import { store } from "./store/configureStore";
import agent, { injectStore } from "./api/agent";
import { fetchWishlistAsync } from "store/wishlistSlice";
import VersionUpdate from "components/VersionUpdate";
import OfflineModal from "components/OfflineModal";


import viewsRoutes from "views/routes";
import adminRoutes from "views_admin/routes";
import memberRoutes from "views_members/routes";
import { ProtectedRoute } from "components/ProtectedRoute";
import RetryModal from "components/RetryModal";
import { ProtectedPage } from "components/ProtectedPage";
import { ScrollToTop } from "components/ScrollToTop";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
/*import 'dayjs/locale/en-gb';*/
import 'dayjs/locale/af';

//const adminRoutes = lazy(() => import('./views_admin/routes'));

injectStore(store);

function subscribe(callback) {
  window.addEventListener('online', callback);
  window.addEventListener('offline', callback);
  return () => {
    window.removeEventListener('online', callback);
    window.removeEventListener('offline', callback);
  };
}

function useOnlineStatus() {
  return useSyncExternalStore(
    subscribe, // React won't resubscribe for as long as you pass the same function
    () => process.env.NODE_ENV === "development" ? true : navigator.onLine,  // How to get the value on the client 
    () => true // How to get the value on the server
  );
}


const App = (): JSX.Element => {
  const [requiresReload, setRequiresReload] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const dispatch = useAppDispatch();

  // Online state
  const isOnline = useOnlineStatus();

  //Referral cookie/storage
  const urlParams = new URLSearchParams(window.location.search);
  const agentkey = urlParams.get('agent');
  if(agentkey != null){
    localStorage.setItem('agentkey', agentkey);
  }

  const checkIfLatestVersion = useCallback(async () => {
    try {
      console.log('checking for latest version');

      //Check version
      agent.Settings.getSetting("version").then((response: any) => {
        const reactversion = process.env.REACT_APP_VERSION;

        if (response.value === reactversion) {
          console.log("Using latest version");
        } else {
          console.log("version", response.value);
          console.log("clientversion", reactversion);
          console.warn("Outdated client version, need to reload");
          setRequiresReload(true);
        }
      });

      //Reload other info
      await dispatch(fetchProductsAsync());
      await dispatch(fetchFilters());
    } catch (error) {
      setErrorLoading(true);
    }
  }, [dispatch]);

  //memoize and not rerun on re-render
  const initApp = useCallback(async () => {
    try {

      console.log('start init');
      await dispatch(startLoading());
      await dispatch(fetchCurrentUser());
      console.log('user fetched');
      await dispatch(fetchBasketAsync());
      console.log('basket fetched');

      checkIfLatestVersion();  //Also loads the below items.
      // await dispatch(fetchProductsAsync());
      // console.log('products fetched');
      // await dispatch(fetchFilters());
      // console.log('filters fetched');
      await dispatch(fetchWishlistAsync());
      console.log('wishlist fetched');
      
    } catch (error) {
      console.log(error);
      setErrorLoading(true);
    }
    finally {
      await dispatch(stopLoading());
    }
  }, [dispatch, checkIfLatestVersion]);


  useEffect(() => {
    initApp();
  }, [initApp]);

  useEffect(() => {

    //Check latest version function which we want to run every 5 mins.
  const MINUTE_MS = 60000 * 10; //1 minute x 10 = 10 minutes.
   
    const interval = setInterval(() => {
      console.log('running interval');

      checkIfLatestVersion();
      
    }, MINUTE_MS);

    return () => { clearInterval(interval); console.log('interval cleared')}
  }, [dispatch, checkIfLatestVersion]);

  function reload() {

    console.log('running reload procedure');
    setErrorLoading(false);
    initApp();
    
  }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="af">
    <Page>
      <div id="back-to-top-anchor"></div>
      
      <BrowserRouter>
        <OfflineModal isOffline={!isOnline} />
        <RetryModal requiresReload={errorLoading} reload={reload} />
        <VersionUpdate
          requiresReload={requiresReload}
          setRequiresReload={setRequiresReload}
        />
        <ToastContainer position="top-right"  />
        <Suspense fallback={<div>Main fallback...</div>}>
        <Routes>
          {viewsRoutes.map((item, i) => (
            <Route key={i} path={item.path} element={item.renderer()} />
          ))}

          <Route
            element={<ProtectedRoute roles={["Admin", "Gewoon", "Agent","Retailer","Wholesaler","Winkel"]} />}
          >
            {memberRoutes.map((item, i) => (
              <Route key={i} path={item.path} element={item.renderer()} />
            ))}
          </Route>

          <Route element={<ProtectedRoute roles={["Admin"]} />}>
            {adminRoutes.map((item, i) => (
              <Route key={i} element={<ProtectedPage users={item.users} />}>
                <Route key={i} path={item.path} element={item.renderer()} />
              </Route>
            ))}
          </Route>
          <Route path="*" element={<Navigate to="/fout404" />} />
        </Routes>
        </Suspense>
      </BrowserRouter>
      <ScrollToTop/>
            </Page>

        </LocalizationProvider>
  );
};

export default App;
