import {
  Typography,
  Box,
  Alert,
} from "@mui/material";
//import agent from "api/agent";
//import { useState } from "react";
import { useNavigate } from "react-router-dom";
//import { toast } from "react-toastify";
//import { fetchCurrentUser } from "store/accountSlice";
import { useAppSelector } from "store/configureStore";
import Page from "./Page";
import Main from "layouts/Main";

export default function CommissionNetworkRetailers() {
  //const [structure, setStructure] = useState<CommissionStructure>(null);
  //const [loading, setLoading] = useState(false);

  const { user } = useAppSelector((state) => state.account);
  //const dispatch = useAppDispatch();

  const navigate = useNavigate();

  if (
    !user.roles.includes("Admin") &&
    !user.roles.includes("Retailer")
  ) {
    navigate("/");
  }

  // async function handleChangeToAgent() {
  //   setLoading(true);

  //   try {
  //     await agent.Commission.changefromretailertoagent();

  //     toast.success(
  //       "Wonderlik! Jy is nou deel van die Agent Kommissie Netwerk."
  //     );
  //     navigate("/profiel-agente-kommissie");

  //     await dispatch(fetchCurrentUser());
  //   } catch (error) {
  //     toast.error("Aggenee, daar was 'n onverwagte fout. Probeer asb weer.");
  //   }
  //   finally {
  //     setLoading(false);
  //   }

    
  // }

  const is_admin = user.roles?.includes("Admin");

  return (
    <Main>
    <Page>

    <Box>
    <Typography variant="h6" gutterBottom fontWeight={700}>
            Kommissie Netwerk
          </Typography>
    
        <Alert severity="warning">
          Jy is nog nie deel van die opwindende kommissie program waarvan almal
          praat nie en jy mis uit op groot voordele! <br/><br/>Om deel te word van die
          kommissie program, kontak ons op info@kleinbederf.co.za om jou profiel te verander vanaf 'Retailer' na 'Agent'.
          'Retailers' kry tot en met 25% afslag op hul aankope, maar kry geen
          kommissie op aankope deur sub-agente of gewone kliente wat hul na ons
          webwerf verwys nie. 'Agente' kry tot en met 20% afslag en kommissie op
          aankope wat sub-agente, retailers, en gewone kliente wat hul na ons webwerf verwys.
           {/*<br /> <br />Kontak ons indien jy {" "}
          <LoadingButton loading={loading} variant="contained" onClick={handleChangeToAgent}>
            Verander na 'Agent' en word deel van kommissie netwerk
          </LoadingButton> */}
        </Alert>
     

      {is_admin && (
        <Alert severity="error" sx={{marginTop: 2}}>
          Die inligting hierso onder is nie heeltemal van toepassing op 'Admins'
          nie.
        </Alert>
      )}

     
          <Typography sx={{ fontWeight: 700, marginTop: 3 }}>
            Skakels:
          </Typography>
          <Typography>
           As jy deel word van die Agent kommissie netwerk, sal jy unieke skakels kry wat jy kan deel met ander om jou netwerk te bou en kommissie kry op hul aankope. 
          </Typography>
        
     
    </Box>
    </Page>
    </Main>
  );
}
