import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import {
  Headline,
  Image,
  Details,
  Reviews,
} from "./components";

import Main from "../../layouts/Main";
import Container from "components/Container";
import LoadingComponent from "layouts/LoadingComponent";
import { useParams, Navigate, useLocation } from "react-router-dom";
import { productSelectors } from "store/catalogSlice";
import { useAppSelector, useAppDispatch } from "store/configureStore";
import { toast } from "react-toastify";
import { addWishlistItemAsync, removeItemAsync } from "store/wishlistSlice";


const Produk = (): JSX.Element => {
  const { slug } = useParams<{ slug: string }>();
  let location = useLocation();

  const { loadingProducts } = useAppSelector((state) => state.catalog);

  const {wishlistItems} = useAppSelector(state => state.wishlist);

  const dispatch = useAppDispatch();

  const product = useAppSelector((state) =>
    productSelectors.selectById(state, slug)
  );

  // useEffect(() => {
  //   if (!product) {
  //     dispatch(fetchProductsAsync());
  //   }
  // }, [slug, product, dispatch]);

  async function handleAddToWishlist(productId: number){
    try {
      if (!localStorage.getItem("user")) {
        toast.warning('Teken in om die wenslys funksies te gebruik');
        return;
      } 

      if (wishlistItems.filter(x => x.product.id === productId).length > 0){
        await dispatch(removeItemAsync({productId: productId}));
        toast.success('Produk uit wenslys uitgehaal');
      }else{
      await dispatch(addWishlistItemAsync({productId: productId}));
      toast.success('Produk in wenslys bygevoeg');
      }
    }catch(error){
      toast.error(error);
    }
  }

  if (loadingProducts)
    return <LoadingComponent message="Klein is besig om te laai..." />;

  if (!product) return (<Navigate to="/Fout404" state={{ from: location }} replace />)

  return (
    <Main>
      <Box bgcolor={"alternate.main"}>
        <Container paddingY={{ xs: 2, sm: 2.5 }}>
          <Headline category={product.productCategory} />
        </Container>
      </Box>
      <Container>
        <Box>
          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid item xs={12} md={5}>
            <Box  sx={{
                  position: "relative"}}>
             <Image
                images={product.productImages}
                title={product.name}
              />
              <Box 
                  padding={2}
                  bgcolor={"background.paper"}
                  boxShadow={2}
                  borderRadius={2}
                  position={"absolute"}
                  top={32}
                  right={16}
                  display={"flex"}
                  alignItems={"center"}
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleAddToWishlist(product.id)}
                >
                  <Box
                    component={"svg"}
                    xmlns="http://www.w3.org/2000/svg"
                    fill={wishlistItems?.filter(x => x.product.id === product.id).length > 0? "red" : "none"}
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    width={20}
                    height={20}
                    color={"text.primary"}
                    
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                    />
                  </Box>
                </Box>
              </Box>
              
            </Grid>
            <Grid item xs={12} md={7}>
              <Details product={product} />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container paddingY={4} id="reviews">
        <Divider />
      </Container>
      <Container>
        <Reviews reviews={product.reviews} productid={product.id} />
      </Container>
      <Container paddingY={4}>
        <Divider />
      </Container>
      {/* <Container>
        <SimilarProducts />
      </Container> */}
      {/* <Box bgcolor={'alternate.main'}>
        <Container>
          <Newsletter />
        </Container>
      </Box> */}
    </Main>
  );
};

export default Produk;
