import * as yup from "yup";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link as LinkR } from "react-router-dom";

import Page from "./Page";
import Main from "layouts/Main";
import { useAppDispatch, useAppSelector } from "store/configureStore";
import { Alert, LoadingButton } from "@mui/lab";
import agent from "api/agent";
import { toast } from "react-toastify";
import { fetchCurrentUser } from "store/accountSlice";
import AppCheckbox from "components/AppCheckbox";

const validationSchema = yup.object({
  firstName: yup.string().trim().required("Naam is 'n noodsaaklike veld."),
  lastName: yup.string().trim().required("Van is 'n noodsaaklike veld."),
  contactNumber: yup
    .string()
    .trim()
    .required("Kontak nommer is 'n noodsaaklike veld."),
});

const General = (): JSX.Element => {
  const { user } = useAppSelector((state) => state.account);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  async function handleSubmitData(data: FieldValues) {
    try {

      
      const finaldata = {...data, dateOfBirth: (data.dateOfBirth === ""? null: data.dateOfBirth) };
      await agent.Account.update_general(finaldata);
      toast.success("Besonderhede suksesvol opgedateer");

      await dispatch(fetchCurrentUser());
    } catch (error) {
     
      if (error.parentUserEmail) {
        setError(
          "parentUserEmail",
          { type: "focus", message: "Daar was geen agent gevind met die eposadres nie. Probeer asb of kontak ons by info@kleinbederf.co.za vir hulp." },
          { shouldFocus: true }
        );
        return;
      }


      toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
    }
  }

  return (
    <Main>
      <Page>
        <Box>
          <Typography variant="h6" gutterBottom fontWeight={700}>
            Algemene Inligting
          </Typography>
          <Typography variant={"subtitle2"} color={"text.secondary"}>
            Lees asseblief ons{" "}
            <Link
              component={LinkR}
              color={"primary"}
              to={"/privaatheidsbeleid"}
              underline={"none"}
            >
              Privaatheidsbeleid
            </Link>{" "}
            en{" "}
            <Link
              component={LinkR}
              color={"primary"}
              to={"/bepalingsenvoorwaardes"}
              underline={"none"}
            >
              Bepalings en Voorwaardes
            </Link>{" "}
            om meer te leer hoe ons jou persoonlike inligting bestuur.
          </Typography>
          <Box paddingY={4}>
            <Divider />
          </Box>
          <form onSubmit={handleSubmit(handleSubmitData)}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginTop: 0 }}
                  fontWeight={700}
                >
                  Naam en Kontak Besonderhede:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Naam"
                  variant="outlined"
                  name={"firstName"}
                  defaultValue={user.firstName ?? ""}
                  fullWidth
                  {...register("firstName")}
                  error={errors?.firstName != null}
                  // @ts-ignore
                  helperText={errors?.firstName?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Van"
                  variant="outlined"
                  name={"lastName"}
                  defaultValue={user.lastName ?? ""}
                  fullWidth
                  {...register("lastName")}
                  error={errors?.lastName != null}
                  // @ts-ignore
                  helperText={errors?.lastName?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="E-Pos"
                  variant="outlined"
                  name={"email"}
                  defaultValue={user.email ?? ""}
                  disabled
                  fullWidth
                  {...register("email")}
                  error={errors?.email != null}
                  // @ts-ignore
                  helperText={errors?.email?.message}
                />
               
                  <Typography variant={"subtitle2"}>
                    <Link
                      component={LinkR}
                      color={"primary"}
                      to={"/profiel/veranderepos"}
                      underline={"none"}
                    >
                      Wil jy jou e-posadres verander?
                    </Link>
                  </Typography>
               
                 
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Selfoonnommer"
                  variant="outlined"
                  name={"contactNumber"}
                  defaultValue={user.contactNumber ?? ""}
                  fullWidth
                  {...register("contactNumber")}
                  error={errors?.contactNumber != null}
                  // @ts-ignore
                  helperText={errors?.contactNumber?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="ID Nommer"
                  variant="outlined"
                  name={"idNumber"}
                  fullWidth
                  defaultValue={user.idNumber ?? ""}
                  {...register("idNumber")}
                  error={errors?.idNumber != null}
                  // @ts-ignore
                  helperText={errors?.idNumber?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Geboorte Datum"
                  variant="outlined"
                  name={"dateOfBirth"}
                  fullWidth
                  type="date"
                  defaultValue={ user.dateOfBirth === null? "" : new Date(user.dateOfBirth)
                    .toISOString()
                    .split("T")[0]
                }
                  {...register("dateOfBirth")}
                  error={errors?.dateOfBirth != null}
                  // @ts-ignore
                  helperText={errors?.dateOfBirth?.message}
                />
                <Alert severity="info" sx={{ marginTop: 1 }}>
                 Vul asb vir ons jou verjaarsdag hier in.
                </Alert>
              </Grid>

                          {!user.roles.includes("Winkel") ?

                              <>
                                  <Grid item xs={12}>
                                      <Typography
                                          variant={"subtitle2"}
                                          sx={{ marginTop: 0 }}
                                          fontWeight={700}
                                      >
                                          Klein Agent & Permissie:
                                      </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                      {user.parentUserEmail && (
                                          <Typography>
                                              Wonderlik, jy is gekoppel aan een van Klein se oulike
                                              agente! Jou Klein Agent se naam is {user.parentUserName}.
                                              Kontak gerus vir {user.parentUserName} op{" "}
                                              <a
                                                  href={"mailto:" + user.parentUserEmail}
                                                  target="_blank"
                                                  rel="noreferrer"
                                              >
                                                  {user.parentUserEmail}
                                              </a>{" "}
                                              indien jy enige hulp of advies of inligting nodig het.
                                          </Typography>
                                      )}

                                      {(user.parentUserEmail === null ||
                                          user.parentUserEmail === "") && (
                                              <>
                                                  <Typography>
                                                      Jy is nog nie gekoppel aan een van Klein se oulike agente nie! Hul is daar om jou te help, inligting te deel en kan jou soms uitnooi na oulike funksies vir lekker bederf!  As jy nie seker is watse agent, kontak ons by <a href="mailto: info@kleinbederf.co.za" target={"_blank"} rel="noreferrer">info@kleinbederf.co.za</a> dan kan ons een van ons oulike agente aan jou voorstel. As jy reeds weet wie jou agent moet wees, sleutel hul e-posadres hieronder in:
                                                  </Typography>
                                                  <TextField
                                                      label="Agent se E-posadres"
                                                      variant="outlined"
                                                      name={"parentUserEmail"}
                                                      defaultValue={user.parentUserEmail ?? ""}
                                                      fullWidth
                                                      {...register("parentUserEmail")}
                                                      error={errors?.parentUserEmail != null}
                                                      // @ts-ignore
                                                      helperText={errors?.parentUserEmail?.message}
                                                      sx={{ marginTop: 2 }}
                                                  />
                                              </>
                                          )}
                                      <Box sx={{ marginTop: 2 }}>
                                          <AppCheckbox
                                              control={control}
                                              defaultValue={user.networkPermissionToSeeDetails}
                                              label="Hiermee gee ek toestemming dat my besonderhede (naam, van, e-posadres) gedeel word op die webblad - met die persoon of persone in wie se Agent Netwerk ek val."
                                              name={"networkPermissionToSeeDetails"}
                                          />
                                          <Alert severity="info" sx={{ marginTop: 2 }}>
                                              Dis belangrik vir Agente in die netwerk om jou Naam, van en
                                              epos-adres te sien maar dis jou keuse of jy die inligting
                                              wil deel. Agente wil graag weet wie is in hul netwerk om al
                                              hul kliente te leer ken, persoonlik kontak te maak en
                                              uitnooi na funksies, help met inligting op al ons oulike
                                              produkte, ens.
                                          </Alert>
                                      </Box>
                                  </Grid>
                              </>

                              : ''}

              <Grid item xs={12}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginTop: 0 }}
                  fontWeight={700}
                >
                  Besigheid Besonderhede (Opsioneel):
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Besigheid Naam"
                  variant="outlined"
                  name={"businessName"}
                  defaultValue={user.businessName ?? ""}
                  fullWidth
                  {...register("businessName")}
                  error={errors?.businessName != null}
                  // @ts-ignore
                  helperText={errors?.businessName?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Besigheid Nommer"
                  variant="outlined"
                  name={"businessNumber"}
                  defaultValue={user.businessNumber ?? ""}
                  fullWidth
                  {...register("businessNumber")}
                  error={errors?.businessNumber != null}
                  // @ts-ignore
                  helperText={errors?.businessNumber?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Besigheid VAT Nommer"
                  variant="outlined"
                  name={"businessVatNumber"}
                  defaultValue={user.businessVatNumber ?? ""}
                  fullWidth
                  {...register("businessVatNumber")}
                  error={errors?.businessVatNumber != null}
                  // @ts-ignore
                  helperText={errors?.businessVatNumber?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ marginTop: 0 }}
                  fontWeight={700}
                >
                  Adres op Fakture (Opsioneel):
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Adres Lyn 1"
                  variant="outlined"
                  name={"addressLine1"}
                  fullWidth
                  defaultValue={user.addressLine1 ?? ""}
                  {...register("addressLine1")}
                  error={errors?.addressLine1 != null}
                  // @ts-ignore
                  helperText={errors?.addressLine1?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Adres Lyn 2"
                  variant="outlined"
                  name={"addressLine2"}
                  fullWidth
                  defaultValue={user.addressLine2 ?? ""}
                  {...register("addressLine2")}
                  error={errors?.addressLine2 != null}
                  // @ts-ignore
                  helperText={errors?.addressLine2?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Dorp / Stad"
                  variant="outlined"
                  name={"addressCity"}
                  fullWidth
                  defaultValue={user.addressCity ?? ""}
                  {...register("addressCity")}
                  error={errors?.addressCity != null}
                  // @ts-ignore
                  helperText={errors?.addressCity?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Provinsie"
                  variant="outlined"
                  name={"addressProvince"}
                  fullWidth
                  defaultValue={user.addressProvince ?? ""}
                  {...register("addressProvince")}
                  error={errors?.addressProvince != null}
                  // @ts-ignore
                  helperText={errors?.addressProvince?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Poskode"
                  variant="outlined"
                  name={"addressCode"}
                  fullWidth
                  defaultValue={user.addressCode ?? ""}
                  {...register("addressCode")}
                  error={errors?.addressCode != null}
                  // @ts-ignore
                  helperText={errors?.addressCode?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Land"
                  variant="outlined"
                  name={"addressCountry"}
                  fullWidth
                  defaultValue={user.addressCountry ?? ""}
                  {...register("addressCountry")}
                  error={errors?.addressCountry != null}
                  // @ts-ignore
                  helperText={errors?.addressCountry?.message}
                />
              </Grid>

              <Grid item container xs={12}>
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "stretched", sm: "center" }}
                  justifyContent={"space-between"}
                  width={1}
                  margin={"0 auto"}
                >
                  <Box marginBottom={{ xs: 1, sm: 0 }}>
                    {/* <Typography variant={'subtitle2'}>
                      You may also consider to update your{' '}
                      <Link
                        color={'primary'}
                        href={'/account-billing'}
                        underline={'none'}
                      >
                        billing information.
                      </Link>
                    </Typography> */}
                  </Box>

                  <LoadingButton
                    size={"large"}
                    variant={"contained"}
                    type={"submit"}
                    loading={isSubmitting}
                  >
                    Stoor
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Page>
    </Main>
  );
};

export default General;
