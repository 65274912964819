import FixedLayout from "layouts/Fixed";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Typography, Button, Box, LinearProgress } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { ProductAdmin } from "../../models/product";
import agent from "../../api/agent";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowsProp,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import React from "react";
import ProductQuickViewDialog from "./ProductQuickViewDialog";
import { toast } from "react-toastify";

const ProdukteLys = (): JSX.Element => {
  const [openId, setOpenId] = useState<ProductAdmin | null>(null);
  const [products, setProducts] = useState<ProductAdmin[] | null>(null);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [loading, setLoading] = useState(true);
  // const [initialised, setInitiliased] = useState(false);

  //name, productCategory.title, ProductBrand.title, price, priceAgent, priceSale, onSale, status

  useEffect(() => {
    agent.Catalog.adminProducts()
      .then((c: ProductAdmin[]) => {
        const products: GridRowsProp = c.map((product) => ({
          id: product.id,
          name: product.name,
          category: product.productCategory.title,
          brand: product.productBrand.title,
          price: product.price,
          priceAgent: product.priceAgent,
          priceRetailer: product.priceRetailer,
          priceSale: product.priceSale,
          priceWinkel: product.priceWinkel,
          onSale: product.onSale,
          status: product.status,
          stockType: product.stockType,
          showStock: product.showStock,
          quantityInStock: product.quantityInStock,
        }));
        setRows(products);
        setProducts(c);
      })
      .catch((error) => {
        toast.error("Aggenee, dit wou nie laai nie. Probeer asb weer");
      })
      .finally(() => setLoading(false));
  }, []);

  // useEffect(() => {
  //   agent.Catalog.adminProducts()
  //     .then((products) => setProducts(products))
  //     .catch((error) =>
  //       toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer")
  //     )
  //     .finally(() => {
  //       setLoading(false);
  //       setInitiliased(true);
  //     });
  // }, []);

  const handleSelectProduct = useCallback(
    (id: number) => {
      setOpenId(products.find((x) => x.id === id));

      // return (event: React.MouseEvent) => {
      //   event.preventDefault();
      // };
    },
    [products]
  );

  async function handleDeleteProduct(id: number) {
    //TODO: Add confirmation modal.

    setLoading(true);

    try {
      await agent.Admin.deleteProduct(id);

      agent.Catalog.adminProducts()
        .then((c: ProductAdmin[]) => {
          const products: GridRowsProp = c.map((product) => ({
            id: product.id,
            name: product.name,
            category: product.productCategory?.title ?? "",
            brand: product.productBrand?.title ?? "",
            price: product.price,
            priceAgent: product.priceAgent,
            priceWinkel: product.priceWinkel,
            priceRetailer: product.priceRetailer,
            priceSale: product.priceSale,
            onSale: product.onSale,
            status: product.status,
            stockType: product.stockType,
            showStock: product.showStock,
            quantityInStock: product.quantityInStock,
          }));
          setRows(products);
          setProducts(c);
        })
        .catch((error) =>
          toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer")
        );
    } catch (error) {
      toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
    } finally {
      setLoading(false);
    }

    // setLoading(false);
  }

  function handleCloseProduct() {
    setOpenId(null);

    setLoading(true);

    agent.Catalog.adminProducts()
      .then((c: ProductAdmin[]) => {
        const products: GridRowsProp = c.map((product) => ({
          id: product.id,
          name: product.name,
          category: product.productCategory?.title ?? "",
          brand: product.productBrand?.title ?? "",
          price: product.price,
          priceAgent: product.priceAgent,
          priceWinkel: product.priceWinkel,
          priceRetailer: product.priceRetailer,
          priceSale: product.priceSale,
          onSale: product.onSale,
          status: product.status,
          stockType: product.stockType,
          showStock: product.showStock,
          quantityInStock: product.quantityInStock,
          visibleAdmin: product?.visibleAdmin,
          visibleRetailer: product?.visibleRetailer,
          visibleAgent: product?.visibleAgent,
          visibleGewoon: product?.visibleGewoon,
          visiblePublic: product?.visiblePublic,
        }));
        setRows(products);
        setProducts(c);
      })
      .catch((error) =>
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer")
      )
      .finally(() => setLoading(false));
  }

  function EditToolbar() {
    const handleClick = () => {
      setLoading(true);

      //Draft product.
      const draftproduct = {
        name: "Draft",
        slug: "",
        description: "",
        price: 0,
        priceSale: 0,
        priceAgent: 0,
        priceRetailer: 0,
        priceWinkel: 0,
        productCategoryId: 1,
        productBrandId: 1,
        quantityInstock: 0,
        status: "Draft",
        stockType: "Onbeperk",
        showStock: false,
        quantityInStock: 0,
      };

      agent.Admin.createProduct(draftproduct)
        .then((product) => setOpenId(product))
        .catch((error) =>
          toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer")
        );

      setLoading(false);
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Skep Nuwe
        </Button>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  // type Row = typeof products[number];

  // const columns = React.useMemo<GridColumns<Row>>(
  //   () => [
  const columns: GridColumns = [
    { field: "name", headerName: "Naam", width: 300 },
    {
      field: "category",
      headerName: "Kategorie",
      width: 200,
    },
    {
      field: "brand",
      headerName: "Reeks",
      width: 200,
    },
    {
      field: "price",
      headerName: "Prys",
      width: 130,
      valueGetter: (params) => "R" + params.row.price.toFixed(2),
    },
    {
      field: "priceAgent",
      headerName: "Agent Prys",
      width: 130,
      valueGetter: (params) => "R" + params.row.priceAgent.toFixed(2),
    },
    {
      field: "priceRetailer",
      headerName: "Retailer Prys",
      width: 130,
      valueGetter: (params) => "R" + params.row.priceRetailer.toFixed(2),
    },
    {
      field: "priceWinkel",
      headerName: "Winkel Prys",
      width: 130,
      valueGetter: (params) => "R" + params.row.priceWinkel.toFixed(2),
    },
    {
      field: "priceSale",
      headerName: "Uitverkoping Prys",
      width: 130,
      valueGetter: (params) => "R" + params.row.priceSale.toFixed(2),
    },
    {
      field: "onSale",
      headerName: "Op Uitverkoping",
      width: 130,
      valueGetter: (params) => (params.row.onSale ? "Ja" : "Nee"),
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      //valueGetter: (params) => (params.row.status == 0 ? "Ja" : "Nee"),
    },
    {
      field: "quantityInStock",
      headerName: "Voorraad",
      width: 130,
      //valueGetter: (params) => (params.row.status == 0 ? "Ja" : "Nee"),
    },
    {
      field: "stockType",
      headerName: "Voorraad Tiepe",
      width: 130,
      //valueGetter: (params) => (params.row.status == 0 ? "Ja" : "Nee"),
    },
    {
      field: "showStock",
      headerName: "Voorraad Sigbaar",
      width: 130,
      //valueGetter: (params) => (params.row.status == 0 ? "Ja" : "Nee"),
    },
    {
      field: "visibleAgent",
      headerName: "Sigbaar - Agent",
      width: 130,
      valueGetter: (params) => (params.row.visibleAgent ? "Ja" : "Nee"),
    },
    {
      field: "visibleRetailer",
      headerName: "Sigbaar - Retailer",
      width: 130,
      valueGetter: (params) => (params.row.visibleRetailer ? "Ja" : "Nee"),
    },
    {
      field: "visibleAdmin",
      headerName: "Sigbaar - Admin",
      width: 130,
      valueGetter: (params) => (params.row.visibleAdmin ? "Ja" : "Nee"),
    },
    {
      field: "visibleGewoon",
      headerName: "Sigbaar - Gewoon",
      width: 130,
      valueGetter: (params) => (params.row.visibleGewoon ? "Ja" : "Nee"),
    },
    {
      field: "visiblePublic",
      headerName: "Sigbaar - Publiek",
      width: 130,
      valueGetter: (params) => (params.row.visiblePublic ? "Ja" : "Nee"),
    },
    {
      field: "actions",
      headerName: "Opsies",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={(event) => handleSelectProduct(params.row.id)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          showInMenu
          onClick={(event) => handleDeleteProduct(params.row.id)}
        />,
      ],
    },
  ];
  //   ,
  //   [handleSelectProduct]
  // );

  return (
    <FixedLayout>
      <Box
        width={1}
        margin={"0 auto"}
        paddingX={2}
        paddingY={{ xs: 2, sm: 2, md: 2 }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ p: 2 }} variant="h5">
            Produkte Lys
          </Typography>
        </Box>

        <div style={{ height: 600, width: "100%" }}>
          {/* {!initialised && <span>Besig om te laai...</span>}
          {initialised && */}
          <DataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  showStock: false,
                  stockType: false,
                  visibleAdmin: false,
                  visibleAgent: false,
                  visibleRetailer: false,
                  visibleGewoon: false,
                  visiblePublic: false,
                },
              },
            }}
            rows={rows}
            columns={columns}
            components={{
              Toolbar: EditToolbar,
              LoadingOverlay: LinearProgress,
            }}
            loading={loading}
          />
        </div>

        {openId && (
          <ProductQuickViewDialog
            open={openId !== null}
            onClose={handleCloseProduct}
            product={openId}
          />
        )}
      </Box>
    </FixedLayout>
  );
};

export default ProdukteLys;
