import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from '@mui/material/LinearProgress';

import agent from "../../api/agent";
import {
  DataGrid,
  GridRowsProp,
  GridColumns,
  GridValueFormatterParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { CommissionList } from "models/commission";
import { Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';



export default function CommissionListPage() {

  const [loading, setLoading] = useState(true);

  const [rows, setRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    agent.Commission.getUserCommissionList()
      .then((list: CommissionList[]) => {

        console.log('list', list);
       
        const commissionlist: GridRowsProp = list.map((commission, index) => ({
          id: index,
          orderNumber: commission.orderNumber,
          orderItemId: commission.orderItemId,
          commissionPercentage: commission.commissionPercentage,
          commissionAmount: commission.commissionAmount,
          potentialAmountA: commission.potentialAmountA,
          potentialAmountB: commission.potentialAmountB,
          finalAmount: commission.finalAmount,
          level: commission.level,
          dateProcessed: commission.dateProcessed,
          status: commission.status,
          type: commission.type,
          orderType: commission.orderType,
          customerType: commission.customerType

        }));
        setRows(commissionlist);
      })
      .catch((error) => toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer"))
      .finally(() => setLoading(false));
  }, []);


  const columns: GridColumns = [
   {
      field: "dateProcessed",
      headerName: "Datum",
      width: 130,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }

        const date = new Date(params.value);

        const valueFormatted = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return valueFormatted;
      },
      },
      { field: "customerType", headerName: "Klient Tiepe", width: 150 },
      { field: "orderType", headerName: "Bestelling Tiepe", width: 150 },
      { field: "orderNumber", headerName: "Bestelling No", width: 120 },
      { field: "status", headerName: "Bestelling Status", width: 320 },
    // { field: "level", headerName: "Kommissie Diepte", width: 120},
    // { field: "commissionPercentage", headerName: "Kommissie % (avg)", width: 120,  valueGetter: (params) => (params.row.commissionPercentage*100).toFixed(0) + "%",},
    // {
    //   field: "potentialAmountA",
    //   headerName: "Vlak A Kommissie",
    //   width: 200,
    //   valueGetter: (params) => "R" + params.row.potentialAmountA.toFixed(2),
    // },
    // {
    //   field: "potentialAmountB",
    //   headerName: "Vlak B Kommissie",
    //   width: 200,
    //   valueGetter: (params) => "R" + params.row.potentialAmountB.toFixed(2),
    // },

      // {
      //     field: "type",
      //     headerName: "Finale Kommissie Vlak",
      //     width: 200,
      //     renderCell: (params: GridRenderCellParams) => {

      //         if (params.value === 'Pending') {
      //             return (
      //                 <Tooltip title='Finale vlak sal aan einde van die maand bereken word.' placement="right" arrow >

      //                     <InfoIcon color='primary' />

      //                 </Tooltip>
      //             );
      //         }

      //         return  params.value;


      //     }
      // },

      {
          field: "finalAmount",
          headerName: "Kommissie R",
          width: 200,
          renderCell: (params: GridRenderCellParams) => {

              if (params.row.type === 'Pending') {
                  return (
                      <Tooltip title='Finale bedrag sal aan einde van die maand bereken word.' placement="right" arrow >

                          <InfoIcon color='primary' />

                      </Tooltip>
                  );
              }
              
              return 'R' + params.row.finalAmount.toFixed(2);
          }
      },
  ];
  
  return (
   

          <Box
          sx={{
            height: 500,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            components={{
              LoadingOverlay: LinearProgress,
            }}
            loading={loading}

          />
        </Box>
       
  );
};