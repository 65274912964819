import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { accountSlice } from "./accountSlice";
import { basketSlice } from "./basketSlice";
import { catalogSlice } from "./catalogSlice";
import {systemSlice} from "./systemSlice";
import { wishlistSlice } from "./wishlistSlice";

export const store = configureStore({
    reducer: {
        account: accountSlice.reducer,
        catalog: catalogSlice.reducer,
        basket: basketSlice.reducer,
        system: systemSlice.reducer,
        wishlist: wishlistSlice.reducer,
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;