import Box from "@mui/material/Box";

import Main from "layouts/Main";
import Container from "components/Container";

import { useTheme } from "@mui/material/styles";
import { Grid, Card, CardMedia, CardContent, Typography, Divider } from "@mui/material";

const mock = [
  {
    image: "/images/winkel-hartenbos.jpeg",
    description: "Winkel GF14, Hartenbos Seefront, Hartenbos",
    title: "Klein Hartenbos",
    hours: "Maandag - Vrydag: 09:00 - 18:00, Saterdae: 09:00 - 15:00, Sondae 09:00-13:00",
    email: "info@kleinbederf.co.za",
  },
  // {
  //   image: "/images/winkel-potchefstroom.jpeg",
  //   description: "Winkel Nr 10, Vyfhoek Winkel Kompleks, Potchefstroom",
  //   title: "Klein Potchefstroom",
  //   hours: "Maandag - Vrydag: 09:00 - 18:00, Saterdae: 09:00 - 15:00, Sondae 09:00-13:00",
  //   email: "info@kleinbederf.co.za",
  // },
];

export default function OnsWinkels() {

  const theme = useTheme();

  return (
    <Main>
      <Box>
      <Box
      sx={{
        position: 'relative',
        backgroundColor: theme.palette.alternate.main,
        backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
        marginTop: -13,
        paddingTop: 13,
      }}
    >
      <Container>
        <Box>
          <Box
            marginBottom={{ xs: 0, sm: 2 }}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <Typography
              variant="h3"
              gutterBottom
              align={'center'}
              sx={{
                fontWeight: 900,
              }}
            >
             Ons Winkels
            </Typography>
            <Typography
              variant="h6"
              component="p"
              color="text.primary"
              align={'center'}
              sx={{ marginBottom: 1 }}
            >
              Besoek gerus een van ons winkels vir 'n klein bietjie bederf! 
          
            </Typography>
           
          </Box>
        
        </Box>
      </Container>
    </Box>
        <Box>
          <Container>
           <Box sx={{textAlign: 'center'}}>
      
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={6} key={i}>
            <Box
              // component={"a"}
              // href={""}
              display={"block"}
              width={1}
              height={1}
              sx={{
                textDecoration: "none",
                transition: "all .2s ease-in-out",
                "&:hover": {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                boxShadow={0}
                sx={{ bgcolor: "transparent", backgroundImage: "none" }}
              >
                <CardMedia
                  image={item.image}
                  title={item.title}
                  sx={{
                    height: { xs: 300, md: 360 },
                    position: "relative",
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0.7)"
                        : "none",
                  }}
                />
                <Box
                  width={"90%"}
                  margin={"0 auto"}
                  display={"flex"}
                  flexDirection={"column"}
                  boxShadow={3}
                  borderRadius={2}
                  bgcolor={"background.paper"}
                  position={"relative"}
                  zIndex={3}
                  sx={{ transform: "translateY(-30px)" }}
                >
                  <Box component={CardContent} position={"relative"}>
                    <Typography variant={"h6"} gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary" >
                      {item.description}
                    </Typography>
                    <Typography color="text.secondary" paddingTop={1}>
                      {item.email}
                    </Typography>
                  </Box>
                  <Box flexGrow={1} />
                  <Box padding={2} display={"flex"} flexDirection={"column"}>
                    <Box marginBottom={2}>
                      <Divider />
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        <Typography color={"text.secondary"}>
                          {item.hours}
                        </Typography>
                      </Box>
                      {/* <Typography color={'text.secondary'}>
                        {item.date}
                      </Typography> */}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
          </Container>
        </Box>
       
      </Box>
    </Main>
  );
}
