

import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import { useController, UseControllerProps } from "react-hook-form";

interface Props extends UseControllerProps {
    label: string;
    disabled?: boolean;
    required?: boolean;
}

export default function AppCheckbox(props: Props) {

    const { fieldState, field } = useController({ ...props, defaultValue: props.defaultValue })

    return (
        <>
            <FormControl fullWidth error={!!fieldState.error}>
                <FormControlLabel
                    control={<Checkbox
                        {...field}
                        checked={field.value}
                        color='secondary' />}
                    disabled={props.disabled ?? false}
                    required={props.required ?? false}
                    label={props.label} />

                <FormHelperText>
                    {fieldState.error?.message}
                </FormHelperText>
            </FormControl>

        </>

        //         <FormControl fullWidth error={!!fieldState.error}>

        //   <Checkbox
        //             {...field}
        //             checked={field.value}
        //             color='secondary' />
        //             <InputLabel>{props.label}</InputLabel>

        // </FormControl>
    )
}