import FixedLayout from "layouts/Fixed";
import {
  Typography,
  Button,
  Box,
} from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect, useState } from "react";
import { ProductCategory } from "../../models/product";
import agent from "../../api/agent";
import React from "react";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  DataGrid,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridColumns,
  GridRowParams,
  MuiEvent,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
} from "@mui/x-data-grid";

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}



export default function Categories() {
  const [loading, setLoading] = useState(true);

  const [rows, setRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    agent.Category.list()
      .then((c: ProductCategory[]) => {
        const cats: GridRowsProp = c.map((cat) => ({
          id: cat.id,
          title: cat.title,
          slug: cat.slug,
          visible: cat.visible,
        }));
        setRows(cats);
      })
      .catch((error) => toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer"))
      .finally(() => setLoading(false));
  }, []);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;

  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {

    setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View },
          });

  };


  const handleDeleteClick = (id: GridRowId) => () => {
   
    if (id === 1) {
      toast.error("Die kategorie kan nie verwyder word nie.");
      return;
    }

    setLoading(true);

    agent.Category.deleteCategory(parseInt(id.toString()))
      .then((c) => setRows(rows.filter((row) => row.id !== id)))
      .catch((error) => {
        toast.warning("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    toast.error('could not save ' + error.message);
    // setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const processRowUpdate = async (newRow: GridRowModel) => {

    setLoading(true);

    await agent.Category.updateCategory(newRow);
    
    setLoading(false);

    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  
  };

  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;
  
    const handleClick = () => {

      setLoading(true);
    
      //create draft in database and provide id.
      agent.Category.createCategory({ title: "", slug: "", visible: false })
        .then((c) => {
          const id = c.id;
          setRows((oldRows) => [
            ...oldRows,
            { id, title: "", slug: "", visible: false, isNew: true },
          ]);
          setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: "title" },
          }));
        })
        .catch((error) => {
          toast.error("Aggenee, dit wil nie werk nie. Probeer asb. weer");
        }).finally(()=> setLoading(false));
    };
  
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Skep Nuwe
        </Button>
      </GridToolbarContainer>
    );
  }

  const columns: GridColumns = [
    { field: "title", headerName: "Titel", width: 180, editable: true },
    { field: "slug", headerName: "Slug", width: 120, editable: true },
    {
      field: "visible",
      headerName: "Sigbaar",
      type: "boolean",
      width: 120,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Opsies",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <FixedLayout>
      <Box
        width={1}
        margin={"0 auto"}
        paddingX={2}
        paddingY={{ xs: 2, sm: 2, md: 2 }}
      >
        
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ p: 2 }} variant="h5">
            Kategorie Lys
          </Typography>
        </Box>

        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            components={{
              Toolbar: EditToolbar,
              LoadingOverlay: LinearProgress,
        
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            experimentalFeatures={{ newEditingApi: true }}
            loading={loading}
          />
        </Box>
      </Box>
    </FixedLayout>
  );
}
