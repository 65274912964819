import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Divider, Skeleton } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import agent from "../../api/agent";
import Link from "@mui/material/Link";
import { Link as LinkR } from "react-router-dom";
import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import AppSelectList from "components/AppSelectList";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, "Naam is te kort")
    .max(50, "Naam is te lank")
    .required("Naam is nodig"),
  lastName: yup
    .string()
    .trim()
    .min(2, "Van is te kort")
    .max(50, "Van is te lank")
    .required("Van is nodig"),
  email: yup
    .string()
    .trim()
    .email("Geldige eposadres is nodig")
    .required("E-Posadres is nodig"),
  idNumber: yup.string().trim().required("ID Nommer is nodig"),
  phone: yup
    .string()
    .trim()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/,
      "Geldige selfoon nommer is nodig"
    ),

  location: yup.string().trim().required("Provinsie is nodig"),
  locationTown: yup.string().trim().required("Dorp/Stad is nodig"),
  addressLine1: yup.string().trim().required("Adres Lyn 1 is nodig"),
  addressCity: yup.string().trim().required("Dorp/Stad is nodig"),
  addressCode: yup.string().trim().required("Poskode is nodig"),
  addressCountry: yup.string().trim().required("Land is nodig"),
  // debitOrderMandate: yup.string().not(["Kies..."], "Die opsie is nodig."),
  // paymentOption: yup.string().required(),
  // bankName: yup.string().when("paymentOption", {
  //   is: (val) =>
  //     val !==
  //     "R35 000.00 (10% korting per kiosk - reeds gekoop - is slegs hier van toepassing)",
  //   then: yup.string().required("Bank naam is nodig"),
  // }),
  // bankAccountHolderName: yup.string().when("paymentOption", {
  //   is: (val) =>
  //     val !==
  //     "R35 000.00 (10% korting per kiosk - reeds gekoop - is slegs hier van toepassing)",
  //   then: yup.string().required("Bank naam is nodig"),
  // }),
  // bankBranch: yup.string().when("paymentOption", {
  //   is: (val) =>
  //     val !==
  //     "R35 000.00 (10% korting per kiosk - reeds gekoop - is slegs hier van toepassing)",
  //   then: yup.string().required("Tak/kode is nodig"),
  // }),
  // bankAccountType: yup.string().when("paymentOption", {
  //   is: (val) =>
  //     val !==
  //     "R35 000.00 (10% korting per kiosk - reeds gekoop - is slegs hier van toepassing)",
  //   then: yup.string().required("Tipe rekening is nodig"),
  // }),
  // bankAccountNumber: yup.string().when("paymentOption", {
  //   is: (val) =>
  //     val !==
  //     "R35 000.00 (10% korting per kiosk - reeds gekoop - is slegs hier van toepassing)",
  //   then: yup.string().required("Rekening nommer is nodig"),
  // }),
});

const FormHandelshoek = (): JSX.Element => {
  const [step, setStep] = useState(1);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });


  useEffect(() => {
    
    //In case user was referred by an agent.
    setValue("referredBy", localStorage.getItem("agentkey") ?? "");

    console.log('setting value');
     
  }, [setValue]);

  //Store the value when recaptcha is checked.
  function onChange(value) {
    setValue("captcha", value);

    // if (value === null) {
    //   setSubmitVisible(false);
    // } else {
    //   setSubmitVisible(true);
    // }
  }

  async function handleSubmitData(data: FieldValues) {
    try {
      //Validation:
      var captcha = getValues("captcha");

      if (captcha === null || captcha === "") {
        toast.error(
          "The reCaptcha validation must be completed, please confirm the reCaptcha then try again to submit. "
        );
        return;
      }

      await agent.Forms.formHandelshoekSubmit(data);

      setStep(2);
    } catch (error) {
      if (error.Captcha) {
        toast.warning(
          "Captcha validasie was nie suksesvol, probeer asb weer om die validasie te doen en dan die vorm te stuur."
        );
        //setSubmitVisible(false);
      }
    }
  }

  if (isSubmitting) {
    return (
      <Box>
        <Box marginBottom={7}>
          <Typography
            align={"center"}
            color={"text.secondary"}
            sx={{ textTransform: "uppercase" }}
            variant={"subtitle2"}
            fontWeight={600}
          >
            AANSLUITING
          </Typography>
          {/* <Typography fontWeight={700} variant={"h4"} align={"center"}>
            Voltooi asseblief die registrasievorm:
          </Typography> */}
        </Box>
        <Typography
          variant={"body1"}
          color={"textSecondary"}
          align={"center"}
          sx={{ paddingBottom: 3 }}
        >
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="rectangular" height={200} />
        </Typography>
      </Box>
    );
  }


  const date = new Date();

  if (date < new Date('1 January 2024') ) {
    return (
      <Box>
        <Box marginBottom={7}>
          <Typography
            align={"center"}
            color={"text.secondary"}
            sx={{ textTransform: "uppercase" }}
            variant={"subtitle2"}
            fontWeight={600}
          >
            AANSLUITING
          </Typography>
          <Typography fontWeight={700} variant={"h4"} align={"center"} paddingTop={2}>
            Nuwe registrasies is gesluit en open weer Januarie 2024.
          </Typography>
        </Box>
        
      </Box>
    );
  }

  if (step === 2) {
    return (
      <Box>
        <Box marginBottom={7}>
          <Typography
            align={"center"}
            color={"text.secondary"}
            sx={{ textTransform: "uppercase" }}
            variant={"subtitle2"}
            fontWeight={600}
          >
            AANSLUITING
          </Typography>
          {/* <Typography fontWeight={700} variant={"h4"} align={"center"}>
            Voltooi asseblief die registrasievorm:
          </Typography> */}
        </Box>
        <Typography
          variant={"body1"}
          color={"textSecondary"}
          align={"center"}
          sx={{ paddingBottom: 3 }}
        >
          Baie dankie. Jou boodskap is ontvang, ons sal binne 1-2 werksdae in
          kontak wees.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box marginBottom={7}>
        <Typography
          align={"center"}
          color={"text.secondary"}
          sx={{ textTransform: "uppercase" }}
          variant={"subtitle2"}
          fontWeight={600}
        >
          AANSLUITING
        </Typography>
        <Typography fontWeight={700} variant={"h4"} align={"center"}>
          Voltooi asseblief die aansoekvorm:
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <Box
          component={Grid}
          marginBottom={{ xs: 10, sm: 0 }}
          container
          spacing={4}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              label="Naam"
              variant="outlined"
              name={"firstName"}
              fullWidth
              {...register("firstName")}
              error={errors?.firstName != null}
              // @ts-ignore
              helperText={errors?.firstName?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Van"
              variant="outlined"
              name={"lastName"}
              fullWidth
              {...register("lastName")}
              error={errors?.lastName != null}
              // @ts-ignore
              helperText={errors?.lastName?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="E-Pos"
              variant="outlined"
              name={"email"}
              fullWidth
              {...register("email")}
              error={errors?.email != null}
              // @ts-ignore
              helperText={errors?.email?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Selfoonnommer"
              variant="outlined"
              name={"phone"}
              fullWidth
              {...register("phone")}
              error={errors?.phone != null}
              // @ts-ignore
              helperText={errors?.phone?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="ID Nommer"
              variant="outlined"
              name={"idNumber"}
              fullWidth
              {...register("idNumber")}
              error={errors?.idNumber != null}
              // @ts-ignore
              helperText={errors?.idNumber?.message}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <AppSelectList
              control={control}
              name="businessType"
              label="Het jy jou eie besigheid waar jy jou Handelshoek gaan sit?"
              defaultValue="Ja, dit is my eie besigheid waar hy gaan staan"
              items={[
                "Ja, dit is my eie besigheid waar hy gaan staan",
                "Nee, dit is 'n ander besigheid waar ek my handelshoek gaan sit.",
                "Ek het my eie besigheid maar ek wil ook graag 'n paar ander Handelshoeke in ander besighede sit",
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              label="In watter provinsie sal jy graag jou Handelshoek(e) wil sit? "
              variant="outlined"
              name={"location"}
              fullWidth
              {...register("location")}
              error={errors?.location != null}
              // @ts-ignore
              helperText={errors?.location?.message}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label="In watter dorp sal jy graag jou Handelshoek(e) wil sit? "
              variant="outlined"
              name={"locationTown"}
              fullWidth
              {...register("locationTown")}
              error={errors?.locationTown != null}
              // @ts-ignore
              helperText={errors?.locationTown?.message}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>
              Adres waar Handelshoek afgelaai moet word: (die meubels is baie swaar,
              maak dus voor die tyd seker die spasie is gereed sodat jy dit nie
              onnodig op en af moet vervoer nie):
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Adres Lyn 1"
              variant="outlined"
              name={"addressLine1"}
              fullWidth
              {...register("addressLine1")}
              error={errors?.addressLine1 != null}
              // @ts-ignore
              helperText={errors?.addressLine1?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Adres Lyn 2"
              variant="outlined"
              name={"addressLine2"}
              fullWidth
              {...register("addressLine2")}
              error={errors?.addressLine2 != null}
              // @ts-ignore
              helperText={errors?.addressLine2?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Dorp / Stad"
              variant="outlined"
              name={"addressCity"}
              fullWidth
              {...register("addressCity")}
              error={errors?.addressCity != null}
              // @ts-ignore
              helperText={errors?.addressCity?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Poskode"
              variant="outlined"
              name={"addressCode"}
              fullWidth
              {...register("addressCode")}
              error={errors?.addressCode != null}
              // @ts-ignore
              helperText={errors?.addressCode?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Land"
              variant="outlined"
              name={"addressCountry"}
              fullWidth
              {...register("addressCountry")}
              error={errors?.addressCountry != null}
              // @ts-ignore
              helperText={errors?.addressCountry?.message}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>
              Is jy reeds ’n Handelshoek-eienaar? Indien wel, hoeveel Handelshoeke het jy reeds?
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label=""
              variant="outlined"
              name={"previousKioskInformation"}
              fullWidth
              {...register("previousKioskInformation")}
              error={errors?.previousKioskInformation != null}
              // @ts-ignore
              helperText={errors?.previousKioskInformation?.message}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography>
              Is daar 'n Klein Agent wat jou verwys of registreer hiervoor,
              indien wel, wat is die Agent se e-posadres?{" "}
            </Typography>

            <TextField
              label=""
              variant="outlined"
              name={"referredBy"}
              fullWidth
              {...register("referredBy")}
              defaultValue={localStorage.getItem("agentkey") ?? ""}
              error={errors?.referredBy != null}
              // @ts-ignore
              helperText={errors?.referredBy?.message}
              inputProps={{
                readOnly: localStorage.getItem("agentkey") ? true : false,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>
              Indien die faktuur aan 'n besigheid uitgemaak moet word, verskaf
              asb die besigheid se besonderhede.{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label=""
              variant="outlined"
              name={"companyDetails"}
              fullWidth
              {...register("companyDetails")}
              error={errors?.companyDetails != null}
              // @ts-ignore
              helperText={errors?.companyDetails?.message}
            />
          </Grid>
          
          <Grid item xs={12} sm={12}>
            <Typography>
            Kies jou betalings opsie:{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <AppSelectList
              control={control}
              name="paymentOption"
              label="Betalings opsie:"
              defaultValue="Volle bedrag van R39000"
              items={[
                "3 maande afbetaal @ R11000 per maand, en R8000 deposito",
                "6 maande afbetaal @ R6000 per maand, en R8000 deposito",
                "Volle bedrag van R39000",
              ]}
            />
          </Grid>

          {/* <Grid item xs={12} sm={12}>
            <AppSelectList
            trigger={trigger}
              control={control}
              name="paymentOption"
              label="Watter Betaal-opsie kies jy?"
              defaultValue="R39 000.00"
              items={[
                "R35 000.00 (10% korting per kiosk - reeds gekoop - is slegs hier van toepassing)",
                "R8 000.00 nou en dan 3 maande opsie (R10 000.00 per maand) Totaal R38 000.00",
                "R8 000.00 nou en dan 6 maande opsie (R5 500.00 per maand) Totaal R41 000.00",
              ]}
            />
          </Grid>

          Once-off betaling van R39000

3 maande afbetaal @ R11000 per maand

6 maande afbetaal @ R6000 per maand.

Die afbetaal opsies het wel elkeen 'n R8000 deposito bedrag van R8000. */}

          {/* <Grid item xs={12} sm={12}>
            <Typography>
              Bankbesonderhede in die geval van afbetaal-opsies:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Banknaam"
              variant="outlined"
              name={"bankName"}
              fullWidth
              {...register("bankName")}
              error={errors?.bankName != null}
              // @ts-ignore
              helperText={errors?.bankName?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Naam van Rekeninghouer"
              variant="outlined"
              name={"bankAccountHolderName"}
              fullWidth
              {...register("bankAccountHolderName")}
              error={errors?.bankAccountHolderName != null}
              // @ts-ignore
              helperText={errors?.bankAccountHolderName?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Tak Naam of Kode"
              variant="outlined"
              name={"bankBranch"}
              fullWidth
              {...register("bankBranch")}
              error={errors?.bankBranch != null}
              // @ts-ignore
              helperText={errors?.bankBranch?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppSelectList
              control={control}
              name="bankAccountType"
              label="Tipe Rekening"
              defaultValue="Tjekrekening"
              items={["Tjekrekening", "Spaarrekening", "Besigheidsrekening"]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Rekeningnommer"
              variant="outlined"
              name={"bankAccountNumber"}
              fullWidth
              {...register("bankAccountNumber")}
              error={errors?.bankAccountNumber != null}
              // @ts-ignore
              helperText={errors?.bankAccountNumber?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppSelectList
              control={control}
              name="debitOrderDate"
              label="Watter datum kies jy vir die Debietorder om af te gaan?"
              defaultValue="Die 25ste van elke maand"
              items={[
                "Die 1ste dag van elke maand",
                "Die 15de van elke maand",
                "Die 25ste van elke maand",
                "Die 28ste van elke maand",
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <AppSelectList
              control={control}
              name="debitOrderMandate"
              label="Toestemming"
              defaultValue="Kies..."
              items={[
                "Kies...",
                "Hiermee gee ek, die bogenoemde, toestemming vir die debietorder soos gereël vir 3 maande",
                "Hiermee gee ek, die bogenoemde, toestemming vir die debietorder soos gereël vir 6 maande",
                "Ek gaan nie afbetaal nie, ek koop die Handelshoek teen R35 000.00 (voor Kiosk afslag)",
              ]}
            />
          </Grid> */}

          <Grid item xs={1} sm={4}></Grid>
          <Grid item xs={10} sm={4}>
            <input type="hidden" name={"captcha"} {...register("captcha")} />

            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={1} sm={4}></Grid>

          <Grid
            item
            container
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Button
              size={"large"}
              variant={"contained"}
              type={"submit"}
              //disabled={!submitVisible}
            >
              Stuur Aansoek
            </Button>
            <Typography
              variant={"subtitle2"}
              color={"textSecondary"}
              sx={{ marginTop: 2 }}
              align={"center"}
            >
              Ons sal binne 1-2 werksdae in kontak wees.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item container justifyContent={"center"} xs={12}>
            <Box>
              <Typography component="p" variant="body2" align="left">
                Deur jou aansoek te stuur, stem jy in tot ons{" "}
                <Link
                  component={LinkR}
                  color={"primary"}
                  to={"/privaatheidsbeleid"}
                  underline={"none"}
                >
                  Privaatheidsbeleid
                </Link>{" "}
                en{" "}
                <Link
                  component={LinkR}
                  color={"primary"}
                  to={"/bepalingsenvoorwaardes"}
                  underline={"none"}
                >
                  Bepalings en Voorwaardes
                </Link>
                .
              </Typography>
            </Box>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default FormHandelshoek;
