import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useAppDispatch, useAppSelector } from "store/configureStore";
import {
  BasketItemVariationPriceFormat,
  priceCalculatePerItem,
  priceCalculatePerItemTotal,
  priceFormatv2BasketItem,
} from "util/util";
import { Alert, LoadingButton } from "@mui/lab";
import { addBasketItemAsync, removeBasketItemAsync } from "store/basketSlice";
import { BasketItem } from "models/basket";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { addWishlistItemAsync, removeItemAsync } from "store/wishlistSlice";

interface Props {
  item: BasketItem;
}

const BasketItemRow = ({ item }: Props): JSX.Element => {
  const theme = useTheme();

  const [quantity, setQuantity] = useState(item.quantity);

  const { status} = useAppSelector((state) => state.basket);
  const {wishlistItems} = useAppSelector(state => state.wishlist);

  const quantityLimit = item.stockType === "Beperk"? item.quantityInStock : 100;

  const dispatch = useAppDispatch();

  async function handleAddToWishlist(productId: number){
    try {
      if (!localStorage.getItem("user")) {
        toast.warning('Teken in om die wenslys funksies te gebruik');
        return;
      } 

      if (wishlistItems.filter(x => x.product.id === productId).length > 0){
        await dispatch(removeItemAsync({productId: productId}));
        toast.success('Produk uit wenslys uitgehaal');
      }else{
      await dispatch(addWishlistItemAsync({productId: productId}));
      toast.success('Produk in wenslys bygevoeg');
      }
    }catch(error){
      toast.error(error);
    }
  }

  function handleUpdateCart() {
    if (!item || quantity > item.quantity) {
      const updatedQuantity = item ? quantity - item.quantity : quantity;
      dispatch(
        addBasketItemAsync({
          productId: item.productId,
          quantity: updatedQuantity,
          productVariationIds: item.basketItemVariations.map((v) => v.id),
          name: "qty",
        })
      );
    } else {
      const updatedQuantity = item.quantity - quantity;
      dispatch(
        removeBasketItemAsync({id: item.id, quantity: updatedQuantity})
      );
    }
  }
  

  return (
    <Box>
    <Box display={"flex"}>
      <Box
        component={Link}
        to={`/produk/${item.slug}`}
        sx={{
          //borderRadius: 2,
          width: 1,
          height: 1,
          maxWidth: { xs: 120, sm: 120 },
          marginRight: 2,
        }}
      >
        <Box
          component={"img"}
          src={"uploads/" + item.mainImageUrlThumbnail}
          alt={item.name}
          sx={{
            borderRadius: 2,
            width: 1,
            height: 1,
            maxWidth: { xs: 120, sm: 120 },
            marginRight: 2,
            filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
          }}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        width={1}
      >
        <Box sx={{ order: 1 }}>
          <Typography fontWeight={700} gutterBottom>
            {item.name}
          </Typography>
          <Typography
            color={"text.secondary"}
            variant={"subtitle2"}
            gutterBottom
          >
            Prys:{" "}
            <Typography
                variant={"inherit"}
                component={"span"}
                color={"inherit"}
                fontWeight={700}
              >
                {priceFormatv2BasketItem(item, true)}
              </Typography>
          </Typography>
         

          {item.basketItemVariations.map((variation) => (
            <Typography key={variation.id}
              color={"text.secondary"}
              variant={"subtitle2"}
              gutterBottom
            >
              {variation.type}: {" "}
              <Typography
                variant={"inherit"}
                component={"span"}
                color={"inherit"}
                fontWeight={700}
              >
                {variation.title} {BasketItemVariationPriceFormat(variation)}
              </Typography>
            </Typography>
          ))}

          
          <Typography
            color={"text.secondary"}
            variant={"subtitle2"}
            noWrap={true}
            gutterBottom
          >
            Hoeveelheid:{" "}
          </Typography>
          <Stack direction={"row"} spacing={2} marginTop={0}>
            <Box
              onClick={() => setQuantity(quantity - 1 >= 1 ? quantity - 1 : 1)}
              sx={{
                borderRadius: 1,
                paddingY: 1,
                paddingX: 2,
                height: "100%",
                border: `1px solid ${theme.palette.divider}`,
                cursor: quantity === 1 ? "not-allowed" : "pointer",
              }}
            >
              <Typography
                color={quantity === 1 ? "text.secondary" : "text.primary"}
              >
                -
              </Typography>
            </Box>
            <Typography
              component={"span"}
              fontWeight={700}
              sx={{
                paddingY: 1,
              }}
            >
              {quantity || 1}
            </Typography>
            <Box
              onClick={() => setQuantity(quantity + 1 <= quantityLimit ? quantity + 1 : quantityLimit)}
              sx={{
                borderRadius: 1,
                paddingY: 1,
                paddingX: 2,
                height: "100%",
                border: `1px solid ${theme.palette.divider}`,
                cursor: "pointer",
              }}
            >
              <Typography color="text.primary">+</Typography>
            </Box>
            <LoadingButton
              variant={"contained"}
              color={"primary"}
              size={"medium"}
              disabled={
                item?.quantity === quantity || (!item && quantity === 0)
              }
              loading={
                status === "pendingRemoveItem" + item.productId + "qty" ||
                status === "pendingAddItem" + item.productId + "qty"
              }
              onClick={handleUpdateCart}
            >
              Stoor
            </LoadingButton>
          </Stack>
          <Box marginTop={1} >
                              
                              {item.showStock &&
                                  <Typography
                                      variant={"caption"}
                                      color={"text.secondary"}
                                      marginLeft={0.5}
                                  >
                                      {item.quantityInStock} in voorraad.
                                  </Typography>}

                                  
                          </Box>
        </Box>
        <Stack
          spacing={1}
          direction={{ xs: "row", sm: "column" }}
          marginTop={{ xs: 2, sm: 0 }}
          sx={{ order: { xs: 3, sm: 2 } }}
        >
          <Typography fontWeight={700}>
            {priceCalculatePerItem(item)} x {item.quantity} ={" "}
            {priceCalculatePerItemTotal(item)}
          </Typography>

          <LoadingButton
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              color: "text.secondary",
              "&:hover": {
                color: "primary.main",
              },
            }}
            loading={status === "pendingWishlistItem" + item.productId + "add"}
            color="error"
            onClick={() => handleAddToWishlist(item.productId)}
          >
            <Box
              component={"svg"}
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              fill={wishlistItems?.filter(x => x.product.id === item.productId).length > 0? "red" : "none"}
              viewBox="0 0 24 24"
              stroke="currentColor"
              marginRight={0.5}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </Box>
            {wishlistItems?.filter(x => x.product.id === item.productId).length > 0? 'Verwyder uit Wenslys': 'Voeg in Wenslys'}
         
          </LoadingButton>
          <LoadingButton
            onClick={() =>
              dispatch(
                removeBasketItemAsync({id: item.id, quantity: item.quantity})
              )
            }
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              color: "text.secondary",
              "&:hover": {
                color: "primary.main",
              },
            }}
            loading={status === "pendingRemoveItem" + item.productId + "del"}
            color="error"
          >
            <Box
              component={"svg"}
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              marginRight={0.5}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </Box>
            Verwyder
          </LoadingButton>
        </Stack>

        <Stack
          spacing={1}
          direction={"row"}
          alignItems={"center"}
          marginTop={{ xs: 2, sm: 0 }}
          sx={{ order: { xs: 2, sm: 3 } }}
        ></Stack>
      </Box>
      
    </Box>
    <Box>
     
    
    {item.stockType === "Beperk" && item.quantity > item.quantityInStock &&
                                  <Alert severity="error">Hoeveelheid in mandjie is meer as hoeveelheid in voorraad. Verminder die hoeveelheid in jou mandjie om te kan voortgaan met jou bestelling.</Alert>
                              }

                              
    </Box>
          <Box>


              {item.errorProductUnavailable &&
                  <Alert severity="error">Die produk is ongelukkig nie meer beskikbaar nie. Verwyder asb die produk uit jou mandjie om te kan voortgaan met jou bestelling.</Alert>
              }


          </Box>
    </Box>
  );
};

const Items = (): JSX.Element => {
 
  const { basket } = useAppSelector((state) => state.basket);

  return (
    <Box>
      {basket.items.map((item, i) => (
        <Box key={i}>
          <BasketItemRow item={item} />
          <Divider
            sx={{
              marginY: { xs: 2, sm: 4 },
              display: i === basket.items.length - 1 ? "none" : "block",
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default Items;
