import { createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { Basket } from "../models/basket";

interface BasketState {
  basket: Basket | null;
  status: string;
}

const initialState: BasketState = {
  basket: null,
  status: "idle",
};

//Create async method
export const addBasketItemAsync = createAsyncThunk<
  Basket,
  {
    productId: number;
    quantity: number;
    productVariationIds: number[];
    name?: string;
  }
>(
  "basket/addBasketItemAsync",
  async ({ productId, quantity, productVariationIds }, thunkAPI) => {
    try {

      return await agent.Basket.addToBasket({
        productId: productId,
        quantity: quantity,
        productVariationIds: productVariationIds,
      });
    } catch (error: any) {
      thunkAPI.rejectWithValue(error);
      //throw(error);
    }
  }
);

export const fetchBasketAsync = createAsyncThunk<Basket>(
  "basket/fetchBasketAsync",
  async (_, thunkAPI) => {
    try {
      console.log('checking basket in slice');
      return await agent.Basket.get();
    } catch (error: any) {
      thunkAPI.rejectWithValue(error);
    }
  },
);

export const removeBasketItemAsync = createAsyncThunk<
  Basket,
  { id: number; quantity: number }
>("basket/removeBasketItemAsync", async ({ id, quantity }, thunkAPI) => {
  try {
    const result = await agent.Basket.removeFromBasket({
      id: id,
      quantity: quantity,
    });


    return result;
  } catch (error: any) {
    thunkAPI.rejectWithValue(error);
    //throw(error);
  }
});

export const adjustDeliveryAsync = createAsyncThunk<
  Basket,
  { deliveryOption: string}
>("basket/adjustDeliveryAsync", async ({deliveryOption}, thunkAPI) => {
  try {

    const result = await agent.Basket.adjustDeliveryOption(deliveryOption);

    return result;

  } catch (error: any) {
    return thunkAPI.rejectWithValue({error: error.response.data});
  }
});

export const addCouponAsync = createAsyncThunk<
  Basket,
  { code: string}
>("basket/addCouponAsync", async ({code}, thunkAPI) => {
  try {
    const result = await agent.Basket.addcoupon(code);

    return result;

  } catch (error: any) {
    return thunkAPI.rejectWithValue({error: error.response.data});
  }
});

export const removeCouponAsync = createAsyncThunk<
  Basket,
  { id: number}
>("basket/removeCouponAsync", async ({id}, thunkAPI) => {
  try {
    const result = await agent.Basket.removecoupon(id);
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({error: error.response.data});
  }
});

export const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    setBasket: (state, action) => {
      state.basket = action.payload;
    },
    clearBasket: (state) => {
      state.basket = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addBasketItemAsync.pending, (state, action) => {
      state.status =
        "pendingAddItem" + action.meta.arg.productId + action.meta.arg.name;
    });

    builder.addCase(addBasketItemAsync.fulfilled, (state, action) => {
      state.basket = action.payload;
      state.status = "idle";
      toast.success('Item in mandjie bygevoeg.');
    });

    builder.addCase(addBasketItemAsync.rejected, (state, action) => {
      state.status = "idle";
      throw(action.payload);
    });

    //Add coupon/voucher
    builder.addCase(addCouponAsync.fulfilled, (state, action) => {
      state.basket = action.payload;
      console.log('basket contents', action.payload);
      state.status = "idle";
    });

    builder.addCase(addCouponAsync.rejected, (state, action) => {
      state.status = "idle";
      throw(action.payload);
    });

     //Adjust delivery
     builder.addCase(adjustDeliveryAsync.fulfilled, (state, action) => {
      state.basket = action.payload;
      state.status = "idle";
    });

    builder.addCase(adjustDeliveryAsync.rejected, (state, action) => {
      state.status = "idle";
      throw(action.payload);
    });

     //Remove coupon/voucher
     builder.addCase(removeCouponAsync.fulfilled, (state, action) => {
      state.basket = action.payload;
      state.status = "idle";
    });

    builder.addCase(removeCouponAsync.rejected, (state, action) => {
      state.status = "idle";
      throw(action.payload);
    });

    //Remove basket items

    builder.addCase(removeBasketItemAsync.pending, (state, action) => {
      state.status = "pendingRemoveItem" + action.meta.arg.id;
    });

    builder.addCase(removeBasketItemAsync.fulfilled, (state, action) => {
      state.basket = action.payload;
      state.status = "idle";
      toast.success('Item uit mandjie verwyder.');
    });

    builder.addCase(removeBasketItemAsync.rejected, (state, action) => {
      state.status = "idle";
    });

    builder.addCase(fetchBasketAsync.pending, (state, action) => {
        state.status = "pendingLoad";
      });

      builder.addCase(fetchBasketAsync.fulfilled, (state, action) => {
        state.basket = action.payload;
        state.status = "idle";
      });

    // builder.addMatcher(
    //   isAnyOf(fetchBasketAsync.fulfilled),
    //   (state, action) => {
    //     state.basket = action.payload;
    //     state.status = "idle";
    //   }
    // );

    builder.addCase(fetchBasketAsync.rejected, (state, action) => {
      state.status = "idle";
      throw(action.payload);
    });

    // builder.addMatcher(
    //   isAnyOf(fetchBasketAsync.rejected),
    //   (state, action) => {
    //   state.status = "idle";
    //    throw(action.payload);
       
    //   }
    // );
  },
});

export const { setBasket, clearBasket } = basketSlice.actions;
