import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { alpha, useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as LinkR } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import {CardGiftcard, MonetizationOn, Info, Chair, EmojiEvents} from "@mui/icons-material"
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LoginIcon from "@mui/icons-material/Login";
import CreateIcon from "@mui/icons-material/Create";
import LanguageIcon from '@mui/icons-material/Language';
import Star from '@mui/icons-material/Star';

import { NavItem } from "./components";
import {
  AccountCircleOutlined,
  Logout,
  Settings,
  ShoppingCartOutlined,
  NotificationsNoneOutlined,
  FavoriteBorderOutlined,
} from "@mui/icons-material";
import {
  IconButton,
  Badge,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Link,
  Typography
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "store/configureStore";
import { signOut } from "store/accountSlice";
import { clearBasket } from "store/basketSlice";
import ThemeModeToggler from "components/ThemeModeToggler";
import { useTranslation } from "react-i18next";

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSidebarOpen: () => void;
  pages: {
    tuisblad: Array<PageItem>;
    oorklein: Array<PageItem>;
    maandboks: Array<PageItem>;
    agente: Array<PageItem>;
  };
  colorInvert?: boolean;
}

const Topbar = ({
  onSidebarOpen,
  pages,
  colorInvert = false,
}: Props): JSX.Element => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const {
    //tuisblad: tuisbladPages,
    oorklein: oorkleinPages,
    maandboks: maandboksPages,
    agente: agentePages,
  } = pages;

  const [language, setLanguage] = useState("af");

  const { t, i18n } = useTranslation();

  const { basket } = useAppSelector((state) => state.basket);
  const { user } = useAppSelector((state) => state.account);
  const { wishlistItems } = useAppSelector((state) => state.wishlist);

  const dispatch = useAppDispatch();

  function changeLanguage() {

    if (language === 'af'){
      setLanguage('en');
      i18n.changeLanguage('en');
    }else {
      setLanguage('af');
      i18n.changeLanguage('af');
    }

  }

  const basketItemCount = basket?.items.reduce(
    (sum, item) => sum + item.quantity,
    0
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { categories, loadingFilters } = useAppSelector(
    (state) => state.catalog
  );

  const categoryArray: PageItem[] = ([{
    isNew: false,
    title_af:  t("menu.allproducts") ,
    title_en:  t("menu.allproducts") ,
    id: 0,
    href: `/winkel?kategorie=alles`,
  }]).concat(categories.map((cat) => ({
    isNew: false,
    title_af: cat.title,
    title_en: cat.title,
    id: cat.id,
    href: `/winkel?kategorie=${cat.slug}`,
  })));

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={1}
    >
      <Box
        display={"flex"}
        component="a"
        href="/"
        title="Klein"
        width={{ xs: 100, md: 120 }}
      >
        <Box
          component={"img"}
          src={
            mode === "light" && !colorInvert
              ? "/images/Klein-logo.svg"
              : "/images/Klein-logo.svg"
          }
          height={0.5}
          width={0.5}

        />
      </Box>

      <Box
        sx={{ display: { xs: "none", md: "flex" } }}
        alignItems={"center"}
      >
        {/* <Box>
          <NavItem
            title={"Tuisblad"}
            id={"tuisblad"}
            items={tuisbladPages}
            colorInvert={colorInvert}
          />
        </Box> */}
        <Box marginLeft={0}>
          <NavItem
            title={t("menu.products")}
            id={"produkte-pages"}
            items={categoryArray}
            colorInvert={colorInvert}
            loading={loadingFilters}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={t("menu.agents")}
            id={"agente-pages"}
            items={agentePages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={t("menu.aboutklein")}
            id={"oorklein-pages"}
            items={oorkleinPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={t("menu.monthlyclub")}
            id={"maandboks-pages"}
            items={maandboksPages}
            colorInvert={colorInvert}
            //isNew={true}
          />
        </Box>
        <Box marginLeft={4}>
        <Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          //aria-describedby={"coloring-book"}
          sx={{ cursor: "pointer", textDecoration: "none" }}
          component={Link}
          href={"https://kleinbederf.com/uploads/meeroorklein/Inkleurboek_Klein.pdf"}
          target="_blank"
          referrerPolicy={"no-referrer"}
          
        >
          <Typography
            fontWeight={400}
            color={colorInvert ? "common.white" : "text.primary"}
          >
            {t("menu.coloringbook")}
          </Typography>{" "}
          
            <Box
              padding={0.5}
              display={"inline-flex"}
              borderRadius={1}
              bgcolor={"primary.main"}
              marginLeft={2}
            >
              <Typography
                variant={"caption"}
                sx={{ color: "common.white", lineHeight: 1 }}
              >
                   {t("menu.new")}
              </Typography>
            </Box>
          
        </Box>
      </Box>
        </Box>
        
      </Box>

      {/* <Box sx={{ flexGrow: 1 }} /> */}
      <Box >
        <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
          {!user && (
            <>
              <Box>
                <Link
                  underline="none"
                  component={LinkR}
                  to="/tekenin"
                  color={colorInvert ? "common.white" : "text.primary"}
                  sx={{ display: "flex", alignItems: "center", fontSize: '0.8em' }}
                >
                  {t("menu.signin")}
                </Link>
              </Box>

              <Typography sx={{ paddingX: 1, fontSize: '0.8em' }} color={colorInvert ? "common.white" : "text.primary"}>|</Typography>

              <Box marginRight={{ xs: 1, sm: 2 }}>
                <Link
                  underline="none"
                  component={LinkR}
                  to="/registreer"
                  color={colorInvert ? "common.white" : "text.primary"}
                  sx={{ display: "flex", alignItems: "center", fontSize: '0.8em' }}
                >
                 {t("menu.register")}
                </Link>
                
              </Box>
            </>
          )}

          {user && (
            <Typography
              color={colorInvert ? "common.white" : "text.primary"}
              sx={{ marginX: 3, fontSize: '0.9em'  }}
            >
              {t("menu.welcomeback") + " " + user.firstName}.
            </Typography>
          )}

          <Box>
            <ThemeModeToggler />
          </Box>
          <Box>
            <Button
              variant={"outlined"}
              onClick={changeLanguage}
              aria-label="Language toggler"
              // color={mode === "light" ? "primary" : "secondary"}
              sx={{
                borderRadius: 2,
                minWidth: "auto",
                padding: 0.5,
                borderColor: alpha(theme.palette.divider, 0.2),
                marginX: 1,
              }}
            >
              <LanguageIcon  sx={
                    mode === "light" && !colorInvert
                      ? { color: "dark-grey", width: 20, height: 20  }
                      : { color: "white", width: 20, height: 20  }} />
            </Button>
          </Box>
        </Box>

        <Box sx={{ display: { xs: "flex", md: "flex" } }}>
          {user && user.roles?.includes("Admin") && (
            <IconButton
              size="large"
              aria-label="Admin Area"
              component={LinkR}
              to="/admin/"
            >
              <Badge badgeContent={0} color="error">
                <Settings
                  sx={
                    mode === "light" && !colorInvert
                      ? { color: "dark-grey" }
                      : { color: "white" }
                  }
                />
              </Badge>
            </IconButton>
          )}

          <Box
            sx={{ display: { xs: "none", md: "flex" } }}
            alignItems={"center"}
          >
            <a
              href="https://www.facebook.com/Kleinprodukte"
              target={"_blank"}
              rel="noreferrer"
            >
              <IconButton size="large" aria-label="facebook link">
                <FacebookIcon />
              </IconButton>
            </a>
            <a
              href="https://www.instagram.com/klein_bederf/"
              target={"_blank"}
              rel="noreferrer"
            >
              <IconButton size="large" aria-label="instagram link">
                <InstagramIcon />
              </IconButton>
            </a>
          </Box>

          <IconButton size="large" aria-label="show 4 new mails">
            <Badge badgeContent={0} color="error">
              <NotificationsNoneOutlined
                sx={
                  mode === "light" && !colorInvert
                    ? { color: "dark-grey" }
                    : { color: "white" }
                }
              />
            </Badge>
          </IconButton>
          <IconButton
            size="large"
            aria-label="show wishlist items"
            component={LinkR}
            to="/wenslys"
          >
            <Badge badgeContent={wishlistItems?.length} color="error">
              <FavoriteBorderOutlined
                sx={
                  mode === "light" && !colorInvert
                    ? { color: "dark-grey" }
                    : { color: "white" }
                }
              />
            </Badge>
          </IconButton>
          <IconButton
            size="large"
            aria-label="show shopping basket"
            component={LinkR}
            to="/mandjie"
          >
            <Badge badgeContent={basketItemCount} color="error">
              <ShoppingCartOutlined
                sx={
                  mode === "light" && !colorInvert
                    ? { color: "dark-grey" }
                    : { color: "white" }
                }
              />
            </Badge>
          </IconButton>

          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            // aria-controls={menuId}
            aria-haspopup="true"
            // onClick={handleProfileMenuOpen}
            onClick={handleClick}
          >
            <AccountCircleOutlined
              sx={
                mode === "light" && !colorInvert
                  ? { color: "dark-grey" }
                  : { color: "white" }
              }
            />
          </IconButton>

          {!user && (
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem component={LinkR} to="/registreer">
                <ListItemIcon>
                  <CreateIcon fontSize="small" />
                </ListItemIcon>
                {t("menu.register")}
              </MenuItem>
              <MenuItem component={LinkR} to="/tekenin">
                <ListItemIcon>
                  <LoginIcon fontSize="small" />
                </ListItemIcon>
                {t("menu.signin")}
              </MenuItem>
            </Menu>
          )}

          {user && (
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem component={LinkR} to="/profiel-algemeen">
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                {t("menu.myprofile")}
              </MenuItem>
              <MenuItem component={LinkR} to="/profiel-bestellings">
                <ListItemIcon>
                  <LocalShippingIcon fontSize="small" />
                </ListItemIcon>
                {t("menu.orders")}
              </MenuItem>
              <MenuItem component={LinkR} to="/profiel-geskenkbewyse">
                <ListItemIcon>
                  <CardGiftcard fontSize="small" />
                </ListItemIcon>
                {t("menu.giftvouchers")}
              </MenuItem>
              {["Agent", "Admin"].some((r) => user.roles?.includes(r)) && 
              <Box>
              <Divider />
              <MenuItem component={LinkR} to="/profiel-agente-welkom">
                <ListItemIcon>
                  <Info fontSize="small" />
                </ListItemIcon>
                {t("menu.welcomnewagents")}
              </MenuItem>
              <MenuItem component={LinkR} to="/profiel-agente-inligting">
                <ListItemIcon>
                  <Chair fontSize="small" />
                </ListItemIcon>
                {t("menu.agentroom")}
              </MenuItem>
              <MenuItem component={LinkR} to="/profiel-agente-kommissie">
                <ListItemIcon>
                  <MonetizationOn fontSize="small" />
                </ListItemIcon>
                {t("menu.agentcommissionnetwork")}
              </MenuItem>
              <MenuItem component={LinkR} to="/profiel-agente-ranks">
                <ListItemIcon>
                  <Star fontSize="small" />
                </ListItemIcon>
                {t("menu.ranks")}
              </MenuItem>
              <MenuItem component={LinkR} to="/profiel-challenge">
                <ListItemIcon>
                  <EmojiEvents fontSize="small" />
                </ListItemIcon>
                {t("menu.challenge")}
              </MenuItem>
              </Box>
              }
                {["Retailer", "Admin"].some((r) => user.roles?.includes(r)) && 
              <Box>
              <Divider />
              <MenuItem component={LinkR} to="/profiel-retailers-kommissie">
                <ListItemIcon>
                  <MonetizationOn fontSize="small" />
                </ListItemIcon>
                {t("menu.retailercommissionnetwork")}
              </MenuItem>
              <MenuItem component={LinkR} to="/profiel-challenge">
                <ListItemIcon>
                  <EmojiEvents fontSize="small" />
                </ListItemIcon>
                {t("menu.challenge")}
              </MenuItem>
              </Box>
              }

              <Divider />

              <MenuItem
                onClick={() => {
                  dispatch(signOut());
                  dispatch(clearBasket());
                }}
              >
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                {t("menu.signout")}
              </MenuItem>
            </Menu>
          )}

<Box
        sx={{ display: { xs: "flex", md: "none" } }}
        alignItems={"center"}
        marginLeft={4}
      >
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={"outlined"}
          sx={{
            borderRadius: 2,
            minWidth: "auto",
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
        </Box>
      </Box>
     
    </Box>
  );
};

export default Topbar;
