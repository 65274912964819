import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import agent from "../../../api/agent";
import {
  DataGrid,
  GridRowsProp,
  GridColumns,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { CommissionPayout } from "models/commission";
import { Button, CircularProgress, Typography } from "@mui/material";
import CommissionPayoutForm from "./Commission_Payout_Form";

export default function CommissionPayoutsPage() {
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState(0);
  const [loadingBalance, setLoadingBalance] = useState(true);
  const [showRequestForm, setShowRequestForm] = useState(false);

  const [rows, setRows] = useState<GridRowsProp>([]);

  function getUserCommissionBalance() {
    setLoadingBalance(true);
    agent.Commission.getUserCommissionBalance()
      .then((balance: number) => {
        setBalance(balance);
      })
      .catch((error) =>
        toast.error("Aggenee, kon nie jou balans aflaai nie, probeer asb weer")
      )
      .finally(() => setLoadingBalance(false));
  }

  function getUserCommissionList() {
    setLoading(true);
    agent.Commission.getUserCommissionPayoutsList()
      .then((list: CommissionPayout[]) => {
        const payoutlist: GridRowsProp = list.map((payout) => payout);
        setRows(payoutlist);
      })
      .catch((error) =>
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer")
      )
      .finally(() => setLoading(false));
  }

  function closeModal(reload: boolean) {
    if (reload) {
      //Load balance
      getUserCommissionBalance();

      //Load commission list.
      getUserCommissionList();
    }
    setShowRequestForm(false);
  }

  useEffect(() => {
    //Load balance
    getUserCommissionBalance();

    //Load commission list.
    getUserCommissionList();
  }, []);

  const columns: GridColumns = [
    { field: "id", headerName: "Verwysing No", width: 120 },
    {
      field: "dateProcessed",
      headerName: "Datum",
      width: 130,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }

        const date = new Date(params.value);

        const valueFormatted = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return valueFormatted;
      },
    },
    {
      field: "amountPaid",
      headerName: "Bedrag",
      width: 100,
      valueGetter: (params) => "R" + params.row.amountPaid.toFixed(2),
    },
    {
      field: "bankAccountBankName",
      headerName: "Bank Rekening",
      width: 300,
      valueGetter: (params) =>
        params.row.bankAccountBankName +
        ", " +
        params.row.bankAccountBranchNo +
        ", " +
        params.row.bankAccountType +
        ", " +
        params.row.bankAccountNo +
        ".",
    },

   
    { field: "status", headerName: "Status", width: 150 },
    { field: "reference", headerName: "Verwysing", width: 200 },
    { field: "notes", headerName: "Notas", width: 250 },
  ];

  return (
    <Box>
      <Box marginBottom={3} sx={{ display: "flex" }}>
        {loadingBalance && <CircularProgress />}
        {!loadingBalance && (
          <Box>
            <Typography>
              Jou balans is huidiglik <strong>R{balance.toFixed(2)}</strong> -
              Jy kan 'n uitbetaling versoek insit sodra jou balans bo R400 is.
            </Typography>
            <Button
              sx={{ margin: 2 }}
              disabled={balance < 400}
              variant="contained"
              onClick={() => setShowRequestForm(true)}
            >
              Versoek Uitbetaling
            </Button>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          height: 500,
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          components={{
            LoadingOverlay: LinearProgress,
          }}
          loading={loading}
        />
      </Box>
      {showRequestForm && (
        <CommissionPayoutForm
          closeModal={closeModal}
          maxAmount={balance}
        />
      )}
    </Box>
  );
}
