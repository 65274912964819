import FixedLayout from "layouts/Fixed";
import {
  Typography,
  Box,
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  Tabs,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import {
  DataGrid,
  GridRowsProp,
  GridColumns,
  GridActionsCellItem,
  GridRowId,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridToolbarContainer,
  GridToolbarExport
} from "@mui/x-data-grid";
import { FormContact } from "models/forms";
import React from "react";

export default function ContactForm() {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);
  const [openDeleteConfirmationId, setOpenDeleteConfirmationId] =
    React.useState<GridRowId>("0");
  const [openDetailsModal, setOpenDetailsModal] = React.useState(false);
  const [openDetailsModalRow, setOpenDetailsModalRow] = React.useState<any>({});
  const [tabStatus, setTabStatus] = React.useState(0);

  const handleStatusChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabStatus(newValue);

    //if 0 then unread,
    //if 1 then read.

    const filter = newValue === 1? "read" : "unread";

    //Load from database filtered values
    setLoading(true);

    agent.Admin.formContactList(filter)
    .then((c: FormContact[]) => {
      const submissions: GridRowsProp = c.map((submission) => ({
        id: submission.id,
        name: submission.name,
        email: submission.email,
        message: submission.message,
        createDate: submission.createDate,
        read: submission.read,
      }));
      setRows(submissions);
    })
    .catch((error) => {

      toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
    })
    .finally(() => setLoading(false));
  };

  

  useEffect(() => {
    agent.Admin.formContactList("unread")
      .then((c: FormContact[]) => {
        const submissions: GridRowsProp = c.map((submission) => ({
          id: submission.id,
          name: submission.name,
          email: submission.email,
          message: submission.message,
          createDate: submission.createDate,
          read: submission.read,
        }));
        setRows(submissions);
      })
      .catch((error) => {

        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    setOpenDeleteConfirmation(true);
    setOpenDeleteConfirmationId(id);
  };

  const handleDeleteClickConfirmed = () => {
    setLoading(true);

    setOpenDeleteConfirmation(false);
    const id = openDeleteConfirmationId;

    agent.Admin.formContactDelete(parseInt(id.toString()))
      .then((c) => setRows(rows.filter((row) => row.id !== id)))
      .catch((error) => {
        toast.warning("Aggenee, dit het nie gewerk nie, probeer asb weer");

      })
      .finally(() => setLoading(false));
  };

  const handleOpenClick = (id: GridRowId) => () => {
    setOpenDetailsModal(true);
    setOpenDetailsModalRow(rows.find((row) => row.id === id));
  };

  const handleMarkAsReadClick = (id: GridRowId) => () => {
    setLoading(true);

    agent.Admin.formContactMarkAsRead(parseInt(id.toString()))
      .then((c) => {
        const oldrow = rows.find((row) => row.id === id);

        const updatedRow = { ...oldrow, read: !oldrow.read, isNew: false };
        const newrows = rows.map((row) =>
          row.id === oldrow.id ? updatedRow : row
        );
        setRows(newrows);
      })
      .catch((error) => {
        toast.warning("Aggenee, dit het nie gewerk nie, probeer asb weer");
        
      })
      .finally(() => setLoading(false));
  };

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Naam",
      flex: 1,
    },
    { field: "email", headerName: "E-Posadres", flex: 1 },
    { field: "message", headerName: "Boodskap", flex: 1 },
    {
      field: "createDate",
      headerName: "Datum",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }

        const date = new Date(params.value);

        const valueFormatted = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        });
        return valueFormatted;
      },
    },
    {
      field: "read",
      headerName: "Status",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Boolean>) => {
        if (params.value === true) {
          return (
            <Chip
              label="Reeds Gelees"
              color="success"
              variant="outlined"
              size="small"
            />
          );
        } else {
          return (
            <Chip
              label="Nie Gelees"
              color="error"
              variant="outlined"
              size="small"
            />
          );
        }
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Opsies",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={
              rows.find((x) => x.id === id)?.read ? (
                <MarkunreadIcon />
              ) : (
                <MarkAsUnreadIcon />
              )
            }
            label={
              rows.find((x) => x.id === id)?.read
                ? "Mark as Read"
                : "Mark as Unread"
            }
            onClick={handleMarkAsReadClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="Read"
            onClick={handleOpenClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <FixedLayout>
      <Box
        width={1}
        margin={"0 auto"}
        paddingX={2}
        paddingY={{ xs: 2, sm: 2, md: 2 }}
      >
        <div>
          <Dialog
            open={openDetailsModal}
            onClose={() => setOpenDetailsModal(false)}
          >
            <Box sx={{ width: 500 }}></Box>
            <DialogTitle id="alert-dialog-title">
              {"Vorm Inligting"}
            </DialogTitle>
            <DialogContent>
              <Typography sx={{ mt: 2 }}>
                <strong>Naam: </strong> <br />
                {openDetailsModalRow?.name ?? ""}
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <strong>E-Posadres: </strong> <br />
                {openDetailsModalRow?.email ?? ""}
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <strong>Boodskap: </strong> <br />
                {openDetailsModalRow?.message ?? ""}
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <strong>Datum: </strong>
                <br />{" "}
                {openDetailsModalRow?.createDate &&
                  new Date(openDetailsModalRow?.createDate).toLocaleDateString(
                    "en-ZA",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    }
                  )}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDetailsModal(false)}>
                Gaan Terug
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div>
          <Dialog
            open={openDeleteConfirmation}
            onClose={() => setOpenDeleteConfirmation(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Bevestig"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Is jy seker jy wil die boodskap verwyder?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDeleteConfirmation(false)}>
                Gaan Terug
              </Button>
              <Button
                color="error"
                onClick={handleDeleteClickConfirmed}
                autoFocus
              >
                Verwyder
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ p: 2 }} variant="h5">
            Kontak Vorm
          </Typography>
        </Box>

        <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', padding: 1 }}>
        <Tabs value={tabStatus} onChange={handleStatusChange} aria-label="tabs">
          <Tab label="Unread" id={'Unread'} />
          <Tab label="Read" id={'Read'} />
        </Tabs>
      </Box>
    </Box>
        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
              LoadingOverlay: LinearProgress,
            }}
            loading={loading}
          />
        </Box>
      </Box>
    </FixedLayout>
  );
}
