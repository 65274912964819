import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import Container from "components/Container";

import { Topbar, Sidebar, Footer } from "./components";

import pages from "../navigation";
import { LinearProgress, Paper } from "@mui/material";
import { useAppSelector } from "store/configureStore";

interface Props {
  children: React.ReactNode;
  colorInvert?: boolean;
  bgcolor?: string;
}

const Main = ({
  children,
  colorInvert = false,
  bgcolor = "transparent",
}: Props): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const {loading } = useAppSelector((state) => state.system);

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = (): void => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = (): void => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 2,
  });

  return (
    <Box>
       
      <AppBar
        position={"sticky"}
        sx={{
          top: 0,
          backgroundColor: trigger ? theme.palette.background.paper : bgcolor,
        }}
        elevation={trigger ? 1 : 0}
      >
         {/* <Box bgcolor={bgcolor} position={'relative'} zIndex={theme.zIndex.appBar}>
        <Container maxWidth="95%" paddingTop={'8px !important'} paddingBottom={'0 !important'}>
         
        </Container>
      </Box> */}
        <Container paddingY={1} maxWidth="95%">
          {/* <Box sx={{paddingBottom: 1}}>
          <TopNav colorInvert={colorInvert} />
          </Box> */}
          <Topbar
            onSidebarOpen={handleSidebarOpen}
            pages={pages}
            colorInvert={trigger ? false : colorInvert}
          />
          {loading && (
            <Box sx={{ width: "100%", paddingX: 7 }}>
              <LinearProgress />
            </Box>
          )}
        </Container>
      </AppBar>

      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      />
      <main>
        {children}
       
      </main>
      {/* <Container paddingY={2}>
        <Footer />
      </Container> */}

      <Paper sx={{ position: { xs: "block", sm: "fixed"}, bottom: 0, left: 0, right: 0, zIndex: 1400 }} elevation={3}>
        <Divider />
        <Box  sx={{padding:1}}>
        <Footer />
        </Box>
      </Paper>
    </Box>
  );
};

export default Main;
