import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { useController, UseControllerProps } from "react-hook-form";

interface Props extends UseControllerProps {
    label: string;
    items: string[];
    defaultValue: string;
    trigger?: () => void;
    fullWidth?: boolean;
}

export default function AppSelectList(props: Props) {

    const {fieldState, field} = useController({...props, defaultValue: props.defaultValue});

  


  return (
      <FormControl fullWidth={props.fullWidth === undefined || props.fullWidth} error={!!fieldState.error}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        value={field.value}
        label={props.label}
        onChange={(e) => {field.onChange(e); if (props.trigger !== undefined) { props.trigger(); console.log('trigger');}}}
      >
       {props.items.map((item, index) => (
            <MenuItem key={index} value={item}>{item}</MenuItem>
       ))}
       
      </Select>
      <FormHelperText>
        {fieldState.error?.message}
      </FormHelperText>
    </FormControl>
  );
}
