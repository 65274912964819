import Box from "@mui/material/Box";

import Main from "layouts/Main";
import Container from "components/Container";

import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Link,
  Button,
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";

const mock = [
  {
    feedback:
      "Om altyd top-kwaliteit, natuurlike produkte te vervaardig wat bekostig- en maklik bekombaar is reg oor Suid-Afrika!",
    title: "Ons Missie",
  },
  {
    feedback:
      "Dit is vir ons baie belangrik om geen skadelike geursels of kleurstowwe te gebruik nie.  Klein se produkte word gemaak van natuurlike olies en sover moontlik natuurlike basisse om gesonde en gelukkige velle te verseker!",
    title: "Ons benadering",
  },
  {
    feedback:
      "Elkeen van ons produkte word altyd gemaak met ‘n KLEIN bietjie ekstra liefde…",
    title: "Wat maak ons anders?",
  },
];

const articles = [
  {
    image: "/images/Klein-Landbouweekblad.jpg",
    description: "Landbouweekblad",
    title: "Artikel",
    color: "#1e2022",
    url: "https://kleinbederf.com/uploads/Klein%20-%20Landbouweekblad.pdf",
  },
  {
    image: "/images/Brosjure-Handelshoek.jpeg",
    description: "Handelshoek",
    title: "Brosjure",
    color: "#1e2022",
    url: "https://kleinbederf.com/uploads/Handeslhoek%20Inligting%2023.pdf",
  },
];

export default function MeerOorKleinBladsy() {
  const theme = useTheme();

  return (
    <Main>
      <Box>
        <Box
          sx={{
            position: "relative",
            backgroundColor: theme.palette.alternate.main,
            backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
            marginTop: -13,
            paddingTop: 13,
          }}
        >
          <Container>
            <Box>
              <Box
                marginBottom={{ xs: 0, sm: 2 }}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Typography
                  variant="h3"
                  gutterBottom
                  align={"center"}
                  sx={{
                    fontWeight: 900,
                  }}
                >
                  Meer Oor Klein
                </Typography>
                <Typography
                  variant="h6"
                  component="p"
                  color="text.primary"
                  align={"center"}
                  sx={{ marginBottom: 1 }}
                >
                  Leer meer oor Klein en ons top-kwaliteit, natuurlike produkte!
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>

        <Container>
          <Box marginBottom={4}>
            <Typography
              sx={{
                textTransform: "uppercase",
                fontWeight: "medium",
              }}
              gutterBottom
              color={"secondary"}
            >
              ARTIKELS / MEDIA / BROSJURE
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 700,
              }}
            >
              Lees meer oor Klein...
            </Typography>
            <Box marginTop={2}></Box>
          </Box>
          <Box>
            <Grid container spacing={2}>
              {articles.map((item, i) => (
                <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                  <Box
                    sx={{
                      position: "relative",
                      overflow: "hidden",
                      borderRadius: 2,
                      "&:hover": {
                        "& img": {
                          transform: "scale(1.2)",
                        },
                      },
                      "& .lazy-load-image-loaded": {
                        display: "flex !important",
                      },
                    }}
                  >
                    <Link href={item.url} target={"_blank"}>
                      <Box
                        component={LazyLoadImage}
                        height={1}
                        width={1}
                        src={item.image}
                        alt="..."
                        effect="blur"
                        //minHeight={{ xs: 300, md: 300 }}
                        //maxHeight={{ xs: 600, md: 700 }}
                        sx={{
                          transition: "transform .7s ease !important",
                          transform: "scale(1.0)",
                          objectFit: "cover",
                          filter:
                            theme.palette.mode === "dark"
                              ? "brightness(0.7)"
                              : "none",
                        }}
                      />
                      <Box
                        position={"absolute"}
                        bottom={0}
                        left={0}
                        right={0}
                        padding={2}
                        sx={{
                          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, ${item.color})`,
                        }}
                      >
                        <Typography
                          variant={"h4"}
                          fontWeight={700}
                          sx={{ color: "common.white" }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          variant={"h6"}
                          fontWeight={700}
                          sx={{ color: "common.white" }}
                          gutterBottom
                        >
                          {item.description}
                        </Typography>

                        <Button size={"large"} variant={"contained"}>
                          {" "}
                          Laai Af
                        </Button>
                      </Box>
                    </Link>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
        <Box bgcolor={"alternate.main"} sx={{marginY: 4}}>
          <Container>
            <Box>
              <Grid container spacing={2}>
                {mock.map((item, i) => (
                  <Grid item xs={12} md={4} key={i}>
                    <Box
                      width={1}
                      height={1}
                      data-aos={"fade-up"}
                      data-aos-delay={i * 100}
                      data-aos-offset={100}
                      data-aos-duration={600}
                      component={Card}
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      boxShadow={0}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          align={"center"}
                          variant={"h6"}
                          sx={{ fontWeight: 700 }}
                          gutterBottom
                        >
                          {item.title}
                        </Typography>
                        <Typography align={"center"} color="text.secondary">
                          {item.feedback}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>
    </Main>
  );
}
