import {
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useController, UseControllerProps } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

interface Props extends UseControllerProps {}

export default function AppRecaptcha(props: Props) {
  const { fieldState, field } = useController({
    ...props,
    defaultValue: false,
  });

  return (
    <FormControl fullWidth error={!!fieldState.error}>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_SITE_KEY}
        onChange={(e) => field.onChange(e)}
      />

      <FormHelperText>{fieldState.error?.message}</FormHelperText>
    </FormControl>
  );
}
