import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { PaginatedResponse } from "../models/pagination";
//import { store } from "../store/configureStore";

let store;

export const injectStore = (_store) => {
  store = _store;
};


const sleep = () => new Promise((resolve) => setTimeout(resolve, 1000));

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;
axios.defaults.timeout = 600000; 
axios.defaults.timeoutErrorMessage = "Die server het nie vinnig genoeg gereageer, probeer asb. weer";

const responseBody = (response: AxiosResponse) => response.data;

//If token exist then send with request as auth header.
axios.interceptors.request.use(async (config) => {
  if (process.env.NODE_ENV === "development") await sleep();

  const token = store.getState().account.user?.token;
  if (token) config.headers!.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    if (process.env.NODE_ENV === "development") await sleep();

    const pagination = response.headers["pagination"];

    if (pagination) {
      response.data = new PaginatedResponse(
        response.data,
        JSON.parse(pagination)
      );
      return response;
    }

    return response;
  },
  function (error: AxiosError) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    const data: any = error.response!.data;
    const status = error.response!.status;

    switch (status) {
      case 400:
        if (data.errors) {
          // const modelStateErrors: string[] = [];
          // for (const key in data.errors) {
          //   if (data.errors[key]) {
          //     modelStateErrors.push(data.errors[key]);
          //   }
          // }

          // throw modelStateErrors.flat();
          throw(data.errors);
        }
        toast.error(data.title!);
        break;
      case 401:
        //toast.error("Jy het nie permissie om dit te doen nie!");
        localStorage.removeItem('user');
        window.location.replace('https://kleinbederf.com/tekenin');
        break;
      case 403:
        toast.error("Jy het nie permissie om dit te doen nie!");
        break;
      case 500:
        toast.error("Server fout!");
        break;
      default:
        toast.error(
          "Ai, 'n onverwagte fout het voorgekom. Probeer asb weer om ons webblad te laai"
        );
        break;
    }

    return Promise.reject(error);
  }
);

const requests = {
  get: (url: string, params?: URLSearchParams ) => axios.get(url, {params} ).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  delete: (url: string) => axios.delete(url).then(responseBody),
  postForm: (url: string, data: FormData) => axios.post(url, data, { headers: { "Content-type": "multipart/form-data" }}).then(responseBody),
  putForm: (url: string, data: FormData) =>
    axios
      .put(url, data, { headers: { "Content-type": "multipart/form-data" }})
      .then(responseBody),
};

// function createFormData(item: any) {
//   let formData = new FormData();

//   for (const key in item) {
//     formData.append(key, item[key]);
//   }

//   return formData;
// }

const Reviews = {
  reviewList: (status: string) => requests.get(`reviews/filter/${status}`),
  reviewDetails: (id: number) => requests.get(`reviews/${id}`),
  reviewCreate: (details: any) => requests.post("reviews", details),
  reviewUpdate: (details: any) => requests.put("reviews", details),
  reviewDelete: (id: number) => requests.delete(`reviews/${id}`),
}

const Coupon = {
  couponsMyVoucherList: () => requests.get("coupon/myvouchers/"),
}

const Admin = {

  profileCreate: (values: any) => requests.post("account/createprofile", values),

  getProduct: (id: number) => requests.get(`products/${id}`),
  createProduct: (product: any) => requests.post("products", product),
  updateProduct: (product: any) => requests.put("products", product),
  deleteProduct: (id: number) => requests.delete(`products/${id}`),

  variationList: (productid: number) => requests.get(`variations/product/${productid}`),
  getVariation: (id: number) => requests.get(`variations/${id}`),
  createVariation: (variation: any) => requests.post("variations", variation),
  updateVariation: (variation: any) => requests.put("variations", variation),
  deleteVariation: (id: number) => requests.delete(`variations/${id}`),

  addProductImage: (data: any) => requests.postForm("productImages", data),
  deleteProductImage: (id: number) => requests.delete(`productImages/${id}`),
  setMainProductImage: (id: any) => requests.post(`productImages/setmain/${id}`, {}),

  monthlyReportList: (date: string) => requests.get(`reports/monthlyreport/${date}`),
  annualReportList: (year: number) => requests.get(`reports/annualreport/${year}`),
  productionList: (age: number) => requests.get(`reports/productionList/${age}`),

  couponsList: () => requests.get("coupon/"),
  couponDelete: (id: number) => requests.delete(`coupon/${id}`),
  couponUpdate: (coupon: any) => requests.put("coupon", coupon),
  couponCreate: (coupon: any) => requests.post("coupon", coupon),

  commissionHierarchyList: () => requests.get("commissionHierarchy/"),
  commissionHierarchyDetails: (id: number) => requests.get(`commissionHierarchy/${id}`),
  commissionHierarchyDelete: (id: number) => requests.delete(`commissionHierarchy/${id}`),
  commissionHierarchyCreate: (details: any) => requests.post("commissionHierarchy", details),
  commissionHierarchyUpdate: (details: any) => requests.put("commissionHierarchy", details),
  commissionUsersList: () => requests.get("commissionHierarchy/usersList"),

  ranksUserPointsDetailedList: () => requests.get("ranks/admin_user_points_detail"),

  challengeOrders: () => requests.get("challenge/admin_challenge_orders"),
  challengeSummary: () => requests.get("challenge/admin_challenge_summary"),

  commissionEarnedList: () => requests.get("commissionEarned/"),
  commissionEarnedListSummary: () => requests.get("commissionEarned/summary"),
  commissionEarnedDetails: (id: number) => requests.get(`commissionEarned/${id}`),
  commissionEarnedSoftDelete: (id: number) => requests.delete(`commissionEarned/${id}`),
  //commissionEarnedCreate: (details: any) => requests.post("commissionEarned", details),

  commissionUserBalanceList: () => requests.get("commission/admin_userbalance/"),

  commissionPayoutList: (filter: string) => requests.get(`commissionpayouts/filter/${filter}`),
  commissionPayoutDetails: (id: number) => requests.get(`commissionpayouts/${id}`),
  commissionPayoutCreate: (details: any) => requests.post("commissionpayouts", details),
  commissionPayoutUpdate: (details: any) => requests.put("commissionpayouts", details),
  commissionPayoutSoftDelete: (id: number) => requests.delete(`commissionpayouts/${id}`),

  paymentList: (orderid: number) => requests.get(`payments/order/${orderid}`),
  paymentDetails: (id: number) => requests.get(`payments/${id}`),
  paymentCreate: (details: any) => requests.post("payments", details),
  paymentUpdate: (details: any) => requests.put("payments", details),
  paymentDelete: (id: number) => requests.delete(`payments/${id}`),

  refundList: (orderid: number) => requests.get(`refunds/order/${orderid}`),
  refundDetails: (id: number) => requests.get(`refunds/${id}`),
  refundCreate: (details: any) => requests.post("refunds", details),
  refundUpdate: (details: any) => requests.put("refunds", details),
  refundDelete: (id: number) => requests.delete(`refunds/${id}`),

  noteList: (orderid: number) => requests.get(`notes/order/${orderid}`),
  noteDetails: (id: number) => requests.get(`notes/${id}`),
  noteCreate: (details: any) => requests.post("notes", details),
  noteUpdate: (details: any) => requests.put("notes", details),
  noteDelete: (id: number) => requests.delete(`notes/${id}`),

  membershipList: (filter: string, typeid: number) => requests.get(`membership/filter/${filter}/type/${typeid}`),
  membershipDetails: (id: number) => requests.get(`membership/${id}`),
  membershipUpdate: (details: any) => requests.put("membership/", details),
  membershipDelete: (id: number) => requests.delete(`membership/${id}`),

  subscriptionList_damesboks: (filter: string) => requests.get(`subscriptions/damesboks/filter/${filter}`),
  subscriptionDetails_damesboks: (id: number) => requests.get(`subscriptions/damesboks/${id}`),
  subscriptionUpdate_damesboks: (details: any) => requests.put("subscriptions/damesboks/", details),
  subscriptionDelete_damesboks: (id: number) => requests.delete(`subscriptions/damesboks/${id}`),

  subscriptionList_kleintjiesboks: (filter: string) => requests.get(`subscriptions/kleintjiesboks/filter/${filter}`),
  subscriptionDetails_kleintjiesboks: (id: number) => requests.get(`subscriptions/kleintjiesboks/${id}`),
  subscriptionUpdate_kleintjiesboks: (details: any) => requests.put("subscriptions/kleintjiesboks/", details),
  subscriptionDelete_kleintjiesboks: (id: number) => requests.delete(`subscriptions/kleintjiesboks/${id}`),

  subscriptionList_oliesboks: (filter: string) => requests.get(`subscriptions/oliesboks/filter/${filter}`),
  subscriptionDetails_oliesboks: (id: number) => requests.get(`subscriptions/oliesboks/${id}`),
  subscriptionUpdate_oliesboks: (details: any) => requests.put("subscriptions/oliesboks/", details),
  subscriptionDelete_oliesboks: (id: number) => requests.delete(`subscriptions/oliesboks/${id}`),

  //ordersList: () => requests.get("orders/adminOrdersList"),
  ordersList: (filter: string) => requests.get(`orders/adminOrdersList/${filter}`),
  orderDetails: (id: number) => requests.get(`orders/adminOrderDetails/${id}`),
  orderUpdate: (details: any) => requests.put("orders", details),
  orderSendPackingListEmail: (id: number) => requests.get(`orders/SendOrderPackinglistEmail/${id}`),
  orderSendConfirmationEmail: (id: number) => requests.get(`orders/SendOrderConfirmationEmail/${id}`),
  orderSendTaxInvoiceEmail: (id: number) => requests.get(`orders/SendTaxInvoice/${id}`),
  orderSendOrderStatusUpdateEmail: (id: number) => requests.get(`orders/SendOrderStatusUpdateEmail/${id}`),
  orderDownloadPackingListPDF: (id:number) =>  axios.get(`orders/DownloadPackingListPDF/${id}`, { responseType: 'blob' }).then(responseBody),
  orderSetMonthlyOrder: (id: number) => requests.get(`orders/adminSetMonthlyOrder/${id}`),
  
  formAgentMarkAsProcessed: (id: number) => requests.get(`FormsAgent/markasprocessed/${id}`),
  formAgentList: (filter: string) => requests.get(`FormsAgent/filter/${filter}`),
  formAgentDetails: (id: number) => requests.get(`FormsAgent/${id}`),
  formAgentDelete: (id: number) => requests.delete(`FormsAgent/${id}`),
  formAgentStatus: () => requests.get(`FormsAgent/registrationstatus`),

  formRetailerMarkAsProcessed: (id: number) => requests.get(`FormsRetailer/markasprocessed/${id}`),
  formRetailerList: (filter: string) => requests.get(`FormsRetailer/filter/${filter}`),
  formRetailerDetails: (id: number) => requests.get(`FormsRetailer/${id}`),
  formRetailerDelete: (id: number) => requests.delete(`FormsRetailer/${id}`),
  formRetailerStatus: () => requests.get(`FormsRetailer/registrationstatus`),
 
  formHandelshoekMarkAsRead: (id: number) => requests.get(`FormsHandelshoek/markasread/${id}`),
  formHandelshoekList: (filter: string) => requests.get(`FormsHandelshoek/filter/${filter}`),
  formHandelshoekDetails: (id: number) => requests.get(`FormsHandelshoek/${id}`),
  formHandelshoekDelete: (id: number) => requests.delete(`FormsHandelshoek/${id}`),

  formNewsletterMarkAsRead: (id: number) => requests.get(`FormsNewsletter/markasread/${id}`),
  formNewsletterList: (filter: string) => requests.get(`FormsNewsletter/filter/${filter}`),
  formNewsletterDetails: (id: number) => requests.get(`FormsNewsletter/${id}`),
  formNewsletterDelete: (id: number) => requests.delete(`FormsNewsletter/${id}`),

  formContactList: (filter: string) => requests.get(`FormsContact/filter/${filter}`),
  formContactDetails: (id: number) => requests.get(`FormsContact/${id}`),
  formContactMarkAsRead: (id: number) => requests.get(`FormsContact/markasread/${id}`),
  formContactDelete: (id: number) => requests.delete(`FormsContact/${id}`),

};

const Category = {
  list: () => requests.get(`categories`),
  getCategory: (id: number) => requests.get(`categories/${id}`),
  createCategory: (details: any) => requests.post("categories", details),
  updateCategory: (details: any) => requests.put("categories", details),
  deleteCategory: (id: number) => requests.delete(`categories/${id}`),
};

const Wishlist = {
  list: () => requests.get(`wishlist`),
  addItem: (productId: number) => requests.post(`wishlist/${productId}`, {}),
  removeItem: (productId: number) => requests.delete(`wishlist/${productId}`),
};

const Users = {
  list: () => requests.get(`users`),
 // get: (id: string) => requests.get(`users/${id}`),
  update: (details: any) => requests.put("users", details),
  delete: (id: string) => requests.delete(`users/${id}`),
};


const Forms = {
  formRetailerRegistrationSubmit: (formdetails: any) => requests.post("FormsRetailer", formdetails),
  formAgentRegistrationSubmit: (formdetails: any) => requests.post("FormsAgent", formdetails),
  formHandelshoekSubmit: (formdetails: any) => requests.post("FormsHandelshoek", formdetails),
  formContactSubmit: (formdetails: any) => requests.post("FormsContact", formdetails),
  formNewsletterSubmit: (formdetails: any) => requests.post("FormsNewsletter", formdetails),
  
};

const Subscriptions = {
  subscriptionCreate_damesboks: (details: any) => requests.post("subscriptions/damesboks/", details),
  subscriptionCreate_kleintjiesboks: (details: any) => requests.post("subscriptions/kleintjiesboks/", details),
  subscriptionCreate_oliesboks: (details: any) => requests.post("subscriptions/oliesboks/", details),
  membershipCreate: (details: any) => requests.post("membership/", details),
}


const Catalog = {
  list: (params: URLSearchParams) => requests.get("products", params),
  details: (id: number) => requests.get(`products/${id}`),
  fetchfilters: () => requests.get("products/filters"),

  //Admin Calls
  adminProducts: () => requests.get("products/adminProducts"),
 
  //adminProductDetails: (id: number) => requests.get(`adminProductDetails/${id}`),
};

const Basket = {
  get: () => requests.get("basket"),
  addToBasket: (basketitem: any) => requests.post("basket", basketitem),
  removeFromBasket: (details: {id: number, quantity: number}) => requests.put("basket", details),
  addcoupon: (code: string) => requests.get(`basket/addcoupon/${code}`),
  removecoupon: (id: number) => requests.get(`basket/removecoupon/${id}`),
  adjustDeliveryOption: (deliveryOption: string) => requests.post(`basket/adjustDeliveryOption?deliveryOption=${deliveryOption}`, {}),
};

const Commission = {
  getstructure: ()=> requests.get("commission/structure"),
  getUserCommissionList: () => requests.get("commission/list"),
  getUserCommissionBalance: () => requests.get("commission/balance"),
  addUserCommissionPayoutRequest: (request: any) => requests.post("commissionpayouts/payoutrequest", request),
  getUserNetworkNameList: () => requests.get("commission/namelist"),
  changefromretailertoagent: ()=> requests.get("commission/changefromretailertoagent"),
  getUserCommissionPayoutsList: () => requests.get("commissionpayouts/list"),

}

const Ranks = {
  getUserAchievements: ()=> requests.get("ranks/user_achievements"),
  getUserPoints: ()=> requests.get("ranks/user_points"),
  getUserReport: (month: string)=> requests.get(`ranks/report/${month}`),

}

const Challenge = {
  getUserChallengeOrders: ()=> requests.get("challenge/user_challenge_orders"),
}

const MonthlyOrder = {
  checkIfQualify: ()=> requests.get("monthlyOrder/qualify"),
}

const Account = {
  login: (values: any) => requests.post("account/login", values),
  register: (values: any) => requests.post("account/register", values),
  requestcode: (email: string) => requests.get(`account/requestcode?email=${email}`),
  setpassword: (values: any) => requests.post("account/setpassword", values),
  currentUser: () => requests.get("account/currentUser"),
  //fetchAddress: () => requests.get("account/savedAddress"),
  update_general: (details: any) => requests.put("account/profile_general", details),
  bevestigepos: (kode: string, epos: string) =>
    requests.get(
      `account/bevestigregistrasie?epos=${epos}&kode=${encodeURIComponent(
        kode
      )}`
    ),


  addressList: () => requests.get(`account/address/`),
  addressDetail: (id: number) => requests.get(`account/address/${id}`),
  addressCreate: (details: any) => requests.post("account/address/", details),
  addressUpdate: (details: any) => requests.put("account/address/", details),
  addressDelete: (id: number) => requests.delete(`account/address/${id}`),

  emailchangerequest: (newemail: string) => requests.get(`account/emailchangerequest?newemail=${newemail}`),
  emailchangeconfirm: (details: any) => requests.put('account/emailchangeconfirm', details),
};

const Handelshoek = {
  handelshoekList: () => requests.get(`handelshoek/`),
  handelshoekDetail: (id: number) => requests.get(`handelshoek/${id}`),
  handelshoekCreate: (details: any) => requests.post("handelshoek/", details),
  handelshoekUpdate: (details: any) => requests.put("handelshoek/", details),
  handelshoekDelete: (id: number) => requests.delete(`handelshoek/${id}`),
}

const Catalogue = {
  catalogList: () => requests.get(`catalog/`),
  catalogDetail: (id: number) => requests.get(`catalog/${id}`),
  catalogCreate: (details: any) => requests.post("catalog/", details),
  catalogUpdate: (details: any) => requests.put("catalog/", details),
  catalogDelete: (id: number) => requests.delete(`catalog/${id}`),
}

const Orders = {
  list: () => requests.get("orders"),
  details: (id: number) => requests.get(`orders/${id}`),
  create: (values: any) => requests.post("orders", values),
  downloadInvoicePDF: (id:number) =>  axios.get(`orders/DownloadInvoicePDF/${id}`, { responseType: 'blob' }).then(responseBody),
};

const Settings = {
  getSetting: (type: string) => requests.get(`settings/${type}`),
};

const agent = {
  Catalog,
  Basket,
  Account,
  Orders,
  Admin,
  Settings,
  Category,
  Users,
  Wishlist,
  Forms, 
  Reviews,
  Commission,
  Subscriptions,
  Coupon,
  MonthlyOrder,
  Handelshoek,
  Catalogue,
  Ranks,
  Challenge
};

export default agent;
