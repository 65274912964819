import { Alert } from "@mui/material";
import React, {useEffect } from "react";

export const FormatErrors: React.FC<{
  listoferrors?: string[];
}> = ({ listoferrors }) => {
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [listoferrors]);


  if ((listoferrors && listoferrors?.length > 0)){
    return (
      <Alert severity="error" sx={{ marginY: 5 }}>
        <span>Die volgende foute benodig korreksie:</span>
        <ul>
          {listoferrors.length > 0 && listoferrors.map((error) => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      </Alert>
    );
  }

  return null;
};
