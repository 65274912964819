import Slider from 'react-slick';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { useTheme } from '@mui/material/styles';

const mock = [
  {
    media: '/images/klein5.jpg',
  },
  {
    media: '/images/winkel-hartenbos.jpeg',
  },
  {
    media: '/images/klein6.jpg',
  },
  {
    media: '/images/klein7.jpg',
  },
  {
    media: '/images/klein8.jpg',
  },
  {
    media: '/images/klein9.jpg',
  },
  {
    media: '/images/winkel-potchefstroom.jpeg',
  },
  {
    media: '/images/klein10.jpg',
  },
  {
    media: '/images/klein11.jpg',
  },
  {
    media: '/images/klein12.jpg',
  },
  {
    media: '/images/klein13.jpg',
  },

];

const Hero = (): JSX.Element => {
  const theme = useTheme();
  // const isMd = useMediaQuery(theme.breakpoints.up('md'), {
  //   defaultMatches: true,
  // });

  const sliderOpts = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <Box>
     
      <Box
        data-aos={'fade-up'}
        maxWidth={{ xs: 420, sm: 620, md: 1 }}
        margin={'0 auto'}
      >
        <Slider {...sliderOpts}>
          {mock.map((item, i) => (
            <Box key={i} padding={{ xs: 1, md: 2, lg: 3 }}>
              <Box
                display={'block'}
                width={1}
                height={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .4s ease-in-out',
                  '&:hover': {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                <Box
                  component={Card}
                  width={1}
                  height={1}
                  display={'flex'}
                  flexDirection={'column'}
                  sx={{ backgroundImage: 'none' }}
                >
                  <CardMedia
                    image={item.media}
                    sx={{
                      position: 'relative',
                      height: { xs: 270, sm: 340, md: 480 },
                      overflow: 'hidden',
                    }}
                  >
                   
                  </CardMedia>
                
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default Hero;
