import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress
} from "@mui/material";
import agent from "api/agent";
import { toast } from "react-toastify";
import { MonthlyReportWrapper } from "models/monthlyreport";
// import { LoadingButton } from "@mui/lab";

export default function AnnualReports() {

  const current_year = new Date().toLocaleDateString('en-us', { year:"numeric"})

  const [reportYear, setReportYear] = useState(current_year);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<MonthlyReportWrapper>(null);


  useEffect(() => {
    setLoading(true);
    setData(null);

    agent.Admin.annualReportList(Number(reportYear))
      .then((c: MonthlyReportWrapper) => {
        setData(c);
        console.log("data", c);
      })
      .catch((error) =>
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer")
      )
      .finally(() => setLoading(false));
  }, [reportYear]);

  return (
    <Box
      width={1}
      margin={"0 auto"}
      paddingX={2}
      paddingY={{ xs: 2, sm: 2, md: 2 }}
    >
      <Typography variant="h5" marginBottom={2}>Jaar Verslag</Typography>
    
        <Box textAlign={"right"}>
        
          <FormControl sx={{paddingRight: 1}}>
            <InputLabel id="demo-simple-select-label">Jaar</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={reportYear}
              label="Maand"
              onChange={(e)=> setReportYear(e.target.value)}
              sx={{width: 100}}
              size="small"
            >
              <MenuItem value={"2023"}>2023</MenuItem>
              <MenuItem value={"2024"}>2024</MenuItem>
              <MenuItem value={"2025"}>2025</MenuItem>
              <MenuItem value={"2026"}>2026</MenuItem>
            </Select>
          </FormControl>
          {/* <LoadingButton loading={loading} sx={{width: 50, padding:1, margin:0}} variant="contained" size="small" onClick={handleChange}>Go</LoadingButton> */}
        </Box>
     

      {loading &&  <Box sx={{ width: '100%' }} marginTop={5}>
      <LinearProgress />
    </Box>}

      {!loading && !data && <span>Fout! Geen data was gelaai</span>}

      {!loading && data && (
        <Box>
          <Box marginY={2}>
            <Typography variant="h6" marginBottom={2}>
              Totale Verkope
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell align="right"># Bestellings</TableCell>
                    <TableCell align="right">Sub Totaal</TableCell>
                    <TableCell align="right">Afslag</TableCell>
                    <TableCell align="right">Koepons / Geskenkbewys</TableCell>
                    <TableCell align="right">Aflewering</TableCell>
                    <TableCell align="right">Totaal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && data.monthlyReportOrders.map((row) => (
                    <TableRow
                      key={row.status}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.status}
                      </TableCell>
                      <TableCell align="right">
                        {row.numberOfOrders.toLocaleString(undefined, {minimumFractionDigits: 2})}
                      </TableCell>
                      <TableCell align="right">
                        {row.amountSubtotal.toLocaleString(undefined, {minimumFractionDigits: 2})}
                      </TableCell>
                      <TableCell align="right">
                        {row.amountDiscount.toLocaleString(undefined, {minimumFractionDigits: 2})}
                      </TableCell>
                      <TableCell align="right">
                        {row.amountCoupon.toLocaleString(undefined, {minimumFractionDigits: 2})}
                      </TableCell>
                      <TableCell align="right">
                        {row.amountDeliveryFee.toLocaleString(undefined, {minimumFractionDigits: 2})}
                      </TableCell>
                      <TableCell align="right">
                        {row.amountTotal.toLocaleString(undefined, {minimumFractionDigits: 2})}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box marginTop={5}>
            <Typography variant="h6" marginBottom={2}>
              Top Kliente
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Naam</TableCell>
                    <TableCell>E-Pos</TableCell>
                    <TableCell>Groep</TableCell>
                    <TableCell align="right">Bestellings #</TableCell>
                    <TableCell align="right">Totaal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.monthlyReportTopCustomers.map((row, index) => (
                    <>
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{row.fullName}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.customerType}</TableCell>
                        <TableCell align="right">{row.orderQuantity}</TableCell>
                        <TableCell align="right">{row.totalAmount.toLocaleString(undefined, {minimumFractionDigits: 2})}</TableCell>
                       
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box marginTop={5}>
            <Typography variant="h6" marginBottom={2}>
              Top Produkte
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Produk</TableCell>
                    <TableCell align="right">Hoeveelheid</TableCell>
                    <TableCell align="right">Variasie (Grootte)</TableCell>
                    <TableCell align="right">Variasie (Geur)</TableCell>
                    <TableCell align="right">Variasie (Ander)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.monthlyReportTopSales.map((row, index) => (
                    <>
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{row.product}</TableCell>
                        <TableCell>{row.quantity}</TableCell>
                        <TableCell>
                          <ul>
                            {" "}
                            {row.monthlyReportTopSalesVariations.filter(f => f.type === "Grootte").map((vari) => (
                              <li key={vari.variation}>
                                {vari.quantity} : {vari.variation}
                              </li>
                            ))}
                          </ul>
                        </TableCell>
                        <TableCell>
                          <ul>
                            {" "}
                            {row.monthlyReportTopSalesVariations.filter(f => f.type === "Geur").map((vari) => (
                              <li key={vari.variation}>
                                {vari.quantity} : {vari.variation}
                              </li>
                            ))}
                          </ul>
                        </TableCell>
                        <TableCell>
                          <ul>
                            {" "}
                            {row.monthlyReportTopSalesVariations.filter(f => f.type !== "Grootte" && f.type !== "Geur").map((vari) => (
                              <li key={vari.variation}>
                                {vari.quantity} : {vari.variation}
                              </li>
                            ))}
                          </ul>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </Box>
  );
}
