import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useAppDispatch, useAppSelector } from "store/configureStore";
import { setProductParams } from "store/catalogSlice";

import { TextField } from "@mui/material";

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSidebarOpen: () => void;
}

const Topbar = ({ onSidebarOpen }: Props): JSX.Element => {
  const theme = useTheme();

  const { productParams } = useAppSelector((state) => state.catalog);

  //const [sortBy, setSortBy] = useState(2);

  const dispatch = useAppDispatch();

  const handleSelectChange = (event) => {
    //setSortBy(event.target.value);

    dispatch(setProductParams({ orderBy: event.target.value }));
  };

  const handleFilterNameChange = (event) => {
    dispatch(setProductParams({ filterTitle: event.target.value }));
  };

  return (
    <Box
      display={"flex"}
      flexDirection={{ xs: "column", sm: "row" }}
      alignItems={{ xs: "flex-start", sm: "center" }}
      justifyContent={"space-between"}
    >
      <Button
        onClick={() => onSidebarOpen()}
        aria-label="Menu"
        variant={"outlined"}
        sx={{
          minWidth: "auto",
          paddingY: 1,
          borderColor: theme.palette.divider,
          color: theme.palette.text.primary,
          display: { xs: "flex", md: "none" },
          marginRight: 2,
        }}
        startIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={20}
            height={20}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
            />
          </svg>
        }
      >
        Filters
      </Button>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={1}
        paddingTop={2}
      >
       
         <Box display={"flex"} alignItems={"left"} justifySelf={"flex-start"} width={'100%'}>
          <TextField
            variant="outlined"
            name={"name"}
            placeholder="Filter produk naam"
            fullWidth
            
            value={productParams.filterTitle}
            onChange={handleFilterNameChange}
            sx={{marginRight: 2}}
            size='small'
          />
          </Box>
       
        <Box display={"flex"} alignItems={"center"} justifySelf={"flex-end"} width={{xs: 100, sm: 350}}  >
          <Typography
            color={"text.primary"}
            marginRight={1}
            display={{ xs: "none", sm: "block" }}
          >
            Sorteer volgens
          </Typography>
          <FormControl sx={{width: 100}} >
            <Select
              value={productParams.orderBy}
              onChange={handleSelectChange}
              sx={{
                ".MuiSelect-select.MuiSelect-outlined": {
                  paddingY: "9px !important",
                },
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.divider,
                },
              }}
            >
              <MenuItem value={"A-Z"}>A-Z</MenuItem>
              <MenuItem value={"Z-A"}>Z-A</MenuItem>
              {/* <MenuItem value={''}>Beste Verkoper</MenuItem>
              <MenuItem value={4}>Beste Resensie</MenuItem> */}
              <MenuItem value={"PriceLow"}>Prys: laag tot hoog</MenuItem>
              <MenuItem value={"PriceHigh"}>Prys: hoog tot laag</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
