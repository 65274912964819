import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, CircularProgress, Grid } from '@mui/material';
import { Link as LinkR } from "react-router-dom";
import agent from 'api/agent';
import { toast } from 'react-toastify';
import { Handelshoek } from "models/handelshoek";

const mock = [
  {
    media: '/images/winkel-hartenbos.jpeg',
    title: 'Klein Hartenbos',
    subtitle:
      'Winkel GF14, Hartenbos Seefront, Hartenbos',
  },

  // {
  //   media: '/images/winkel-potchefstroom.jpeg',
  //   title: 'Klein Potchefstroom',
  //   subtitle:
  //     'Winkel Nr 10, Vyfhoek Winkel Kompleks, Potchefstroom',
  // },

];

const Articles = (): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const sliderOpts = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: isMd ? 2 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const sliderOptsStores = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: isMd ? 1 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<Handelshoek[]>([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response: Handelshoek[] =
          await agent.Handelshoek.handelshoekList();
        setList(response);
      } catch (error) {
        toast.error(
          "Aggenee, kon nie die handelshoek lys aflaai nie. Probeer asb weer."
        );
      }

      setLoading(false);
    }

    fetchData();
  }, []);

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
        >
          WINKELS / HANDELSHOEKE
        </Typography>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Besoek een van ons winkels / handelshoeke in jou area
        </Typography>
        <Box marginTop={2}>
          
          
        </Box>
      </Box>
      <Grid container spacing={2} >
        <Grid item xs={12} sm={4}>
        <Button
              component={LinkR}
              to='ons-winkels'
          sx={{marginLeft: 2}}
            variant="contained"
            color="primary"
            size="large"
            endIcon={
              <Box
                component={'svg'}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width={24}
                height={24}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </Box>
            }
          >
            Sien al ons Winkels
          </Button>
        <Box
        data-aos={'fade-up'}
        maxWidth={{ xs: 420, sm: 620, md: 1 }}
        margin={'0 auto'}
      >
        <Slider {...sliderOptsStores}>
          {mock.map((item, i) => (
            <Box key={i} padding={{ xs: 1, md: 2, lg: 3 }}>
              <Box
                display={'block'}
                width={1}
                height={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                <Box
                  component={Card}
                  width={1}
                  height={1}
                  display={'flex'}
                  flexDirection={'column'}
                  sx={{ backgroundImage: 'none' }}
                >
                  <CardMedia
                    title={item.title}
                    image={item.media}
                    sx={{
                      position: 'relative',
                      height: { xs: 240, sm: 340, md: 280 },
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      component={'svg'}
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 1921 273"
                      sx={{
                        position: 'absolute',
                        width: '100%',
                        left: 0,
                        bottom: 0,
                        right: 0,
                        zIndex: 1,
                      }}
                    >
                      <polygon
                        fill={theme.palette.background.paper}
                        points="0,273 1921,273 1921,0 "
                      />
                    </Box>
                  </CardMedia>
                  <CardContent>
                    <Typography
                      variant={'h6'}
                      gutterBottom
                      align={'left'}
                      sx={{ fontWeight: 700 }}
                    >
                      {item.title}
                    </Typography>
                    <Typography align={'left'} color={'text.secondary'}>
                      {item.subtitle}
                    </Typography>
                  </CardContent>
                  <Box flexGrow={1} />
                  {/* <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button>Learn more</Button>
                  </CardActions> */}
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
        <Button
          component={LinkR}
          to='ons-handelshoeke'
            variant="contained"
            color="primary"
            size="large"
            endIcon={
              <Box
                component={'svg'}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width={24}
                height={24}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </Box>
            }
          >
            Sien al ons Handelshoeke
          </Button>
          {loading && <CircularProgress />
              
            }

            
        <Box
        data-aos={'fade-up'}
        maxWidth={{ xs: 420, sm: 620, md: 1 }}
        margin={'0 auto'}
      >
        <Slider {...sliderOpts}>
          {list.sort(() => Math.random() - 0.5).map((item, i) => (
            <Box key={i} padding={{ xs: 1, md: 2, lg: 3 }}>
              <Box
                display={'block'}
                width={1}
                height={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                <Box
                  component={Card}
                  width={1}
                  height={1}
                  display={'flex'}
                  flexDirection={'column'}
                  sx={{ backgroundImage: 'none' }}
                >
                  <CardMedia
                    title={item.title}
                    image={`/uploads/handelshoeke/${item.imageFileName}`}
                    sx={{
                      position: 'relative',
                      height: { xs: 240, sm: 340, md: 280 },
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      component={'svg'}
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 1921 273"
                      sx={{
                        position: 'absolute',
                        width: '100%',
                        left: 0,
                        bottom: 0,
                        right: 0,
                        zIndex: 1,
                      }}
                    >
                      <polygon
                        fill={theme.palette.background.paper}
                        points="0,273 1921,273 1921,0 "
                      />
                    </Box>
                  </CardMedia>
                  <CardContent>
                    <Typography
                      variant={'h6'}
                      gutterBottom
                      align={'left'}
                      sx={{ fontWeight: 700 }}
                    >
                      {item.title}
                    </Typography>
                    <Typography align={'left'} color={'text.secondary'}>
                      {item.description}, {item.province}, {item.country}<br/>
                      {item.contact}
                    </Typography>
                  </CardContent>
                  <Box flexGrow={1} />
                  {/* <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button>Learn more</Button>
                  </CardActions> */}
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>

        </Grid>
      </Grid>
      
    </Box>
  );
};

export default Articles;
