import { FormControl, FormHelperText} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useController, UseControllerProps } from "react-hook-form";
import 'dayjs/locale/af';

interface Props extends UseControllerProps {
    label: string;
    format: string;
    disabled?: boolean;
    defaultValue?: string;
}

export default function AppDateTime(props: Props) {

    const { fieldState, field } = useController({ ...props })
    const [textvalue, setTextValue] = useState(null);

    useEffect(() => {

        setTextValue(dayjs(props.defaultValue, 'DD MMMM YYYY', 'af'));
    }, [props.defaultValue]);

    return (

        <FormControl fullWidth error={!!fieldState.error}>
            {/*<InputLabel>{props.label}</InputLabel>*/}


            <DatePicker
                value={textvalue}
                label={props.label}
                onChange={(e) => { setTextValue(e); field.onChange(dayjs(e).format('DD MMMM YYYY'));  }}
                disabled={props.disabled}
                format={props.format}
                name={field.name }
            />
            <FormHelperText>
                {fieldState.error?.message}
            </FormHelperText>

        </FormControl>
    )
}