import { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Main from "../../layouts/Main";
import Container from "components/Container";

import Items from "./Items";
import Headline from "./Headline";

import { Button, Skeleton } from "@mui/material";
import { fetchBasketAsync } from "store/basketSlice";
import { useAppDispatch, useAppSelector } from "store/configureStore";
import CartSummary from "./CartSummary";
import { Link } from "react-router-dom";
import FactoryClosedNotice from "util/FactoryClosedNotice";

const Cart = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { status, basket } = useAppSelector((state) => state.basket);


  useEffect(() => {
    console.log('checking basket in view');
    dispatch(fetchBasketAsync());

  }, [dispatch]);



  if (status.includes("pending")) {
    return (
      <Main>
        <Box bgcolor={"alternate.main"}>
          <Container paddingY={{ xs: 2, sm: 2.5 }}>
            <Headline />
          </Container>
        </Box>
        <Container>
          <Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <Skeleton variant="rectangular" height={150} />
                <Skeleton
                  variant="rectangular"
                  height={150}
                  sx={{ marginY: 5 }}
                />
                <Skeleton
                  variant="rectangular"
                  height={150}
                  sx={{ marginY: 5 }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Skeleton variant="rectangular" height={520} />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Main>
    );
  }

  if (!basket || basket.items?.length === 0)
    return (
      <Main>
        <Box bgcolor={"alternate.main"}>
          <Container paddingY={{ xs: 2, sm: 2.5 }}>
            <Headline />
          </Container>
        </Box>
        <Container>
          <Typography>
            Jou mandjie is leeg, besoek ons Winkel vir oulike produkte.{" "}
          </Typography>
        </Container>
      </Main>
    );

  return (
    <Main>
      <Box bgcolor={"alternate.main"}>
        <Container paddingY={{ xs: 2, sm: 2.5 }}>
          <Headline />
        </Container>
      </Box>
      <Container>
      <FactoryClosedNotice/>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Items />
            </Grid>
            <Grid item xs={12} md={4}>
              <CartSummary/>
             <Box sx={{textAlign: 'center'}}>
              <Button
                      component={Link}
                      to="/bestel"
                      variant={"contained"}
                      size={"large"}
                      disabled={basket.items.filter(x => x.errorStockQuantity || x.errorProductUnavailable).length > 0}
                      sx={{margin: 2, width: '90%'}}
                    >
                      Plaas Bestelling
                </Button>
                </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Main>
  );
};

export default Cart;
