import * as yup from 'yup';

export const validationSchema = [
    yup.object({
        customerFirstName: yup.string().required('Naam is nodig'),
        customerLastName: yup.string().required('Van is nodig'),
        customerPhoneNumber: yup.string().required('Kontak nommer is nodig'),
        customerEmail: yup.string().email().required(),
        acceptTermsAndConditions: yup.bool().isTrue('Bevestiging van die Bepalings en Voorwaardes is nodig om te kan voortgaan'),
    }),
    yup.object(),
    yup.object(),
    yup.object({
        paymentOption: yup.string().required()
    }),

]