import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { User } from "../models/user";
import { toast } from "react-toastify";
import { setBasket } from "./basketSlice";

interface AccountState {
    user: User | null;
    userLoaded: boolean;

}

const initialState: AccountState = {
    user: null,
    userLoaded: false
}

export const confirmEmailAndSignInUser = createAsyncThunk<User, any>(
    'account/confirmEmailAndSignInUser',
    async (data, thunkAPI) => {
        try{
            const userDto = await agent.Account.bevestigepos(data.kode, data.epos);
            const {basket, ...user} = userDto;

            if(basket) 
                thunkAPI.dispatch(setBasket(basket));
            
            localStorage.setItem('user', JSON.stringify(user));

            
            return user;

        } catch (error: any) {
     
            return thunkAPI.rejectWithValue({error: error.response.data});
        }
    }
)

export const signInUser = createAsyncThunk<User, any>(
    'account/signInUser',
    async (data, thunkAPI) => {
        try{
            const userDto = await agent.Account.login(data);
            const {basket, ...user} = userDto;

            if(basket) 
                thunkAPI.dispatch(setBasket(basket));

            
            localStorage.setItem('user', JSON.stringify(user));
            return user;

        } catch (error: any) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const setPasswordAndSignInUser = createAsyncThunk<User, any>(
    'account/setPasswordAndSignInUser',
    async (data, thunkAPI) => {
        try{
            const userDto = await agent.Account.setpassword(data);
            const {basket, ...user} = userDto;

            if(basket) 
                thunkAPI.dispatch(setBasket(basket));

            
            localStorage.setItem('user', JSON.stringify(user));
            return user;

        } catch (error: any) {
           
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const setNewEmailAndSignInUser = createAsyncThunk<User, any>(
    'account/setNewEmailAndSignInUser',
    async (data, thunkAPI) => {
        try{
            const userDto = await agent.Account.emailchangeconfirm(data);
            const {basket, ...user} = userDto;

            if(basket) 
                thunkAPI.dispatch(setBasket(basket));

            
            localStorage.setItem('user', JSON.stringify(user));
            return user;

        } catch (error: any) {
           
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// export const fetchCurrentUserOrders = createAsyncThunk<User>(
//     'account/fetchCurrentUserOrders',
//     async (_, thunkAPI) => {

//        try{

//             const orders = await agent.Account.currentUserOrders();
//             if(orders) thunkAPI.dispatch(setOrders(orders));

//         } catch (error: any) {
//             return thunkAPI.rejectWithValue({error: error.data});
//         }
//     },
//     {
//         condition: () => {
//             if (!localStorage.getItem('user')) return false;
//         }
//     }
// )

export const fetchCurrentUser = createAsyncThunk<User>(
    'account/fetchCurrentUser',
    async (_, thunkAPI) => {

      

        if (!localStorage.getItem('user')) {
            thunkAPI.dispatch(finishLoading());
            return null;
        }

        thunkAPI.dispatch(setUser(JSON.parse(localStorage.getItem('user')!)));

        try{
          
            const userDto = await agent.Account.currentUser();
            const {basket, ...user} = userDto;

          

            if(basket) thunkAPI.dispatch(setBasket(basket));
            localStorage.setItem('user', JSON.stringify(user));
            return user;

        } catch (error: any) {
            if (error?.code === "ERR_NETWORK"){
                return;
            }

            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
)

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        signOut:(state) => {
            state.user = null;
            localStorage.removeItem('user');
            //history.push('/');
            state.userLoaded = true;
            window.location.replace('https://kleinbederf.com');
            // window.location.reload();
            
        },
        finishLoading:(state) => {
           
            state.userLoaded = true;
        },
        setUser:(state, action) => {
            let claims = JSON.parse(atob(action.payload.token.split('.')[1]));
            let roles = claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
            state.user = {...action.payload, roles: typeof(roles) === 'string' ? [roles] : roles}
            state.userLoaded = true;
        },
       
    },
    extraReducers: (builder => {
        builder.addCase(fetchCurrentUser.rejected, (state) => {
            
            state.user = null;
            localStorage.removeItem('user');
            toast.error('Sessie het verval - teken weer in asseblief.');
            state.userLoaded = true;
            //history.push('/');
        });
        builder.addCase(signInUser.rejected, (state, action) => {
            state.userLoaded = true;
            throw action.payload;
        });
        builder.addCase(setPasswordAndSignInUser.rejected, (state, action) => {
            state.userLoaded = true;
            throw action.payload;
        });
        builder.addCase(setNewEmailAndSignInUser.rejected, (state, action) => {
            state.userLoaded = true;
            throw action.payload;
        });
        builder.addCase(confirmEmailAndSignInUser.rejected, (state, action) => {
            state.userLoaded = true;
            throw action.payload;
        });
        builder.addMatcher(isAnyOf(signInUser.fulfilled, fetchCurrentUser.fulfilled, confirmEmailAndSignInUser.fulfilled, setPasswordAndSignInUser.fulfilled, setNewEmailAndSignInUser.fulfilled), (state, action) => {
         
            if (action.payload != null){
              
                let claims = JSON.parse(atob(action.payload.token.split('.')[1]));
                let roles = claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
                state.user = {...action.payload, roles: typeof(roles) === 'string' ? [roles] : roles}
                state.userLoaded = true;

            }
        });

       
    })
})

export const {signOut, setUser, finishLoading} = accountSlice.actions;