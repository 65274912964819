import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";

interface Props {
  isOffline: boolean;
}

export default function OfflineModal({ isOffline }: Props) {
  const handleClose = () => {};

  return (
    <div>
      <Dialog
        open={isOffline}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Typography
            fontWeight={700}
            sx={{ color: (theme) => theme.palette.primary.contrastText }}
          >
            Internet konneksie fout!
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ marginTop: 2 }}>
          <DialogContentText id="alert-dialog-description">
            Ons webwerf benodig 'n internet konneksie om te werk, en dit lyk of
            jou internet nie op die oomlik gekoppel is nie.
            <br />
            <br />
            Ons webwerf sal weer werk sodra die internet konneksie herstel is.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} autoFocus>
            Herlaai
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
