import {
  Typography,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";
import agent from "../../api/agent";
import React from "react";
import { toast } from "react-toastify";
import {
  DataGrid,
  GridRowsProp,
  GridColumns,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { ProductionList } from "models/productionList";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function ProductionListReport() {
  const [loading, setLoading] = useState(true);

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [age, setAge] = useState<number>(0); //Number of days the order is old. 

  useEffect(() => {

    setLoading(true);

    agent.Admin.productionList(age)
      .then((c: ProductionList[]) => {
        const items: GridRowsProp = c.map((item, index) => ({
          id: index,
          productId: item.productId,
          productName: item.productName,
          quantity: item.quantity,
          //variations: item.variations.map(function (variation) {return variation.variationType + ': ' + variation.variationName;}).join(';  '),
          variation: item.variation,
        }));
        setRows(items);
      })
      .catch((error) =>
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer")
      )
      .finally(() => setLoading(false));
  }, [age]);

  const columns: GridColumns = [
    { field: "productId", headerName: "Produk Kode", width: 150 },
    { field: "productName", headerName: "Produk", width: 250 },
    { field: "variation", headerName: "Variasie", width: 500 },
    {
      field: "quantity",
      headerName: "Hoeveelheid",
      width: 120,
    },
  ];

  return (
    <Box
      width={1}
      //margin={"0 auto"}
      //paddingX={2}
      //paddingY={{ xs: 2, sm: 2, md: 2 }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography sx={{ paddingY: 2 }} variant="h5">
          Produksie Lys
        </Typography>
      </Box>

      <Box
        sx={{
          height: 500,
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="age-filter-label">Bestelling Ouderdom (Dae)</InputLabel>
          <Select
            labelId="age-filter-label"
            id="age-filter"
            value={age}
            label="Bestelling Ouderdom"
            onChange={e => setAge(parseInt(e.target.value.toString()))}
            sx={{width: '300px', marginY: 2}}
          >
            <MenuItem value={0}>Alles</MenuItem>
            <MenuItem value={1}>1+</MenuItem>
            <MenuItem value={2}>2+</MenuItem>
            <MenuItem value={3}>3+</MenuItem>
            <MenuItem value={4}>4+</MenuItem>
            <MenuItem value={5}>5+</MenuItem>
            <MenuItem value={10}>10+</MenuItem>
            <MenuItem value={15}>15+</MenuItem>
            <MenuItem value={20}>20+</MenuItem>
          </Select>
        </FormControl>

        <DataGrid
          rows={rows}
          columns={columns}
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
          }}
          loading={loading}
        />
      </Box>
    </Box>
  );
}
