import { createSlice } from "@reduxjs/toolkit";

interface AccountState {
    loading: boolean;
}

const initialState: AccountState = {
    loading: false
}

export const systemSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        startLoading:(state) => {
            state.loading = true;
        },
        stopLoading:(state) => {
            state.loading = false;
        },
       
    },
    
})

export const {startLoading, stopLoading} = systemSlice.actions;