
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import image_klein from './klein.svg';
import image_klein_kamers from './klein-kamers.svg';
import image_klein_kombuis from './klein-kombuis.svg';
import image_klein_fejnbosoils from './fejnbosoils.png';
import image_klein_baba from './klein_baba_small.png';


const Hero = (): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const LeftSide = () => (
    <Box>
      <Box marginBottom={2} display="flex" flexWrap="wrap" justifyContent={'center'}>
        <Typography component={'span'} variant="h3" sx={{ fontWeight: 700 }}>
         Welkom by{' '}
          <Typography
            component={'span'}
            variant={'inherit'}
            color={'primary'}
            sx={{
              background: `linear-gradient(180deg, transparent 82%, ${alpha(
                theme.palette.secondary.main,
                0.3,
              )} 0%)`,
            }}
          >
           Klein!
          </Typography>
        </Typography>
        <Typography variant="h6" component="p" color={'text.secondary'}>
      Top-kwaliteit, natuurlike produkte! Elkeen van ons produkte word altyd gemaak met ‘n KLEIN bietjie ekstra liefde!
      </Typography>
      </Box>
     
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent={'center'}
        marginTop={4}
      >
        {[
          image_klein,
          image_klein_kamers,
          image_klein_kombuis,
          image_klein_baba
        ].map((item, i) => (
          <Box maxWidth={70} marginTop={2} marginRight={4} key={i}>
            <Box
              component="img"
              height={1}
              width={1}
              src={item}
              alt="..."
              // sx={{
              //   filter:
              //     theme.palette.mode === 'dark'
              //       ? 'brightness(0) invert(0)'
              //       : 'contrast(0) brightness(0)',
              // }}
            />
          </Box>
        ))}
        <Box maxWidth={70} marginTop={2} marginRight={4}>
            <Box
              component="img"
              height={0.8}
              width={0.8}
              src={image_klein_fejnbosoils}
              alt="..."
              // sx={{
              //   filter:
              //     theme.palette.mode === 'dark'
              //       ? 'brightness(0) invert(0)'
              //       : 'contrast(0) brightness(0)',
              // }}
            />
          </Box>
      </Box>
    </Box>
  );

  return (
    <Grid container spacing={4}>
      <Grid item container alignItems={'center'} xs={12} md={12}>
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <LeftSide />
        </Box>
      </Grid>
     
    </Grid>
  );
};

export default Hero;
