import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import FixedLayout from "layouts/Fixed";
import { Tabs, Tab } from "@mui/material";
import MonthlyReports from "./MonthlyReports";
import ProductionListReport from "./ProductionList";
import LoadingComponent from "layouts/LoadingComponent";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "store/configureStore";
import AnnualReports from "./AnnualReports";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Reports = (): JSX.Element => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { user, userLoaded } = useAppSelector((state) => state.account);
  if (!userLoaded)
    return <LoadingComponent message="Klein is besig om te laai..." />;

  if (!user) {
    // user is not authenticated
    toast.error("Oops! Jy is nie ingeteken op ons weblad.");
    return <Navigate to="tekenin" />;
  }

  return (
    <FixedLayout>
      <Box>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
                <Tab label="Maand Opsomming" {...a11yProps(0)} />
                <Tab label="Jaar Opsomming" {...a11yProps(1)} />
                <Tab label="Produksie Lys" {...a11yProps(2)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            {["info@kleinbederf.co.za","admin@test.com"].includes(user.email) ? <MonthlyReports /> : <Typography>Jammer, jy het nie toegang om die verslag te sien nie.</Typography>  }
          </TabPanel>

          <TabPanel value={value} index={1}>
            {["info@kleinbederf.co.za","admin@test.com"].includes(user.email) ? <AnnualReports /> : <Typography>Jammer, jy het nie toegang om die verslag te sien nie.</Typography>  }
          </TabPanel>

          
            <TabPanel value={value} index={2}>
            {["info@kleinbederf.co.za","admin@test.com","johan@kleinbederf.co.za","Delainecv123@yahoo.com", "delaine@kleinbederf.co.za"].includes(user.email) ? (
              <ProductionListReport />
              ) : <Typography>Jammer, jy het nie toegang om die verslag te sien nie.</Typography>}
            </TabPanel>
         

          {/* <TabPanel value={value} index={1}>
            Item Two
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three
          </TabPanel> */}
        </Box>
      </Box>
    </FixedLayout>
  );
};

export default Reports;
