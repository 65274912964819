import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const mock = [
  {
    title: 'Wat sluit die Handelshoek alles in?',
    subtitle:
      "Alle meubels, dit sluit in jou kas (onder) en die rak (bo). Alle koerier-uitgawes (in Suid-Afrika) – die meubels is baie swaar so die vervoer daarop is nogal duur! 'n Pragtige, uniek aan Klein - “chandelier” soos in al ons winkels (Daar is nie ‘n bracket ingesluit nie, indien jou “chandelier” nie uit die dak gaan hang nie, maar teen ‘n muur moet jy asb self rondom dit beplan en so ‘n “bracket” laat maak). Alle produkte, ter waarde van meer as R36 400.00), 3x Groot glasbakke, met sout, skaal en alles anders wat jy nodig het vir jou SELF-SKEP-STASIE bo op jou rak (as jou sakkies en “stickers” op is kan jy dit weer self aanvul)",
  },
  {
    title: 'Wat gaan my Handelshoek kos?',
    subtitle:
      "Alhoewel die waarde van ons Handelshoek vêr bo R60 000.00 is, is Klein se hart nog altyd om besighede te help bou wat vir ewig gaan hou.  Ons wil graag hê dat elkeen van ons Handelshoek-eienaars eendag 'n hele paar van hulle gaan besit en bestuur.  Ons wil 'n Handelshoek in elke dorp in Suid-Afrika, en later ook in Namibia sien in die volgende paar jaar! Daarom kan jy jou hele handelshoek NOU koop vir slegs R39 000.00! (Hou ingedagte dat net die produkte wat saam met die handelshoek kom, se verkoopswaarde R36 276.00 is)",
  },
  {
    title: 'Is daar enige afbetaal-opsies?',
    subtitle:
      "Om selfs nog meer van ons Agente te help met hierdie GROOT droom het ons dit goed gedink om wel 'n opsie of twee daar te stel indien jy sou wou afbetaal aan jou Handelshoek. Die afbetaal-opies het 'n deposito bedrag van R8000.00 en dan kan jy kies om: Af te betaal oor 3 maande @ R11 000.00 per maand (via EFT, Bianca stuur elke maand ‘n faktuur) Totaal werk uit op  R41 000.00; Af te betaal oor 6 maande @ R6 000.00 per maand (via EFT, Bianca stuur elke maand ‘n faktuur) Totaal werk uit op R44 000.00; In die geval van die afbetaal-opsie sal die Handelshoek wel Klein se eiendom bly tot hy klaar betaal is en sodra jy die laaste paaiement betaal het, sal ons jou Handelshoek aflewer by die adres wat jy gaan invul op jou vorm.",
  },
  {
    title: 'Bemarking',
    subtitle:
      "Elke Handelshoek en sy eienaar word op Klein se webblad gelys en bemark. (sien die 'Meer oor Klein' skakel op webblad) ",
  },
  {
    title: 'Handelshoek Beperkings',
    subtitle:
      "Handelshoeke sal ten minste 25km uitmekaar wees - dit beteken dat daar wel in meeste klein dorpe en voorstede slegs EEN gaan wees en ons kan ongelukkig geen dorpe uithou nie - first come first serve.  ",
  },
  {
    title: 'Enige ander vrae of opsies',
    subtitle:
      "Kontak ons gerus by bianca@kleinbederf.com",
  },

  
];

const CompanyValues = (): JSX.Element => {
  return (
    <Box>
      <Box marginBottom={7}>
        <Typography
          align={'center'}
          color={'text.secondary'}
          sx={{ textTransform: 'uppercase'}}
          variant={'subtitle2'}
          fontWeight={600}
        >
          MEER INLIGTING
        </Typography>
        <Typography fontWeight={700} variant={'h4'} align={'center'}>
          Handelshoek Inligting
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12} sm={6} md={4}>
            <Typography variant={'h6'} fontWeight={600} gutterBottom>
              {item.title}
            </Typography>
            <Typography color="text.secondary">{item.subtitle}</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CompanyValues;
