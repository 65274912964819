import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { setProductParams } from "store/catalogSlice";
import { useAppSelector, useAppDispatch } from "store/configureStore";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const FilterBrand = (): JSX.Element => {
  const [open, setOpen] = useState(true);

  const [searchParams] = useSearchParams();
  const reeksParams = searchParams.get("reeks");

  const { productParams, brands, filtersLoaded } = useAppSelector(
    (state) => state.catalog
  );

  const dispatch = useAppDispatch();

  const handleClick = (): void => {
    setOpen(!open);
  };

  const [checkedItems, setCheckedItems] = useState(productParams.brands || []);

  function handleChecked(value: number) {
    const curentIndex = checkedItems.findIndex((item) => item === value);

    let newChecked: number[] = [];
    if (curentIndex === -1) newChecked = [...checkedItems, value];
    else newChecked = checkedItems.filter((item) => item !== value);
    setCheckedItems(newChecked);
    dispatch(setProductParams({ brands: newChecked }));
    //dispatch(fetchProductsAsync());
  }

  useEffect(() => {
    if (filtersLoaded && reeksParams) {
      try {
        var brand = brands.filter((x) => x.slug === reeksParams);

        if (brand.length === 0) {
          toast.error("Die reeks is nie op die oomlik beskikbaar nie");
        } else {
          var catId = brands.find((x) => x.slug === reeksParams).id;

          setCheckedItems([catId]);
          dispatch(setProductParams({ brands: [catId] }));
          //dispatch(fetchProductsAsync());
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [reeksParams, brands, dispatch, filtersLoaded]);

  function handleSelectAll(){
    setCheckedItems([]); 
    dispatch(setProductParams({ brands: [] }));
  }

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ cursor: "pointer" }}
        marginBottom={1}
        onClick={() => handleClick()}
      >
        <Typography fontWeight={700}>Reeks</Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          marginBottom={1}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0 !important",
            },
            "& .MuiOutlinedInput-input": {
              padding: 1,
            },
          }}
        >
        
        </Box>
        <Stack spacing={1}>
        <FormControlLabel
                sx={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    color="primary"
                    checked={productParams.brands.length === 0}
                    onChange={handleSelectAll}
                    sx={{
                      padding: 0,
                      marginRight: 1,
                    }}
                  />
                }
                label={'Alles'}
              />
          {brands.map((brand) => (
            <Box key={brand.id}>
              <FormControlLabel
                sx={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      productParams.brands.indexOf(brand.id) !== -1
                    }
                    onChange={() => handleChecked(brand.id)}
                    sx={{
                      padding: 0,
                      marginRight: 1,
                    }}
                  />
                }
                label={brand.title}
              />
            </Box>
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
};

export default FilterBrand;
