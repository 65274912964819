import {
  Divider,
  Typography,
  TextField,
  Box,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import agent from "api/agent";
import { UserAddress } from "models/user";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import { useAppSelector } from "store/configureStore";
import FactoryClosedNotice from "util/FactoryClosedNotice";

export default function AddressForm() {
  const { user } = useAppSelector((state) => state.account);
  const { basket } = useAppSelector((state) => state.basket);
  const [showInvoiceAdddress, setShowInvoiceAddress] = useState(false);

  const [loading, setLoading] = useState(true);

  const [preferredDeliveryAddress, setPreferredDeliveryAddress] =
    useState<UserAddress>(undefined);
  const [preferredInvoiceAddress, setPreferredInvoiceAddress] =
    useState<UserAddress>(undefined);

  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  useEffect(() => {
    setValue("deliveryOption", basket.deliveryOption);

    if (user) {
      agent.Account.addressList()
        .then((list: UserAddress[]) => {
          const prefDeliveryAddress =
            list?.find(
              (x) => x.preferred === true && x.type === "Aflewering"
            ) ?? undefined;
          const prefInvoiceAddress =
            list?.find((x) => x.preferred === true && x.type === "Faktuur") ??
            undefined;

          setPreferredDeliveryAddress(prefDeliveryAddress);
          setPreferredInvoiceAddress(prefInvoiceAddress);
        })
        .catch((error) => toast.error("Kon nie adres besonderhede aflaai nie."))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [user, basket.deliveryOption, setValue]);

  if (loading) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress />
        <Typography>Besig om besonderhede te laai...</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <FactoryClosedNotice />
      <Grid container spacing={3}>
        {basket.deliveryOption === "Aflewering" && (
          <>
            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle1">Aflewering Adres</Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                label="Ontvanger Naam"
                variant="outlined"
                name={"deliveryFullName"}
                defaultValue={preferredDeliveryAddress?.fullName ?? ""}
                fullWidth
                {...register("deliveryFullName")}
                error={errors?.deliveryFullName != null}
                // @ts-ignore
                helperText={errors?.deliveryFullName?.message}
                required={basket.deliveryOption === "Aflewering"}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Adres Lyn 1"
                variant="outlined"
                name={"deliveryAddress1"}
                defaultValue={preferredDeliveryAddress?.address1 ?? ""}
                fullWidth
                {...register("deliveryAddress1")}
                error={errors?.deliveryAddress1 != null}
                // @ts-ignore
                helperText={errors?.deliveryAddress1?.message}
                required={basket.deliveryOption === "Aflewering"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Adres Lyn 2"
                variant="outlined"
                name={"deliveryAddress2"}
                defaultValue={preferredDeliveryAddress?.address2 ?? ""}
                fullWidth
                {...register("deliveryAddress2")}
                error={errors?.deliveryAddress2 != null}
                // @ts-ignore
                helperText={errors?.deliveryAddress2?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Dorp / Stad"
                variant="outlined"
                name={"deliveryCity"}
                defaultValue={preferredDeliveryAddress?.city ?? ""}
                fullWidth
                {...register("deliveryCity")}
                error={errors?.deliveryCity != null}
                // @ts-ignore
                helperText={errors?.deliveryCity?.message}
                required={basket.deliveryOption === "Aflewering"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Provinsie"
                variant="outlined"
                name={"deliveryProvince"}
                defaultValue={preferredDeliveryAddress?.province ?? ""}
                fullWidth
                {...register("deliveryProvince")}
                error={errors?.deliveryProvince != null}
                // @ts-ignore
                helperText={errors?.deliveryProvince?.message}
                required={basket.deliveryOption === "Aflewering"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Land"
                variant="outlined"
                name={"deliveryCountry"}
                InputProps={{ readOnly: true }}
                fullWidth
                defaultValue={"South Africa"}
                {...register("deliveryCountry")}
                error={errors?.deliveryCountry != null}
                // @ts-ignore
                helperText={errors?.deliveryCountry?.message}
                required={basket.deliveryOption === "Aflewering"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Poskode"
                variant="outlined"
                name={"deliveryPostalCode"}
                defaultValue={preferredDeliveryAddress?.postalCode ?? ""}
                fullWidth
                {...register("deliveryPostalCode")}
                error={errors?.deliveryPostalCode != null}
                // @ts-ignore
                helperText={errors?.deliveryPostalCode?.message}
                required={basket.deliveryOption === "Aflewering"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Aflewering Kontak Nommer"
                variant="outlined"
                name={"deliveryContactNumber"}
                defaultValue={preferredDeliveryAddress?.contactNumber ?? ""}
                fullWidth
                {...register("deliveryContactNumber")}
                error={errors?.deliveryContactNumber != null}
                // @ts-ignore
                helperText={errors?.deliveryContactNumber?.message}
                required={basket.deliveryOption === "Aflewering"}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={12}>
          <Typography variant="subtitle1">
            <Checkbox
              onChange={() => setShowInvoiceAddress(!showInvoiceAdddress)}
            />{" "}
            Wil jy 'n ander adres op die faktuur laat verskyn?
          </Typography>
        </Grid>

        {showInvoiceAdddress && (
          <>
            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle1">Faktuur Adres</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Adres Lyn 1"
                variant="outlined"
                name={"customerAddress1"}
                defaultValue={preferredInvoiceAddress?.address1 ?? ""}
                fullWidth
                {...register("customerAddress1")}
                error={errors?.customerAddress1 != null}
                // @ts-ignore
                helperText={errors?.customerAddress1?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Adres Lyn 2"
                variant="outlined"
                name={"customerAddress2"}
                defaultValue={preferredInvoiceAddress?.address2 ?? ""}
                fullWidth
                {...register("customerAddress2")}
                error={errors?.customerAddress2 != null}
                // @ts-ignore
                helperText={errors?.customerAddress2?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Dorp / Stad"
                variant="outlined"
                name={"customerCity"}
                defaultValue={preferredInvoiceAddress?.city ?? ""}
                fullWidth
                {...register("customerCity")}
                error={errors?.customerCity != null}
                // @ts-ignore
                helperText={errors?.customerCity?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Provinsie"
                variant="outlined"
                name={"customerProvince"}
                defaultValue={preferredInvoiceAddress?.province ?? ""}
                fullWidth
                {...register("customerProvince")}
                error={errors?.customerProvince != null}
                // @ts-ignore
                helperText={errors?.customerProvince?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Land"
                variant="outlined"
                name={"customerCountry"}
                defaultValue={preferredInvoiceAddress?.country ?? ""}
                fullWidth
                {...register("customerCountry")}
                error={errors?.customerCountry != null}
                // @ts-ignore
                helperText={errors?.customerCountry?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Poskode"
                variant="outlined"
                name={"customerPostalCode"}
                defaultValue={preferredInvoiceAddress?.postalCode ?? ""}
                fullWidth
                {...register("customerPostalCode")}
                error={errors?.customerPostalCode != null}
                // @ts-ignore
                helperText={errors?.customerPostalCode?.message}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}
