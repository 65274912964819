import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Link as LinkR } from "react-router-dom";

const mock = [
  {
    caption: "Klein Bederf",
    media: "/images/klein8.jpg",
    title: "Klein Bederf",
    description:
      "Klein se produkte word gemaak van natuurlike olies en sover moontlik natuurlike basisse om gesonde en gelukkige velle te verseker!",
    href: "/winkel?reeks=klein-bederf",
  },
  {
    caption: "Klein Leer",
    media: "/images/klein-luuks.jpg",
    title: "Klein Leer",
    description:
      "Hoe nader aan die NATUUR, hoe beter vir ons by Klein. Ons maak seker ons leerprodukte word van A-graad rou materiaal gemaak.",
    href: "/winkel?reeks=klein-leer",
  },
  {
    caption: "Klein K'mers",
    media: "/images/klein-kamers.jpg",
    title: "Klein K'mers",
    description:
      "Ons nuutste byvoeging tot Klein, produkte vir in en om die huis!",
    href: "/winkel?reeks=klein-kamers",
  },
  {
    caption: "Klein K'buis",
    media: "/images/klein-kombuis2.jpeg",
    title: "Klein K'buis",
    description:
      "Wat het die son met jou gedoen, Lemoen? Hy't my gestreel, hy't my gesoen......Nou is ek rooi en ryp en rond, 'n vreugde vir die oog en lyf en mond!",
    href: "/winkel?reeks=klein-kombuis",
  },

  {
    caption: "Féjnbos Oils",
    media: "/images/fejnbos-oils2.jpeg",
    title: "Féjnbos Oils",
    description:
      "Essensiële olies is vir baie jare al die natuur se goue geheim... Die SUIWERSTE vorm van nektar, reg uit die aar van 'n plant se stam, blom, vrug of blaar.  Perfek geskape - met baie meer waarde as net sy lekker reuk en mooi kleur!  Die tyd het gekom, 'n avontuur wag op ons tussen al die mooiste boorde, velde... tuine en lanings - so pak jou rugsak, trek aan jou stapskoene...en onthou jou Féjnbos-veldjoernaal... Saam gaan ons leer van al die verskillende olies... hul elkeen se gebruike, hul geheime... alles van geur tot kleur, elkeen se verhaal!",
    href: "/winkel?reeks=fejnbos-oils",
  },
];

const PromoGrid = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Grid container spacing={{ xs: 2, sm: 4 }}>
      <Grid item xs={12} md={5}>
        <Grid container spacing={{ xs: 2, sm: 4 }}>
          <Grid item xs={12} md={12}>
            <Card
              elevation={3}
              sx={{
                p: { xs: 2, sm: 4 },
                height: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box sx={{ p: 2, mb: 2 }}>
                <Box
                  component={LazyLoadImage}
                  effect="blur"
                  src={mock[0].media}
                  width={1}
                  maxWidth={1}
                  sx={{
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0.5)"
                        : "none",
                    borderRadius: "10px",
                    //maxHeight: 420,
                    // overflow: 'hidden',
                  }}
                />
              </Box>
              <Box>
                <Typography
                  color={"text.secondary"}
                  fontWeight={700}
                  variant={"caption"}
                  sx={{ textTransform: "uppercase" }}
                >
                  {mock[0].caption}
                </Typography>
                <Typography variant={"h5"} fontWeight={700} marginY={1}>
                  {mock[0].title}
                </Typography>
                <Typography color={"text.secondary"}>
                  {mock[0].description}
                </Typography>
                <Link
                  component={LinkR}
                  to={mock[0].href}
                  variant={"subtitle2"}
                  color={"primary"}
                  fontWeight={700}
                  underline={"hover"}
                  sx={{
                    textTransform: "uppercase",
                    display: "block",
                    mt: { xs: 2, sm: 4 },
                  }}
                >
                  Sien Produkte
                </Link>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={12}>
            <Card
              elevation={3}
              sx={{
                p: { xs: 2, sm: 4 },
                height: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box sx={{ p: 2, mb: 2 }}>
                <Box
                  component={LazyLoadImage}
                  effect="blur"
                  src={mock[4].media}
                  width={1}
                  maxWidth={1}
                  sx={{
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0.5)"
                        : "none",
                    borderRadius: "10px",
                    //maxHeight: 420,
                    // overflow: 'hidden',
                  }}
                />
              </Box>
              <Box>
                <Typography
                  color={"text.secondary"}
                  fontWeight={700}
                  variant={"caption"}
                  sx={{ textTransform: "uppercase" }}
                >
                  {mock[4].caption}
                </Typography>
                <Typography variant={"h5"} fontWeight={700} marginY={1}>
                  {mock[4].title}
                </Typography>
                <Typography color={"text.secondary"}>
                  {mock[4].description}
                </Typography>
                <Link
                  component={LinkR}
                  to={mock[4].href}
                  variant={"subtitle2"}
                  color={"primary"}
                  fontWeight={700}
                  underline={"hover"}
                  sx={{
                    textTransform: "uppercase",
                    display: "block",
                    mt: { xs: 2, sm: 4 },
                  }}
                >
                  Sien Produkte
                </Link>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={7}>
        <Grid container spacing={{ xs: 2, sm: 4 }}>
         
          <Grid item xs={12}>
            <Card
              elevation={3}
              sx={{
                p: { xs: 2, sm: 4 },
                height: 1,
                display: "flex",
                flexDirection: { xs: "column", sm: "row-reverse" },
                alignItems: "center",
              }}
            >
              <Box sx={{ p: 2, mb: 2 }}>
                <Box
                  component={LazyLoadImage}
                  effect="blur"
                  src={mock[1].media}
                  width={1}
                  maxWidth={1}
                  sx={{
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0.5)"
                        : "none",
                    borderRadius: "10px",
                    maxHeight: 520,
                    // overflow: 'hidden',
                  }}
                />
              </Box>
              <Box>
                <Typography
                  color={"text.secondary"}
                  fontWeight={700}
                  variant={"caption"}
                  sx={{ textTransform: "uppercase" }}
                >
                  {mock[1].caption}
                </Typography>
                <Typography variant={"h5"} fontWeight={700} marginY={1}>
                  {mock[1].title}
                </Typography>
                <Typography color={"text.secondary"}>
                  {mock[1].description}
                </Typography>
                <Link
                  component={LinkR}
                  to={mock[1].href}
                  variant={"subtitle2"}
                  color={"primary"}
                  fontWeight={700}
                  underline={"hover"}
                  sx={{
                    textTransform: "uppercase",
                    display: "block",
                    mt: { xs: 2, sm: 4 },
                  }}
                >
                  Sien Produkte
                </Link>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              elevation={3}
              sx={{
                p: { xs: 2, sm: 4 },
                height: 1,
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
              }}
            >
              <Box sx={{ p: 2, mb: 2 }}>
                <Box
                  component={LazyLoadImage}
                  effect="blur"
                  src={mock[2].media}
                  width={1}
                  maxWidth={1}
                  sx={{
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0.5)"
                        : "none",
                    borderRadius: "10px",
                    maxHeight: 320,
                    overflow: "hidden",
                  }}
                />
              </Box>
              <Box>
                <Typography
                  color={"text.secondary"}
                  fontWeight={700}
                  variant={"caption"}
                  sx={{ textTransform: "uppercase" }}
                >
                  {mock[2].caption}
                </Typography>
                <Typography variant={"h5"} fontWeight={700} marginY={1}>
                  {mock[2].title}
                </Typography>
                <Typography color={"text.secondary"}>
                  {mock[2].description}
                </Typography>
                <Link
                  component={LinkR}
                  to={mock[2].href}
                  variant={"subtitle2"}
                  color={"primary"}
                  fontWeight={700}
                  underline={"hover"}
                  sx={{
                    textTransform: "uppercase",
                    display: "block",
                    mt: { xs: 2, sm: 4 },
                  }}
                >
                  Sien Produkte
                </Link>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              elevation={3}
              sx={{
                p: { xs: 2, sm: 4 },
                height: 1,
                display: "flex",
                flexDirection: { xs: "column", sm: "row-reverse" },
                alignItems: "center",
              }}
            >
              <Box sx={{ p: 2, mb: 2 }}>
                <Box
                  component={LazyLoadImage}
                  effect="blur"
                  src={mock[3].media}
                  width={1}
                  maxWidth={1}
                  sx={{
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0.5)"
                        : "none",
                    borderRadius: "10px",
                    maxHeight: 520,
                    // overflow: 'hidden',
                  }}
                />
              </Box>
              <Box>
                <Typography
                  color={"text.secondary"}
                  fontWeight={700}
                  variant={"caption"}
                  sx={{ textTransform: "uppercase" }}
                >
                  {mock[3].caption}
                </Typography>
                <Typography variant={"h5"} fontWeight={700} marginY={1}>
                  {mock[3].title}
                </Typography>
                <Typography color={"text.secondary"}>
                  {mock[3].description}
                </Typography>
                <Link
                  component={LinkR}
                  to={mock[3].href}
                  variant={"subtitle2"}
                  color={"primary"}
                  fontWeight={700}
                  underline={"hover"}
                  sx={{
                    textTransform: "uppercase",
                    display: "block",
                    mt: { xs: 2, sm: 4 },
                  }}
                >
                  Sien Produkte
                </Link>
              </Box>
            </Card>
          </Grid>
          
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PromoGrid;
