import { useCallback, useEffect } from "react";
import Box from "@mui/material/Box";

import Main from "../../layouts/Main";
import Container from "components/Container";

import Headline from "./Headline";
import Products from "./Products";
import SidebarFilters from "./SidebarFilters";
import { useAppDispatch } from "store/configureStore";
import { fetchFilters, fetchProductsAsync } from "store/catalogSlice";

const Winkel = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const loadlatest = useCallback(async () => {
    try {
      await dispatch(fetchProductsAsync());
      await dispatch(fetchFilters());
    } catch (error) {}
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadlatest();
  }, [loadlatest]);

  return (
    <Main>
      <Box bgcolor={"alternate.main"}>
        <Container paddingY={{ xs: 2, sm: 2.5 }}>
          <Headline />
        </Container>
      </Box>

      <Container>
        <SidebarFilters>
          <Products />
        </SidebarFilters>
      </Container>
    </Main>
  );
};

export default Winkel;
