import {
  Alert,
  Box,
  Button,
  Divider,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import AddressForm from "./AddressForm";
import Review from "./Review";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./checkoutValidation";

import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "store/configureStore";
import agent from "api/agent";
import { clearBasket } from "store/basketSlice";
import { Link } from "react-router-dom";
import Headline from "./Headline";
import { Main } from "layouts";
import Container from "components/Container";
import CustomerDetailsForm from "./CustomerDetails";
import OtherForm from "./OtherForm";

const steps = [
  "Koper Besonderhede",
  "Hersien Items",
  "Aflewering / Optel ",
  "Ander",
];

function getStepContent(step: number) {
  switch (step) {
    case 0:
      return <CustomerDetailsForm />;
    case 1:
      return <Review />;
    case 2:
      return <AddressForm />;
    case 3:
      return <OtherForm />;
    default:
      throw new Error("Unknown step");
  }
}

export default function CheckoutPage() {
  const dispatch = useAppDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [orderNumber, setOrderNumber] = useState(0);
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema[activeStep]),
  });

  const handleNext = async (data: FieldValues) => {
    //const {...orderDetails } = data;

    if (activeStep === steps.length - 1) {
      setLoading(true);
      try {

        const orderNumber = await agent.Orders.create({ ...data });

        setOrderNumber(orderNumber);
        setActiveStep(activeStep + 1);
        dispatch(clearBasket());
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Main>
      <Box bgcolor={"alternate.main"}>
        <Container paddingY={{ xs: 2, sm: 2.5 }}>
          <Headline />
        </Container>
      </Box>
      <Container padding={{ xs: 2, sm: 4, lg: 6 }}>
        <FormProvider {...methods}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box sx={{ paddingBottom: 3 }}>
            <Divider />
          </Box>
          <>
            {activeStep === steps.length ? (
              <>
                {/* <Typography variant="h5" gutterBottom>
                            Thank you for your order.
                        </Typography>
                        <Typography variant="subtitle1">
                            Your order number is #{orderNumber}. We have emailed your order
                            confirmation, and will send you an update when your order has
                            shipped.
                        </Typography> */}

                <Typography
                  variant={"h4"}
                  fontWeight={700}
                  align={"center"}
                  marginY={2}
                >
                  Bestelling suksesvol geplaas!
                </Typography>
                <Typography
                  fontWeight={400}
                  color={"text.secondary"}
                  align={"center"}
                >
                  Baie dankie vir jou bestelling!
                  <br />
                  <br />
                  Gebruik jou bestelling nommer{" "}
                  <strong> #{orderNumber} </strong> as verwysing wanneer jy, die
                  bedrag verskuldig, direk in ons bankrekening deponeer: <br />
                  EFT: FNB, 250655, Tjek Rekening, 62814053972
                  <br />
                  <br />
                  Stuur asseblief jou bewys van betaling na
                  betalings@kleinbederf.co.za
                  <br/><br/>
                  <Alert severity="warning">
                    Ons bank besonderhede is soos hierso bo beskryf (EFT: FNB, 250655,
                    Tjek Rekening, 62814053972, Verwysing: Bestelling Nommer).
                    Ons sal nie via epos of telefoon of sms versoek om die bank
                    besonderhede te verander nie. Slegs eposse vanaf
                    @kleinbederf.co.za of @kleinbederf.com is vanaf Klein
                    gestuur, ons sal nooit ander eposse soos @hotmail of @gmail
                    of so gebruik nie. Kontak ons by info@kleinbederf.co.za as
                    jy enige epos kry met ander bank besonderhede.
                  </Alert>
                  <br />
                  <br />
                  Ons sal 'n epos stuur sodra jou betaling ontvang is en dan
                  weer sodra jou bestelling uitgestuur word.
                  <br />
                 
                  <Button
                    component={Link}
                    to="/"
                    variant={"contained"}
                    size={"large"}
                    sx={{ marginTop: 4 }}
                  >
                    Keer terug na tuisblad
                  </Button>
                </Typography>
              </>
            ) : (
              <Box sx={{ paddingBottom: 4 }}>
                <form onSubmit={methods.handleSubmit(handleNext)}>
                  {getStepContent(activeStep)}
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        Terug
                      </Button>
                    )}
                    <LoadingButton
                      loading={loading}
                      disabled={!methods.formState.isValid}
                      variant="contained"
                      type="submit"
                      sx={{ mt: 3, ml: 1 }}
                    >
                      {activeStep === steps.length - 1
                        ? "Plaas Bestelling"
                        : "Volgende"}
                    </LoadingButton>
                  </Box>
                </form>
              </Box>
            )}
          </>
        </FormProvider>
      </Container>
    </Main>
  );
}
