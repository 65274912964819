import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link as LinkR } from "react-router-dom";
import { useState } from "react";
import agent from "api/agent";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";
import { setPasswordAndSignInUser } from "store/accountSlice";
import { useAppDispatch } from "store/configureStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const validationSchemaStep1 = yup.object({
  email: yup
    .string()
    .trim()
    .email("'n Geldige eposadres is nodig'")
    .required("Eposadres is nodig"),
});

const validationSchemaStep2 = yup.object({
  code: yup
    .number()
    .required(
      "Kode is noodskap - loer asb. vir die kode in die epos wat ons gestuur het."
    ),
  password: yup
    .string()
    .required("Die nuwe wagwoord veld is noodsaaklik")
    .min(8, "Die wagwoord moet tenminste 8 karakters lank wees")
    .matches(
      /^(?=.*[a-z])/,
      "Die wagwoord moet tenminste een kleinletter bevat, bv. a,b,c,d"
    )
    .matches(
      /^(?=.*[A-Z])/,
      "Die wagwoord moet tenminste een hoofletter bevat, bv. A,B,C,D"
    )
    .matches(
      /^(?=.*[0-9])/,
      "Die wagwoord moet tenminste een nommer bevat, bv. 1,2,3,4"
    ),
});

const Form = (): JSX.Element => {
  const [step, setStep] = useState(1);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(
      step === 1 ? validationSchemaStep1 : validationSchemaStep2
    ),
  });

  async function onSubmitStep1(values) {
    try {
      await agent.Account.requestcode(values["email"]);
      toast.success(
        "Kode gestuur. Loer asb in jou eposbus vir die kode om jou wagwoord te verander."
      );

      setStep(2);
    } catch (errors: any) {
      if (errors.email) {
        setError(
          "email",
          { type: "focus", message: errors.email },
          { shouldFocus: true }
        );
        return;
      }
    }
  }

  async function onSubmitStep2(values) {
    try {
      await dispatch(setPasswordAndSignInUser({password: values.password, code: values.code, email: values.email}));

      toast.success("Wagwoord suksesvol opgedateer!");

      setStep(3);
    } catch (errors: any) {
      if (errors.code) {
        setError(
          "code",
          { type: "focus", message: errors.code },
          { shouldFocus: true }
        );
        return;
      }

      if (errors.password) {
        setError(
          "password",
          { type: "focus", message: errors.password },
          { shouldFocus: true }
        );
        return;
      }
    }
  }

  //Show loading when processing.
  if (isSubmitting) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        </Grid>
        <Grid item container xs={12}>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "stretched", sm: "center" }}
            justifyContent={"space-between"}
            width={1}
            maxWidth={600}
            margin={"0 auto"}
          >
            <Skeleton
              variant="text"
              sx={{
                fontSize: "1rem",
                width: "90%",
                paddingRight: 5,
                marginRight: 5,
              }}
            />
            <Skeleton variant="rectangular" width={210} height={60} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  //Step 1 to request password reset token
  if (step === 1) {
    return (
      <Box>
        <Box marginBottom={4}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              paddingBottom: 1,
            }}
          >
            Wagwoord vergeet?
          </Typography>
          <Typography color="text.secondary">
            Sleutel in jou epos adres dan stuur ons 'n kode om jou wagwoord te
            verander.
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmitStep1)}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                label="E-posadres"
                variant="outlined"
                name={"email"}
                fullWidth
                defaultValue={""}
                {...register("email")}
                error={errors?.email != null}
                // @ts-ignore
                helperText={errors?.email?.message}
              />
            </Grid>
            <Grid item container xs={12}>
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "stretched", sm: "center" }}
                justifyContent={"space-between"}
                width={1}
                maxWidth={600}
                margin={"0 auto"}
              >
                <Box marginBottom={{ xs: 1, sm: 0 }}>
                  <Button
                    size={"large"}
                    variant={"outlined"}
                    component={LinkR}
                    to={"/tekenin"}
                    fullWidth
                  >
                    Terug na Teken In
                  </Button>
                </Box>
                <Button size={"large"} variant={"contained"} type={"submit"}>
                  Stuur
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  }

  //Step 1 to request password reset token
  if (step === 2) {
    return (
      <Box>
        <Box marginBottom={4}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
            }}
          >
            Bevestig Kode en Nuwe Wagwoord
          </Typography>
          <Typography color="text.secondary">
            Sleutel in die kode wat ons na jou epos gestuur het saam met die
            nuwe wagwoord.
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmitStep2)}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                label="Kode"
                variant="outlined"
                name={"code"}
                fullWidth
                defaultValue={""}
                {...register("code")}
                error={errors?.code != null}
                // @ts-ignore
                helperText={errors?.code?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Wagwoord"
                variant="outlined"
                name={"password"}
                type={"password"}
                fullWidth
                defaultValue={""}
                {...register("password")}
                error={errors?.password != null}
                // @ts-ignore
                helperText={errors?.password?.message}
              />
            </Grid>
            <Grid item container xs={12}>
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "stretched", sm: "center" }}
                justifyContent={"space-between"}
                width={1}
                maxWidth={600}
                margin={"0 auto"}
              >
                <Box marginBottom={{ xs: 1, sm: 0 }}>
                  <Button
                    size={"large"}
                    variant={"outlined"}
                    component={LinkR}
                    to={"/tekenin"}
                    fullWidth
                  >
                    Terug na Teken In
                  </Button>
                </Box>
                <Button size={"large"} variant={"contained"} type={"submit"}>
                  Stoor
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  }

  //Step 3 is successfully password rest.
  if (step === 3) {
    return (
      <Box>
        <Box marginBottom={4}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
            }}
          >
            Sukses!
          </Typography>
          <Typography color="text.secondary">
            Wagwoord suksesvol verander en jy is aangesluit op ons webwerf.
          </Typography>
        </Box>
      </Box>
    );
  }
};

export default Form;
