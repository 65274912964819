/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import NewsletterModal from './NewsletterModal';
import { useAppSelector } from 'store/configureStore';

const Newsletter = (): JSX.Element => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const [openNewsletterModel, setOpenNewsletterModel] = useState(false);

  const { user } = useAppSelector((state) => state.account);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      paddingBottom={4}
    >
      <Box marginBottom={2}>
        <Typography
          variant="caption"
          component={'p'}
          align={'center'}
          color={'text.secondary'}
          gutterBottom
          sx={{ fontWeight: 700, textTransform: 'uppercase' }}
        >
          Nuusbrief
        </Typography>
        {user?.subscribeToNewsletter? <Typography fontSize={15} align={'center'} sx={{ fontWeight: 500 }}>
        Wonderlik! Jy is reeds aangesluit by ons nuusbrief vir eksklusiewe afslag en die jongste nuus vanaf Klein!
        </Typography>:
        <Typography fontSize={15} align={'center'} sx={{ fontWeight: 500 }}>
        Sluit aan by ons nuusbrief vir eksklusiewe afslag en die jongste nuus vanaf Klein!
        </Typography>
        }
      </Box>

      {!user?.subscribeToNewsletter &&
      <Box
        width={1}
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'stretched', sm: 'flex-start' }}
        justifyContent={'center'}
      >
        <Box
          component={Button}
          onClick={() => setOpenNewsletterModel(true)}
          variant="contained"
          color="primary"
          size="large"
          fullWidth={isSm ? false : true}
          marginTop={{ xs: 2, sm: 0 }}
          marginLeft={0}
          height={54}
          // sx={{
          //   borderTopLeftRadius: { sm: 0 },
          //   borderBottomLeftRadius: { sm: 0 },
          // }}
          endIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              width={24}
              height={24}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
          }
        >
          Sluit aan
        </Box>
      </Box>}
      <NewsletterModal modalOpen={openNewsletterModel} setModalOpen={setOpenNewsletterModel} />
    </Box>
  );
};

export default Newsletter;
