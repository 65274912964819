import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import agent from "api/agent";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";
import { setNewEmailAndSignInUser } from "store/accountSlice";
import { useAppDispatch } from "store/configureStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const validationSchemaStep1 = yup.object({
  newemail: yup
    .string()
    .trim()
    .email("'n Geldige eposadres is nodig'")
    .required("Nuwe e-posadres is nodig"),
});

const validationSchemaStep2 = yup.object({
  code: yup
    .number()
    .required(
      "Kode is nodig - loer asb. vir die kode in die epos wat ons gestuur het."
    )
});

const Form = (): JSX.Element => {
  const [step, setStep] = useState(1);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(
      step === 1 ? validationSchemaStep1 : validationSchemaStep2
    ),
  });

  async function onSubmitStep1(values) {
    try {
      await agent.Account.emailchangerequest(values["newemail"]);
      toast.success(
        "Kode gestuur. Loer asb in jou nuwe e-posbus (" + values["newemail"] + ") vir die kode om jou e-posadres te verander."
      );

      setStep(2);
    } catch (errors: any) {
      if (errors.email) {
        setError(
          "newemail",
          { type: "focus", message: errors.email },
          { shouldFocus: true }
        );
        return;
      }
    }
  }

  async function onSubmitStep2(values) {
    try {
      await dispatch(setNewEmailAndSignInUser({code: values.code, newemail: values.newemail}));

      toast.success("E-posadres suksesvol opgedateer!");

      setStep(3);
    } catch (errors: any) {
      if (errors.code) {
        setError(
          "code",
          { type: "focus", message: errors.code },
          { shouldFocus: true }
        );
        return;
      }

    }
  }

  //Show loading when processing.
  if (isSubmitting) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        </Grid>
        <Grid item container xs={12}>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "stretched", sm: "center" }}
            justifyContent={"space-between"}
            width={1}
            maxWidth={600}
            margin={"0 auto"}
          >
            <Skeleton
              variant="text"
              sx={{
                fontSize: "1rem",
                width: "90%",
                paddingRight: 5,
                marginRight: 5,
              }}
            />
            <Skeleton variant="rectangular" width={210} height={60} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  //Step 1 to request email change  token
  if (step === 1) {
    return (
      <Box>
        <Box marginBottom={4}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              paddingBottom: 1,
            }}
          >
            Wil jy jou e-posadres verander?
          </Typography>
          <Typography color="text.secondary">
            Sleutel jou nuwe e-posadres hieronder in, dan stuur ons vir jou 'n kode om jou nuwe e-posadres te
            bevestig.
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmitStep1)}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                label="E-posadres"
                variant="outlined"
                name={"newemail"}
                fullWidth
                defaultValue={""}
                {...register("newemail")}
                error={errors?.newemail != null}
                // @ts-ignore
                helperText={errors?.newemail?.message}
              />
            </Grid>
            <Grid item container xs={12}>
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "stretched", sm: "center" }}
                justifyContent={"space-between"}
                width={1}
                maxWidth={600}
                margin={"0 auto"}
              >
               
                <Button size={"large"} variant={"contained"} type={"submit"}>
                  Stuur kode na nuwe e-posadres
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  }

  //Step 1 to request password reset token
  if (step === 2) {
    return (
      <Box>
        <Box marginBottom={4}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
            }}
          >
            Bevestig Kode 
          </Typography>
          <Typography color="text.secondary">
            Sleutel in die kode wat ons na jou nuwe e-posadres gestuur het.
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmitStep2)}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                label="Kode"
                variant="outlined"
                name={"code"}
                fullWidth
                defaultValue={""}
                {...register("code")}
                error={errors?.code != null}
                // @ts-ignore
                helperText={errors?.code?.message}
              />
            </Grid>
         
            <Grid item container xs={12}>
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "stretched", sm: "center" }}
                justifyContent={"space-between"}
                width={1}
                maxWidth={600}
                margin={"0 auto"}
              >
               
                <Button size={"large"} variant={"contained"} type={"submit"}>
                  Stoor nuwe e-posadres
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  }

  //Step 3 is successfully password rest.
  if (step === 3) {
    return (
      <Box>
        <Box marginBottom={4}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
            }}
          >
            Sukses!
          </Typography>
          <Typography color="text.secondary">
            Jou e-posadres is suksesvol verander.
          </Typography>
        </Box>
      </Box>
    );
  }
};

export default Form;
