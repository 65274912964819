import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { MetaData } from "../models/pagination";
import { Product, ProductBrand, ProductCategory, ProductParams } from "../models/product";
import { RootState } from "../store/configureStore";


interface CatalogState {
    productsLoaded: boolean;
    filtersLoaded: boolean;
    status: string;
    flavours: string[];
    brands: ProductBrand[];
    categories: ProductCategory[];
    //priceRange: number[];
    //filterTitle: string;
    productParams: ProductParams;
    metaData: MetaData | null;
    loadingProducts: boolean;
    loadingFilters: boolean;
    //products: Product[];
    adminBrands: ProductBrand[];
    adminCategories: ProductCategory[];
}

const productsAdaptor = createEntityAdapter<Product>({selectId: (product) => product.slug});



function getAxiosParams(productParams: ProductParams){
    const params = new URLSearchParams();
    // params.append('pageNumber', productParams.pageNumber.toString());
    // params.append('pageSize', productParams.pageSize.toString());
    // params.append('orderBy', productParams.orderBy);

    // if(productParams.searchTerm) params.append('searchTerm', productParams.searchTerm);
    // if(productParams.brands.length > 0) params.append('brands', productParams.brands.toString());
    // if(productParams.categories.length > 0) params.append('categories', productParams.categories.toString());
    // if(productParams.flavours.length > 0) params.append('flavours', productParams.flavours.toString());
    // if(productParams.priceRange.length > 0) params.append('priceRange', productParams.priceRange.toString());

    return params;
}

export const fetchProductsAsync = createAsyncThunk<Product[],void, {state: RootState}>(
    'catalog/fetchProductsAsync',
    async (_,thunkAPI) => {

        const params = getAxiosParams(thunkAPI.getState().catalog.productParams)
        try {
           
            const response = await agent.Catalog.list(params);
            thunkAPI.dispatch(setMetaData(response.metaData));

            return response.items;
        }catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)

export const fetchProductAsync = createAsyncThunk<Product, number>(
    'catalog/fetchProductAsync',
    async (productId, thunkAPI) => {
        try {
            return await agent.Catalog.details(productId);
        }catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)

export const fetchFilters = createAsyncThunk(
    'catalog/fetchFilters',
    async (_, thunkAPI) => {
        try {
            return await agent.Catalog.fetchfilters();
        }catch (error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)

function initParams(){
    return {
        pageNumber: 1,
        pageSize: 1000,
        orderBy: 'A-Z',
        brands: [],
        categories: [],
        flavours: [],
        priceRange: [0,25000],
        filterTitle: ''
    }
}

export const catalogSlice = createSlice({
    name: 'catalog',
    initialState: productsAdaptor.getInitialState<CatalogState>({
        productsLoaded: false,
        filtersLoaded: false,
        status: 'idle',
        brands: [],
        categories: [],
        flavours: [],
        //priceRange: [0,9999],
        productParams: initParams(),
        metaData: null,
        loadingProducts: true,
        loadingFilters: true,
        adminBrands: [],
        adminCategories: [],
        //products: [],
        //filterTitle: ''
    }),
    reducers: {
        setProductParams: (state, action) => {
            //state.productsLoaded = false;
            state.productParams = {...state.productParams,...action.payload, pageNumber: 1}
        },
        setPageNumber: (state, action) => {
            state.productsLoaded = false;
            state.productParams = {...state.productParams,...action.payload}
        },
        resetProductParams: (state, action) => {
            state.productParams = initParams();
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        setProduct: (state, action) => {
            state.productsLoaded = false;
        },
        removeProduct: (state, action) => {
            state.productsLoaded = false;
        }

    },
    extraReducers: (builder => {
        builder.addCase(fetchProductsAsync.pending, (state) => {
            state.loadingProducts = true;
            state.status = 'pendingFetchProducts';
        });

        builder.addCase(fetchProductsAsync.fulfilled, (state, action) => {
            productsAdaptor.setAll(state, action.payload);
            state.status ="idle";
            state.productsLoaded = true;
            state.loadingProducts = false;
        });

        builder.addCase(fetchProductsAsync.rejected, (state, action) => {
            state.productsLoaded = false;
            state.status ="idle";
            state.loadingProducts = false;

            throw(action.payload);
        });

        builder.addCase(fetchProductAsync.pending, (state) => {
            state.status = 'pendingFetchProduct';
        });

        builder.addCase(fetchProductAsync.fulfilled, (state, action) => {
            productsAdaptor.upsertOne(state, action.payload);
            state.status ="idle";
            state.productsLoaded = true;
        });

        builder.addCase(fetchProductAsync.rejected, (state, action) => {
            state.status = 'idle';
            throw(action.payload);
        });

        builder.addCase(fetchFilters.pending, (state) => {
            state.status = 'pendingFetchFilters';
            state.loadingFilters = true;
        });

        builder.addCase(fetchFilters.fulfilled, (state, action) => {
            state.brands = action.payload.brands.filter(x=> x.visible === true);
            state.categories = action.payload.categories.filter(x=> x.visible === true);
            state.adminBrands = action.payload.brands;
            state.adminCategories = action.payload.categories;
            state.flavours = action.payload.flavours;
            state.filtersLoaded = true;
            state.status = 'idle';
            state.loadingFilters = false;
        });

        builder.addCase(fetchFilters.rejected, (state, action) => {
            state.status = 'idle';
            state.loadingFilters = false;
            throw(action.payload);
        });



    })
})

export const productSelectors = productsAdaptor.getSelectors((state: RootState)=> state.catalog);

export const {setProductParams, resetProductParams, setMetaData, setPageNumber, setProduct, removeProduct} = catalogSlice.actions;