
import Box from "@mui/material/Box";

import Main from "layouts/Main";
import Container from "components/Container";

import Hero from './Hero';
import FormContact from "./FormContact";
import Contact from "./Contact";

const Kontak = (): JSX.Element => {

  return (
    <Main>
      <Box>
        <Hero />
        <Container>
          <Contact />
        </Container>
        <Box >
       
      </Box>
        <Box bgcolor={'alternate.main'}>
          <Container>
            <FormContact />
          </Container>
        </Box>
      </Box>
    </Main>
  );
};

export default Kontak;
