import Box from "@mui/material/Box";

const Content = (): JSX.Element => {
  return (
    <Box>
      <>
       
        <p>
          Lees asseblief ons privaatheidsbeleid noukeurig om ‘n duidelike begrip
          te verkry van hoe ons u Persoonlike Identifiserende Inligting
          versamel, gebruik, beskerm of andersins in ooreenstemming met ons
          webwerf.
        </p>
        <h3>Watter persoonlike inligting versamel ons?</h3>
        <p>
          Wanneer u op ons webwerf bestel of registreer, word u gevra om u naam,
          e-posadres, posadres, telefoonnommer of ander besonderhede in te vul
          om u te help met u ondervinding.
        </p>
        <p>Wanneer u as agent aansluit gee u toestemming dat u naam, van, en e-posadres gedeel word en sigbaar is aan  die ander agente in die kommissie netwerk.</p>
        <h3>Wanneer versamel ons inligting?</h3>
        <p>
          Ons versamel inligting van u wanneer u op ons webwerf registreer, ‘n
          bestelling plaas, ‘n nuusbrief skryf, ‘n opname beantwoord, ‘n vorm
          invul, Live Chat gebruik, ‘n Ondersteuningskaart oopmaak of inligting
          op ons webwerf insleutel.
        </p>
        <h3>Hoe gebruik ons ​​u inligting?</h3>
        <p>
          Ons kan die inligting wat ons van u insamel, gebruik wanneer u
          registreer, ‘n aankoop doen, inteken vir ons nuusbrief, ‘n opname of
          bemarkingskommunikasie reageer, die webwerf navigeer of sekere ander
          werfkenmerke op die volgende maniere gebruik:
        </p>
        <ul>
          <li>
            Om u ervaring te personaliseer en ons toe te laat om die tipe inhoud
            en produkaanbiedings te lewer waarin u die meeste belangstel.
          </li>
          <li>Om ons webwerf te verbeter om u beter te kan dien.</li>
          <li>
            Om ons in staat te stel om u beter te bedien om te reageer op u
            kliëntediensversoeke.
          </li>
          <li>
            Om ‘n wedstryd-, bevordering-, opname- of ander werffunksie te
            administreer.
          </li>
          <li>Om u transaksies vinnig te verwerk.</li>
          <li>Om te vra vir graderings en resensies van dienste of produkte</li>
          <li>
            Om op te volg na korrespondensie (live chat, e-pos of telefoon
            navrae)
          </li>
        </ul>
        <h3>Hoe beskerm ons u inligting?</h3>
        <p>
          ‘n eksterne betaling gateway hanteer alle krediet kaart transaksies.
        </p>
        <p>
          U persoonlike inligting is agter veilige netwerke vervat en is slegs
          toeganklik vir ‘n beperkte aantal persone wat spesiale toegangsregte
          het tot sulke stelsels en moet die inligting vertroulik hou.
          Daarbenewens word alle sensitiewe / krediet kaart inligting wat u
          verskaf, geïnkripteer via Secure Socket Layer (SSL) tegnologie.
        </p>
        <p>
          Ons implementeer ‘n verskeidenheid sekuriteitsmaatreëls wanneer ‘n
          gebruiker ‘n bestelling plaas, ingee of toegang tot hul inligting het
          om die veiligheid van u persoonlike inligting te behou.
        </p>
        <p>
          Alle kredietkaarttransaksies word deur ‘n gateway-verskaffer verwerk
          en word nie op ons bedieners gestoor of verwerk nie.
        </p>
        <h3>Gebruik ons ​​’cookies’?</h3>
        <p>
          Ja. Cookies is klein lêers wat ‘n webwerf of diensverskaffer oorskakel
          na u rekenaar se hardeskyf deur u webblaaier (indien u toelaat) wat
          die stelsel of diensverskaffer se stelsels in staat stel om u browser
          te herken en sekere inligting in te win en te onthou. Byvoorbeeld, ons
          gebruik cookies om ons te help om die items in u inkopies lys te
          onthou en verwerk. Hulle word ook gebruik om ons te help om jou
          voorkeure te verstaan ​​gebaseer op vorige of huidige werfaktiwiteite,
          wat ons in staat stel om u met verbeterde dienste te voorsien.
        </p>
        <p>
          Ons gebruik ook cookies om ons te help om data op te stel oor
          werfverkeer en werf-interaksie sodat ons in die toekoms beter
          ervarings en gereedskap vir die werf kan bied.
        </p>
        <p>Ons gebruik cookies om:</p>
        <ul>
          <li>Help om die items in die inkopieslys te onthou en verwerk.</li>
          <li>
            Verstaan ​​en stoor gebruiker se voorkeure vir toekomstige besoeke.
          </li>
        </ul>
        <p>
          U kan kies om u rekenaar te waarsku elke keer as ‘n cookie gestuur
          word, of u kan kies om alle cookies uit te skakel. U doen dit deur u
          blaaierinstellings. Aangesien blaaier ‘n bietjie anders is, kyk na die
          helplys van u blaaier om die regte manier om u cookies te verander, te
          leer.
        </p>
        <p>
          As gebruikers cookies in hul blaaier deaktiveer: As jy cookies
          uitskakel, sal dit sommige van die kenmerke van die werf afskakel.
        </p>
        <h3>Derdepartye</h3>
        <p>
          Ons verkoop nie, handel of andersins oordra u persoonlike
          identifiseerbare inligting aan buite-partye nie, tensy ons gebruikers
          vooraf kennis gee. Dit sluit nie webtuiste hosting vennote en ander
          partye in wat ons help om ons webwerf te bedryf, ons besigheid te
          bedryf of om ons gebruikers te bedien nie, solank daardie partye
          ooreenkom om hierdie inligting vertroulik te hou. Ons kan ook
          inligting vrystel wanneer dit vrylating toepaslik is om aan die wet te
          voldoen, ons webwerfbeleid te handhaaf, of die regte van ons of ander
          se eiendom, eiendom of veiligheid te beskerm. Nie-persoonlike
          identifiseerbare besoekersinligting kan egter aan ander partye verskaf
          word vir bemarking, advertensies of ander gebruike.
        </p>
        <h3>Derdeparty skakels</h3>
        <p>
          Soms, na ons diskresie, kan ons produkte of dienste van derde partye
          op ons webwerf insluit of aanbied. Hierdie derdeparty-webwerwe het
          afsonderlike en onafhanklike privaatheidsbeleide. Ons het dus geen
          verantwoordelikheid of aanspreeklikheid vir die inhoud en aktiwiteite
          van hierdie gekoppelde webwerwe nie. Nietemin, ons poog om die
          integriteit van ons webwerf te beskerm en verwelkom enige terugvoer
          oor hierdie webwerwe.
        </p>
        <h3>Google</h3>
        <p>
          Google se advertensievereistes kan opgesom word deur Google se
          advertensiebeginsels. Hulle word ingestel om ‘n positiewe ervaring vir
          gebruikers te bied.
          https://support.google.com/adwordspolicy/answer/1316548?hl=en
        </p>
        <p>
          Ons het nie Google AdSense op ons webwerf geaktiveer nie, maar ons kan
          dit dalk in die toekoms doen.
        </p>
        <h3>Wysigings</h3>
        <p>
          U sal in kennis gestel word van enige veranderinge in die
          Privaatheidsbeleid op ons Privaatheidsbeleid-bladsy
        </p>
        <p>
          Jy kan jou persoonlike inligting verander: 
          <ul>
          <li>Deur ons te e-pos</li>
          <li>
          Deur
          jou aanlyn profiel op ons webwerf.
          </li>
        </ul>
         
        </p>
      </>
    </Box>
  );
};

export default Content;
