import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const About = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          align={"center"}
          color={"text.secondary"}
          sx={{ textTransform: "uppercase" }}
          variant={"subtitle2"}
          fontWeight={600}
        >
          HANDELSHOEK
        </Typography>
        <Typography fontWeight={700} variant={"h4"} align={"center"}>
          Begin jou eie Klein besigheid
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid
          item
          container
          justifyContent={{ xs: "flex-start" }}
          xs={12}
          md={6}
        >
          <Typography color={"text.secondary"}>
            Klein se mooi winkels het die land aan die gons en ons produkte het
            mammas en maats se harte aan die bons! “Natuurlik en HEMELS” is hoe
            van ons Agente, Klein beskryf…. Elkeen met wie ek praat wil só graag
            hul eie Klein winkel oopmaak, maar ongelukkig is dit net nie
            moontlik nie.<br/><br/>
          </Typography>

          <Typography color={"text.secondary"}>
            Gelukkig is ons by Klein bekend vir planne maak... nou is jou kans!
            Na al die baie navrae wat ons gekry het oor ons mooie Klein-winkels,
            het ons besluit om 'n plan te maak sodat meer van ons kosbare Agente
            kan bou aan dié droom!<br/><br/>
          </Typography>
          <Typography color={"text.secondary"}>
          'n Groot winkel het enorme uitgawes en die kapitaal om een op te sit draai by 'n reuse R380 000.00-R500 000.00+... saam met dit is jou huur per maand tussen R16 000.00 en R30 000.00+ sonder salarisse en al die ander uitgawes!   Met vandag se ekonomie wat op en af gaan is dit 'n risiko want jy teken jou VAS vir ten minste 3jaar by meeste sentrums. 
          <br/><br/>
          </Typography>
          <Typography color={"text.secondary"}>
Ons het die afgelope 5 jaar baie moeite (en geld) ingesit om al die moontlikhede te toets en vir JOU die beste opsies en advies te kan bied. 
<br/><br/>
          </Typography>
          <Typography color={"text.secondary"}>
Ons Handelshoeke is die naaste wat jy aan jou eie Klein-winkel (en groot-besigheid) sal kom – sonder al die onnodige kopseer! <br/><br/>
Jy kan die Handelshoek in enige salon, winkel, koffiewinkel, handelshuis, apteek, padstal (die lys gaan aan en aan) sit... Al wat jy nodig het is 'n 2.5 x 1m oop muur- spasie! (Jy kan speel met die kleur van die muur agter jou Handelshoek, dalk hou jy van 'n mooi muurpapier.. gestreep of geblom... of dalk net natuurlik - moet net nie te helder of donker gaan nie.. ons meubels werk die beste saam met 'n natuurlike of pastel kleur.
<br/><br/>
Die besigheid waar jy jou Handelshoek sit, indien dit nie jou eie is nie, gaan vir jou moet sê op watter voorwaarde jy dit kan doen. 
Opsies sal byvoorbeeld insluit: 
<br/>
•	'n Maandelikse huur-fooi (dit kan nie te veel wees nie want jy gebruik 'n klein spasie EN jou eie rakke - alles is ingesluit by jou Handelshoek) <br/>
•	'n Sekere % van jou wins per maand soos tussen jul ooreengekom <br/>
•	Enige ander opsie waaroor jy nie seker is nie, kontak ons gerus! bianca@kleinbederf.com of marlana@kleinbederf.com <br/><br/>
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={6}
          md={6}
          sx={{
            "& .lazy-load-image-background.lazy-load-image-loaded": {
              width: "100%",
              height: "100%",
            },
          }}
        >
          <Box
            component={LazyLoadImage}
            height={1}
            //width={1}
            maxHeight={{ xs: 300, sm: 400, md: 520 }}
            borderRadius={2}
            src={"/images/Handelshoek.jpeg"}
            alt="..."
            effect="blur"
            sx={{
              //objectFit: "cover",
              filter:
                theme.palette.mode === "dark" ? "brightness(0.6)" : "none",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default About;
