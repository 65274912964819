import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import LinearProgress from '@mui/material/LinearProgress';

import Page from "../Page";
import Main from "layouts/Main";
import agent from "api/agent";
import { Order } from "models/order";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridRowsProp,
  GridColumns,
  GridActionsCellItem,
  GridRowId,
} from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import { toast } from "react-toastify";
import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";



const General = (): JSX.Element => {

  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  useEffect(() => {
    agent.Orders.list()
      .then((c: Order[]) => {
        const orders: GridRowsProp = c.map((order) => ({
          id: order.id,
          orderDate: order.orderDate,
          amountTotal: order.amountTotal,
          status: order.status,
        }));
        setRows(orders);
      })
      .catch((error) => toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer"))
      .finally(() => setLoading(false));
  }, []);

  const handleViewClick = (id: GridRowId) => () => {

    navigate(`/profiel-bestellings/${id}`, { replace: true });
    
  };

  
  const handleDownloadInvoice = (id: GridRowId) => () => {
    //toast.info("Paklys word verwerk...");
    setShowDownloadModal(true);

    agent.Orders.downloadInvoicePDF(parseInt(id.toString()))
      .then((response) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([response], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        console.log("url", url);
        link.setAttribute("download", `Faktuur - ${id}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

      })
      .catch((error) => {
        //toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
        console.log("error", error);
      })
      .finally(() => {
        setShowDownloadModal(false);
      });
  };

  const columns: GridColumns = [
    { field: "id", headerName: "Verwysing", width: 180 },
    { field: "orderDate", headerName: "Datum", width: 180, valueGetter: (params) => params.row.orderDate.split('T')[0] },
    {
      field: "amountTotal",
      headerName: "Totaal",
      width: 180,
      valueGetter: (params) => "R" + params.row.amountTotal.toFixed(2),
    },
    {
      field: "status",
      headerName: "Status",
      width: 180
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Opsies",
      width: 100,
      cellClassName: "actions",
      getActions: (params) => {

        if (params.row.status !== 'Gekanselleer'){
          return [
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="View"
              className="textPrimary"
              onClick={handleViewClick(params.id)}
              color="inherit"
            />,
            
            <GridActionsCellItem
            icon={<CloudDownloadIcon />}
            label="Faktuur (PDF)"
            onClick={handleDownloadInvoice(params.id)}
            showInMenu
          />
          
          ];
        }else{
          return [
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="View"
              className="textPrimary"
              onClick={handleViewClick(params.id)}
              color="inherit"
            />,
          ];
        }
        
      },
    },
  ];
  
  return (
    <Main>
      <Page>
        <Box>
          <Typography variant="h6" gutterBottom fontWeight={700}>
            Bestellings
          </Typography>
         
          <Box paddingY={4}>
            <Divider />
          </Box>

          <Box
          sx={{
            height: 500,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            components={{
              LoadingOverlay: LinearProgress,
            }}
           
            loading={loading}
          />
        </Box>
        <Dialog
          open={showDownloadModal}
          onClose={() => setShowDownloadModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography>Versoek is besig om te verwerk.</Typography>

              <LinearProgress />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDownloadModal(false)}>
              Kanselleer
            </Button>
          </DialogActions>
        </Dialog>
        
        </Box>
      </Page>
    </Main>
  );
};

export default General;
