// Admin pages

import {
  Introduction as IntroductionView,
  ProdukteLys as ProdukteLysView,
  Categories as CategoriesView,
  Users as UsersView,
  Orders as OrdersView,
  OrderDetails as OrderDetailsView,
  HierarchyList as CommissionHierarchyListView
} from 'views_admin';

import Coupons from './Coupons';
import ContactForm from './Forms/ContactForm';
import HandelshoekForm from './Forms/HandelshoekForm';
import AgentForm from './Forms/AgentForm';
import Reviews from './Reviews';
import CommissionEarningsList from './CommissionEarnings/CommissionEarningsList';
import CommissionBalanceList from './CommissionBalance/CommissionBalanceList';
import CommissionPayouts from './CommissionPayouts/CommissionPayouts';
import CommissionEarningsListSummary from './CommissionEarnings/CommissionEarningsListSummary';
import GroothandelaarForm from './Forms/GroothandelForm';
import Reports from './Reports/Reports';
import RanksPointsDetail from './Ranks/RanksPointsDetail';
import ChallengeAdmin from './Challenge/Challenge';
import Memberships from './Subscriptions/Memberships';
//import { lazy } from 'react';

//const Reports = lazy(() => import('./Reports/Reports'));
// const Home = lazy(() => import('./routes/Home'));


const routes = [
  {
    path: '/admin',
    renderer: (params = {}): JSX.Element => <IntroductionView {...params} />,
  },
  {
    path: '/admin/introduction',
    renderer: (params = {}): JSX.Element => <IntroductionView {...params} />,
  },
  {
    path: '/admin/produkte',
    renderer: (params = {}): JSX.Element => <ProdukteLysView {...params} />,
  },
  {
    path: '/admin/kategoriee',
    renderer: (params = {}): JSX.Element => <CategoriesView {...params} />,
  },
  {
    path: '/admin/gebruikers',
    renderer: (params = {}): JSX.Element => <UsersView {...params} />,
  },
  {
    path: '/admin/bestellings',
    renderer: (params = {}): JSX.Element => <OrdersView {...params} />,
  },
  {
    path: '/admin/bestellings/:id',
    renderer: (params = {}): JSX.Element => <OrderDetailsView {...params} />,
  },
  {
    path: '/admin/koepons',
    renderer: (params = {}): JSX.Element => <Coupons {...params} />,
  },
  {
    path: '/admin/vorms/kontak',
    renderer: (params = {}): JSX.Element => <ContactForm {...params} />,
  },
  {
    path: '/admin/vorms/handelshoeke',
    renderer: (params = {}): JSX.Element => <HandelshoekForm {...params} />,
  },
  {
    path: '/admin/vorms/agente',
    renderer: (params = {}): JSX.Element => <AgentForm {...params} />,
  },
  {
    path: '/admin/vorms/groothandel',
    renderer: (params = {}): JSX.Element => <GroothandelaarForm {...params} />,
  },
  {
    path: '/admin/koepons',
    renderer: (params = {}): JSX.Element => <Coupons {...params} />,
  },
  {
    path: '/admin/resensies',
    renderer: (params = {}): JSX.Element => <Reviews {...params} />,
  },
  {
    path: '/admin/ranks/punte-detail',
    renderer: (params = {}): JSX.Element => <RanksPointsDetail {...params} />,
    users: ["info@kleinbederf.co.za","admin@test.com"]
  },
  {
    path: '/admin/kommissie/struktuur',
    renderer: (params = {}): JSX.Element => <CommissionHierarchyListView {...params} />,
    users: ["info@kleinbederf.co.za","admin@test.com"]
  },
  {
    path: '/admin/kommissie/verdienste',
    renderer: (params = {}): JSX.Element => <CommissionEarningsList {...params} />,
    users: ["info@kleinbederf.co.za","admin@test.com"]
  },
  {
    path: '/admin/kommissie/verdienste-opsomming',
    renderer: (params = {}): JSX.Element => <CommissionEarningsListSummary {...params} />,
    users: ["info@kleinbederf.co.za","admin@test.com"]
  },
  {
    path: '/admin/kommissie/balans',
    renderer: (params = {}): JSX.Element => <CommissionBalanceList {...params} />,
    users: ["info@kleinbederf.co.za","admin@test.com"]
  },
  {
    path: '/admin/kommissie/uitbetalings',
    renderer: (params = {}): JSX.Element => <CommissionPayouts {...params} />,
    users: ["info@kleinbederf.co.za","admin@test.com"]
  },
  {
    path: '/admin/subskripsies',
    renderer: (params = {}): JSX.Element => <Memberships {...params} />,
  },
  {
    path: '/admin/verslagte',
    renderer: (params = {}): JSX.Element => <Reports {...params} />,
    users: ["info@kleinbederf.co.za","admin@test.com","johan@kleinbederf.co.za","Delainecv123@yahoo.com", "delaine@kleinbederf.co.za"]
  },
  {
    path: '/admin/challenge',
    renderer: (params = {}): JSX.Element => <ChallengeAdmin {...params} />,
    users: ["info@kleinbederf.co.za","admin@test.com"]
  },

  
 
];

export default routes;
