import { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import agent from "../../../api/agent";

import { toast } from "react-toastify";
import { Ranks_UserAchievement } from "models/ranks";
import { Avatar,CircularProgress, Grid, Typography } from "@mui/material";
import DiamondIcon from "@mui/icons-material/Diamond";



export default function RanksRankList() {
  const [loading, setLoading] = useState(true);

  const [list, setList] = useState<Ranks_UserAchievement[]>([]);

  useEffect(() => {
    agent.Ranks.getUserAchievements()
      .then((response: Ranks_UserAchievement[]) => {
        setList(response);
      })
      .catch((error) =>
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer")
      )
      .finally(() => setLoading(false));
  }, []);

  if (loading)
    return (
      <Box sx={{ textAlign: "left" }}>
        <CircularProgress />
        <Typography>Besig om jou ranks te laai...</Typography>
      </Box>
    );

  return (
    <Box sx={{maxWidth: '800px' }} padding={3}>
    <Grid container spacing={2} >

    {list.map((item) => (
      
        <Grid item xs={4} textAlign="center" sx={{ opacity: (item.achieved ? 1 : 0.3) }}>
            <Typography fontWeight={700} paddingY={2}>{item.title}</Typography>
            <Box sx={{ justifyContent: "center", display: "flex" }}>
                <Avatar alt="Diamand" sx={{ width: 120, height: 120, backgroundColor: (item.achieved ? item.achievedColor : 'grey') }}>
           
           <DiamondIcon sx={{ fontSize: 80 }}  />
         </Avatar>
            </Box>
            

            {!item.achieved &&
                <Typography paddingY={2}>Benodig {item.pointsRequired} Punte</Typography>
            }

      </Grid>))}
      
    </Grid>
    </Box>
  );
}
