import Main from "layouts/Main";
import Container from "components/Container";
import { Catalogue } from "models/catalog";
import agent from "api/agent";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function KatalogusPage() {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<Catalogue[]>([]);

  const theme = useTheme();

  useEffect(() => {
    async function fetchData() {
      try {
        const response: Catalogue[] = await agent.Catalogue.catalogList();
        setList(response.filter((x) => x.active === true));
      } catch (error) {
        toast.error(
          "Aggenee, kon nie die katalogus lys aflaai nie. Probeer asb weer."
        );
      }

      setLoading(false);
    }

    fetchData();
  }, []);

  return (
    <Main>
      <Box
        sx={{
          position: "relative",
          backgroundColor: theme.palette.alternate.main,
          backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
          marginTop: -13,
          paddingTop: 13,
        }}
      >
        <Container>
          <Box>
            <Box
              marginBottom={{ xs: 0, sm: 2 }}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Typography
                variant="h3"
                gutterBottom
                align={"center"}
                sx={{
                  fontWeight: 900,
                }}
              >
                Katalogus
              </Typography>
              <Typography
                variant="h6"
                component="p"
                color="text.primary"
                align={"center"}
                sx={{ marginBottom: 1 }}
              ></Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Container>
        <Box>
          {loading && (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
            </Box>
          )}

          {!loading && (
            <Grid container spacing={2}>
              {list.map((item, i) => (
                <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                  <Box
                    sx={{
                      position: "relative",
                      overflow: "hidden",
                      borderRadius: 2,
                      "&:hover": {
                        "& img": {
                          transform: "scale(1.2)",
                        },
                      },
                      "& .lazy-load-image-loaded": {
                        display: "flex !important",
                      },
                    }}
                  >
                    <Link href={item.link} target={"_blank"}>
                      <Box
                        component={LazyLoadImage}
                        height={1}
                        width={1}
                        src={"uploads/catalog/" + item.image}
                        alt="..."
                        effect="blur"
                        minHeight={{ xs: 200, md: 300 }}
                        maxHeight={{ xs: 500, md: 600 }}
                        sx={{
                          transition: "transform .7s ease !important",
                          transform: "scale(1.0)",
                          objectFit: "cover",
                          filter:
                            theme.palette.mode === "dark"
                              ? "brightness(0.7)"
                              : "none",
                        }}
                      />
                      <Box
                        position={"absolute"}
                        bottom={0}
                        left={0}
                        right={0}
                        padding={2}
                        sx={{
                          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, ${item.color})`,
                        }}
                      >
                        <Typography
                          variant={"h4"}
                          fontWeight={700}
                          sx={{ color: "common.white" }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          variant={"h6"}
                          fontWeight={700}
                          sx={{ color: "common.white" }}
                          gutterBottom
                        >
                          {item.description}
                        </Typography>

                        <Button size={"large"} variant={"contained"}>
                          {" "}
                          Laai Af
                        </Button>
                      </Box>
                    </Link>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Container>
    </Main>
  );
}
