import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const About = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          align={'center'}
          color={'text.secondary'}
          sx={{ textTransform: 'uppercase' }}
          variant={'subtitle2'}
          fontWeight={600}
        >
          ONS PRODUKTE IN ELKE HUIS
        </Typography>
        <Typography fontWeight={700} variant={'h4'} align={'center'}>
         Gaan groot met Klein
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid
          item
          container
          justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
          xs={12}
          md={6}
        >
          <Typography color={'text.secondary'}>
          Klein het sommer vinnig nes geskrop in talle huishoudings, harte en handsakke reg oor Suid-Afrika die afgelope 5 jaar.  
          
          <br/>  <br/>
          Ons produkte sal verseker ‘n groot aanwins wees vir jou familie, huis-winkel of besigheid, dit sal jou rakke optooi en natuurlik ook sorg vir sommer baie bederf met meer as 500 produkte in 20+ gewilde geure.


          </Typography>
        </Grid>
        <Grid item container xs={12} md={6}>
          <Typography color={'text.secondary'}>
          Ons produkte is uniek en alles, van die produksie tot die pak van elke bestelling, word altyd gedoen met ‘n KLEIN bietjie ekstra liefde…

          <br/><br/>
          Maak asseblief seker jy laai ook die nuutste Klein Katalogus af om meer te leer oor Klein en ons produkte.  Die “Katalogus”- skakel is bo op die webblad beskikbaar onder 'Oor Klein...'.
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{
            '& .lazy-load-image-background.lazy-load-image-loaded': {
              width: '100%',
              height: '100%',
            },
          }}
        >
          <Box
            component={LazyLoadImage}
            height={1}
            width={1}
            maxHeight={{ xs: 300, sm: 400, md: 520 }}
            borderRadius={2}
            src={'/images/titlebar-1.jpeg'}
            alt="..."
            effect="blur"
            sx={{
              objectFit: 'cover',
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.6)' : 'none',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default About;
