import FixedLayout from "layouts/Fixed";
import {
  Typography,
  Box,
  Tab,
  Tabs,
  styled,
} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";
import agent from "../api/agent";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  DataGrid,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridColumns,
  GridRowParams,
  MuiEvent,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridValueFormatterParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import React from "react";
import { ProductReview } from "models/product";

export default function Reviews() {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [tabStatus, setTabStatus] = React.useState(0);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;

  };

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    setLoading(false);
  }, []);

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = React.useCallback(
    async (newRow: GridRowModel) => {
      setLoading(true);

      const response = await agent.Reviews.reviewUpdate(newRow);

      setLoading(false);

      const updatedRow = { ...response, isNew: false };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    },
    [rows]
  );

  const handleStatusChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabStatus(newValue);

    let filter = "Benodig Hersiening";

    switch (newValue) {
      case 0:
        filter = "Benodig Hersiening";
        break;
      case 1:
        filter = "Sigbaar";
        break;
      case 2:
        filter = "Versteek";
        break;
    }

    //Load from database filtered values
    setLoading(true);

    agent.Reviews.reviewList(filter)
      .then((c: ProductReview[]) => {
        const submissions: GridRowsProp = c.map((s) => ({
          id: s.id,
          productName: s.productName,
          dateCreated: s.dateCreated,
          status: s.status,
          comments: s.comments,
          rating: s.rating,
          firstName: s.firstName,
        }));
        setRows(submissions);
      })
      .catch((error) => {
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    agent.Reviews.reviewList("Benodig Hersiening")
      .then((c: ProductReview[]) => {

     

        const submissions: GridRowsProp = c.map((s) => ({
          id: s.id,
          productName: s.productName,
          dateCreated: s.dateCreated,
          status: s.status,
          comments: s.comments,
          rating: s.rating,
          firstName: s.firstName,
        }));
        setRows(submissions);
      })
      .catch((error) => {
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));

     
  }, []);

  const handleDeleteClick = (id: GridRowId) => () => {
    setLoading(true);

    agent.Reviews.reviewDelete(parseInt(id.toString()))
      .then((c) => setRows(rows.filter((row) => row.id !== id)))
      .catch((error) => {
        toast.warning("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
      border: '1px solid #dadde9',
    },
  }));
 

  const columns: GridColumns = [
    {
      field: "firstName",
      headerName: "Naam",
      flex: 1,
      editable: false,
    },
    {
      field: "productName",
      headerName: "Produk",
      flex: 1,
      editable: false,
    },
    {
      field: "rating",
      headerName: "Rating",
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        return `${(params.row.rating / 10).toFixed(1)}`;
      },
    },
    {
      field: "comments",
      headerName: "Boodskap",
      width: 250,
      editable: false,
      renderCell: (params: GridRenderCellParams<string>) => (
            <HtmlTooltip title={params.value} placement="bottom">
              <span>{params.value.substring(0,20)}...</span>
          </HtmlTooltip>

      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Benodig Hersiening", "Sigbaar", "Versteek"],
    },



    {
      field: "dateCreated",
      headerName: "Datum",
      flex: 1,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }

        const date = new Date(params.value);

        const valueFormatted = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        });
        return valueFormatted;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Opsies",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <FixedLayout>
      <Box
        width={1}
        margin={"0 auto"}
        paddingX={2}
        paddingY={{ xs: 2, sm: 2, md: 2 }}
      >
        
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ p: 2 }} variant="h5">
            Resensies
          </Typography>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", padding: 1 }}>
            <Tabs
              value={tabStatus}
              onChange={handleStatusChange}
              aria-label="tabs"
            >
              <Tab label="Benodig Hersiening" id={"Benodig Hersiening"} />
              <Tab label="Sigbaar" id={"Sigbaar"} />
              <Tab label="Versteek" id={"Versteek"} />
            </Tabs>
          </Box>
        </Box>

        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            editMode="row"
            disableSelectionOnClick
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            components={{
              LoadingOverlay: LinearProgress,
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            experimentalFeatures={{ newEditingApi: true }}
            loading={loading}
          />
        </Box>
      </Box>
    </FixedLayout>
  );
}
