import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const mock = [
  {
    feedback:
      "Om altyd top-kwaliteit, natuurlike produkte te vervaardig wat bekostig- en maklik bekombaar is reg oor Suid-Afrika!",
    title: 'Ons Missie',
   
  },
  {
    feedback:
      'Dit is vir ons baie belangrik om geen skadelike geursels of kleurstowwe te gebruik nie.  Klein se produkte word gemaak van natuurlike olies en sover moontlik natuurlike basisse om gesonde en gelukkige velle te verseker!',
    title: 'Ons benadering',
   
  },
  {
    feedback:
      'Elkeen van ons produkte word altyd gemaak met ‘n KLEIN bietjie ekstra liefde…',
    title: 'Wat maak ons anders?',
   
  },
];

const MeerOorKlein = (): JSX.Element => {
  return (
    <Box width={1} height={1} data-aos={'fade-up'} component={Card}>
      <CardContent>
        <Box>
          <Box marginBottom={4}>
            {/* <Box marginBottom={2}>
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 'medium',
                }}
                color={'primary'}
                align={'center'}
              >
                Process
              </Typography>
            </Box> */}
            <Typography
              variant="h4"
              align={'center'}
              data-aos={'fade-up'}
              gutterBottom
              sx={{
                fontWeight: 700,
                marginTop: 1,
              }}
            >
              Meer oor Klein
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {mock.map((item, i) => (
              <Grid item xs={12} md={4} key={i}>
                <Box
                  width={1}
                  height={1}
                  data-aos={'fade-up'}
                  data-aos-delay={i * 100}
                  data-aos-offset={100}
                  data-aos-duration={600}
                  component={Card}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  boxShadow={0}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                   
                    <Typography
                      align={'center'}
                      variant={'h6'}
                      sx={{ fontWeight: 700 }}
                      gutterBottom
                    >
                      {item.title}
                    </Typography>
                    <Typography align={'center'} color="text.secondary">
                      {item.feedback}
                    </Typography>
                  </CardContent>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </CardContent>
    </Box>
  );
};

export default MeerOorKlein;
