import FixedLayout from "layouts/Fixed";
import {
  Typography,
  Box,
  Button,
  Skeleton,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

import agent from "../../api/agent";
import { Order } from "models/order";
import { Link, useParams } from "react-router-dom";
import {
  currencyFormat,
  priceFormatOrderItemVariation,
  priceFormatOrderItem,
} from "util/util";
import Payments from "./Payments";
import Refunds from "./Refunds";
import Notes from "./Notes";
import { toast } from "react-toastify";

export default function OrderDetails() {
  const { id } = useParams<{ id: string }>();

  const [order, setOrder] = useState<Order | null>();
  const [loading, setLoading] = useState(true);

  const [order_trackingNumber, SetOrderTrackingNumber] = useState("");
  const [order_status, SetOrderStatus] = useState("");

  //public string DeliveryTrackingNo {get; set;}

  async function updateOrder() {
    try {
      setLoading(true);
      await agent.Admin.orderUpdate({
        id: order.id,
        deliveryTrackingNo: order_trackingNumber,
        status: order_status,
      });

      toast.success("Bestelling suksesvol opgedateer");

      await agent.Admin.orderSendOrderStatusUpdateEmail(order.id);
      toast.success("Epos aan klient gestuur");

      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);

    agent.Admin.orderDetails(parseInt(id))
      .then((order: Order) => {
        setOrder(order);

        SetOrderStatus(order.status);
        SetOrderTrackingNumber(order.deliveryTrackingNo);
      })
      .catch((error) =>
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer")
      )
      .finally(() => setLoading(false));
  }, [id]);

  if (loading)
    return (
      <FixedLayout>
        <Box
          width={1}
          margin={"0 auto"}
          paddingX={2}
          paddingY={{ xs: 2, sm: 2, md: 2 }}
        >
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Button
                  component={Link}
                  to="/admin/bestellings"
                  sx={{ m: 1 }}
                  size="large"
                  variant="contained"
                >
                  Terug na Bestellings
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{ p: 1 }}
                  // gutterBottom
                  variant="h6"
                  fontWeight={700}
                  textAlign="left"
                >
                  <Skeleton variant="text" />
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box paddingY={4}>
            <Divider />
          </Box>
          <Skeleton variant="rectangular" height={400} />
        </Box>
      </FixedLayout>
    );

  if (!order)
    return (
      <FixedLayout>
        <Box
          width={1}
          margin={"0 auto"}
          paddingX={2}
          paddingY={{ xs: 2, sm: 2, md: 2 }}
        >
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Button
                  component={Link}
                  to="/admin/bestellings"
                  sx={{ m: 1 }}
                  size="large"
                  variant="contained"
                >
                  Terug na Bestellings
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{ p: 1 }}
                  // gutterBottom
                  variant="h6"
                  fontWeight={700}
                  textAlign="left"
                >
                  Bestelling # {order.id} - Nie Gevind
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box paddingY={4}>
            <Divider />
          </Box>
          <Typography>Bestelling nie gevind nie.</Typography>
        </Box>
      </FixedLayout>
    );

  return (
    <FixedLayout>
      <Box
        width={1}
        margin={"0 auto"}
        paddingX={2}
        paddingY={{ xs: 2, sm: 2, md: 2 }}
      >
        <Box>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Button
                component={Link}
                to="/admin/bestellings"
                sx={{ m: 1 }}
                size="large"
                variant="contained"
              >
                Terug na Bestellings
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                sx={{ p: 0 }}
                // gutterBottom
                variant="h6"
                fontWeight={700}
                textAlign="right"
              >
                Bestelling # {order.id} - {order_status}
              </Typography>
            </Grid>
          </Grid>

          <Box paddingY={4}>
            <Divider />
          </Box>

          <Box paddingX={{ xs: 2, sm: 3, md: 7, lg: 15 }}>
            <Paper elevation={2} sx={{ padding: 3, margin: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Box
                    component={"img"}
                    src={"/images/Klein-logo.svg"}
                    sx={{ width: 150 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography textAlign="right">
                    Klein Bederf Pty Ltd
                  </Typography>
                  <Typography textAlign="right">Winkel GF14,</Typography>
                  <Typography textAlign="right">Hartenbos Seefront,</Typography>
                  <Typography textAlign="right">Hartenbos</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography fontWeight={700}>Klient ({order.customerType})</Typography>
                  {order.customerAddress_BusinessName !== "" && (
                    <Typography>
                      {order.customerAddress_BusinessName}
                    </Typography>
                  )}

                  {order.customerAddress_BusinessNumber !== "" && (
                    <Typography>
                      Reg No: {order.customerAddress_BusinessNumber}
                    </Typography>
                  )}

                  {order.customerAddress_BusinessVatNumber !== "" && (
                    <Typography gutterBottom>
                      VAT No: {order.customerAddress_BusinessVatNumber}
                    </Typography>
                  )}

                  <Typography>{order.customerAddress_FullName}</Typography>
                  <Typography>{order.customerAddress_Address1}</Typography>
                  <Typography>{order.customerAddress_Address2}</Typography>
                  <Typography>{order.customerAddress_City}</Typography>
                  <Typography>{order.customerAddress_Province}</Typography>
                  <Typography>{order.customerAddress_Country}</Typography>
                  <Typography gutterBottom>
                    {order.customerAddress_PostalCode}
                  </Typography>

                  <Typography>{order.customerAddress_ContactNumber}</Typography>
                  <Typography>{order.email}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography fontWeight={700}>
                    {order.deliveryOption}
                  </Typography>
                  <Typography>{order.shippingAddress_FullName}</Typography>
                  <Typography>{order.shippingAddress_Address1}</Typography>
                  <Typography>{order.shippingAddress_Address2}</Typography>
                  <Typography>{order.shippingAddress_City}</Typography>
                  <Typography>{order.shippingAddress_Province}</Typography>
                  <Typography>{order.shippingAddress_Country}</Typography>
                  <Typography>{order.shippingAddress_PostalCode}</Typography>
                  <Typography>{order.shippingAddress_ContactNumber}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Typography fontWeight={700}>Bestelling Ontvang</Typography>
                  <Typography>
                    {new Date(order.orderDate).toLocaleString("en-ZA", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: false,
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Typography fontWeight={700}>Bestelling No</Typography>
                  <Typography>{order.id}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Typography fontWeight={700}>Status</Typography>

                  <Select
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={order_status}
                    onChange={(e) => SetOrderStatus(e.target.value)}
                    disabled={order.status === "Gekanselleer"}
                  >
                    <MenuItem key={1} value={"Benodig Betaling"}>
                      {"Benodig Betaling"}
                    </MenuItem>
                    <MenuItem key={2} value={"Verwerking"}>
                      {"Verwerking"}
                    </MenuItem>
                    <MenuItem key={3} value={"Afgehandel"}>
                      {"Afgehandel"}
                    </MenuItem>
                    <MenuItem key={4} value={"Gekanselleer"}>
                      {"Gekanselleer"}
                    </MenuItem>
                  </Select>

                  {/* <Typography>{order.status}</Typography> */}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Typography fontWeight={700}>Opsporings Nommer</Typography>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={order_trackingNumber}
                    onChange={(e) => SetOrderTrackingNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Typography fontWeight={700}>
                    Datum Verwerk
                  </Typography>
                  {order.dateProcessed !== null && (
                    <Typography>
                      {new Date(order.dateProcessed).toLocaleString("en-ZA", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: false,
                      })}
                    </Typography>
                  )}

                  {order.dateProcessed === null && <Typography>-</Typography>}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Typography fontWeight={700}>
                   Datum Afgehandel
                  </Typography>
                  {order.dateFinalised !== null && (
                    <Typography>
                    
                      {new Date(order.dateFinalised).toLocaleString("en-ZA", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: false,
                      })}
                    </Typography>
                  )}

                  {order.dateFinalised === null && <Typography>-</Typography>}
                  
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} >
                  <Typography fontWeight={700}>Boodskap / Versoek:</Typography>
                  <Typography>
                    {order.remarks}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} >
                  <Typography fontWeight={700}>Verwys Deur (Agent):</Typography>
                  <Typography>
                    {order.referredBy}
                  </Typography>
                </Grid>

                <Grid item xs={12} sx={{ paddingTop: 10 }}>
                  <Box sx={{ display: "block", overflowX: "auto" }}>
                    <Table sx={{ minWidth: "650px" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Produk</TableCell>
                          <TableCell align="right">Prys</TableCell>
                          <TableCell align="right">Hoeveelheid</TableCell>
                          <TableCell align="right">Sub Totaal</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {order.orderItems.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell component="th" scope="row">
                              <Box>
                                <Typography gutterBottom>
                                  {item.name}
                                </Typography>

                                {item.itemVariations.map((variation) => (
                                  <Typography
                                    key={variation.id}
                                    color={"text.secondary"}
                                    variant={"subtitle2"}
                                    gutterBottom
                                  >
                                    {variation.type}:{" "}
                                    <Typography
                                      variant={"inherit"}
                                      component={"span"}
                                      color={"inherit"}
                                    >
                                      {variation.title}{" "}
                                      {priceFormatOrderItemVariation(variation,true, order.customerType)}
                        
                                    </Typography>
                                  </Typography>
                                ))}
                              </Box>
                            </TableCell>
                            <TableCell align="right">
                            {priceFormatOrderItem(item,true, order.customerType)}
                            </TableCell>
                            <TableCell align="right">{item.quantity}</TableCell>
                            <TableCell align="right">
                              R{item.amountSubTotal.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} />
                <Grid item xs={12} sm={6}>
                  <TableContainer component={Paper} variant={"outlined"}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={2}>Sub Totaal</TableCell>
                          <TableCell align="right">
                            {currencyFormat(order.amountSubtotal)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Afslag</TableCell>
                          <TableCell align="right">
                            {currencyFormat(order.amountDiscount)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            Koepon / Geskenkbewys
                            {order.coupons?.length > 0 && (
                              <>
                                <br />
                                <ul>
                                  {order.coupons.map((c) => (
                                    <li key={c.id}>
                                      {c.title} ({c.code})
                                    </li>
                                  ))}
                                </ul>
                              </>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            -{currencyFormat(order.amountCoupon)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Aflewering</TableCell>
                          <TableCell align="right">
                            {currencyFormat(order.amountDeliveryFee)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>BTW 15% Ingesluit</TableCell>
                          <TableCell align="right">
                            {currencyFormat(order.amountTax)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Totaal</TableCell>
                          <TableCell align="right">
                            {currencyFormat(order.amountTotal)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              
                <Grid item xs={12} textAlign="right">
                  <Button
                    sx={{ m: 1 }}
                    size="medium"
                    variant="contained"
                    onClick={updateOrder}
                  >
                    Stoor Veranderinge
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Payments orderid={order.id} />
            </Grid>

            <Grid item xs={12}>
              <Refunds orderid={order.id} />
            </Grid>

            <Grid item xs={12}>
              <Notes orderid={order.id} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </FixedLayout>
  );
}
