/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as LinkR, useNavigate } from "react-router-dom";
import { setPasswordAndSignInUser, signInUser } from "../../store/accountSlice";
import { useAppDispatch } from "store/configureStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("'n Geldige e-posadres is nodig")
    .required("E-posadres is nodig"),
  password: yup
    .string()
    .required("'n Wagwoord is nodig'")
});

const validationSchemaStep2 = yup.object({
  code: yup
    .number()
    .required("Kode is noodskap - loer asb. vir die kode in die epos wat ons gestuur het."),
  newpassword: yup
    .string()
    .required("Die nuwe wagwoord veld is noodsaaklik")
    .min(8, "Die wagwoord moet tenminste 8 karakters lank wees")
    .matches(
      /^(?=.*[a-z])/,
      "Die wagwoord moet tenminste een kleinletter bevat, bv. a,b,c,d"
    )
    .matches(
      /^(?=.*[A-Z])/,
      "Die wagwoord moet tenminste een hoofletter bevat, bv. A,B,C,D"
    )
    .matches(
      /^(?=.*[0-9])/,
      "Die wagwoord moet tenminste een nommer bevat, bv. 1,2,3,4"
    ),

});

const Form = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  //const [loginErrors, setLoginErrors] = useState<string[]>([]);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [showPasswordResetSuccessful, setShowPasswordResetSuccessful] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(showPasswordReset? validationSchemaStep2 : validationSchema),
  });

  async function handleSubmitPasswordChange(values) {


    try {

      await dispatch(setPasswordAndSignInUser({password: values.newpassword, code: values.code, email: values.email}));
     
      toast.success(
        "Wagwoord suksesvol opgedateer!"
      );

      setShowPasswordReset(false);
      setShowPasswordResetSuccessful(true);

    } catch (errors: any) {

     

      if (errors.code) {
        setError(
          "code",
          { type: "focus", message: errors.code },
          { shouldFocus: true }
        );
        return;
      }

      if (errors.password) {
        setError(
          "newpassword",
          { type: "focus", message: errors.password },
          { shouldFocus: true }
        );
        return;
      }

    }
    
  }

  async function handleSubmitData(values) {
    try {
      await dispatch(signInUser(values));
      

      navigate("/");
    } catch (errors) {
      console.log('errors', errors);

      if (errors.email) {
        setError(
          "email",
          { type: "focus", message: errors.email },
          { shouldFocus: true }
        );
        return;
      }

      if (errors.password) {
        console.log('error with password');
        setError(
          "password",
          { type: "focus", message: errors.password },
          { shouldFocus: true }
        );
        return;
      }

      if (errors.requirePasswordReset) {
        setShowPasswordReset(true);

        return;
      }

      //const errorslist: string[] = [];

      // if (errors.title) {
      //   setLoginErrors([errors.title]);
      // }

      // if (errors.data && Object.keys(errors.data.errors).length > 0) {
      //   Object.values(errors.data.errors)
      //     .flat()
      //     .forEach((err: any, i) => {
      //       errorslist.push(err);
      //     });

      //   setLoginErrors(errorslist);
      // }
    }
  }

  if (showPasswordReset) {
    return (
      <Box>
        <Box marginBottom={4}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
            }}
          >
          
          Nuwe Wagwoord
          </Typography>
          <Typography color="text.secondary">
            Ons benodig dat jy jou wagwoord opdateer. Sleutel asb die kode wat ons na jou epos gestuur het saam met die nuwe wagwoord wat jy wil gebruik hieronder in:
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(handleSubmitPasswordChange)}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                label="Kode"
                variant="outlined"
                name={"code"}
                fullWidth
                defaultValue={""}
                {...register("code")}
                error={errors?.code != null}
                // @ts-ignore
                helperText={errors?.code?.message}
              />
            </Grid>
            <Grid item xs={12}>
                <TextField
                  label="Wagwoord"
                  variant="outlined"
                  name={"newpassword"}
                  type={"password"}
                  fullWidth
                  defaultValue={""}
                  {...register("newpassword")}
                  error={errors?.newpassword != null}
                  // @ts-ignore
                  helperText={errors?.newpassword?.message}
                />
              </Grid>
            <Grid item container xs={12}>
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "stretched", sm: "center" }}
                justifyContent={"space-between"}
                width={1}
                maxWidth={600}
                margin={"0 auto"}
              >
               
                <Button size={"large"} variant={"contained"} type={"submit"}>
                  Stoor
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  }

  if (showPasswordResetSuccessful) {
    return (
      <Box>
        <Box marginBottom={4}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
            }}
          >
            Sukses!
          </Typography>
          <Typography color="text.secondary">
            Wagwoord suksesvol verander en jy is aangesluit op ons webwerf.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Welkom Terug
        </Typography>
        <Typography color="text.secondary">Teken in op ons webblad</Typography>
      </Box>

      
          {/* {loginErrors.length > 0 && (
            <FormatErrors listoferrors={loginErrors} />
          )} */}

          <form onSubmit={handleSubmit(handleSubmitData)}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  label="E-Posadress"
                  variant="outlined"
                  name={"email"}
                  fullWidth
                  defaultValue={""}
                  {...register("email")}
                  error={errors?.email != null}
                  // @ts-ignore
                  helperText={errors?.email?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Wagwoord"
                  variant="outlined"
                  name={"password"}
                  type={"password"}
                  fullWidth
                  defaultValue={""}
                  {...register("password")}
                  error={errors?.password != null}
                  // @ts-ignore
                  helperText={errors?.password?.message}
                />
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "stretched", sm: "center" }}
                  justifyContent={"space-between"}
                  width={1}
                  marginBottom={2}
                >
                  <Typography variant={"subtitle2"}>
                    <Link
                      component={LinkR}
                      color={"primary"}
                      to={"/wagwoordvergeet"}
                      underline={"none"}
                    >
                      Wagwoord vergeet?
                    </Link>
                  </Typography>
                </Box>
              </Grid>
              <Grid item container xs={12}>
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "stretched", sm: "center" }}
                  justifyContent={"space-between"}
                  width={1}
                  maxWidth={600}
                  margin={"0 auto"}
                >
                  <Box marginBottom={{ xs: 1, sm: 0 }}>
                    <Typography variant={"subtitle2"}>
                      Is nog nie geregistreer nie?{" "}
                      <Link
                        component={LinkR}
                        color={"primary"}
                        to={"/registreer"}
                        underline={"none"}
                      >
                        Skep 'n profiel hierso.
                      </Link>
                    </Typography>
                  </Box>
                  <LoadingButton loading={isSubmitting} size={"large"} variant={"contained"} type={"submit"}>
                    Teken In
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
       
    </Box>
  );
};

export default Form;
