import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';

import ProductDetails  from './ProductDetails';
import { Divider } from '@mui/material';
import { ProductAdmin } from 'models/product';

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClose: () => void;
  open: boolean;
  product: ProductAdmin;
}

const ProductQuickViewDialog = ({
  onClose,
  open,
  product
}: Props): JSX.Element => {

  return (
    <Dialog onClose={onClose} open={open} fullScreen >
      <Box paddingY={{ xs: 1, sm: 2 }} paddingX={{ xs: 2, sm: 4 }}>
        <Box
          paddingY={{ xs: 1, sm: 2 }}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Typography variant={"h5"} fontWeight={700}>Produk Besonderhede</Typography>
          <Box
            component={'svg'}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={24}
            height={24}
            onClick={onClose}
            sx={{ cursor: 'pointer' }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </Box>
        </Box>
        <Divider />
        <Box paddingY={2}>
          <ProductDetails product={product} onClose={onClose} />
        </Box>
      </Box>
    </Dialog>
  );
};

export default ProductQuickViewDialog;
