import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import agent from "api/agent";
import { Order } from "models/order";
import {
  currencyFormat,
  priceFormatOrderItem,
  priceFormatOrderItemVariation,
} from "util/util";
import { Main } from "layouts";
import Page from "../Page";
import { toast } from "react-toastify";

export default function OrderDetails() {
  const { id } = useParams<{ id: string }>();

  const [order, setOrder] = useState<Order | null>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    agent.Orders.details(parseInt(id))
      .then((order) => {
        setOrder(order);
        console.log('order', order);
      })
      .catch((error) => toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer"))
      .finally(() => setLoading(false));
  }, [id]);

  if (loading)
    return (
      <Main>
        <Page>
          <Box>
            <Box display="flex" justifyContent="space-between">
              <Typography
                sx={{ p: 2 }}
                gutterBottom
                variant="h6"
                fontWeight={700}
              >
                <Skeleton width={180} />
              </Typography>
              <Button
                component={Link}
                to="/profiel-bestellings"
                sx={{ m: 2 }}
                size="large"
                variant="contained"
              >
                Terug na Bestellings
              </Button>
            </Box>
            <Box paddingY={4}>
              <Divider />
            </Box>
            <Skeleton variant="rectangular" height={400} />
          </Box>
        </Page>
      </Main>
    );

  if (!order)
    return (
      <Main>
        <Page>
          <Box>
            <Typography>Bestelling nie gevind nie.</Typography>
          </Box>
        </Page>
      </Main>
    );

  return (
    <Main>
      <Page>
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Button
                component={Link}
                to="/profiel-bestellings"
                sx={{ m: 2 }}
                size="large"
                variant="contained"
              >
                Terug na Bestellings
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                sx={{ p: 2 }}
                gutterBottom
                variant="h6"
                fontWeight={700}
              >
                Bestelling # {order.id} - {order.status}
              </Typography>
            </Grid>
          </Grid>

          <Box paddingY={4}>
            <Divider />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box
                component={"img"}
                src={"/images/Klein-logo.svg"}
                sx={{ width: 150 }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign="right">Klein Bederf Pty Ltd</Typography>
              <Typography textAlign="right">Winkel GF14,</Typography>
              <Typography textAlign="right">Hartenbos Seefront,</Typography>
              <Typography textAlign="right">Hartenbos</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography fontWeight={700}>Klient</Typography>
              {order.customerAddress_BusinessName !== "" && (
                <Typography>{order.customerAddress_BusinessName}</Typography>
              )}

              {order.customerAddress_BusinessNumber !== "" && (
                <Typography>
                  Reg No: {order.customerAddress_BusinessNumber}
                </Typography>
              )}

              {order.customerAddress_BusinessVatNumber !== "" && (
                <Typography gutterBottom>
                  VAT No: {order.customerAddress_BusinessVatNumber}
                </Typography>
              )}

              <Typography>{order.customerAddress_FullName}</Typography>
              <Typography>{order.customerAddress_Address1}</Typography>
              <Typography>{order.customerAddress_Address2}</Typography>
              <Typography>{order.customerAddress_City}</Typography>
              <Typography>{order.customerAddress_Province}</Typography>
              <Typography>{order.customerAddress_Country}</Typography>
              <Typography gutterBottom>
                {order.customerAddress_PostalCode}
              </Typography>

              <Typography>{order.customerAddress_ContactNumber}</Typography>
              <Typography>{order.email}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={700}>{order.deliveryOption}</Typography>

              <Typography>{order.shippingAddress_FullName}</Typography>
              <Typography>{order.shippingAddress_Address1}</Typography>
              <Typography>{order.shippingAddress_Address2}</Typography>
              <Typography>{order.shippingAddress_City}</Typography>
              <Typography>{order.shippingAddress_Province}</Typography>
              <Typography>{order.shippingAddress_Country}</Typography>
              <Typography>{order.shippingAddress_PostalCode}</Typography>
              <Typography>{order.shippingAddress_ContactNumber}</Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3} >
              <Typography fontWeight={700}>Bestelling Ontvang</Typography>
              <Typography>{new Date(order.orderDate).toLocaleString("en-ZA", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: false
        })}</Typography>



            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} >
              <Typography fontWeight={700}>Bestelling No</Typography>
              <Typography>{order.id}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} >
              <Typography fontWeight={700}>Status</Typography>
              <Typography>{order.status}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} >
              <Typography fontWeight={700}>Opsporings Nommer</Typography>
              <Typography>{order.deliveryTrackingNo}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} >
                  <Typography fontWeight={700}>
                    Datum Verwerk
                  </Typography>
                  {order.dateProcessed !== null && (
                    <Typography>
                      {new Date(order.dateProcessed).toLocaleString("en-ZA", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: false,
                      })}
                    </Typography>
                  )}

                  {order.dateProcessed === null && <Typography>-</Typography>}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={3} >
                  <Typography fontWeight={700}>
                    Datum Afgehandel
                  </Typography>
                  {order.dateFinalised !== null && (
                    <Typography>
                      {new Date(order.dateFinalised).toLocaleString("en-ZA", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: false,
                      })}
                    </Typography>
                  )}

                  {order.dateFinalised === null && <Typography>-</Typography>}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} >
                  <Typography fontWeight={700}>Boodskap / Versoek:</Typography>
                  <Typography>
                    {order.remarks}
                  </Typography>
                </Grid>

            <Grid item xs={12} sx={{ paddingTop: 10 }}>
              <Box sx={{ display: "block", overflowX: "auto" }}>
                <Table sx={{ minWidth: "650px" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Produk</TableCell>
                      <TableCell align="right">Prys</TableCell>
                      <TableCell align="right">Hoeveelheid</TableCell>
                      <TableCell align="right">Sub Totaal</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.orderItems.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell component="th" scope="row">
                          <Box>
                            <Typography gutterBottom>{item.name}</Typography>

                            {item.itemVariations.map((variation) => (
                              <Typography
                                key={variation.id}
                                color={"text.secondary"}
                                variant={"subtitle2"}
                                gutterBottom
                              >
                                {variation.type}:{" "}
                                <Typography
                                  variant={"inherit"}
                                  component={"span"}
                                  color={"inherit"}
                                >
                                  {variation.title}{" "}
                                  {priceFormatOrderItemVariation(variation,true, order.customerType)}
                          
                                </Typography>
                              </Typography>
                            ))}
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          {priceFormatOrderItem(
                            item,
                            true,
                            order.customerType
                          )}
                        </TableCell>
                        <TableCell align="right">{item.quantity}</TableCell>
                        <TableCell align="right">
                          R{item.amountSubTotal.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={6}>
              <TableContainer component={Paper} variant={"outlined"}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2}>Sub Totaal</TableCell>
                      <TableCell align="right">
                        {currencyFormat(order.amountSubtotal)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>Afslag</TableCell>
                      <TableCell align="right">
                        -{currencyFormat(order.amountDiscount)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>Koepon / Geskenkbewys
                     
                        {order.coupons?.length > 0 && (
                          <>
                          <br />
                          <ul>
                            {order.coupons.map((c) => (
                              <li key={c.id}>{c.title} ({c.code})</li>
                            ))}
                          </ul>
                          </>
                        )}
                      
                      </TableCell>
                      <TableCell align="right">
                        -{currencyFormat(order.amountCoupon)}
                       
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>Aflewering</TableCell>
                      <TableCell align="right">
                        {currencyFormat(order.amountDeliveryFee)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>BTW 15% Ingesluit</TableCell>
                      <TableCell align="right">
                        {currencyFormat(order.amountTax)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>Totaal</TableCell>
                      <TableCell align="right">
                        {currencyFormat(order.amountTotal)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Page>
    </Main>
  );
}
