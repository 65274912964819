import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import {Link as LinkR} from 'react-router-dom';

import LanguageIcon from '@mui/icons-material/Language';

import { ThemeModeToggler } from './components';
import { useTranslation } from 'react-i18next';

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSidebarOpen: () => void;
}

const Topbar = ({ onSidebarOpen }: Props): JSX.Element => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const [language, setLanguage] = useState("af");

  const { t, i18n } = useTranslation();

  function changeLanguage() {

    if (language === 'af'){
      setLanguage('en');
      i18n.changeLanguage('en');
    }else {
      setLanguage('af');
      i18n.changeLanguage('af');
    }

  }

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component={'a'}
        href="/"
        title="Klein Bederf"
        width={{ xs: 100, md: 120 }}
      >
        <Box
          component={'img'}
          src={
            mode === 'light'
              ? '/images/klein-logo.svg'
              : '/images/klein-logo-white.svg'
          }
          height={0.3}
          width={0.3}
        />
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'flex' } }} alignItems={'center'} justifyItems='flex-end'>
      <Box sx={{ display: { xs: 'flex', md: 'flex' } }} alignItems={'center'}>
        <Box marginLeft={3}>
          <Link underline="none" component={LinkR} to="/" color="text.primary">
            {t("menu.homepage")}
          </Link>
        </Box>
        {/* <Box marginLeft={3}>
          <Link
            underline="none"
            component="a"
            href="/home"
            color="text.primary"
          >
            Pages
          </Link>
        </Box> */}
    
    
        <Box marginLeft={2}>
          <ThemeModeToggler />
        </Box>
        <Box>
            <Button
              variant={"outlined"}
              onClick={changeLanguage}
              aria-label="Language toggler"
              // color={mode === "light" ? "primary" : "secondary"}
              sx={{
                borderRadius: 2,
                minWidth: "auto",
                padding: 1,
                borderColor: alpha(theme.palette.divider, 0.2),
                marginX: 1,
              }}
            >
              <LanguageIcon  sx={
                    mode === "light" 
                      ? { color: "dark-grey", width: 24, height: 24  }
                      : { color: "white", width: 24, height: 24  }} />
            </Button>
          </Box>
      
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems={'center'} marginLeft={2}>
      
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
    </Box>
  );
};

export default Topbar;
