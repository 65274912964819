import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import agent from "../../api/agent";
import { DataGrid, GridRowsProp, GridColumns } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { CommissionNameList } from "models/commission";
import { Alert, Typography } from "@mui/material";

export default function CommissionNameListPage() {
  const [loading, setLoading] = useState(true);

  const [rows, setRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    agent.Commission.getUserNetworkNameList()
      .then((list: CommissionNameList[]) => {
        const networklist: GridRowsProp = list.map((network) => ({
          id: network.id,
          firstName: network.firstName,
          lastName: network.lastName,
          email: network.email,
          comments: network.comments,
        }));
        setRows(networklist);
      })
      .catch((error) =>
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer")
      )
      .finally(() => setLoading(false));
  }, []);

  const columns: GridColumns = [
    { field: "id", headerName: "Verwysing No", width: 120 },
    { field: "firstName", headerName: "Naam", width: 200 },
    { field: "lastName", headerName: "Van", width: 200 },
    { field: "email", headerName: "E-Posadres", width: 250 },
    { field: "comments", headerName: "Nota", width: 350 },
  ];

  return (
    <Box>
      <Typography sx={{ fontWeight: 700, marginTop: 3, marginBottom: 1 }}>
        Naamlys (Netwerk Diepte #1):
      </Typography>
      <Alert severity="info" sx={{ marginTop: 2 }}>
        Die lys bevat almal wat op jou eerste vlak is (Netwerk diepte #1). Die
        volledige inligting is slegs beskikbaar as hul in hul profiel
        toestemming gegee het om hul inligting met agente in die netwerk te
        deel.
      </Alert>
      <Alert severity="warning" sx={{ marginTop: 2 }}>
        Die inligting mag slegs gebruik word vir Klein verwante besigheid en mag
        glad nie vir enige ander doel gebruik word nie.
      </Alert>

      <Box
        sx={{
          height: 500,
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <DataGrid
          sx={{ marginTop: 2 }}
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          components={{
            LoadingOverlay: LinearProgress,
          }}
          loading={loading}
        />
      </Box>
      <Box sx={{ fontSize: "0.8em" }}>
        <ul>
          <li>
            Die sub-agente wat automaties deur Klein ge-allokeer was kan
            verander met tyd soos wat ons agente op hul regte plekke allokeer.
          </li>
        </ul>
      </Box>
    </Box>
  );
}
