import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import LinearProgress from '@mui/material/LinearProgress';

import Main from "layouts/Main";
import agent from "../../api/agent";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  DataGrid,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridColumns,
  GridRowParams,
  MuiEvent,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
} from "@mui/x-data-grid";
import Page from "./Page";
import { Button } from "@mui/material";
import { UserAddress } from "models/user";
import { toast } from "react-toastify";

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}


const Adresses = (): JSX.Element => {


  const [loading, setLoading] = useState(true);

  const [rows, setRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    agent.Account.addressList()
      .then((list: UserAddress[]) => {
        const addresses: GridRowsProp = list.map((address) => ({
          id: address.id,
          fullName: address.fullName,
          address1: address.address1,
          address2: address.address2,
          city: address.city,
          province: address.province,
          postalCode: address.postalCode,
          country: address.country,
          contactNumber: address.contactNumber,
          type: address.type,
          preferred: address.preferred
        }));
        setRows(addresses);
      })
      .catch((error) => toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer"))
      .finally(() => setLoading(false));
  }, []);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;

  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {

    setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View },
          });

  };


  const handleDeleteClick = (id: GridRowId) => () => {
   
    setLoading(true);

    agent.Account.addressDelete(parseInt(id.toString()))
      .then((c) => setRows(rows.filter((row) => row.id !== id)))
      .catch((error) => {
       toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    setLoading(false);
  }, []);

  const processRowUpdate = async (newRow: GridRowModel) => {

    setLoading(true);

    await agent.Account.addressUpdate(newRow);
    
    setLoading(false);

    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  
  };

  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;
  
    const handleClick = () => {


      const newaddress = {
        id: 0,
        fullName: '',
        address1: '',
        address2: '',
        city: '',
        province: '',
        postalCode: '',
        country: '',
        contactNumber: '',
        type: 'Aflewering',
        preferred: true,
        isNew: true,
      };

      setRows((oldRows) => [...oldRows, newaddress]);

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [newaddress.id]: { mode: GridRowModes.Edit, fieldToFocus: "fullName" },
      }));
    };
  
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}  disabled={rows.find((x) => x.id === 0) !== undefined}>
          Skep Nuwe
        </Button>
      </GridToolbarContainer>
    );
  }

  const columns: GridColumns = [
    { field: "fullName", headerName: "Ontvanger Naam", width: 180, editable: true },
    { field: "address1", headerName: "Adres Lyn 1", width: 120, editable: true },
    { field: "address2", headerName: "Adres Lyn 2", width: 120, editable: true },
    { field: "city", headerName: "Dorp / Stad", width: 120, editable: true },
    { field: "province", headerName: "Provinsie", width: 120, editable: true },
    { field: "postalCode", headerName: "Poskode", width: 120, editable: true },
    { field: "country", headerName: "Land", width: 120, editable: true },
    { field: "contactNumber", headerName: "Kontak Nommer", width: 120, editable: true },
    { field: "type", headerName: "Soort", width: 120, type: "singleSelect",valueOptions: ["Aflewering", "Faktuur"], editable: true },
    { field: "preferred", headerName: "Voorkeur", width: 120, type: 'boolean', editable: true },
  
    {
      field: "actions",
      type: "actions",
      headerName: "Opsies",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  
  return (
    <Main>
      <Page>
        <Box>
          <Typography variant="h6" gutterBottom fontWeight={700}>
            Adresse
          </Typography>
         
          <Box paddingY={4}>
            <Divider />
          </Box>

          <Box
          sx={{
            height: 500,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            disableSelectionOnClick
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            components={{
              Toolbar: EditToolbar,
              LoadingOverlay: LinearProgress,
        
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            experimentalFeatures={{ newEditingApi: true }}
            loading={loading}
          />
        </Box>
        
        </Box>
      </Page>
    </Main>
  );
};

export default Adresses;
