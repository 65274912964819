import LoadingComponent from "layouts/LoadingComponent";
import { Navigate, Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "store/configureStore";

interface Props {
  roles?: string[];
}

export const ProtectedRoute = ({ roles }: Props) => {


  const { user, userLoaded } = useAppSelector((state) => state.account);
  if (!userLoaded) return <LoadingComponent message="Klein is besig om te laai..." />


  if (!user) {
    // user is not authenticated
    toast.error("Oops! Jy is nie ingeteken op ons weblad.");
    return <Navigate to="tekenin" />;
  }

  if (roles && !roles?.some((r) => user.roles?.includes(r))) {
    toast.error("Oops! Jy het nie permissie om hierdie bladsy te laai nie.");

    return <Navigate to="/" />;
  }

 return ( <Outlet />);
};
