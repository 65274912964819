import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Page from "../Page";
import Main from "layouts/Main";
import {
  Tabs,
  Tab,
} from "@mui/material";
import React from "react";
//import RanksPointList from "./Ranks_PointList";
import RanksRankList from "./Ranks_RankList";
import RanksPointDetails from "./Ranks_MonthlyPointDetails";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Ranks() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Main>
      <Page>
        <Box>
          <Typography variant="h6" gutterBottom fontWeight={700}>
            Ranks
          </Typography>
       
          <Box sx={{ width: "100%"}}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Ranks" {...a11yProps(0)} />
                <Tab label="Jou Punte" {...a11yProps(1)} />
                {/* <Tab label="History" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
             <RanksRankList/>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <RanksPointDetails/>
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
              
             <RanksPointList/>
            </TabPanel> */}
           
          </Box>
        </Box>
      </Page>
    </Main>
  );
}
