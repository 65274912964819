import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import agent from "api/agent";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";

interface Props {
  closeModal: (reloaddata: boolean) => void;
  maxAmount: number;
}

const CommissionPayoutForm = ({
  closeModal,
  maxAmount,
}: Props): JSX.Element => {
  const validationSchema = yup.object({
    amountPaid: yup
      .number()
      .min(400, "Bedrag om uit te betaal moet meer as R400 wees.")
      .max(
        maxAmount,
        "Bedrag om uit te betaal moet gelyk of minder as jou balans van " +
          maxAmount.toFixed(2) +
          " wees."
      )
      .required("Bedrag is noodsaaklik"),
    bankAccountBankName: yup
      .string()
      .required()
      .min(3, "Bank naam is te kort")
      .max(200, "Bank naam is te lank")
      .required("Bank naam is noodsaaklik"),
    bankAccountBranchNo: yup
      .string()
      .required()
      .min(3, "Tak kode/naam is te kort")
      .max(200, "Tak kode/naam is te lank")
      .required("Tak kode/naam is noodsaaklik"),
    bankAccountType: yup
      .string()
      .required()
      .min(3, "Bank rekening tipe is te kort")
      .max(200, "Bank rekening tipe is te lank")
      .required("Bank rekening tipe is noodsaaklik"),
    bankAccountNo: yup
      .string()
      .required()
      .min(3, "Bank rekening nommer is te kort")
      .max(200, "Bank rekening nommer is te lank")
      .required("Bank rekening nommer is noodsaaklik"),
  });

  React.useEffect(() => {
    // let active = true;
    // if (!loading) {
    //   return undefined;
    // }
    // (async () => {
    //   if (active) {
    //     agent.Admin.commissionUsersList()
    //       .then((c: UserListItem[]) => {
    //         setOptions(c);
    //       })
    //       .catch((error) => {
    //         toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
    //       });
    //   }
    // })();
    // return () => {
    //   active = false;
    // };
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  async function handleSubmitData(data: FieldValues) {
    console.log("data", data);

    agent.Commission.addUserCommissionPayoutRequest(data)
      .then(() => {
        toast.success("Suksesvol bygevoeg");
        closeModal(true);
      })
      .catch((errors) => {
        
        // if (errors.error) {
        //   Object.keys(errors.error).forEach((key) => {
        //     if (key.includes("Password")) {
        //       setError(
        //         "password",
        //         { type: "focus", message: errors.error[key] },
        //         { shouldFocus: true }
        //       );
        //     } else if (key.includes("Email")) {
        //       setError(
        //         "email",
        //         { type: "focus", message: errors.error[key] },
        //         { shouldFocus: true }
        //       );
        //     }
        //   });
        // }
      });
  }

  return (
    <Dialog
      open
      onClose={() => closeModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        <Typography
          fontWeight={700}
          sx={{ color: (theme) => theme.palette.primary.contrastText }}
        >
          {" "}
          Uitbetaling Versoek
        </Typography>

        <IconButton
          aria-label="close"
          onClick={() => closeModal(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ marginTop: 2 }}>
        <Box>
          <form onSubmit={handleSubmit(handleSubmitData)}>
            <Grid container spacing={4} marginTop={1}>
              <Grid item xs={12}>
                <TextField
                  label="Bedrag om uit te betaal"
                  variant="outlined"
                  name={"amountPaid"}
                  fullWidth
                  type={"number"}
                  defaultValue={maxAmount.toFixed(2)}
                  {...register("amountPaid")}
                  error={errors?.amountPaid != null}
                  // @ts-ignore
                  helperText={errors?.amountPaid?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Bank Naam"
                  variant="outlined"
                  name={"bankAccountBankName"}
                  fullWidth
                  {...register("bankAccountBankName")}
                  error={errors?.bankAccountBankName != null}
                  // @ts-ignore
                  helperText={errors?.bankAccountBankName?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Tak Naam/Nommer"
                  variant="outlined"
                  name={"bankAccountBranchNo"}
                  fullWidth
                  {...register("bankAccountBranchNo")}
                  error={errors?.bankAccountBranchNo != null}
                  // @ts-ignore
                  helperText={errors?.bankAccountBranchNo?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Rekening Nommer"
                  variant="outlined"
                  name={"bankAccountNo"}
                  fullWidth
                  {...register("bankAccountNo")}
                  error={errors?.bankAccountNo != null}
                  // @ts-ignore
                  helperText={errors?.bankAccountNo?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Rekening Tipe"
                  variant="outlined"
                  name={"bankAccountType"}
                  fullWidth
                  {...register("bankAccountType")}
                  error={errors?.bankAccountType != null}
                  // @ts-ignore
                  helperText={errors?.bankAccountType?.message}
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => closeModal(false)}>
          Keer Terug
        </Button>
        <LoadingButton
          size={"large"}
          variant={"contained"}
          //disabled={!submitVisible}
          onClick={handleSubmit(handleSubmitData)}
          loading={isSubmitting}
          disabled={!isValid || !isDirty}
        >
          Stoor
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CommissionPayoutForm;
