import FixedLayout from "layouts/Fixed";
import { Typography, Box, Dialog, Divider, Button } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";
import agent from "../../api/agent";
import React from "react";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {
  DataGrid,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridColumns,
  GridRowParams,
  MuiEvent,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { UserInformation } from "models/user";
import AddUserForm from "./AddUserForm";

export default function Users() {
  const [loading, setLoading] = useState(true);

  const [rows, setRows] = useState<GridRowsProp>([]);

  const [newModalOpen, setNewModalOpen] = useState(false);

  useEffect(() => {
    agent.Users.list()
      .then((c: UserInformation[]) => {
        const users: GridRowsProp = c;
        setRows(users);
      })
      .catch((error) =>
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer")
      )
      .finally(() => setLoading(false));
  }, []);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  function handleCloseNewModal(reloaddata: boolean) {
    setNewModalOpen(false);

    if (reloaddata) {
      setLoading(true);

      agent.Users.list()
        .then((c: UserInformation[]) => {
          const users: GridRowsProp = c;
          setRows(users);
        })
        .catch((error) =>
          toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer")
        )
        .finally(() => setLoading(false));
    }
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    if (id === 2) {
      toast.error("Die gebruiker kan nie verwyder word nie.");
      return;
    }

    setLoading(true);

    agent.Users.delete(id.toString())
      .then((c) => setRows(rows.filter((row) => row.id !== id)))
      .catch((error) => {
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    toast.error("could not save ");

    setLoading(false);

    // setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const processRowUpdate = async (newRow: GridRowModel) => {
    setLoading(true);

    try {
      await agent.Users.update(newRow);
    } catch (error) {
      toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
      setLoading(false);

      const oldrow = rows.find((c) => c.id === newRow.id);

      return oldrow;
    }

    setLoading(false);

    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    return updatedRow;
  };

  function EditToolbar() {
    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setNewModalOpen(true)}
        >
          Skep Nuwe
        </Button>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const columns: GridColumns = [
    { field: "firstName", headerName: "Naam", width: 180, editable: true },
    { field: "lastName", headerName: "Van", width: 180, editable: true },
    {
      field: "email",
      headerName: "E-Posadres",
      width: 180,
      editable: false,
    },
    {
      field: "contactNumber",
      headerName: "Kontak Nommer",
      width: 140,
      editable: true,
    },
    {
      field: "addressLine1",
      headerName: "Adres - Lyn 1",
      width: 140,
      editable: false,
    },
    {
      field: "addressLine2",
      headerName: "Adres - Lyn 2",
      width: 140,
      editable: false,
    },
    {
      field: "addressCity",
      headerName: "Adres - Dorp/Stad",
      width: 140,
      editable: false,
    },
    {
      field: "addressCode",
      headerName: "Adres - Kode",
      width: 140,
      editable: false,
    },
    {
      field: "addressProvince",
      headerName: "Adres - Provinsie",
      width: 140,
      editable: false,
    },
    {
      field: "addressCountry",
      headerName: "Adres - Land",
      width: 140,
      editable: false,
    },
    {
      field: "idNumber",
      headerName: "ID Nommer",
      width: 140,
      editable: false,
    },
    {
      field: "dateOfBirth",
      headerName: "Geboorte Datum",
      width: 140,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }

        const date = new Date(params.value);

        const valueFormatted = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return valueFormatted;
      },
    },
    {
      field: "networkPermissionToSeeDetails",
      headerName: "Inligting Sigbaar in Netwerk",
      width: 140,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value) {
          return "Ja";
        } else return "Nee";
      },
    },
    {
      field: "emailConfirmed",
      headerName: "E-Pos Bevestig",
      type: "boolean",
      width: 120,
      editable: true,
    },
    {
      field: "role",
      headerName: "Groep",
      width: 120,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Gewoon", "Agent", "Retailer", "Winkel", "Admin"],
    },
    // parentUserName: user.parentUserName,
    // parentUserEmail: user.parentUserEmail
    {
      field: "parentUserEmail",
      headerName: "Parent Agent",
      width: 180,
      editable: false,
    },
    {
      field: "lastOrderDate",
      headerName: "Laaste Bestelling",
      width: 140,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }

        const date = new Date(params.value);

        const valueFormatted = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return valueFormatted;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Opsies",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
            showInMenu
          />,
        ];
      },
    },
  ];

  return (
    <FixedLayout>
      <Box
        width={1}
        margin={"0 auto"}
        paddingX={2}
        paddingY={{ xs: 2, sm: 2, md: 2 }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ p: 2 }} variant="h5">
            Gebruikers Lys
          </Typography>
        </Box>

        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  businessName: false,
                  businessNumber: false,
                  businessVatNumber: false,
                  addressLine1: false,
                  addressLine2: false,
                  addressCity: false,
                  addressCode: false,
                  addressProvince: false,
                  addressCountry: false,
                  idNumber: false,
                  dateOfBirth: false,
                  networkPermissionToSeeDetails: false,
                  lastOrderDate: false,
                },
              },
            }}
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            components={{
              Toolbar: EditToolbar,
              LoadingOverlay: LinearProgress,
            }}
            experimentalFeatures={{ newEditingApi: true }}
            loading={loading}
          />
        </Box>

        <Dialog
          onClose={() => setNewModalOpen(false)}
          open={newModalOpen}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <Box paddingY={{ xs: 1, sm: 2 }} paddingX={{ xs: 2, sm: 4 }}>
            <Box
              paddingY={{ xs: 1, sm: 2 }}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography variant={"h5"} fontWeight={700}>
                Voeg by
              </Typography>
              <Box
                component={"svg"}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width={24}
                height={24}
                onClick={() => setNewModalOpen(false)}
                sx={{ cursor: "pointer" }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </Box>
            </Box>
            <Divider />
            <Box paddingY={2}>
              <AddUserForm closeModal={handleCloseNewModal} />
            </Box>
          </Box>
        </Dialog>
      </Box>
    </FixedLayout>
  );
}
