import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import agent from "api/agent";
import { toast } from "react-toastify";
import {
  Autocomplete,
  CircularProgress
} from "@mui/material";
import React from "react";
import AppSelectList from "components/AppSelectList";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your first name"),
  lastName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your last name"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  password: yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8")
    .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase letter")
    .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase letter")
    .matches(/^(?=.*[0-9])/, "Must contain at least one number"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //   "Must contain at least 8 Characters, One Uppercase, One Lowercase, One Number"
  // )
});

interface UserListItem {
  userEmail: string;
  isInHierarchy: boolean;
}

interface Props {
  closeModal: (reloaddata: boolean) => void;
}

const AddUserForm = ({ closeModal }: Props): JSX.Element => {
  //State for Parent User drop down list
  const [openParentAgent, setOpenParentAgent] = React.useState(false);
  const [options, setOptions] = React.useState<readonly UserListItem[]>([]);
  const loading = options.length === 0;

  // const initialValues = {
  //   firstName: user.firstName,
  //   lastName: user.lastName,
  //   email: user.email,
  //   contactNumber: user.contactNumber ?? ''
  // };

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        agent.Admin.commissionUsersList()
          .then((c: UserListItem[]) => {
            setOptions(c);
          })
          .catch((error) => {
            toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
          });
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  async function handleSubmitData(data: FieldValues) {
    try {
      console.log("data", data);
      
      await agent.Admin.profileCreate(data);

      toast.success("Suksesvol bygevoeg");

      closeModal(true);
    } catch (errors) {

      if (errors.DuplicateEmail) {
        setError(
          "email",
          { type: "focus", message: "E-pos is reeds geregistreer." },
          { shouldFocus: true }
        );
        return;
      }

      if (errors.error) {
        Object.keys(errors.error).forEach((key) => {
          if (key.includes("Password")) {
            setError(
              "password",
              { type: "focus", message: errors.error[key] },
              { shouldFocus: true }
            );
          } else if (key.includes("Email")) {
            setError(
              "email",
              { type: "focus", message: errors.error[key] },
              { shouldFocus: true }
            );
          }
        });
      }
    }
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Naam"
              variant="outlined"
              name={"firstName"}
              fullWidth
              {...register("firstName")}
              error={errors?.firstName != null}
              // @ts-ignore
              helperText={errors?.firstName?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Van"
              variant="outlined"
              name={"lastName"}
              fullWidth
              {...register("lastName")}
              error={errors?.lastName != null}
              // @ts-ignore
              helperText={errors?.lastName?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="E-posadres"
              variant="outlined"
              name={"email"}
              fullWidth
              {...register("email")}
              error={errors?.email != null}
              // @ts-ignore
              helperText={errors?.email?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Wagwoord"
              variant="outlined"
              name={"password"}
              type={"password"}
              fullWidth
              {...register("password")}
              error={errors?.password != null}
              // @ts-ignore
              helperText={errors?.password?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <AppSelectList control={control} name="userType" label="Groep" defaultValue="Gewoon" items={["Gewoon", "Agent","Retailer","Winkel"]}/>
             
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="asynchronous-demo"
              //sx={{ width: 300 }}
              open={openParentAgent}
              onOpen={() => {
                setOpenParentAgent(true);
              }}
              onClose={() => {
                setOpenParentAgent(false);
              }}
              isOptionEqualToValue={(option, value) =>
                option.userEmail === value.userEmail
              }
              getOptionLabel={(option: UserListItem) => option.userEmail}
              options={options.filter((x) => x.isInHierarchy === true)}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Parent Agent (Opsioneel)"
                  {...register("agentKey")}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  error={errors?.agentKey != null}
                  // @ts-ignore
                  helperText={errors?.agentKey?.message}
                />
              )}
            />
          </Grid>

          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              margin={"0 auto"}
            >
              <LoadingButton
                size={"large"}
                variant={"contained"}
                type={"submit"}
                loading={isSubmitting}
              >
                Stoor
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AddUserForm;
