import FixedLayout from "layouts/Fixed";
import { Typography, Box, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import agent from "../../api/agent";
import {
  DataGrid,
  GridColumns,
  GridRowsProp,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import React from "react";
//import ProductQuickViewDialog from "./ProductQuickViewDialog";
import { toast } from "react-toastify";
import { Ranks_UserPoints } from "models/ranks";

const RanksPointsDetail = (): JSX.Element => {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    agent.Admin.ranksUserPointsDetailedList()
      .then((c: Ranks_UserPoints[]) => {
        const s: GridRowsProp = c.map((entry) => ({
          id: entry.id,
          date: entry.date,
          monthlyOrdersGreaterThan3800: entry.monthlyOrdersGreaterThan3800,
          monthlyOrdersGreaterThan3800Points: entry.monthlyOrdersGreaterThan3800Points,
          activeAgents: entry.activeAgents,
          activeAgentsPoints: entry.activeAgentsPoints,
          activeSubscriptions: entry.activeSubscriptions,
          activeSubscriptionsPoints: entry.activeSubscriptionsPoints,
          bonusPoints: entry.bonusPoints,
          totalPoints: entry.totalPoints,
          userId: entry.userId,
          userName: entry.userName,
          userEmail: entry.userEmail,
        }));
        setRows(s);
      })
      .catch((error) => {
        toast.error("Aggenee, dit wou nie laai nie. Probeer asb weer");
      })
      .finally(() => setLoading(false));
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const columns: GridColumns = [
    { field: "userName", headerName: "Naam", width: 120 },
    { field: "userEmail", headerName: "Epos", width: 120 },
    {
      field: "date",
      headerName: "Datum",
      width: 130,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }

        const date = new Date(params.value);

        const valueFormatted = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "short",
        });
        return valueFormatted;
      },
    },
    {
      field: "monthlyOrdersGreaterThan3800",
      headerName: "Maand Bestellings >R3800",
      width: 200,
      valueGetter: (params) => params.row.monthlyOrdersGreaterThan3800 + " (" + params.row.monthlyOrdersGreaterThan3800Points  + " Punte)",
    },
    { field: "activeAgents", headerName: "Aktiewe Agente", width: 200, valueGetter: (params) => params.row.activeAgents + " (" + (params.row.activeAgentsPoints  === 1? "1 Punt" : + params.row.activeAgentsPoints + " Punte)") },
    { field: "activeSubscriptions", headerName: "Maandbokse", width: 200, valueGetter: (params) => params.row.activeSubscriptions + " (" + (params.row.activeSubscriptionsPoints === 1? "1 Punt" : params.row.activeSubscriptionsPoints + " Punte)") },
    { field: "bonusPoints", headerName: "Bonus Punte", width: 200, valueGetter: (params) => (params.row.bonusPoints === 1? "1 Punt" : params.row.bonusPoints  + " Punte") },
    { field: "totalPoints", headerName: "Totale Punte", width: 120 },
  ];

  return (
    <FixedLayout>
      <Box
        width={1}
        margin={"0 auto"}
        paddingX={2}
        paddingY={{ xs: 2, sm: 2, md: 2 }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ p: 2 }} variant="h5">
            Ranks Punte (Detail)
          </Typography>
        </Box>

        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              LoadingOverlay: LinearProgress,
            }}
            loading={loading}
          />
        </div>
      </Box>
    </FixedLayout>
  );
};

export default RanksPointsDetail;
