import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

interface Props {
    requiresReload: any;
    reload: () => void;
}

export default function RetryModal({requiresReload, reload}: Props) {

  // const handleClose = () => {
    
  //   window.location.reload(); 
  // };

  return (
    <div>
     
      <Dialog
        open={requiresReload}
        onClose={reload}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Typography
            fontWeight={700}
            sx={{ color: (theme) => theme.palette.primary.contrastText }}
          >
            Konneksie Fout
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ marginTop: 2 }}>
          <DialogContentText id="alert-dialog-description">
           Aggene, daar was 'n konneksie fout. <br/>Druk op herlaai om weer die inligting te probeer laai.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
          <Button variant={"contained"} onClick={reload} autoFocus>
            Herlaai
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
