import FixedLayout from "layouts/Fixed";
import { Typography, Button, Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";
import agent from "../api/agent";
import React from "react";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  DataGrid,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridColumns,
  GridRowParams,
  MuiEvent,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridToolbarColumnsButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Coupon } from "models/basket";

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

export default function Coupons() {
  const [loading, setLoading] = useState(true);

  const [rows, setRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    agent.Admin.couponsList()
      .then((c: Coupon[]) => {
        const coupons: GridRowsProp = c.map((coupon) => ({
          id: coupon.id,
          title: coupon.title,
          code: coupon.code,
          discountPercentage: coupon.discountPercentage,
          discountValue: coupon.discountValue,
          discountValueRemaining: coupon.discountValueRemaining,
          excludeSaleItems: coupon.excludeSaleItems,
          startDate: coupon.startDate,
          endDate: coupon.endDate,
          status: coupon.status,
          type: coupon.type,
          visibleAdmin: coupon.visibleAdmin,
          visibleRetailer: coupon.visibleRetailer,
          visibleAgent: coupon.visibleAgent,
          visibleGewoon: coupon.visibleGewoon,
          visiblePublic: coupon.visiblePublic,
        }));
        setRows(coupons);
      })
      .catch((error) => {
        toast.error("Aggenee, dit wou nie laai nie. Probeer asb weer");
      })
      .finally(() => setLoading(false));
  }, []);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setLoading(true);

    agent.Admin.couponDelete(parseInt(id.toString()))
      .then((c) => setRows(rows.filter((row) => row.id !== id)))
      .catch((error) => {
        toast.warning("Aggenee, dit het nie gewerk nie. Probeer asb. weer ");
      })
      .finally(() => setLoading(false));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    setLoading(false);
    //toast.error("could not save " + error.message);
    // setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  //React.useCallback(() => { ... }, []);

  const processRowUpdate = React.useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      setLoading(true);

      await agent.Admin.couponUpdate(newRow);

      setLoading(false);

      const updatedRow = { ...newRow, isNew: false };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    },
    [rows]
  );

  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      setLoading(true);

      const currentdate = new Date();

      //create draft in database and provide id.
      const c = {
        title: "",
        code: "",
        discountValue: 0,
        discountValueRemaining: 0,
        discountPercentage: 0,
        startDate: currentdate,
        endDate: currentdate,
        excludeSaleItems: true,
        status: "Draft",
        type: "Koepon",
        visibleAdmin: true,
        visibleRetailer: true,
        visibleAgent: true,
        visibleGewoon: true,
        visiblePublic: true,
        
      };

      agent.Admin.couponCreate(c)
        .then((coupon) => {
          const id = coupon.id;

          setRows((oldRows) => [
            ...oldRows,
            {
              id,
              title: "",
              code: "",
              discountValue: 0,
              discountValueRemaining: 0,
              discountPercentage: 0,
              startDate: currentdate,
              endDate: currentdate,
              excludeSaleItems: true,
              status: "Draft",
              type: "Koepon",
              isNew: false,
              visibleAdmin: true,
              visibleRetailer: true,
              visibleAgent: true,
              visibleGewoon: true,
              visiblePublic: true,
            },
          ]);

          setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: "title" },
          }));
        })
        .catch((error) => {
          toast.error("Aggenee, dit wil nie werk nie. Probeer asb. weer ");
        })
        .finally(() => setLoading(false));
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Skep Nuwe
        </Button>
        <GridToolbarExport />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  const columns: GridColumns = [
    {
      field: "type",
      width: 180,
      headerName: "Soort",
      editable: true,
      type: "singleSelect",
      valueOptions: ["Koepon", "Geskenbewys"],
    },
    { field: "title", width: 180, headerName: "Titel", editable: true },
    { field: "code", width: 180, headerName: "Kode", editable: true },
    {
      field: "discountValue",
      width: 180,
      headerName: "Afslag Bedrag",
      editable: true,
      type: "number",
    },
    {
      field: "discountValueRemaining",
      width: 180,
      headerName: "Afslag Bedrag Balans",
      editable: true,
      type: "number",
    },
    {
      field: "discountPercentage",
      width: 180,
      headerName: "Afslag Persentasie %",
      editable: true,
      type: "number",
    },
    {
      field: "LimitUsageCustomer",
      width: 180,
      headerName: "Beperk Gebruikers",
      editable: true,
      type: "number",
    },
    {
      field: "LimitUsageQuantity",
      width: 180,
      headerName: "Beperk Gebruik Hoeveelheid",
      editable: true,
      type: "number",
    },
    {
      field: "startDate",
      width: 180,
      headerName: "Geldig Vanaf",
      editable: true,
      type: "dateTime",
    },
    {
      field: "endDate",
      width: 180,
      headerName: "Verval",
      editable: true,
      type: "dateTime",
    },
    {
      field: "excludeSaleItems",
      width: 180,
      headerName: "Uitlsluitend items op Uitverkoping",
      editable: true,
      type: "boolean",
    },
    {
      field: "visibleAdmin",
      width: 180,
      headerName: "Admins",
      editable: true,
      type: "boolean",
    },
    {
      field: "visibleRetailer",
      width: 180,
      headerName: "Retailers",
      editable: true,
      type: "boolean",
    },
    {
      field: "visibleAgent",
      width: 180,
      headerName: "Agents",
      editable: true,
      type: "boolean",
    },
    {
      field: "visibleGewoon",
      width: 180,
      headerName: "Gewoon",
      editable: true,
      type: "boolean",
    },
    {
      field: "visiblePublic",
      width: 180,
      headerName: "Publiek",
      editable: true,
      type: "boolean",
    },
    {
      field: "status",
      width: 180,
      headerName: "Status",
      editable: true,
      type: "singleSelect",
      valueOptions: ["Aktief", "Onaktief", "Draft"],
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Opsies",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <FixedLayout>
      <Box
        width={1}
        margin={"0 auto"}
        paddingX={2}
        paddingY={{ xs: 2, sm: 2, md: 2 }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ p: 2 }} variant="h5">
            Koepons / Geskenbewyse
          </Typography>
        </Box>

        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            components={{
              Toolbar: EditToolbar,
              LoadingOverlay: LinearProgress,
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            experimentalFeatures={{ newEditingApi: true }}
            loading={loading}
          />
        </Box>
      </Box>
    </FixedLayout>
  );
}
