import React, { useEffect, useState } from "react";
import { alpha, useTheme } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  items: Array<PageItem>;
  loading?: boolean;
}

const NavItem = ({ title, items, loading = false }: Props): JSX.Element => {
  const theme = useTheme();
  const [activeLink, setActiveLink] = useState("");
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  }, []);

  const { t, i18n } = useTranslation();

  const hasActiveLink = () => items.find((i) => i.href === activeLink);

  if (items.length === 1) {
    return (
      <Box
        //disableGutters
        //elevation={0}
        sx={{ backgroundColor: "transparent", paddingBottom: 1, justifyContent: 'left' }}
      >
        <Button
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ padding: 0 }}
          component={Link}
          to={items[0].href}
        >
          <Typography
            fontWeight={hasActiveLink() ? 600 : 400}
            color={hasActiveLink() ? "primary" : "text.primary"}
          >
            {title}
            {loading && (
              <span>
                &nbsp;
                <CircularProgress size={25} />{" "}
              </span>
            )}
          </Typography>
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <Accordion
        disableGutters
        elevation={0}
        sx={{ backgroundColor: "transparent" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ padding: 0 }}
        >
          <Typography
            fontWeight={hasActiveLink() ? 600 : 400}
            color={hasActiveLink() ? "primary" : "text.primary"}
          >
            {title}
            {loading && (
              <span>
                &nbsp;
                <CircularProgress size={25} />{" "}
              </span>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Grid container spacing={1}>
            {items.map((p, i) => (
              <Grid item key={i} xs={12}>
                <Button
                  size={"large"}
                  component={Link}
                  to={p.href}
                  //href={p.href}
                  fullWidth
                  sx={{
                    justifyContent: "flex-start",
                    color:
                      activeLink === p.href
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                    backgroundColor:
                      activeLink === p.href
                        ? alpha(theme.palette.primary.main, 0.1)
                        : "transparent",
                    fontWeight: activeLink === p.href ? 600 : 400,
                  }}
                >
                  {i18n.language === "af"? p.title_af : p.title_en}
                  {p.isNew && (
                    <Box
                      padding={0.5}
                      display={"inline-flex"}
                      borderRadius={1}
                      bgcolor={"primary.main"}
                      marginLeft={2}
                    >
                      <Typography
                        variant={"caption"}
                        sx={{ color: "common.white", lineHeight: 1 }}
                      >
                          {t("menu.new")}
                      </Typography>
                    </Box>
                  )}
                </Button>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default NavItem;
