import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import NavItem from './components/NavItem';
import { useAppSelector } from 'store/configureStore';
import NavItemProducts from './components/NavItem/NavItemProducts';
import { useTranslation } from 'react-i18next';
import { Link, Typography } from '@mui/material';


interface Props {
  pages: {
    tuisblad: Array<PageItem>;
    oorklein: Array<PageItem>;
    maandboks: Array<PageItem>;
    agente: Array<PageItem>;
    coloringbook: Array<PageItem>;
  };
}

const SidebarNav = ({ pages }: Props): JSX.Element => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const {
    tuisblad: tuisbladPages,
    oorklein: oorkleinPages,
    maandboks: maandboksPages,
    agente: agentePages,
    coloringbook: coloringbookPages,
  } = pages;

  const { t } = useTranslation();

  const { categories, loadingFilters } = useAppSelector(state => state.catalog);

  //const categoryArray: PageItem[] = categories.map((cat) => ({isNew: false, title: cat.title, id: cat.id, href: `/winkel?kategorie=${cat.slug}`, slug: cat.slug}));

  const categoryArray: PageItem[] = ([{
    isNew: false,
    title_af:  t("menu.allproducts") ,
    title_en:  t("menu.allproducts") ,
    id: 0,
    href: `/winkel?kategorie=alles`,
    slug: "alles"
  }]).concat(categories.map((cat) => ({
    isNew: false,
    title_af: cat.title,
    title_en: cat.title,
    id: cat.id,
    href: `/winkel?kategorie=${cat.slug}`,
    slug: cat.slug
  })));

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="Klein"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={
              mode === 'light'
              ? "/images/Klein-logo.svg"
              : "/images/Klein-logo.svg"
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={t("menu.homepage")} items={tuisbladPages} />
        </Box>
        <Box>
          <NavItemProducts title={t("menu.products")} items={categoryArray} loading={loadingFilters} />
        </Box>
        <Box>
          <NavItem title={t("menu.agents")} items={agentePages} />
        </Box>
        <Box>
          <NavItem title={t("menu.aboutklein")} items={oorkleinPages} />
        </Box>
        <Box>
          <NavItem title={t("menu.monthlyclub")} items={maandboksPages} />
        </Box>
        <Box>
          <NavItem title={t("menu.coloringbook")} items={coloringbookPages} />
        </Box>

        {/* <Box
          display={"flex"}
          alignItems={"center"}
          //aria-describedby={"coloring-book"}
          sx={{ cursor: "pointer", textDecoration: "none" }}
          component={Link}
          href={"https://kleinbederf.com/uploads/meeroorklein/Inkleurboek_Klein.pdf"}
          target="_blank"
          referrerPolicy={"no-referrer"}
          
        >
          <Typography
            fontWeight={400}
            color={"text.primary"}
          >
            {t("menu.coloringbook")}
          </Typography>{" "}
          
            <Box
              padding={0.5}
              display={"inline-flex"}
              borderRadius={1}
              bgcolor={"primary.main"}
              marginLeft={2}
            >
              <Typography
                variant={"caption"}
                sx={{ color: "common.white", lineHeight: 1 }}
              >
                   {t("menu.new")}
              </Typography>
            </Box>
          
        </Box> */}
      
       
       
       
      </Box>
    </Box>
  );
};

export default SidebarNav;
