import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { Link as LinkR } from "react-router-dom";

import Main from 'layouts/Main';
import Container from 'components/Container';

import Hero from './Hero';
import About from './About';
import Form from './Form';
import CompanyValues from './CompanyValues';
import { useAppSelector } from 'store/configureStore';
import { Link, Typography } from '@mui/material';

export default function Groothandelaar() {

  const { user } = useAppSelector((state) => state.account);

  const theme = useTheme();
  
  return (
    <Main>
      <Box>
        <Hero />
        <Container>
          <About />
        </Container>
        <Container>
          <Divider />
        </Container>
        <Container>
          <CompanyValues />
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.alternate.main}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
      <Box bgcolor={'alternate.main'}>
      <Container maxWidth={800} paddingBottom={'0 !important'}>

          {!user && <>
            <Typography
          align={"center"}
          color={"text.secondary"}
          sx={{ textTransform: "uppercase", marginBottom: 4 }}
          variant={"subtitle2"}
          fontWeight={600}
        >
          SLUIT AAN
        </Typography>
        <Typography sx={{marginBottom: 3}}>
                    As jy nuut is tot ons webwerf, gebruik hierdie skakel om te <Link
                      component={LinkR}
                      color={"primary"}
                      to={"/registreer"}
                      underline={"none"}
                    >
                      registreer vir 'n Groothandelaar (Retailer) profiel.
                    </Link> Ons sien sommer klaar daarna uit om jou as deel van ons familie te verwelkom. Jy sal nog
            sien, hier is die bederf eintlik GROOT!</Typography>

        <Typography sx={{marginBottom: 3}}>
          As jy reeds 'n gewone gebruikers profiel op ons webwerf geskep het, gebruik  <Link
                      component={LinkR}
                      color={"primary"}
                      to={"/tekenin"}
                      underline={"none"}
                    >
                     die skakel om in te teken
                    </Link> en keer dan terug na die hierdie Groothandelaar (Retailer) bladsy om die Retailer registrasie vorm te voltooi.
                    </Typography>
          </> }

        {user && 
          <Form />
        }
        </Container>
       
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
    </Main>
  );
};

