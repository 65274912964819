import { useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
import { setProductParams } from 'store/catalogSlice';

const valuetext = (value) => `R${value[0]} - R${value[1]}`;

const FilterPrice = (): JSX.Element => {
 

  const { productParams } = useAppSelector(
    (state) => state.catalog
  );

  //const [value, setValue] = useState(productParams.priceRange || [0, 99999]);
  const [open, setOpen] = useState(true);

  const dispatch = useAppDispatch();

  const handleClick = (): void => {
    setOpen(!open);
  };

  const handleChange = (event, newValue) => {
    //setValue(newValue);
    dispatch(setProductParams({ priceRange: newValue }));
    //dispatch(fetchProductsAsync());

  };

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        marginBottom={1}
        sx={{ cursor: 'pointer' }}
        onClick={() => handleClick()}
      >
        <Typography fontWeight={700}>
          Prys:
        </Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box paddingX={3}>
          <Slider
            getAriaLabel={() => 'Price range'}
            value={productParams.priceRange}
            onChange={handleChange}
            valueLabelDisplay="off"
            getAriaValueText={valuetext}
            min={0}
            max={1000}
            disableSwap={true}
          />
        </Box>
      </Collapse>
      <Typography component={'span'}>
            {valuetext(productParams.priceRange) || ''}
          </Typography>
        
    </Box>
  );
};

export default FilterPrice;
