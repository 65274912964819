import { Box, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import agent from "../../api/agent";
import {
  DataGrid,
  GridColumns,
  GridRowsProp,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import React from "react";
//import ProductQuickViewDialog from "./ProductQuickViewDialog";
import { toast } from "react-toastify";
import { ChallengeOrderAdmin } from "models/challenge";

const ChallengeOrdersListAdmin = (): JSX.Element => {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    agent.Admin.challengeOrders()
      .then((c: ChallengeOrderAdmin[]) => {
        console.log(c);
        const s: GridRowsProp = c.map((entry) => ({
          id: entry.orderId,
          orderId: entry.orderId,
          orderDate: entry.orderDate,
          orderStatus: entry.orderStatus,
          quantity: entry.quantity,
          name: entry.name,
          email: entry.email,
          userId: entry.userId,
        }));
        setRows(s);
      })
      .catch((error) => {
        toast.error("Aggenee, dit wou nie laai nie. Probeer asb weer");
      })
      .finally(() => setLoading(false));
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const columns: GridColumns = [
    { field: "userId", headerName: "User Id", width: 250 },
    { field: "name", headerName: "Naam", width: 250 },
    { field: "email", headerName: "Epos", width: 250 },
    { field: "orderId", headerName: "Bestelling No", width: 120 },
    { field: "orderStatus", headerName: "Status", width: 170 },
    {
      field: "orderDate",
      headerName: "Datum",
      width: 180,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }

        const date = new Date(params.value);

        const valueFormatted = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "short",
          day: "numeric"
        });
        return valueFormatted;
      },
    },
    {
      field: "quantity",
      headerName: "Hoeveelheid Sakkies",
      width: 250,
    },
  ];

  return (
    <Box
      width={1}
      margin={"0 auto"}
      paddingX={2}
      paddingY={{ xs: 2, sm: 2, md: 2 }}
    >

      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
          }}
          loading={loading}
        />
      </div>
    </Box>
  );
};

export default ChallengeOrdersListAdmin;
