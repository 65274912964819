const pages = [
  {
    groupTitle: 'Algemeen',
    groupTitle_en: 'Getting Started',
    id: 'getting-started',
    pages: [
      {
        title_af: 'Inleiding',
        title_en: 'Introduction',
        href: '/admin/introduction',
      },
      {
        title_af: 'Verslagte',
        title_en: 'Reports',
        href: '/admin/verslagte',
        users: ["info@kleinbederf.co.za","admin@test.com","johan@kleinbederf.co.za","Delainecv123@yahoo.com", "delaine@kleinbederf.co.za"],
      },
    ],
  },
  {
    groupTitle: 'Bestellings',
    groupTitle_en: 'Orders',
    id: 'bestellings',
    users: ["info@kleinbederf.co.za","admin@test.com","johan@kleinbederf.co.za","Delainecv123@yahoo.com", "delaine@kleinbederf.co.za","bianca@kleinbederf.co.za", "hanna@kleinbederf.co.za",],
    pages: [
      {
        title_af: 'Bestellings Lys',
        title_en: 'Orders',
        href: '/admin/bestellings',
      },
    ],
  },
  {
    groupTitle: 'Produkte',
    groupTitle_en: 'Products',
    id: 'produkte',
    users: ["info@kleinbederf.co.za","admin@test.com","johan@kleinbederf.co.za","bianca@kleinbederf.co.za", "hanna@kleinbederf.co.za"],
    pages: [
      {
        title_af: 'Produkte Lys',
        title_en: 'Products',
        href: '/admin/produkte',
        users: ["info@kleinbederf.co.za","admin@test.com","johan@kleinbederf.co.za","bianca@kleinbederf.co.za", "hanna@kleinbederf.co.za"],
      },
      {
        title_af: 'Kategorie Lys',
        title_en: 'Categories',
        href: '/admin/kategoriee',
        users: ["info@kleinbederf.co.za","admin@test.com","johan@kleinbederf.co.za","bianca@kleinbederf.co.za", "hanna@kleinbederf.co.za"],
      },
      {
        title_af: 'Resensies',
        title_en: 'Reviews',
        href: '/admin/resensies',
        users: ["info@kleinbederf.co.za","admin@test.com","johan@kleinbederf.co.za","bianca@kleinbederf.co.za", "hanna@kleinbederf.co.za"],
      },
    ],
  },
  {
    groupTitle: 'Gebruikers',
    groupTitle_en: 'Users',
    id: 'gebruikers',
    users: ["info@kleinbederf.co.za","admin@test.com","bianca@kleinbederf.co.za", "hanna@kleinbederf.co.za"],
    pages: [
      {
        title_af: 'Gebruikers Lys',
        title_en: 'Users',
        href: '/admin/gebruikers',
      },
    ],
  },

  {
    groupTitle: 'Kommissie Netwerk',
    groupTitle_en: 'Commission Network',
    id: 'kommisie-netwerk',
    users: ["info@kleinbederf.co.za","admin@test.com"],
    pages: [
      
      {
        title_af: 'Struktuur',
        title_en: 'Structure',
        href: '/admin/kommissie/struktuur',
        users: ["info@kleinbederf.co.za","admin@test.com"],
      },
      {
        title_af: 'Uitbetalings',
        title_en: 'Payouts',
        href: '/admin/kommissie/uitbetalings',
        users: ["info@kleinbederf.co.za","admin@test.com"],
      },
      {
        title_af: 'Balans',
        title_en: 'Balance',
        href: '/admin/kommissie/balans',
        users: ["info@kleinbederf.co.za","admin@test.com"],
      },
      {
        title_af: 'Verdienste (Detail) ',
        title_en: 'Earnings (Detail) ',
        href: '/admin/kommissie/verdienste',
        users: ["info@kleinbederf.co.za","admin@test.com"],
      },
      {
        title_af: 'Verdienste (Opsomming) ',
        title_en: 'Earnings (Summary) ',
        href: '/admin/kommissie/verdienste-opsomming',
        users: ["info@kleinbederf.co.za","admin@test.com"],
      },
    ],
  },
  {
    groupTitle: 'Ranks',
    groupTitle_en: 'Ranks',
    id: 'ranks',
    users: ["info@kleinbederf.co.za","admin@test.com"],
    pages: [
      
      {
        title_af: 'Ranks Punte (Detail)',
        title_en: 'Ranks Points (Detail)',
        href: '/admin/ranks/punte-detail',
        users: ["info@kleinbederf.co.za","admin@test.com"],
      },
      
    ],
  },
  {
    groupTitle: 'Challenge',
    groupTitle_en: 'Challenge',
    id: 'challenge',
    users: ["info@kleinbederf.co.za","admin@test.com"],
    pages: [
      
      {
        title_af: 'Challenge',
        title_en: 'ChallengeRanks Points (Detail)',
        href: '/admin/challenge',
        users: ["info@kleinbederf.co.za","admin@test.com"],
      },
      
    ],
  },

  {
    groupTitle: 'Ander',
    groupTitle_en: 'Other',
    id: 'ander',
    users: ["info@kleinbederf.co.za","admin@test.com","bianca@kleinbederf.co.za", "hanna@kleinbederf.co.za"],
    pages: [
      {
        title_af: 'Koepons / Geskenkbewys',
        title_en: 'Coupons / Vouchers',
        href: '/admin/koepons',
        users: ["info@kleinbederf.co.za","admin@test.com","bianca@kleinbederf.co.za", "hanna@kleinbederf.co.za"],
      },
    ],
  },
  {
    groupTitle: 'Subskripsies',
    groupTitle_en: 'Subscriptions',
    id: 'subskripsies',
    users: ["info@kleinbederf.co.za","admin@test.com","johan@kleinbederf.co.za", "bianca@kleinbederf.co.za","ulrike@kleinbederf.co.za"],
    pages: [
      {
        title_af: 'Maandbokse',
        title_en: 'Monthly Boxes',
        href: '/admin/subskripsies',
      },
    ],
  },
  {
    groupTitle: 'Vorms',
    groupTitle_en: 'Forms',
    id: 'vorms',
    users: ["info@kleinbederf.co.za","admin@test.com","bianca@kleinbederf.co.za", "hanna@kleinbederf.co.za"],
    pages: [
      {
        title_af: 'Agent Registrasie',
        title_en: 'Agent Registration',
        href: '/admin/vorms/agente',
        users: ["info@kleinbederf.co.za","admin@test.com","bianca@kleinbederf.co.za", "hanna@kleinbederf.co.za"],
      },
      {
        title_af: 'Groothandel Registrasie',
        title_en: 'Retailer Registration',
        href: '/admin/vorms/groothandel',
        users: ["info@kleinbederf.co.za","admin@test.com","bianca@kleinbederf.co.za", "hanna@kleinbederf.co.za"],
      },
      {
        title_af: 'Handelshoeke',
        title_en: 'Trade Corners',
        href: '/admin/vorms/handelshoeke',
        users: ["info@kleinbederf.co.za","admin@test.com","bianca@kleinbederf.co.za", "hanna@kleinbederf.co.za"],
      },
      {
        title_af: 'Kontak',
        title_en: 'Contact',
        href: '/admin/vorms/kontak',
        users: ["info@kleinbederf.co.za","admin@test.com"],
      },

    ],
  },
  
 
  // {
  //   groupTitle: 'Support & Change Log',
  //   id: 'support-change-log',
  //   pages: [
  //     {
  //       title: 'Support',
  //       href: '/docs/support',
  //     },
  //     {
  //       title: 'Change Log (v4.7.0)',
  //       href: '/docs/change-log',
  //     },
  //   ],
  // },
];

export default pages;
