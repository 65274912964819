import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Main from "../../layouts/Main";
import Container from "components/Container";

import Headline from "./Headline";

import { useAppSelector } from "store/configureStore";
import Products from "./Products";

const Wishlist = (): JSX.Element => {

  const {wishlistItems } = useAppSelector((state) => state.wishlist);

  // useEffect(() => {
  //   dispatch(fetchBasketAsync());
  // }, [dispatch]);

  // if (status.includes("pending")) {
  //   return (
  //     <Main>
  //     <Box bgcolor={"alternate.main"}>
  //       <Container paddingY={{ xs: 2, sm: 2.5 }}>
  //         <Headline />
  //       </Container>
  //     </Box>
  //     <Container>
  //       <Box>
  //         <Grid container spacing={4}>
  //           <Grid item xs={12} md={8}>
           
  //           <Skeleton variant="rectangular"  height={150}  />
  //           <Skeleton variant="rectangular"  height={150} sx={{marginY: 5}} />
  //           <Skeleton variant="rectangular"  height={150} sx={{marginY: 5}}/>
  //           </Grid>
  //           <Grid item xs={12} md={4}>
  //           <Skeleton variant="rectangular"  height={520} />
  //           </Grid>
  //         </Grid>
  //       </Box>
  //     </Container>
  //   </Main>
  //   )
  // }

  if (!wishlistItems || wishlistItems.length === 0)
    return (
      <Main>
      <Box bgcolor={"alternate.main"}>
        <Container paddingY={{ xs: 2, sm: 2.5 }}>
          <Headline />
        </Container>
      </Box>
      <Container>
      <Typography>
        Jou wenslys is leeg, besoek ons Winkel vir oulike produkte.{" "}
      </Typography>
      </Container>
      </Main>
    );

  return (
    <Main>
      <Box bgcolor={"alternate.main"}>
        <Container paddingY={{ xs: 2, sm: 2.5 }}>
          <Headline />
        </Container>
      </Box>
      <Container>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <Products />
            </Grid>
         
          </Grid>
        </Box>
      </Container>
    </Main>
  );
};

export default Wishlist;
