/* eslint-disable react/no-unescaped-entities */
import { useEffect, useState } from "react";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Skeleton from "@mui/material/Skeleton";
import { Link as LinkR } from "react-router-dom";
import agent from "api/agent";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AppCheckbox from "components/AppCheckbox";
import Alert from "@mui/material/Alert";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import AppRecaptcha from "components/AppRecaptcha";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, "Naam is te kort")
    .max(50, "Naam is te lank")
    .required("Naam is nodig"),
  lastName: yup
    .string()
    .trim()
    .min(2, "Van is te kort")
    .max(50, "Van is te lank")
    .required("Van is nodig"),
  email: yup
    .string()
    .trim()
    .email("'n Geldige eposadres is nodig'")
    .required("Eposadres is nodig"),
  password: yup
    .string()
    .required("Die nuwe wagwoord veld is noodsaaklik")
    .min(8, "Die wagwoord moet tenminste 8 karakters lank wees")
    .matches(
      /^(?=.*[a-z])/,
      "Die wagwoord moet tenminste een kleinletter bevat, bv. a,b,c,d"
    )
    .matches(
      /^(?=.*[A-Z])/,
      "Die wagwoord moet tenminste een hoofletter bevat, bv. A,B,C,D"
    )
    .matches(
      /^(?=.*[0-9])/,
      "Die wagwoord moet tenminste een nommer bevat, bv. 1,2,3,4"
    ),

  captcha: yup
    .string()
    .required("Die reCaptcha validasie is nodig.")
    .min(8, "Die reCaptcha validasie is nodig."),
});

const validationSchemaAgentsOrRetailers = yup.object({
  addressLine1: yup
    .string()
    .trim()
    .min(2, "'n Geldige adres is nodig")
    .max(200, "'n Geldige adres is nodig")
    .required("Hierdie is 'n noodsaaklike veld. "),
  addressCity: yup
    .string()
    .trim()
    .min(2, "'n Geldige dorp/stad is nodig")
    .max(100, "'n Geldige dorp/stad is nodig")
    .required("Hierdie is 'n noodsaaklike veld. "),
  addressProvince: yup
    .string()
    .trim()
    .min(2, "'n Geldige provinsie is nodig")
    .max(100, "'n Geldige provinsie is nodig")
    .required("Hierdie is 'n noodsaaklike veld. "),
  addressCode: yup
    .string()
    .trim()
    .min(2, "'n Geldige kode is nodig")
    .max(10, "'n Geldige kode is nodig")
    .required("Hierdie is 'n noodsaaklike veld. "),
  addressCountry: yup
    .string()
    .trim()
    .min(2, "'n Geldige land is nodig")
    .max(50, "'n Geldige land is nodig")
    .required("Hierdie is 'n noodsaaklike veld. "),
  phone: yup
    .string()
    .trim()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/,
      "'n Geldige kontak nommer is nodig."
    ),
  idNumber: yup
    .string()
    .trim()
    .min(2, "ID Nommer is te kort, 'n Geldige ID Nommer is nodig. ")
    .max(20, "ID Nommer is te lank, 'n Geldige ID Nommer is nodig.")
    .required("ID Nommer is 'n noodsaaklike veld."),
  ageConfirmation: yup
    .boolean()
    .isTrue("Jy moet die veld bevestig om te kan voort gaan met registrasie."),
});

const Form = (): JSX.Element => {
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  // const [submitVisible, setSubmitVisible] = useState(false);
  const [targetrole, setTargetRole] = useState("Gewoon");
  const [activeValidationSchema, setActiveValidationSchema] =
    useState(validationSchema);

  // const activeValidationSchema = targetRole === "Gewoon"? validationSchema : validationSchema.concat(validationSchemaAgentsOrRetailers);

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(activeValidationSchema),
  });

  function changeRole(value: string) {
    setValue("targetRole", value);
    setActiveValidationSchema(
      value === "Gewoon"
        ? validationSchema
        : validationSchema.concat(validationSchemaAgentsOrRetailers)
    );
    setTargetRole(value);
  }

  // useEffect(() => {
  //   setValue("targetRole", targetrole);
  //   setActiveValidationSchema(
  //     targetrole === "Gewoon"
  //       ? validationSchema
  //       : validationSchema.concat(validationSchemaAgentsOrRetailers)
  //   );
  // }, [targetrole, setValue]);

  useEffect(() => {
    
    //In case user was referred by an agent.
    setValue("agentkey", localStorage.getItem("agentkey") ?? "");
     
  }, [setValue]);



  // //Store the value when recaptcha is checked.
  // function onChange(value) {
  //   setValue("captcha", value);

  //   if (value === null) {
  //     setSubmitVisible(false);
  //   } else {
  //     setSubmitVisible(true);
  //   }
  // }

  async function handleSubmitData(values) {
    try {
      await agent.Account.register(values);
      toast.success(
        "Registrasie suksesvol. Loer asb in jou eposbus vir 'n epos om jou registrasie te bevestig."
      );
      setRegistrationSuccess(true);
    } catch (errors: any) {
      if (errors.email) {
        setError(
          "email",
          { type: "focus", message: errors.email },
          { shouldFocus: true }
        );
        return;
      }

      if (errors.error) {
        Object.keys(errors.error).forEach((key) => {
          if (key.includes("Password")) {
            setError(
              "password",
              { type: "focus", message: errors.error[key] },
              { shouldFocus: true }
            );
          } else if (key.includes("Email")) {
            setError(
              "email",
              { type: "focus", message: errors.error[key] },
              { shouldFocus: true }
            );
          }
        });
      }
    }
  }

  if (registrationSuccess) {
    return (
      <Box>
        <Box marginBottom={4}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              marginBottom: 3,
            }}
          >
            Registrasie Ontvang
          </Typography>
          <Typography>
            Loer asb in jou eposbus vir 'n kode om jou e-posadres te bevestig.
          </Typography>

          {(targetrole === "Agent" || targetrole === "Retailer") && (
            <Typography sx={{ paddingBottom: 3, marginTop: 3 }}>
              Onthou om jou {targetrole} registrasiefooi van R480.00 in ons
              rekening te betaal (jy kry die bedrag weer terug in die vorm van
              ‘n geskenkbewys), en om jou bewys van betaling aan
              hanna@kleinbederf.co.za te stuur.
              <br />
              <br />
              Ons bank rekening vir EFT betalings: FNB, 250655, Tjek Rekening,
              62814053972 (Gebruik jou naam en van as verwysing)
              <br />
              <br />
              Sodra jou betaling ontvang word sal ons jou profiel verander vanaf
              'Gewoon' na {targetrole}. Intussen kan jy loer wat beskikbaar is
              op ons webwerf, die {targetrole} funksies en afslag sal wys sodra
              ons jou betaling bevestig het.
            </Typography>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Skep jou Profiel
        </Typography>
        <Typography color="text.secondary">
          Voltooi die vorm om jou profiel te skep.
        </Typography>
      </Box>
      {isSubmitting && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  width: "90%",
                  paddingRight: 5,
                  marginRight: 5,
                }}
              />
              <Skeleton variant="rectangular" width={210} height={60} />
            </Box>
          </Grid>
        </Grid>
      )}
      {!isSubmitting && (
        <>
          <form onSubmit={handleSubmit(handleSubmitData)}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Naam"
                  variant="outlined"
                  name={"firstName"}
                  fullWidth
                  {...register("firstName")}
                  error={errors?.firstName != null}
                  // @ts-ignore
                  helperText={errors?.firstName?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Van"
                  variant="outlined"
                  name={"lastName"}
                  fullWidth
                  {...register("lastName")}
                  error={errors?.lastName != null}
                  // @ts-ignore
                  helperText={errors?.lastName?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="E-posadres"
                  variant="outlined"
                  name={"email"}
                  fullWidth
                  {...register("email")}
                  error={errors?.email != null}
                  // @ts-ignore
                  helperText={errors?.email?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Wagwoord"
                  variant="outlined"
                  name={"password"}
                  type={"password"}
                  fullWidth
                  {...register("password")}
                  error={errors?.password != null}
                  // @ts-ignore
                  helperText={errors?.password?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>
                    Vir watse tiepe profiel wil jy registreer?
                  </InputLabel>
                  <Select
                    value={targetrole}
                    label={"Vir watse tiepe profiel wil jy registreer?"}
                    onChange={(e) => {
                      changeRole(e.target.value);
                    }}
                  >
                    {["Gewoon", "Agent", "Retailer"].map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <input
                  type="hidden"
                  name={"targetRole"}
                  defaultValue={targetrole}
                  {...register("targetRole")}
                />
              </Grid>
              {localStorage.getItem("agentkey") && (
                <Grid item xs={12}>
                  <Alert severity="success">
                    {" "}
                    Wonderlik! Jy was verwys deur een van ons agente (verwysing:{" "}
                    <strong> {localStorage.getItem("agentkey")} </strong>) en
                    word deel van hul netwerk.{" "}
                  </Alert>
                  {/* <Typography
                    sx={{
                      fontSize: "0.8em",
                    }}
                  >
                   
                  </Typography> */}
                </Grid>
              )}

              {(targetrole === "Agent" || targetrole === "Retailer") && (
                <>
                  <Grid item xs={12}>
                    Addisionele inligting vir Agent/Retailer registrasies:
                  </Grid>
                  <Grid item xs={12}>
                    <Alert severity="info">
                      <Typography variant={"subtitle2"}>
                        Verwys na hierdie skakels om meer uit te vind wat 'n{" "}
                        <Link
                          component={LinkR}
                          color={"primary"}
                          to={"/agente"}
                          underline={"none"}
                        >
                          Agent
                        </Link>{" "}
                        /{" "}
                        <Link
                          component={LinkR}
                          color={"primary"}
                          to={"/groothandelaar"}
                          underline={"none"}
                        >
                          Retailer
                        </Link>{" "}
                        behels.
                      </Typography>
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <Alert severity="info">
                      Daar is ‘n eenmalige registrasiefooi van R480.00
                      betaalbaar wanneer jy registreer as 'n {targetrole} en
                      hierdie bedrag kry jy weer terug in die vorm van ‘n
                      geskenkbewys om te gebruik saam met jou eerste
                      aanlyn-bestelling!
                    </Alert>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Selfoonnommer"
                      variant="outlined"
                      name={"phone"}
                      fullWidth
                      defaultValue={""}
                      {...register("phone")}
                      error={errors?.phone != null}
                      // @ts-ignore
                      helperText={errors?.phone?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="ID Nommer"
                      variant="outlined"
                      name={"idNumber"}
                      fullWidth
                      defaultValue={""}
                      {...register("idNumber")}
                      error={errors?.idNumber != null}
                      // @ts-ignore
                      helperText={errors?.idNumber?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="Besigheid Naam (opsioneel)"
                      variant="outlined"
                      name={"companyName"}
                      fullWidth
                      defaultValue={""}
                      {...register("companyName")}
                      error={errors?.companyName != null}
                      // @ts-ignore
                      helperText={errors?.companyName?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Besigheid Registrasie No (opsioneel)"
                      variant="outlined"
                      name={"companyNumber"}
                      fullWidth
                      defaultValue={""}
                      {...register("companyNumber")}
                      error={errors?.companyNumber != null}
                      // @ts-ignore
                      helperText={errors?.companyNumber?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Besigheid VAT No (opsioneel)"
                      variant="outlined"
                      name={"businessVatNumber"}
                      fullWidth
                      defaultValue={""}
                      {...register("businessVatNumber")}
                      error={errors?.businessVatNumber != null}
                      // @ts-ignore
                      helperText={errors?.businessVatNumber?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Adres Lyn 1"
                      variant="outlined"
                      name={"addressLine1"}
                      fullWidth
                      defaultValue={""}
                      {...register("addressLine1")}
                      error={errors?.addressLine1 != null}
                      // @ts-ignore
                      helperText={errors?.addressLine1?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Adres Lyn 2"
                      variant="outlined"
                      name={"addressLine2"}
                      fullWidth
                      defaultValue={""}
                      {...register("addressLine2")}
                      error={errors?.addressLine2 != null}
                      // @ts-ignore
                      helperText={errors?.addressLine2?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Dorp / Stad"
                      variant="outlined"
                      name={"addressCity"}
                      fullWidth
                      defaultValue={""}
                      {...register("addressCity")}
                      error={errors?.addressCity != null}
                      // @ts-ignore
                      helperText={errors?.addressCity?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Provinsie"
                      variant="outlined"
                      name={"addressProvince"}
                      fullWidth
                      defaultValue={""}
                      {...register("addressProvince")}
                      error={errors?.addressProvince != null}
                      // @ts-ignore
                      helperText={errors?.addressProvince?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Poskode"
                      variant="outlined"
                      name={"addressCode"}
                      fullWidth
                      defaultValue={""}
                      {...register("addressCode")}
                      error={errors?.addressCode != null}
                      // @ts-ignore
                      helperText={errors?.addressCode?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Land"
                      variant="outlined"
                      name={"addressCountry"}
                      fullWidth
                      defaultValue={""}
                      {...register("addressCountry")}
                      error={errors?.addressCountry != null}
                      // @ts-ignore
                      helperText={errors?.addressCountry?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <AppCheckbox
                      control={control}
                      name="ageConfirmation"
                      label="Ek bevestig hiermee dat ek ouer as 18 is. Wanneer jy as agent/retailer aansluit gee jy toestemming dat jou naam, van, en e-posadres gedeel word en sigbaar is aan die ander agente in die kommissie netwerk. "
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={12}>
                {/* <input
                  type="hidden"
                  name={"captcha"}
                  {...register("captcha")}
                /> */}
                <input
                  type="hidden"
                  name={"agentkey"}
                  {...register("agentkey")}
                />
                {/* 
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  onChange={onChange}
                /> */}

                <AppRecaptcha name="captcha" control={control} />
              </Grid>

              <Grid item container xs={12}>
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "stretched", sm: "center" }}
                  justifyContent={"space-between"}
                  width={1}
                  maxWidth={600}
                  margin={"0 auto"}
                >
                  <Box marginBottom={{ xs: 1, sm: 0 }}>
                    <Typography variant={"subtitle2"}>
                      Is reeds geregistreer?{" "}
                      <Link
                        component={LinkR}
                        color={"primary"}
                        to={"/tekenin"}
                        underline={"none"}
                      >
                        Teken in.
                      </Link>
                    </Typography>
                  </Box>
                  <Button
                    size={"large"}
                    variant={"contained"}
                    type={"submit"}
                    //disabled={!isValid}
                  >
                    Registreer
                  </Button>
                </Box>
              </Grid>
              <Grid
                item
                container
                xs={12}
                justifyContent={"left"}
                alignItems={"center"}
              >
                <Box>
                  <Typography component="p" variant="subtitle2" align="left">
                    Deur te registreer, stem jy in tot ons{" "}
                    <Link
                      component={LinkR}
                      color={"primary"}
                      to={"/privaatheidsbeleid"}
                      underline={"none"}
                    >
                      Privaatheidsbeleid
                    </Link>{" "}
                    en{" "}
                    <Link
                      component={LinkR}
                      color={"primary"}
                      to={"/bepalingsenvoorwaardes"}
                      underline={"none"}
                    >
                      Bepalings en Voorwaardes
                    </Link>
                    .
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </Box>
  );
};

export default Form;
