import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import LinearProgress from '@mui/material/LinearProgress';

import Main from "layouts/Main";
import agent from "../../api/agent";
import {
  DataGrid,
  GridRowsProp,
  GridColumns,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import Page from "./Page";
import { toast } from "react-toastify";
import { Coupon } from "models/basket";



export default function Giftvouchers() {

  const [loading, setLoading] = useState(true);

  const [rows, setRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    agent.Coupon.couponsMyVoucherList()
      .then((list: Coupon[]) => {
        const vouchers: GridRowsProp = list.map((voucher) => ({
          id: voucher.id,
          code: voucher.code,
          title: voucher.title,
          type: voucher.type,
          discountValue: voucher.discountValue,
          startDate: voucher.startDate,
          endDate: voucher.endDate,
          status: voucher.status,
          discountValueRemaining: voucher.discountValueRemaining
        }));
        setRows(vouchers);
      })
      .catch((error) => toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer"))
      .finally(() => setLoading(false));
  }, []);


  const columns: GridColumns = [
    { field: "code", headerName: "Kode", width: 100,  },
    { field: "title", headerName: "Beskrywing", width: 300,  },
    { field: "discountValue", headerName: "Waarde", width: 120, valueGetter: (params) => "R" + params.row.discountValue.toFixed(2), },
    { field: "discountValueRemaining", headerName: "Balans", width: 120, valueGetter: (params) => "R" + params.row.discountValueRemaining.toFixed(2),  },
    {
      field: "startDate",
      headerName: "Geldig Vanaf",
      width: 180,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }

        const date = new Date(params.value);

        const valueFormatted = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return valueFormatted;
      },
    },
    {
      field: "endDate",
      headerName: "Verval",
      width: 180,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }

        const date = new Date(params.value);

        const valueFormatted = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return valueFormatted;
      },
    },
    { field: "status", headerName: "Status", width: 180,  },
    
  
  ];
  
  return (
    <Main>
      <Page>
        <Box>
          <Typography variant="h6" gutterBottom fontWeight={700}>
            Geskenkbewyse
          </Typography>
         
          <Box paddingY={4}>
            <Divider />
          </Box>

          <Box
          sx={{
            height: 500,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            components={{
             
              LoadingOverlay: LinearProgress,
        
            }}
           
            loading={loading}
          />
        </Box>
        
        </Box>
      </Page>
    </Main>
  );
};
