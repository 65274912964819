import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import Main from "layouts/Main";
import Container from "components/Container";

import PhotoScroller from "./PhotoScroller";
import { Button, Typography } from "@mui/material";
import { useState } from "react";
import { MembershipForm } from "./Form";
import { LazyLoadImage } from "react-lazy-load-image-component";

const MembershipKleintjies = (): JSX.Element => {
  const theme = useTheme();

  const [openSubscribeModel, setOpenSubscribeModel] = useState(false);

  return (
    <Main>
      <Box>
        <Box
          sx={{
            position: "relative",
            backgroundColor: theme.palette.alternate.main,
            backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
            marginTop: -13,
            paddingTop: 13,
          }}
        >
          <Container>
            <Box>
              <Box
                marginBottom={{ xs: 0, sm: 2 }}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Typography
                  variant="h3"
                  gutterBottom
                  align={"center"}
                  sx={{
                    fontWeight: 900,
                  }}
                >
                  Kleintjies Maandboks-Klub
                </Typography>
                <Typography
                  variant="h6"
                  component="p"
                  color="text.primary"
                  align={"center"}
                  sx={{ marginBottom: 1 }}
                >
                  Met Japsnoet en Fanjan!
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>

        <Container>
          <Box>
            <Container maxWidth={800} padding={"0 !important"}>
              <Box marginBottom={4} textAlign="center">
                <Typography
                  align={"center"}
                  color={"text.secondary"}
                  sx={{ textTransform: "uppercase" }}
                  variant={"subtitle2"}
                  fontWeight={600}
                >
                  Klein se eksklusiewe Kleintjies Maandboks-Klub.
                </Typography>

                <Typography
                  fontWeight={700}
                  variant={"h4"}
                  align={"center"}
                  lineHeight={1.5}
                >
                  Registreer NOU!
                </Typography>
              </Box>
              <Box marginBottom={4} textAlign="center">
                <Box
                  component={LazyLoadImage}
                  width={1}
                  borderRadius={5}
                  src={"/images/maandboks-kleintjies/KleintjiesKlub2.jpeg"}
                  alt="..."
                  effect="blur"
                  sx={{
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0.6)"
                        : "none",
                  }}
                />
              </Box>

              <Box color={"text.secondary"} textAlign="center">
                Wil jy ook deel wees van die "BESTE-MAMMA-KLUB"?!
                <br />
                Dan moet jy DEFINITIEF vir jou Kleintjie(s) ons Kleintjie-Klub
                Maandboks kry!
                <br />
                Dit is propvol kinder bederwe en het al regoor Suid-Afrika
                kinder hartjies baie opgewonde gemaak!
              </Box>
            </Container>
          </Box>
          <PhotoScroller />
        </Container>

        <Box
          component={"svg"}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.alternate.main}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
      <Box bgcolor={"alternate.main"}>
        <Container maxWidth={800} padding={"0 !important"}>
          <Box marginBottom={3}>
            <Typography
              align={"center"}
              color={"text.secondary"}
              sx={{ textTransform: "uppercase", paddingBottom: 2 }}
              variant={"subtitle2"}
              fontWeight={600}
            >
              AANSLUITING
            </Typography>
            <Typography align={"center"}>
            Vul asseblief ons Debietorder Registrasievorm in sodat ons jou
              KLEIN-maatjie(s) se Maandboks(e) elke maand kan stuur en jy
              sommer lekker kan spaar ook! Maandbokse word vir die
              maand vooruit betaal en sal voor die einde van die betrokke maand
              afgelewer word. <br />
              <br />
              LW. Die maandbokse word uitgewerk met 12 bokse (12 maande)
              ingedagte. Maak asseblief seker jy verstaan dit wanneer jy
              registreer (Dat daar dus vir 12 maande, elke maand 'n Debietorder
              sal afgaan by jou rekening). Daar sal ongelukkig 'n
              kansellasiefooi van R380 gehef word (waarvoor jy nie 'n boks gaan
              kry nie) sou jy voor dit wil kanselleer. <br />
              <br />
              Sou jy na die 10de van 'n maand registreer, sal jou subskripsies
              en dus jou eerste boks die daaropvolgende maand s'n wees en nie
              die een waarin jy registreer nie. <br />
              <br />
              Laat die GROOT bederf begin! <br />
              <br />
              <Button
                onClick={() => setOpenSubscribeModel(true)}
                variant="contained"
                color="primary"
                size="large"
                sx={{ width: 300, height: 55 }}
              >
                <Typography fontWeight={600}> Sluit aan!</Typography>
              </Button>
            </Typography>
          </Box>
          <MembershipForm
            modalOpen={openSubscribeModel}
            setModalOpen={setOpenSubscribeModel}
          />
        </Container>

        <Box
          component={"svg"}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
    </Main>
  );
};

export default MembershipKleintjies;
