import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import agent from "../../../api/agent";
import { toast } from "react-toastify";
import { MonthlyRankReportWrapper } from "models/ranks";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Alert,
} from "@mui/material";
import React from "react";

export default function RanksPointDetails() {
  const current_date = React.useMemo<string>(() => {
    const oneMonthAgo = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      new Date().getDate()
    );

    const current_year = oneMonthAgo.toLocaleDateString("en-us", {
      year: "numeric",
    });

    const current_month = oneMonthAgo.toLocaleDateString("en-us", {
      month: "short",
    });

    const current_date = current_month + " " + current_year;

    return current_date;
  }, []);

  const daysOfYear = React.useMemo<string[]>(() => {
    const now = new Date();
    var _daysOfYear = [];

    // calculate and return value for path
    for (
      var d = new Date(2024, 0, 1);
      d <= new Date(now.getFullYear(), now.getMonth() - 1, 1);
      d.setMonth(d.getMonth() + 1)
    ) {
      _daysOfYear.push(
        new Date(d).toLocaleDateString("en-ZA", {
          month: "short",
          year: "numeric",
        })
      );
    }

    return _daysOfYear;
  }, []);

  const [loading, setLoading] = useState(true);
  const [reportDate, setReportDate] = useState(current_date);
  const [data, setData] = useState<MonthlyRankReportWrapper>(null);

  useEffect(() => {
    setLoading(true);

    agent.Ranks.getUserReport(reportDate)
      .then((list: MonthlyRankReportWrapper) => {
        console.log(list);
        setData(list);
      })
      .catch((error) =>
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer")
      )
      .finally(() => setLoading(false));

    // const testdata: MonthlyRankReportWrapper = {
    //   rankReportMonthlyOrders: [],
    //   rankReportActiveAgents: [],
    //   rankReportBonusPoints: [],
    //   totalPointsMonthlyOrders: 1,
    //   totalPointsActiveAgents: 2,
    //   totalPointsBonus: 3,
    // };

    // setData(testdata);

    // setLoading(false);

    //console.log(reportDate);
  }, [reportDate]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <Box textAlign={"right"}>
        <FormControl sx={{ paddingRight: 1 }}>
          <InputLabel id="demo-simple-select-label">Maand</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={reportDate}
            label="Maand"
            onChange={(e) => setReportDate(e.target.value)}
            sx={{ width: 120 }}
            size="small"
          >
            {/* <MenuItem value={"Apr 2024"}>Apr 2024 </MenuItem>
            <MenuItem value={"Mar 2024"}>Mar 2024 </MenuItem>
            <MenuItem value={"Feb 2024"}>Feb 2024 </MenuItem>
            <MenuItem value={"Jan 2024"}>Jan 2024 </MenuItem> */}

            {daysOfYear.reverse().map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {loading && (
        <Box sx={{ width: "100%" }} marginTop={5}>
          <LinearProgress />
        </Box>
      )}

      {!loading && !data && <span>Fout! Geen data was gelaai</span>}

      {!loading && data && (
        <Box>
          <Box
            sx={{ justifyContent: "center", display: "flex", paddingTop: 2 }}
          >
            <img
              src={"https://kleinbederf.com/images/Logo 1.png"}
              alt={"Kleinbederf Logo"}
              loading="lazy"
              width={"20%"}
            />
          </Box>
          <Typography variant="h3" paddingY={2} textAlign={"center"}>
            {data.totalPointsActiveAgents +
              data.totalPointsBonus +
              data.totalPointsMonthlyOrders}{" "}
            Punte
          </Typography>

          <Box marginY={0}>
            <Typography variant="h6" marginBottom={2}>
              Maand Bestellings
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Maand</TableCell>
                    <TableCell>Bestelling No</TableCell>
                    <TableCell>Kwalifiseer</TableCell>
                    <TableCell>Punte</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.rankReportMonthlyOrders &&
                    data.rankReportMonthlyOrders.map((row) => (
                      <TableRow
                        key={row.orderId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.month}
                        </TableCell>
                        <TableCell>{row.orderId}</TableCell>
                        <TableCell align="right">{row.qualifiedFor}</TableCell>
                        <TableCell align="right">{row.points}</TableCell>
                      </TableRow>
                    ))}
                  <TableRow key="totalrow">
                    <TableCell colSpan={3}>Sub Totaal</TableCell>
                    <TableCell align="right">
                      {data.totalPointsMonthlyOrders}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box marginTop={5}>
            <Typography variant="h6" marginBottom={2}>
              Bonus Punte
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Maand</TableCell>
                    <TableCell>Beskrywing</TableCell>
                    <TableCell>Punte</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.rankReportBonusPoints &&
                    data.rankReportBonusPoints.map((row) => (
                      <TableRow
                        key={row.month}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.month}
                        </TableCell>
                        <TableCell>{row.title}</TableCell>
                        <TableCell align="right">{row.points}</TableCell>
                      </TableRow>
                    ))}
                  <TableRow key="totalrow">
                    <TableCell colSpan={2}>Sub Totaal</TableCell>
                    <TableCell align="right">{data.totalPointsBonus}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box marginTop={5}>
        
            <Typography variant="h6" marginBottom={2}>
              Aktiewe Agente
            </Typography>
            <Alert severity="info" sx={{ margin: 2 }}>
        Die aktiewe agentlys is eers vanaf Junie 2024 beskikbaar.
      </Alert>

      <Typography variant="body1" marginBottom={2}>
              Punte:   {data.totalPointsActiveAgents}
            </Typography>
    

            {/* <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Naam</TableCell>
                    <TableCell>Epos</TableCell>
                    <TableCell>Punte</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.rankReportActiveAgents &&
                    data.rankReportActiveAgents.map((row) => (
                      <TableRow
                        key={row.email}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell align="right">{row.points}</TableCell>
                      </TableRow>
                    ))}
                  <TableRow key="totalrow">
                    <TableCell colSpan={2}>Sub Totaal</TableCell>
                    <TableCell align="right">
                      {data.totalPointsActiveAgents}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer> */}
          </Box>
        </Box>
      )}
    </Box>
  );
}
