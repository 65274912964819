import FixedLayout from "layouts/Fixed";
import {
  Typography,
  Box,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import agent from "../../api/agent";
import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
  GridRowsProp,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import React from "react";
//import ProductQuickViewDialog from "./ProductQuickViewDialog";
import { toast } from "react-toastify";
import { CommissionEarned } from "models/commission";
import InfoIcon from '@mui/icons-material/Info';


const CommissionEarningsListSummary = (): JSX.Element => {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    agent.Admin.commissionEarnedListSummary()
      .then((c: CommissionEarned[]) => {

        const s: GridRowsProp = c.map((entry, index) => ({
          id: index,
          orderId: entry.orderId,
          orderItemId: entry.orderItemId,
          userId: entry.userId,
          userEmail: entry.userEmail,
          commissionPercentage: entry.commissionPercentage,
          commissionAmount: entry.commissionAmount,
          taxDeductionAmount: entry.taxDeductionAmount,
          finalAmount: entry.finalAmount,
          level: entry.level,
          dateProcessed: entry.dateProcessed,
          status: entry.status,
          potentialAmountA: entry.potentialAmountA,
            potentialAmountB: entry.potentialAmountB,
            type: entry.type,
            finalAmountMessage: entry.finalAmountMessage,

        }));
        setRows(s);
      })
      .catch((error) => {
        toast.error("Aggenee, dit wou nie laai nie. Probeer asb weer");
      })
      .finally(() => setLoading(false));
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }


    const columns: GridColumns = [

        {
            field: "orderId",
            headerName: "Bestelling No",
            width: 100,
        },
        {
            field: "status",
            headerName: "Bestelling Status",
            width: 120,
        },

        {
            field: "userEmail",
            headerName: "Kommissie Ontvanger",
            width: 200,
        },
        {
            field: "level",
            headerName: "Diepte",
            width: 100,
        },
        // { field: "commissionPercentage", headerName: "Kommissie % (avg)", width: 120,  valueGetter: (params) => (params.row.commissionPercentage*100).toFixed(0) + "%",},

        {
            field: "potentialAmountA",
            headerName: "Vlak A Kommissie",
            width: 200,
            valueGetter: (params) => "R" + params.row.potentialAmountA.toFixed(2),
        },
        {
            field: "potentialAmountB",
            headerName: "Vlak B Kommissie",
            width: 200,
            valueGetter: (params) => "R" + params.row.potentialAmountB.toFixed(2),
        },
        {
            field: "type",
            headerName: "Finale Kommissie Vlak",
            width: 200,
            renderCell: (params: GridRenderCellParams) => {

                if (params.value === 'Pending') {
                    return (
                        <Tooltip title='Finale vlak sal aan einde van die maand bereken word.' placement="right" arrow >
                          
                                <InfoIcon color='primary' />
                           
                        </Tooltip>
                    );
                }

                return params.value;


            }
        },

        {
            field: "finalAmount",
            headerName: "Finale Kommissie R",
            width: 200,
            renderCell: (params: GridRenderCellParams) => {

                if (params.row.type === 'Pending') {
                    return (
                        <Tooltip title='Finale bedrag sal aan einde van die maand bereken word.' placement="right" arrow >

                            <InfoIcon color='primary' />

                        </Tooltip>
                    );
                }

                return params.value.toFixed(2);


            }
        },
    
    {
      field: "dateProcessed",
      headerName: "Datum",
      width: 130,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }

        const date = new Date(params.value);

        const valueFormatted = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return valueFormatted;
      },
    },
   
  ];

  return (
    <FixedLayout>
      <Box
        width={1}
        margin={"0 auto"}
        paddingX={2}
        paddingY={{ xs: 2, sm: 2, md: 2 }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ p: 2 }} variant="h5">
            Verdienste (Opsomming)
          </Typography>
        </Box>

        <div style={{ height: 600, width: "100%" }}>
       
          <DataGrid
            rows={rows}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              LoadingOverlay: LinearProgress,
            }}
            loading={loading}
          />
        </div>

      

        
      </Box>
    </FixedLayout>
  );
};

export default CommissionEarningsListSummary;
