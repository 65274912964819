
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';



const Content = (): JSX.Element => {
  return (
    <Box>
        <p>
      
			
<h3>Algemene Inligting</h3>
<p>Ons bestuur die webwerf www.kleinbederf.co.za . Ons is Klein Bederf (Pty) Ltd wat sake doen as Kleinbederf, ’n maatskappy in Suid-Afrika geregistreer onder die maatskappynommer 2018/069511/07 en met ons geregistreerde kantoor en handelsadres as MC Roode Rylaan, Van der Hoffpark, Potchefstroom, 2531
E-pos: info@kleinbederf.co.za</p>
<h3>Jou Status</h3>
<p>Deur ’n bestelling op ons webwerf te plaas, waarborg/stem jy in dat jy wettiglik daartoe in staat is om bindende kontrakte aan te gaan; en ten minste 18 jaar oud is.</p>
<h3>Gebruik</h3>
<p>Persone wat die Kleinbederf webwerf (kleinbederf.co.za / kleinbederf.com) gebruik onderwerp hulself aan en aanvaar die bepalings en voorwaardes van Kleinbederf.
Gebruikers wat die inhoud van hierdie webwerf vir kommersiële doeleindes wil gebruik, mag dit slegs doen met skriftelike toestemming wat vooraf verkry is vanaf eienaar van Kleinbederf.</p>
<h3>Skriftelike Kommunikasie</h3>
<p>Toepaslike wetgewing vereis dat sekere inligting en kommunikasie skriftelik aan jou gestuur moet word. Wanneer jy ons webwerf gebruik, aanvaar jy dat kommunikasie met ons meestal elektronies sal geskied. Ons sal jou per e-pos kontak of deur middel van kennisgewings op ons webwerf inligting aan jou deurgee. Vir kontraktuele doeleindes stem jy in tot die elektroniese kommunikasie en erken jy dat alle kontrakte, kennisgewings, inligting en ander vorme van kommunikasie wat ons elektronies aan jou verskaf, aan enige wetlike vereistes dat sulke kommunikasie op skrif moet wees, voldoen. Hierdie voorwaarde beïnvloed nie jou statutêre regte nie.</p>
<h3>Intellektuele Eiendomsregte</h3>
<p>Alle inhoud, handelsmerke en data op hierdie webwerf, wat sagteware, databasisse, teks, grafika, ikone, hiperskakels, private inligting, en ontwerp insluit, maar nie daartoe beperk is nie, is die eiendom van of gelisensieer aan Kleinbederf, en word as sodanig teen oortreding en skending beskerm deur plaaslike en internasionale wetgewing en ooreenkomste. Onderhewig aan die regte toegeken aan die gebruiker binne die bogenoemde wetgewing, word alle ander regte tot alle intellektuele eiendom op hierdie webwerf uitdruklik gereserveer.</p>
<p>Ons is die eienaar of lisensiehouer van alle intellektuele eiendomsregte op ons webwerf, ongeag of dit geregistreer is of nie, asook die materiaal wat daarop gepubliseer is. Hierdie werke word deur kopiereg beskerm en alle sulke regte word voorbehou.</p>
<p>Indien jy kommentaar oor die Produkte en Dienste gelewer op enige webwerf, blog of sosiale media platform plaas (Kommentaar), moet jy seker maak dat sulke Kommentaar jou opinies is. Deur op die dienste in te teken gee jy ons onherroeplik toestemming om op ons webwerf uit jou Kommentaar aan te haal, asook in enige reklame-veldtogte of op sosiale media platforms wat ons mag skep, of tot sal bydra.</p>
<p>Kleinbederf behou die reg voor om alle inhoud wat deur jou verskaf of gepubliseer word te monitor vir die doel om te bepaal of jy aan die terme en voorwaardes voldoen, sowel as ander operasionele reëls soos vasgestel deur Kleinbederf. Ons het die reg tot besluitneming rakende die inhoud, en sal dit verwyder indien nodig.</p>
<p>Kleinbederf kan nie al die inhoud wat op die webwerf beskikbaar is, deurwerk of redigeer nie en aanvaar geen aanspreeklikheid vir onwettige, lasterlike of onwelvoeglike inhoud nie.</p>
<p>Kleinbederf behou die reg voor om dienste te weier, rekeninge te beëindig, of bestellings te kanselleer op enige stadium volgens eie diskresie, insluitende, en sonder voorbehoud, gebaseer op die gedrag deur ŉ intekenaar wat die term een toepaslike wetgewing oortree.</p>
<p>Kleinbederf het die reg om die Diens (of enige deel daarvan) te verander of te staak, tydelik of permanent, met of sonder kennisgewing. Jy stem in dat Kleinbederf nie aanspreeklik gehou kan word teen jou of enige derde party vir enige veranderings, staking of opskorting van die Diens.</p>
<h3>Algemeen</h3>
<p>Die plasing van ‘n item in ‘n inkopiemandjie sonder om die koopsiklus te voltooi, sal nie enige bestelling van produkte of verkoopooreenkoms uitmaak of aanleiding daartoe gee nie. Kleinbederf mag enige sodanige produk uit die mandjie verwyder indien voorraad nie beskikbaar is nie, en gebruikers kan nie Kleinbederf verantwoordelik hou indien sodanige items nie beskikbaar is wanneer die koopsiklus wel later voltooi word nie.
Kleinbederf behou die reg voor om, vir die doeleindes van die voorkoming van vermeende bedrog en met kennisgewing aan jou, te weier om betaling vir ‘n bestelling te aanvaar en/of te verwerk, en/of om enige verkoopstransaksie in geheel of gedeeltelik te kanselleer. Kleinbederf is slegs aanspreeklik vir die terugbetaling van gelde wat reeds deur die gebruiker betaal is en aanvaar geen ander aanspreeklikheid wat mag spruit uit sodanige weiering om enige bestelling/verkope te verwerk nie.</p>
<p>Kleinbederf behou die reg voor om sonder enige kennisgewing die pryse en tariewe wat op hierdie webwerf gekwoteer word, te verander en te wysig.
Kleinbederf streef daarna om elke produk op die webwerf akkuraat te beskryf en/of uit te beeld. Kleinbederf sal alles doen wat redelik binne sy vermoë is om pryse en afleweringskoste akkuraat aan te dui. Indien produkte egter foutiewelik teen die verkeerde pryse en/of aflewerkoste aangebied word, sal Kleinbederf nie verplig wees om die produkte teen sodanige foutiewe pryse en/of aflewerkoste te verkoop nie, maar sal enige gelde wat deur jou betaal is aan jou terugbetaal indien jy verkies om nie voort te gaan met die kooptransaksie teen die korrekte prys en/of aflewerkoste nie.</p>
<h3>Geen Versteekte Kostes of Verpligte Aankope Nie</h3>
<p>Daar is geen fooie betrokke by die registrering by Kleinbederf nie. Daar is geen Aaankoopverpligtinge vir gewone gebruikers nie – jy bestel wat jy wil, wanneer jy wil.</p>
<p>Klein-Agente het minimum aankoopverpligtinge van R300 per bestelling en ten minste een bestelling elke 3 maande.</p>
<h3>Terugbetalingsbeleid</h3>
<p>Indien enige produk wat jy ontvang defektief, beskadig of onvanpas is vir die doel waarvoor dit bedoel is, is jy daarop geregtig om die produk terug te besorg (op Kleinbederf se onkoste) vir ‘n volle terugbetaling of vir vervanging, op voorwaarde dat die foutiewe produk binne een maand vanaf aflewering daarvan aan Kleinbederf terugbesorg word.</p>
<p>Let asseblief in dié verband daarop dat:</p>
<ul>
<li>‘n&nbsp;“defek”&nbsp;nie iets is wat uit normale slytasie voorspruit nie;</li>
<li>“beskadig”&nbsp;beteken dat die produk beskadig moet wees wanneer jy dit ontvang en dat dit nie deur jou of enigiemand anders wat dit ontvang het beskadig is nie;</li>
<li>‘n item nie&nbsp;“onvanpas”&nbsp;is gegrond op die aard van die inhoud nie of omdat jy die item geniet het of nie geniet het nie; en</li>
<li>Kleinbederf se verskaffer sal die terugbesorgde produk ondersoek om vas te stel of die beweerde probleem inderdaad geregverdig is of nie. Indien die probleem nie geregverdig is nie, sal jy aanspreeklik wees vir die onkoste van terugbesorging aan Kleinbederf, vir inspeksie en heraflewering aan jou. Ons moedig jou dus aan om enige probleme wat jy ontdek stiptelik nadat jy dit ontdek het aan ons te rapporteer.</li>
</ul>
<p>Kontak ons by&nbsp;betalings@kleinbederf.co.za binne 24 uur indien die item nie is wat jy bestel het nie.</p>
<h3>Betalingsmetodes</h3>
<p>Kleinbederf bied die volgende betaalmetode aan: Elektroniese Fonds oorplasing</p>
<h3>Geskenkbewyse</h3>
<p>Al ons algemene bepalings en voorwaardes is van toepassing op geskenkbewyse. Hierdie bepalings en voorwaardes sal ook van toepassing wees op die aankoop en aflossing van ons geskenkbewyse.</p>
<p>Om ‘n geskenkbewys te koop, moet u ‘n rekening by ons registreer via ons webwerf. Geskenkbewyse wat deur ons webwerf bestel word, sal outomaties per elektroniese manier aan die e-pos adres wat deur u verskaf was tydens die bestelling, afgelewer word. Ons kan nie verantwoordelik gehou word indien u die verkeerde e-pos adres verskaf het.</p>
<p>Ons geskenkbewyse moet op ons webwerf afgelos word as volle of gedeeltelike betaling van produkte vanaf ons webwerf. ‘n Geskenkbewys kan nie gebruik word om ‘n verdere geskenkertifikaat te koop nie.</p>
<p>Alle geskenkbewyse is gedateer en verval 12 maande vanaf die datum van uitreiking.</p>
<p>Geskenkbewyse kan nie vir kontant verruil word nie.</p>
<p>Waar produkte wat aanlyn gekoop is met ‘n geskenkbewys, gekanselleer en terugbetaal word, sal die verskuldigde geld terugbetaal word deur ‘n geskenkbewys.</p>
<p>Ons is nie verantwoordelik as ‘n geskenkbewys verlore, gesteel, vernietig of gebruik word sonder toestemming nie en onder hierdie omstandighede sal geen vervanging verskaf word nie.</p>
<p>Vrae, kommentaar en versoeke rakende geskenkbewys, Bepalings en Voorwaardes word verwelkom, kontak ons gerus op info@kleinbederf.co.za</p>
<h3>Aflewering</h3>
<p>Vir aflewerings gebruik ons Courier Guy. Ons behou die reg voor om enige ander diensteverskaffer wat die mees ekonomies is te gebruik indien nodig. Groot bestellings, swaar items of aflewering na sommige areas kan moontlik addisionele aflewerings kostes benodig. Maak seker jy verskaf ʼn geldige afleweringsadres en kontaknommer, en kies die korrekte afleweringsmetode en –adres gedurende die betalingsproses aanlyn. Kontak ons as jou pakkie nie binne die geraamde afleweringstyd ontvang word nie. Ons help graag om die pakkie na te speur.</p>
<p>Alhoewel ons van Klein en die fabriek se kant af alles doen om bokse stewig te verpak en elke produk ook apart toedraai, kan ons ongelukkig nie keer as die koerier van die bokse rof hanteer nie.  Alle koerier is op eie risiko en skade deur die koerier se drywers aan pakkies moet asb direk met die betrokke koerier opgeneem word.  Jy kan wel ook jou eie koerier stuur.</p>

<p>Sou ʼn pakkie onafgelewer terugkom, sal ons jou in kennis stel en jou versoek om alternatiewe reëlings te tref vir die heraflewering aan jou. Jy stem in en gee ons volmag om ontslae te raak van die pakkie, dit te skenk, of te herverkoop indien dit nie binne ses maande opgeëis word nie.
Aflewerings in die areas buite die diensteverskaffer se gebied sal teen ʼn hoër koers gehef word, en is addisioneel tot die prys van die produk wat aangekoop is.</p>
<p>Omdat ons elke dag vars produkte maak en groot volumes bestellings daagliks inkry, neem prosessering vanaf bestelling en bewys van betaling ontvang is, tot jou pakkie die fabriek verlaat 7-9 werksdae.</p>
<h3>Gebeurtenisse Buite Ons Beheer</h3>
<p>Ons sal nie aanspreeklik of verantwoordelik wees vir enige mislukking of vertraging in optrede van enige van ons verpligtinge in terme van die Kontrak, wanneer dit deur gebeure buite ons redelike beheer veroorsaak word nie (Force Majeure).
’n Force Majeure sluit in enige daad, gebeurtenis, nie-gebeurtenis, weglating of ongeluk buite ons beheer, en sluit spesifiek (sonder beperking) die volgende in:</p>
<ul>
<li>Stakings, uitsluitings of enige ander industriële aksie;</li>
<li>Burgerlike opstand, oproerings, invalle, terroriste-aanvalle of bedreigings hiervan, oorloë (verklaar of nie) of bedreigings of voorbereidings vir oorloë;</li>
<li>Brande, ontploffings, storms, vloede, aardbewings, insinkings, epidemies of ander natuurrampe;</li>
<li>Gevalle waar die spoorweë, skepe, vliegtuie, motors of enige ander vorms van publieke of private vervoermiddels nie bruikbaar is nie;</li>
<li>Geval waar publieke of private telekommunikasienetwerke nie bruikbaar is nie; en</li>
<li>Die dade, bevele, wetgewing, regulasies of beperkings van enige goewerment.</li>
</ul>
<p>Ons optrede onder hierdie Kontrak sal opgeskort wees vir die periode van die Force Majeure, en ons sal hiertydens ’n langer periode vir optrede hê. Ons sal ’n redelike poging aanwend om die Force Majeure stop te sit, of ’n oplossing probeer vind sodat ons verpligtinge onder die Kontrak nagekom kan word ten spyte van die Force Majeure.</p>
<h3>Kwytskelding</h3>
<p>Indien ons nie daarin slaag om, enige tyd gedurende die Kontrak, daarop aan te dring dat jy jou vereistes onder die Kontrak of enige van hierdie Bepalings en Voorwaardes nakom nie, of as ons nie op enige van die regte of regsmiddele waarop ons onder hierdie Kontrak geregtig is aandring nie, sal dit nie as kwytskelding van sulke regte of regsmiddele beskou word nie en sal dit jou nie van jou nakoming ten opsigte van sulke verpligtinge vryspreek nie.
’n Kwytskelding deur ons van enige wanbetaling, geld nie as die kwytskelding van die opeenvolgende wanbetaling nie.
Geen kwytskelding deur ons van enige van hierdie Bepalings en Voorwaardes sal effektief wees tensy dit duidelik as ’n kwytskelding uiteengesit en skriftelik aan jou gekommunikeer is nie.</p>
<h3>Ons Reg om Hierdie Bepalings en Voorwaardes te Wysig</h3>
<p>Ons het die reg om hierdie Bepalings en Voorwaardes van tyd tot tyd te hersien en aan te pas om die volgende te weerspieël: veranderinge in marktoestande wat ons besigheid affekteer, veranderinge in tegnologie, veranderinge in betaalmetodes, veranderinge in relevante wette en regulatoriese vereistes en veranderinge in die vermoëns van ons stelsels.</p>
<p>Jy sal onderworpe wees aan die beleide, Bepalings en Voorwaardes wat geldig is wanneer jy jou produk by ons bestel, tensy ’n verandering in hierdie beleide, Bepalings en Voorwaardes volgens wet vereis word of deur regeringsgesag gemaak word (in so ’n geval sal dit op bestellings wat jy voorheen geplaas het van toepassing wees), of indien ons jou van die verandering in die beleide, Bepalings en Voorwaardes in kennis stel voordat ons die Bevestiging van Versending aan jou stuur (in so ’n geval het ons die reg om te aanvaar dat jy die verandering in Bepalings en Voorwaardes aanvaar het, tensy jy ons binne sewe werksdae vandat jy die produkte ontvang het van die teenoorgestelde in kennis stel).</p>
<h3>Wetgewing En Jurisdiksie</h3>
<p>Kontrakte met die doel om produkte via ons webtuiste te koop en enige geskil of eis wat hieruit of in verband met hierdie kontrakte, of uit hulle onderwerp of vorming geskied (insluitende nie-kontraktuele geskille of eise) sal deur die Suid-Afrikaanse reg regeer word.</p>
<h3>Gebruiker-Gegenereerde Inhoud</h3>
<p>Die resensies, kommentaar en ander gebruiker-gegenereerde inhoud word vir jou gebruik voorsien. Maar enige sienings en opinies wat deur gebruikers van ons webtuiste gegee word, is nie noodwendig die sienings en opinies van Kleinbederf nie.
Gebruiker-gegenereerde inhoudsvelde moet nie aan die volgende voldoen nie:</p>
<ul>
<li>in oortreding van enige relevante plaaslike, nasionale of internasionale wet wees nie, of die oortreding van so ’n wet aanmoedig of aanvuur nie;</li>
<li>rassisties, seksisties of homofobies;</li>
<li>pornografies, onsedelik, godslasterlik of vulgêr;</li>
<li>lasterlik teenoor enige persoon, entiteit of organisasie;</li>
<li>lasterend, bedreigend, aanstootlik of andersins skadelik nie</li>
<li>inbreuk maak op die regte (insluitend die intellektuele eiendomsreg) van enige persoon;</li>
</ul>
<p>Indien jy hierdie bepalings en voorwaardes oortree behou ons die reg voor om jou rekening op te skort of te skrap.</p>
<p>Kleinbederf behou die reg voor om enige gebruiker-gegenereerde inhoud vanaf die webwerf oor te dra of te redigeer sonder vooraf kennisgewing aan die skrywer van hierdie inhoud.</p>
<p>Kleinbederf aanvaar nie verantwoordelikheid of aanspreeklikheid vir enige gebruiker-gegenereerde inhoud nie en daarom, deur die inhoud te publiseer, aanvaar die skrywer dat die inhoud in die publieke domein is.</p>
<h3>Kopiereg en Intellektuele Eiendomsreg</h3>
<p>Die verskaffer voorsien sekere inligting op die webwerf. Inhoud wat op die oomblik op die webwerf vertoon word of in die toekoms vertoon sal word, word verskaf deur die Verskaffer, sy suster- en /of dogtermaatskappy, of enige ander derde party verskaffers van sulke inhoud, en sluit in maar is nie beperk nie tot literêre werke, musikale werke, kunswerke, klankopnames, filmkuns, klank- en televisie-uitsendings, programdraende seine, uitgawes en rekenaarprogramme (“die Inhoud”). Al hierdie werke waaroor eienaarskap beskik word, en die samevoeging van die werke waaroor eienaarskap beskik word, is onder die kopiereg van die Verskaffer, sy suster- en /of dogtermaatskappy, of enige ander derde party verskaffers van sulke inhoud (“die Eienaars”), en word beskerm deur Suid-Afrikaanse en internasionale kopieregwette. Die Verskaffer behou die reg om ter enige tyd, sonder kennisgewing, veranderings aan te bring aan die Webwerf, die Inhoud of produkte en/of dienste wat gelewer word deur die Webwerf. Alle regte rakende die Inhoud word deur die Eienaars gereserveer. Behalwe soos gespesifiseer in hierdie Bepaling en Voorwaardes, word die Gebruiker geen lisensie of enige ander reg gegee rakende die Inhoud nie, insluitende, sonder enige uitsondering, die Kopiereg, Handelsmerk, Patent of ander Intellektuele Eiendomsreg.</p>
<h3>Beperkte Lisensie aan Algemene Gebruikers</h3>
<p>Die Verskaffer gee die Gebruiker ’n nie-eksklusiewe, nie-oordraagbare, beperkte en herroepbare reg, onderworpe aan die verdere Bepalings en Voorwaardes, om toegang te verkry tot die huidige en toekomstige inhoud, dit ten toon te stel, te gebruik, af te laai en ander afskrifte te maak vir persoonlike en nie-kommersiële gebruik, slegs vir die doel van inligting.
Hierdie webwerf en die Inhoud mag nie weer geproduseer, gekopieer, herverkoop, besoek word of andersins in die bedryf gestel word vir enige kommersiële doel, sonder die skriftelike toestemming van die Verskaffer nie. Die lisensie laat nie die Gebruiker toe om enige lyste van produkte of dienste bymekaar te maak nie, ook nie beskrywings of enige ander inligting wat hier ten toon gestel word nie, en laat geen afgeleide gebruik van hierdie Webwerf of die Inhoud daarvan vir die voordeel van ander handelaars toe nie. Die Gebruiker mag geen samestellings maak of enige versinningstegnologie gebruik deur die Webwerf van die Verskaffer of die Inhoud, of enige gedeelte daarvan in te sluit, sonder die skriftelike toestemming van die Verskaffer nie. Die Verskaffer en die Eienaars voorsien geen produkte of dienste aan minderjariges nie. As jy jonger is as 18 mag jy nie op enige aanbieding van die Webwerf reageer of dit aanvaar nie.</p>
<p>Die Verskaffer en die Eienaars, sy suster- en /of dogtermaatskappy, behou die reg om na goeddunke enige diens te weier, rekenings op te sê, inhoud te verwyder of aan te pas, of bestellings te kanselleer.</p>
<h3>Privaatheidsbeleid</h3>
<p>Lees on privaatheidsbeleid by: <Link to="/privaatheidsbeleid" >Privaatsheidsbeleid</Link></p>
<p>Kontak ons gerus met enige navrae rakende hierdie beleid. Stuur ʼn epos na info@kleinbederf.co.za</p>


		</p>
        
    </Box>
  );
};

export default Content;
