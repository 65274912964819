import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ProductImage } from "models/product";

interface Props {
  images: Array<ProductImage>;
  title: string;
}

const Image = ({ images, title }: Props): JSX.Element => {
  return (
    <Grid container spacing={2}>
      {/* sx={{ height: 1 }} */}
      {images.filter((f) => f.isMain === true).length !== 0 && (
        <Grid key={'primary'} item xs={12}>
          <Box
            sx={{
              display: "flex",
              //height: 1,
              "& img": {
                width: 1,
                height: 1,
                objectFit: "cover",
                borderRadius: 2,
              },
            }}
          >
            <img src={'/uploads/' + images.filter(f => f.isMain === true)[0].url} alt={title} loading={"lazy"} />
          </Box>
        </Grid>
      )}
      {images.filter((f) => f.isMain === false).map((image, i) => (
        <Grid key={i} item xs={6}>
          <Box
            sx={{
              display: "flex",
              //height: 1,
              "& img": {
                width: 1,
                height: 1,
                objectFit: "cover",
                borderRadius: 2,
              },
            }}
          >
            <img src={"/uploads/" + image.url} alt={title} loading={"lazy"} />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default Image;
