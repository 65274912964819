import FixedLayout from "layouts/Fixed";
import {
  Typography,
  Box,
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  Tabs,
  Grid,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import {
  DataGrid,
  GridRowsProp,
  GridColumns,
  GridActionsCellItem,
  GridRowId,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { FormHandelshoek } from "models/forms";
import React from "react";

export default function HandelshoekForm() {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);
  const [openDeleteConfirmationId, setOpenDeleteConfirmationId] =
    React.useState<GridRowId>("0");
  const [openDetailsModal, setOpenDetailsModal] = React.useState(false);
  const [openDetailsModalRow, setOpenDetailsModalRow] = React.useState<any>({});
  const [tabStatus, setTabStatus] = React.useState(0);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const handleStatusChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabStatus(newValue);

    //if 0 then unread,
    //if 1 then read.

    const filter = newValue === 1 ? "read" : "unread";

    //Load from database filtered values
    setLoading(true);

    agent.Admin.formHandelshoekList(filter)
      .then((c: FormHandelshoek[]) => {

        console.log('data from server', c);

        const submissions: GridRowsProp = c.map((s) => ({
          id: s.id,
          firstName: s.firstName,
          lastName: s.lastName,
          email: s.email,
          phone: s.phone,
          createDate: s.createDate,
          read: s.read,
          businessType: s.businessType,
          location: s.location,
          companyDetails: s.companyDetails,
          addressLine1: s.addressLine1,
          addressLine2: s.addressLine2,
          addressCity: s.addressCity,
          addressCode: s.addressCode,
          addressCountry: s.addressCountry,
          referredBy: s.referredBy,
          paymentOption: s.paymentOption,
          bankName: s.bankName,
          bankAccountHolderName: s.bankAccountHolderName,
          bankBranch: s.bankBranch,
          bankAccountType: s.bankAccountType,
          bankAccountNumber: s.bankAccountNumber,
          debitOrderDate: s.debitOrderDate,
          debitOrderMandate: s.debitOrderMandate,
          previousKioskInformation: s.previousKioskInformation,
        }));
        setRows(submissions);
      })
      .catch((error) => {
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    agent.Admin.formHandelshoekList("unread")
      .then((c: FormHandelshoek[]) => {
        const submissions: GridRowsProp = c.map((s) => ({
          id: s.id,
          firstName: s.firstName,
          lastName: s.lastName,
          idNumber: s.idNumber,
          email: s.email,
          phone: s.phone,
          createDate: s.createDate,
          read: s.read,
          businessType: s.businessType,
          location: s.location,
          companyDetails: s.companyDetails,
          addressLine1: s.addressLine1,
          addressLine2: s.addressLine2,
          addressCity: s.addressCity,
          addressCode: s.addressCode,
          addressCountry: s.addressCountry,
          referredBy: s.referredBy,
          paymentOption: s.paymentOption,
          bankName: s.bankName,
          bankAccountHolderName: s.bankAccountHolderName,
          bankBranch: s.bankBranch,
          bankAccountType: s.bankAccountType,
          bankAccountNumber: s.bankAccountNumber,
          debitOrderDate: s.debitOrderDate,
          debitOrderMandate: s.debitOrderMandate,
          previousKioskInformation: s.previousKioskInformation,
        }));
        setRows(submissions);
      })
      .catch((error) => {
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  }, []);

  const handleDeleteClick = (id: GridRowId) => () => {
    setOpenDeleteConfirmation(true);
    setOpenDeleteConfirmationId(id);
  };

  const handleDeleteClickConfirmed = () => {
    setLoading(true);

    setOpenDeleteConfirmation(false);
    const id = openDeleteConfirmationId;

    agent.Admin.formHandelshoekDelete(parseInt(id.toString()))
      .then((c) => setRows(rows.filter((row) => row.id !== id)))
      .catch((error) => {
        toast.warning("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  };

  const handleOpenClick = (id: GridRowId) => () => {
    setOpenDetailsModal(true);
    setOpenDetailsModalRow(rows.find((row) => row.id === id));
  };

  const handleMarkAsReadClick = (id: GridRowId) => () => {
    setLoading(true);

    agent.Admin.formHandelshoekMarkAsRead(parseInt(id.toString()))
      .then((c) => {
        const newrows = rows.filter((row) => row.id !== id);
        setRows(newrows);
      })
      .catch((error) => {
        toast.warning("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  };

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Naam",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
      },
    },

    { field: "email", headerName: "E-Posadres", flex: 1 },
    { field: "phone", headerName: "Kontak Nommer", flex: 1 },
    {
      field: "createDate",
      headerName: "Datum",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }

        const date = new Date(params.value);

        const valueFormatted = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        });
        return valueFormatted;
      },
    },
    {
      field: "read",
      headerName: "Status",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Boolean>) => {
        if (params.value === true) {
          return (
            <Chip
              label="Reeds Gelees"
              color="success"
              variant="outlined"
              size="small"
            />
          );
        } else {
          return (
            <Chip
              label="Nie Gelees"
              color="error"
              variant="outlined"
              size="small"
            />
          );
        }
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Opsies",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={
              rows.find((x) => x.id === id)?.read ? (
                <MarkunreadIcon />
              ) : (
                <MarkAsUnreadIcon />
              )
            }
            label={
              rows.find((x) => x.id === id)?.read
                ? "Mark as Read"
                : "Mark as Unread"
            }
            onClick={handleMarkAsReadClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="Read"
            onClick={handleOpenClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <FixedLayout>
      <Box
        width={1}
        margin={"0 auto"}
        paddingX={2}
        paddingY={{ xs: 2, sm: 2, md: 2 }}
      >
        <div>
          <Dialog
            open={openDetailsModal}
            onClose={() => setOpenDetailsModal(false)}
          >
            <Box sx={{ width: 500 }}></Box>
            <DialogTitle id="alert-dialog-title">
              {"Vorm Inligting"}
            </DialogTitle>
            <DialogContent>
              <Box
                component={Grid}
                marginBottom={{ xs: 10, sm: 0 }}
                container
                spacing={4}
              >
                <Grid item xs={12} sm={6}>
                <strong>Naam: </strong>
                <br />{" "}
                  {openDetailsModalRow?.firstName ?? ""}
                </Grid>
                <Grid item xs={12} sm={6}>
                <strong>van: </strong>
                <br />{" "}
                  {openDetailsModalRow?.lastName ?? ""}
                </Grid>
                <Grid item xs={12} sm={6}>
                <strong>ID Nommer: </strong>
                <br />{" "}
                  {openDetailsModalRow?.idNumber ?? ""}
                </Grid>
                <Grid item xs={12} sm={6}>
                <strong>E-Posadres: </strong>
                <br />{" "}
                  {openDetailsModalRow?.email ?? ""}
                </Grid>
                <Grid item xs={12} sm={6}>
                <strong>Selfoon Nommer: </strong>
                <br />{" "}
                {openDetailsModalRow?.phone ?? ""} 
                </Grid>
                <Grid item xs={12} sm={12}>
                <strong>Het jy jou eie besigheid waar jy jou Handelshoek gaan sit?: </strong>
                <br />{" "}
                {openDetailsModalRow?.businessType ?? ""} 
                </Grid>

                <Grid item xs={12} sm={12}>
                <strong>In watter provinsie sal jy graag jou Handelshoek(e) wil sit?: </strong>
                <br />{" "}
                {openDetailsModalRow?.location ?? ""} 
                </Grid>

                <Grid item xs={12} sm={12}>
                <strong>In watter dorp sal jy graag jou Handelshoek(e) wil sit?: </strong>
                <br />{" "}
                {openDetailsModalRow?.locationTown ?? ""} 
                </Grid>
               
                <Grid item xs={12} sm={12}>
                <strong> Adres waar Kiosk afgelaai moet word: (die meubels is baie
                    swaar, maak dus voor die tyd seker die spasie is gereed
                    sodat jy dit nie onnodig op en af moet vervoer nie):</strong>
                <br />{" "}
                {openDetailsModalRow?.addressLine1 ?? ""} <br />
                {openDetailsModalRow?.addressLine2 ?? ""} <br />
                {openDetailsModalRow?.addressCity ?? ""} <br />
                {openDetailsModalRow?.addressCode ?? ""} <br />
                {openDetailsModalRow?.addressCountry ?? ""} <br />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <strong>
                  Is jy reeds ’n Handelshoek-eienaar? Indien wel, hoeveel Handelshoeke het jy reeds?
                  </strong>
                </Grid>
                <Grid item xs={12} sm={12}>
                {openDetailsModalRow?.previousKioskInformation ?? ""} 
                </Grid>

                <Grid item xs={12} sm={12}>
                  <strong>
                    Is daar 'n Klein Agent wat jou verwys of registreer
                    hiervoor, indien wel, wat is die Agent se e-posadres?{" "}
                  </strong>

                  {openDetailsModalRow?.referredBy ?? ""} 
                </Grid>
                <Grid item xs={12} sm={12}>
                  <strong>
                    Indien die faktuur aan 'n besigheid uitgemaak moet word,
                    verskaf asb die besigheid se besonderhede.{" "}
                  </strong>
                </Grid>
                <Grid item xs={12} sm={12}>
                <strong>Indien die faktuur aan 'n besigheid uitgemaak moet word, verskaf asb die besigheid se besonderhede: </strong>
                <br />{" "}
                {openDetailsModalRow?.companyDetails ?? ""} 
                </Grid>

                <Grid item xs={12} sm={12}>
                <strong>Watter Betaal-opsie kies jy? </strong>
                <br />{" "}
                {openDetailsModalRow?.paymentOption ?? ""} 
                </Grid>

                <Grid item xs={12} sm={12}>
                  <strong>
                    Bankbesonderhede in die geval van afbetaal-opsies:
                  </strong>
                </Grid>
                <Grid item xs={12} sm={6}>
                <strong>Banknaam: </strong>
                <br />{" "}
                {openDetailsModalRow?.bankName ?? ""} 
                </Grid>
                <Grid item xs={12} sm={6}>
                <strong>Naam van Rekeninghouer: </strong>
                <br />{" "}
                {openDetailsModalRow?.bankAccountHolderName ?? ""} 
                </Grid>
                <Grid item xs={12} sm={6}>
                <strong>Tak Naam of Kode: </strong>
                <br />{" "}
                {openDetailsModalRow?.bankBranch ?? ""} 
                </Grid>
                <Grid item xs={12} sm={6}>
                <strong>Tipe Rekening: </strong>
                <br />{" "}
                {openDetailsModalRow?.bankAccountType ?? ""} 
                </Grid>
                <Grid item xs={12} sm={6}>
                <strong>Rekeningnommer: </strong>
                <br />{" "}
                {openDetailsModalRow?.bankAccountNumber ?? ""} 
                </Grid>
                <Grid item xs={12} sm={6}>
                <strong>Watter datum kies jy vir die Debietorder om af te gaan?: </strong>
                <br />{" "}
                {openDetailsModalRow?.debitOrderDate ?? ""} 
                </Grid>
                <Grid item xs={12} sm={12}>
                <strong>Toestemming: </strong>
                <br />{" "}
                {openDetailsModalRow?.debitOrderMandate ?? ""} 
                </Grid>
              </Box>

              <Typography sx={{ mt: 2 }}>
                <strong>Datum: </strong>
                <br />{" "}
                {openDetailsModalRow?.createDate &&
                  new Date(openDetailsModalRow?.createDate).toLocaleDateString(
                    "en-ZA",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    }
                  )}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDetailsModal(false)}>
                Gaan Terug
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div>
          <Dialog
            open={openDeleteConfirmation}
            onClose={() => setOpenDeleteConfirmation(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Bevestig"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Is jy seker jy wil die boodskap verwyder?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDeleteConfirmation(false)}>
                Gaan Terug
              </Button>
              <Button
                color="error"
                onClick={handleDeleteClickConfirmed}
                autoFocus
              >
                Verwyder
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ p: 2 }} variant="h5">
            Handelshoek Vorm
          </Typography>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", padding: 1 }}>
            <Tabs
              value={tabStatus}
              onChange={handleStatusChange}
              aria-label="tabs"
            >
              <Tab label="Unread" id={"Unread"} />
              <Tab label="Read" id={"Read"} />
            </Tabs>
          </Box>
        </Box>

        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
              LoadingOverlay: LinearProgress,
            }}
            loading={loading}
          />
        </Box>
      </Box>
    </FixedLayout>
  );
}
