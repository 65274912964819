import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import agent from "api/agent";
import { toast } from "react-toastify";
import { Autocomplete, CircularProgress } from "@mui/material";
import React from "react";

const validationSchema = yup.object({
  parentAgent: yup
    .string()
    .trim()
    .required("Parent Agent is 'n noodsaaklike veld."),
  subUser: yup.string().trim().required("Sub User is 'n noodsaaklike veld."),
});

interface UserListItem {
  userEmail: string;
  isInHierarchy: boolean;
}

interface Props {
  closeModal: (reloaddata: boolean) => void;
}

const AddHierarchyForm = ({closeModal}: Props): JSX.Element => {
  //State for Parent User drop down list
  const [openParentAgent, setOpenParentAgent] = React.useState(false);
  const [openSubUser, setOpenSubUser] = React.useState(false);
  const [options, setOptions] = React.useState<readonly UserListItem[]>([]);
  const loading = options.length === 0;

  // const initialValues = {
  //   firstName: user.firstName,
  //   lastName: user.lastName,
  //   email: user.email,
  //   contactNumber: user.contactNumber ?? ''
  // };

  React.useEffect(() => {
      let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        agent.Admin.commissionUsersList()
          .then((c: UserListItem[]) => {
            setOptions(c);
          })
          .catch((error) => {
            toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
          });
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  async function handleSubmitData(data: FieldValues) {
    try {
      
      await agent.Admin.commissionHierarchyCreate({parentUserEmail: data.parentAgent, userEmail: data.subUser, effectiveDate: data.effectiveDate });
      
      toast.success("Suksesvol bygevoeg");

      closeModal(true);

    } catch (error) {
      toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
    }
    }

    var curr = new Date();
    //curr.setDate(curr.getDate());
    var defaultdate = curr.toISOString().substring(0, 10);

  return (
    <Box>
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Autocomplete
              id="asynchronous-demo"
              //sx={{ width: 300 }}
              open={openParentAgent}
              onOpen={() => {
                setOpenParentAgent(true);
              }}
              onClose={() => {
                setOpenParentAgent(false);
              }}
              isOptionEqualToValue={(option, value) =>
                option.userEmail === value.userEmail
              }
              getOptionLabel={(option: UserListItem) => option.userEmail}
              options={options.filter((x) => x.isInHierarchy === true)}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Parent Agent"
                  {...register("parentAgent")}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  error={errors?.parentAgent != null}
                  // @ts-ignore
                  helperText={errors?.parentAgent?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              id="asynchronous-demo"
              //sx={{ width: 300 }}
              open={openSubUser}
              onOpen={() => {
                setOpenSubUser(true);
              }}
              onClose={() => {
                setOpenSubUser(false);
              }}
              isOptionEqualToValue={(option, value) =>
                option.userEmail === value.userEmail
              }
              getOptionLabel={(option: UserListItem) => option.userEmail}
              options={options}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sub Agent/User"
                  {...register("subUser")}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  error={errors?.subUser != null}
                  // @ts-ignore
                  helperText={errors?.subUser?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="date"
              label="Effektief Vanaf"
              type="date"
               defaultValue={ defaultdate }
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              {...register("effectiveDate")}
              error={errors?.effectiveDate != null}
              // @ts-ignore
              helperText={errors?.effectiveDate?.message}
            />
          </Grid>

          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              margin={"0 auto"}
            >
              <LoadingButton
                size={"large"}
                variant={"contained"}
                type={"submit"}
                loading={isSubmitting}
              >
                Stoor
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AddHierarchyForm;
