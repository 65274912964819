import FixedLayout from "layouts/Fixed";
import {
  Typography,
  Box,
  LinearProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import agent from "../../api/agent";
import {
  DataGrid,
  GridColumns,
  GridRowsProp,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import React from "react";
//import ProductQuickViewDialog from "./ProductQuickViewDialog";
import { toast } from "react-toastify";
import { CommissionBalance } from "models/commission";


const CommissionBalanceList = (): JSX.Element => {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    agent.Admin.commissionUserBalanceList()
      .then((c: CommissionBalance[]) => {
        const s: GridRowsProp = c.map((entry) => ({
          id: entry.userId,
          userId: entry.userId,
          userEmail: entry.userEmail,
          userName: entry.userName,
          earnedAmount: entry.earnedAmount,
          paidAmount: entry.paidAmount,
          balanceAmount: entry.balanceAmount,
          adjustmentsAmount: entry.adjustmentsAmount,
        }));
        setRows(s);
      })
      .catch((error) => {
        toast.error("Aggenee, dit wou nie laai nie. Probeer asb weer");
      })
      .finally(() => setLoading(false));
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }


  const columns: GridColumns = [
    {
      field: "userName",
      headerName: "Naam",
      width: 200,
    },
    {
      field: "userEmail",
      headerName: "E-pos",
      width: 200,
    },
    {
      field: "earnedAmount",
      headerName: "Verdienste R",
      width: 200,
    },
    {
      field: "adjustmentsAmount",
      headerName: "Adjustments R",
      width: 200,
    },
    {
      field: "paidAmount",
      headerName: "Uitbetalings R",
      width: 200,
    },
    {
      field: "balanceAmount",
      headerName: "Balans R",
      width: 200,
    }
  ];

  return (
    <FixedLayout>
      <Box
        width={1}
        margin={"0 auto"}
        paddingX={2}
        paddingY={{ xs: 2, sm: 2, md: 2 }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ p: 2 }} variant="h5">
            Balans
          </Typography>
        </Box>

        <div style={{ height: 600, width: "100%" }}>
       
          <DataGrid
            rows={rows}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              LoadingOverlay: LinearProgress,
            }}
            loading={loading}
          />
        </div>

      

        
      </Box>
    </FixedLayout>
  );
};

export default CommissionBalanceList;
