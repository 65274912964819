import Alert from "@mui/material/Alert";
import { Box } from "@mui/material";

export default function FactoryClosedNotice() {
  const date = new Date();

  if (date < new Date("13 January 2025") && date > new Date("9 December 2024")) {
    return (
      <Box padding={3}>
        <Alert variant="outlined" icon={false} severity="success">
          Neem asb kennis ons fabriek is gesluit en nuwe bestellings sal vanaf
          13 Januarie 2025 verwerk word.
        </Alert>
      </Box>
    );
  }

  return null;
}
