import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import agent from "api/agent";
import { toast } from "react-toastify";
import { Autocomplete, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { CommissionHierarchy } from "models/commission";
import CloseIcon from "@mui/icons-material/Close";

const validationSchema = yup.object({
  parentAgent: yup
    .string()
    .trim()
    .required("Parent Agent is 'n noodsaaklike veld."),
   subUser: yup.string().trim().required("Sub User is 'n noodsaaklike veld."),
});

interface UserListItem {
  userEmail: string;
  isInHierarchy: boolean;
}

interface Props {
  closeModal: (reloaddata: boolean) => void;
  id: number;
}

const EditHierarchyForm = ({ closeModal, id }: Props): JSX.Element => {
  //State for Parent User drop down list
  const [openParentAgent, setOpenParentAgent] = React.useState(false);
  const [openSubUser, setOpenSubUser] = React.useState(false);
  const [options, setOptions] = React.useState<readonly UserListItem[]>([]);
  const [details, setDetails] = useState<CommissionHierarchy>(null);
  const loading = options.length === 0;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        agent.Admin.commissionUsersList()
          .then((c: UserListItem[]) => {
            setOptions(c);
           
          })
          .catch((error) => {
            toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
          });
      }
    })();

    (async () => {
      if (active) {
        agent.Admin.commissionHierarchyDetails(id)
          .then((d: CommissionHierarchy) => {
            setDetails(d);
            setValue("subUser", d.userEmail);
    
          })
          .catch((error) => {
            toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
          });
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, id, setValue]);



  async function handleSubmitData(data: FieldValues) {
    try {
      await agent.Admin.commissionHierarchyUpdate({
        parentUserEmail: data.parentAgent,
        userEmail: data.subUser,
        effectiveDate: data.effectiveDate,
      });

      toast.success("Suksesvol verander");

      closeModal(true);
    } catch (error) {
      toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
    }
  }

  return (
    <Dialog
      onClose={() => closeModal(false)}
      open={true}
      fullWidth={true}
      maxWidth={"sm"}
    > <DialogTitle
    sx={{
      m: 0,
      p: 2,
      backgroundColor: (theme) => theme.palette.primary.main,
    }}
  >
    <Typography
      fontWeight={700}
      sx={{ color: (theme) => theme.palette.primary.contrastText }}
    >
    
      Verander Netwerk Skakel: {id}
    </Typography>

    <IconButton
      aria-label="close"
      onClick={() => closeModal(false)}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.primary.contrastText,
      }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>
  <DialogContent sx={{ marginTop: 2 }}>
      <Box paddingY={{ xs: 1, sm: 2 }} paddingX={{ xs: 2, sm: 4 }}>
     
       
     
          {!loading && details &&
          <Box>
            <form onSubmit={handleSubmit(handleSubmitData)}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Autocomplete
                    id="asynchronous-demo"
                    //sx={{ width: 300 }}
                    open={openParentAgent}
                    onOpen={() => {
                      setOpenParentAgent(true);
                    }}
                    onClose={() => {
                      setOpenParentAgent(false);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.userEmail === value.userEmail
                    }
                    getOptionLabel={(option: UserListItem) => option.userEmail}
                    options={options.filter((x) => x.isInHierarchy === true)}
                    loading={loading}
                    defaultValue={{userEmail: details.parentUserEmail, isInHierarchy: true}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Parent Agent"
                        defaultValue={details.parentUserEmail}
                        {...register("parentAgent")}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        error={errors?.parentAgent != null}
                        // @ts-ignore
                        helperText={errors?.parentAgent?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    id="asynchronous-demo"
                    //sx={{ width: 300 }}
                    open={openSubUser}
                    onOpen={() => {
                      setOpenSubUser(true);
                    }}
                    onClose={() => {
                      setOpenSubUser(false);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.userEmail === value.userEmail
                    }
                    getOptionLabel={(option: UserListItem) => option.userEmail}
                    options={options}
                    loading={loading}
                    disabled={true}
                    defaultValue={{userEmail: details.userEmail, isInHierarchy: true}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sub Agent/User"
                        defaultValue={details.userEmail}
                        {...register("subUser")}
                        InputProps={{
                        
                          ...params.InputProps,
                          disabled: true,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        error={errors?.subUser != null}
                        // @ts-ignore
                        helperText={errors?.subUser?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="date"
                    label="Effektief Vanaf"
                    type="date"
                    //defaultValue={details.effectiveDate.toISOString}
                    sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={ details.effectiveDate === null? "" : new Date(details.effectiveDate)
                    .toISOString()
                    .split("T")[0]
                }
                    {...register("effectiveDate")}
                    error={errors?.effectiveDate != null}
                    // @ts-ignore
                    helperText={errors?.effectiveDate?.message}
                  />
                </Grid>

                <Grid item container xs={12}>
                  <Box
                    display="flex"
                    flexDirection={{ xs: "column", sm: "row" }}
                    alignItems={{ xs: "stretched", sm: "center" }}
                    justifyContent={"space-between"}
                    width={1}
                    margin={"0 auto"}
                  >
                    <LoadingButton
                      size={"large"}
                      variant={"contained"}
                      type={"submit"}
                      loading={isSubmitting}
                    >
                      Stoor
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
}
{loading && <Typography>Loading...</Typography>}

{!loading && !details && <Typography>Fout...</Typography>}
        
      </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditHierarchyForm;
