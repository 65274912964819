import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as yup from "yup";
import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Divider, Skeleton } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import agent from "../../../../api/agent";
import Link from "@mui/material/Link";
import { Link as LinkR } from "react-router-dom";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { addServerErrors } from "util/util";

interface Props {
  modalOpen: any;
  setModalOpen: any;
}

const validationSchema = yup.object({
  FirstName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your first name"),
  LastName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your last name"),
  Email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  Captcha: yup
    .string()
    .required("The reCaptcha validation must be completed, please confirm the reCaptcha then try again to submit. "),
});

export default function NewsletterModal({ modalOpen, setModalOpen }: Props) {
  const [step, setStep] = useState(1);

  const handleClose = () => {
    setModalOpen(false);
  };

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: {errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: { FirstName: "", LastName: "", Email: "", Captcha: "abc" },
    resolver: yupResolver(validationSchema),
  });

  //Store the value when recaptcha is checked.
  function onChange(value) {
    setValue("Captcha", value, {shouldValidate: true});
  }

  async function handleSubmitData(data: FieldValues) {
    try {

      await agent.Forms.formNewsletterSubmit(data);

      setStep(2);

    } catch (error) {
      
      addServerErrors(error, setError);

    }
  }

  return (
    <div>
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       
        <DialogContent>
        
          {isSubmitting && (
            <Box>
              <Box marginBottom={7}>
                <Typography
                  align={"center"}
                  color={"text.secondary"}
                  sx={{ textTransform: "uppercase" }}
                  variant={"subtitle2"}
                  fontWeight={600}
                >
                  NUUSBRIEF AANSLUITING
                </Typography>
              </Box>

              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="rectangular" height={200} width={500} />
            </Box>
          )}

          {!isSubmitting && step === 1 && (
            <Box>
              <Box marginBottom={2}>
                <Typography
                  align={"center"}
                  color={"text.secondary"}
                  sx={{ textTransform: "uppercase", padding: 2 }}
                  variant={"subtitle2"}
                  fontWeight={600}
                >
                  NUUSBRIEF AANSLUITING
                </Typography>
                <Typography align={"center"}>
                  Voltooi asseblief die registrasievorm:
                </Typography>
              </Box>
              <form onSubmit={handleSubmit(handleSubmitData)}>
                <Box
                  component={Grid}
                  marginBottom={{ xs: 10, sm: 0 }}
                  container
                  spacing={4}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Naam"
                      variant="outlined"
                      name={"FirstName"}
                      fullWidth
                      {...register("FirstName")}
                      error={errors?.FirstName != null}
                      // @ts-ignore
                      helperText={errors?.FirstName?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Van"
                      variant="outlined"
                      name={"LastName"}
                      fullWidth
                      {...register("LastName")}
                      error={errors?.LastName != null}
                      // @ts-ignore
                      helperText={errors?.LastName?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="E-Pos"
                      variant="outlined"
                      name={"Email"}
                      fullWidth
                      {...register("Email")}
                      error={errors?.Email != null}
                      // @ts-ignore
                      helperText={errors?.Email?.message}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <input
                      type="hidden"
                      name={"Captcha"}
                      {...register("Captcha")}
                    />

                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      onChange={onChange}
                    />

                  {errors?.Captcha && <Alert severity="error" sx={{ marginY: 5 }}>
                    <p>{errors?.Captcha?.message}</p>
                    </Alert>}
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"column"}
                  >
                    <Button
                      size={"large"}
                      variant={"contained"}
                      type={"submit"}
                      disabled={!isValid}
                    >
                      Registreer
                    </Button>
                    
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item container justifyContent={"center"} xs={12}>
                    <Box>
                      <Typography component="p" variant="body2" align="left">
                        Deur op ons nuusbrief aan te sluit, stem jy in tot ons{" "}
                        <Link
                          component={LinkR}
                          color={"primary"}
                          to={"/privaatheidsbeleid"}
                          underline={"none"}
                        >
                          Privaatheidsbeleid
                        </Link>{" "}
                        en{" "}
                        <Link
                          component={LinkR}
                          color={"primary"}
                          to={"/bepalingsenvoorwaardes"}
                          underline={"none"}
                        >
                          Bepalings en Voorwaardes
                        </Link>
                        .
                      </Typography>
                    </Box>
                  </Grid>
                </Box>
              </form>
            </Box>
          )}

          {!isSubmitting && step === 2 && (
            <Box>
              <Typography
                variant={"body1"}
                color={"textSecondary"}
                align={"center"}
                sx={{ paddingBottom: 3 }}
              >
                Wonderlik! Jou aansluiting is ontvang.
              </Typography>
            </Box>
          )}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Keer Terug</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
