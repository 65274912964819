import { Typography, Button, Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";
import agent from "../../api/agent";
import React from "react";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  DataGrid,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridColumns,
  GridRowParams,
  MuiEvent,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { OrderRefund } from "models/order";

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

interface Props {
  orderid: number;
}

export default function Refunds({ orderid }: Props) {
  const [loading, setLoading] = useState(true);

  const [rows, setRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    agent.Admin.refundList(orderid)
      .then((c: OrderRefund[]) => {
        const refunds: GridRowsProp = c.map((refund) => ({
          id: refund.id,
          orderId: refund.orderId,
          dateCreated: refund.dateCreated,
          status: refund.status,
          amount: refund.amount,
          type: refund.type,
          reference: refund.reference,
          note: refund.note,
        }));
        setRows(refunds);
      })
      .catch((error) => {
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  }, [orderid]);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;

  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setLoading(true);

    agent.Admin.refundDelete(parseInt(id.toString()))
      .then((c) => setRows(rows.filter((row) => row.id !== id)))
      .catch((error) => {
        toast.warning("Aggenee, dit het nie gewerk nie, probeer asb weer");
      })
      .finally(() => setLoading(false));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    setLoading(false);
    //toast.error("could not save " + error.message);
    // setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  //React.useCallback(() => { ... }, []);

  const processRowUpdate = React.useCallback(
    async (newRow: GridRowModel) => {
      setLoading(true);

      const response = await agent.Admin.refundUpdate(newRow);

      setLoading(false);

      const updatedRow = { ...response, isNew: false };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    },
    [rows]
  );

  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const currentdate = new Date();

      const newrefund = {
        id: 0,
        orderId: orderid,
        dateCreated: currentdate,
        status: "Ontvang",
        amount: 0,
        type: "EFT",
        reference: "",
        note: "",
        isNew: true,
      };

      setRows((oldRows) => [...oldRows, newrefund]);

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [newrefund.id]: { mode: GridRowModes.Edit, fieldToFocus: "amount" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleClick}
          disabled={rows.find((x) => x.id === 0) !== undefined}
        >
          Voeg by
        </Button>
      </GridToolbarContainer>
    );
  }

  const columns: GridColumns = [
    {
      field: "type",
      headerName: "Metode",
      editable: true,
      type: "singleSelect",
      valueOptions: ["EFT", "Kontant", "Krediet Kaart", "Ander"],
      flex: 1
    },
    {
      field: "amount",
      headerName: "Bedrag",
      editable: true,
      type: "number",
      align: 'left',
      headerAlign: 'left',
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted = Number(params.value).toFixed(2);
        return `R${valueFormatted}`;
      },
      flex: 1
    },
    {
      field: "status",
      headerName: "Status",
      editable: true,
      type: "singleSelect",
      valueOptions: ["Ontvang"],
      flex: 1
    },
    {
      field: "reference",
      headerName: "Verwysing",
      editable: true,
      flex: 1
    },
    {
      field: "note",
      headerName: "Notas",
      editable: true,
      flex: 1
    },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      headerName: "Opsies",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box>
      <Typography fontWeight={700}>Terug Betalings (Refunds):</Typography>

      
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              autoHeight
              rows={rows}
              columns={columns}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              components={{
                Toolbar: EditToolbar,
                LoadingOverlay: LinearProgress,
              }}
              componentsProps={{
                toolbar: { setRows, setRowModesModel },
              }}
              experimentalFeatures={{ newEditingApi: true }}
              loading={loading}
            />
          </div>
        </div>
     
    </Box>
  );
}
