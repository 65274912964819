import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store/configureStore";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

// Sentry.init({
//   dsn: "https://e28ab0bb0e114219bd61f3df88f51a34@o4504144355000320.ingest.sentry.io/4504144899735552",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 0.1,
// });

// import i18n (needs to be bundled ;)) 
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
 
    <Provider store={store}>
      <App />
    </Provider>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
