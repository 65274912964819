import FixedLayout from "layouts/Fixed";
import {
  Typography,
  Box,
  LinearProgress,
  Dialog,
  Divider,
  Tab,
  Tabs,
} from "@mui/material";
import { useEffect, useState } from "react";
import agent from "../../api/agent";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueFormatterParams,
  MuiEvent,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { CommissionPayout } from "models/commission";
import React from "react";

const CommissionPayouts = (): JSX.Element => {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [loading, setLoading] = useState(true);
  const [newModalOpen, setNewModalOpen] = useState(false);

  //For tabs
  const [tab, setTab] = React.useState("Versoek Ontvang");
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});


  const handleStatusChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setTab(newValue);
  };

  useEffect(() => {
    setLoading(true);
    setRows([]);

    agent.Admin.commissionPayoutList(tab)
      .then((c: CommissionPayout[]) => {
        const s: GridRowsProp = c.map((entry) => ({
          id: entry.id,
          userId: entry.userId,
          userFullName: entry.userFullName,
          userEmail: entry.userEmail,
          amountPaid: entry.amountPaid,
          dateProcessed: entry.dateProcessed,
          payoutType: entry.payoutType,
          reference: entry.reference,
          notes: entry.notes,
          status: entry.status,
          bankAccountNo: entry.bankAccountNo,
          bankAccountBranchNo: entry.bankAccountBranchNo,
          bankAccountBankName: entry.bankAccountBankName,
          bankAccountType: entry.bankAccountType,
          orderId: entry.orderId,
        }));
        setRows(s);
      })
      .catch((error) => {
        toast.error("Aggenee, dit wou nie laai nie. Probeer asb weer");
      })
      .finally(() => setLoading(false));
  }, [tab]);

  function EditToolbar() {
    return (
      <GridToolbarContainer>
        {/* <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setNewModalOpen(true)}
        >
          Skep Nuwe
        </Button> */}

        <GridToolbarColumnsButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;

  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    setLoading(false);
    //toast.error("could not save " + error.message);
    // setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const processRowUpdate = React.useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {
    setLoading(true);

    await agent.Admin.commissionPayoutUpdate(newRow);
 
    setLoading(false);

    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
    
  }, [rows]);


  const columns: GridColumns = [
    {
      field: "id",
      headerName: "Id",
      width: 50,
    },
    {
      field: "userFullName",
      headerName: "Klient Naam",
      width: 200,
    },
    {
      field: "userEmail",
      headerName: "Klient E-pos",
      width: 200,
    },
    {
      field: "amountPaid",
      headerName: "Bedrag",
      width: 100,
      valueGetter: (params) => "R" + params.row.amountPaid.toFixed(2),
    },
    {
      field: "dateProcessed",
      headerName: "Datum",
      width: 130,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }

        const date = new Date(params.value);

        const valueFormatted = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return valueFormatted;
      },
    },
    {
      field: "payoutType",
      headerName: "Tiepe Betaling",
      width: 200,
    },
    {
      field: "status",
      width: 200,
      headerName: "Status",
      editable: true,
      type: "singleSelect",
      valueOptions: ["Versoek Ontvang", "Afgehandel", "Gekanselleer"],
    },
    {
      field: "reference",
      headerName: "Verwysing",
      width: 200,
      editable: true,
    },
    {
      field: "notes",
      headerName: "Notas",
      width: 300,
      editable: true,
    },
   
    {
      field: "bankingdetails",
      headerName: "Bank Rekening",
      width: 400,
      valueGetter: (params) =>
        params.row.bankAccountBankName +
        ", " +
        params.row.bankAccountBranchNo +
        ", " +
        params.row.bankAccountType +
        ", " +
        params.row.bankAccountNo,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Opsies",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <FixedLayout>
      <Box
        width={1}
        margin={"0 auto"}
        paddingX={2}
        paddingY={{ xs: 2, sm: 2, md: 2 }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ p: 2 }} variant="h5">
            Uitbetalings
          </Typography>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", padding: 1 }}>
            <Tabs value={tab} onChange={handleStatusChange} aria-label="tabs">
              <Tab label="Versoek Ontvang" value="Versoek Ontvang" />

              <Tab label="Afgehandel" value="Afgehandel" />
              <Tab label="Gekanselleer" value="Gekanselleer" />
              <Tab label="Alles" value="Alles" />
            </Tabs>
          </Box>
        </Box>

        <div style={{ height: 600, width: "100%" }}>
          {/* {!initialised && <span>Besig om te laai...</span>}
          {initialised && */}
          <DataGrid
            rows={rows}
            columns={columns}
            components={{
              Toolbar: EditToolbar,
              LoadingOverlay: LinearProgress,
            }}
            loading={loading}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </div>

        <Dialog
          onClose={() => setNewModalOpen(false)}
          open={newModalOpen}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <Box paddingY={{ xs: 1, sm: 2 }} paddingX={{ xs: 2, sm: 4 }}>
            <Box
              paddingY={{ xs: 1, sm: 2 }}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography variant={"h5"} fontWeight={700}>
                Voeg by
              </Typography>
              <Box
                component={"svg"}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width={24}
                height={24}
                onClick={() => setNewModalOpen(false)}
                sx={{ cursor: "pointer" }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </Box>
            </Box>
            <Divider />
            <Box paddingY={2}>
              {/* <AddHierarchyForm closeModal={handleCloseNewModal} /> */}
              <p>Die funksie is nog nie beskikbaar.</p>
            </Box>
          </Box>
        </Dialog>
      </Box>
    </FixedLayout>
  );
};

export default CommissionPayouts;
