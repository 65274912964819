import FixedLayout from "layouts/Fixed";
import {
  Typography,
  Box,
  Tab,
  Tabs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";

import agent from "../../api/agent";

import VisibilityIcon from "@mui/icons-material/Visibility";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

import {
  DataGrid,
  GridRowsProp,
  GridColumns,
  GridActionsCellItem,
  GridRowId,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridValueFormatterParams,
  useGridApiRef,
} from "@mui/x-data-grid";
import { Order } from "models/order";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SendIcon from "@mui/icons-material/Send";

export default function Orders() {
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  let navigate = useNavigate();

  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [rows, setRows] = useState<GridRowsProp>([]);

  const [tabStatus, setTabStatus] = useState("Benodig Betaling");

  const handleStatusChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setTabStatus(newValue);
  };

  const apiRef = useGridApiRef();

  useEffect(() => {
    setLoading(true);
    setRows([]);

    agent.Admin.ordersList(tabStatus)
      .then((c: Order[]) => {
        const orders: GridRowsProp = c.map((order) => ({
          id: order.id,
          orderDate: order.orderDate,
          amountTotal: order.amountTotal,
          status: order.status,
          customerName: order.customerAddress_FullName,
          amountDeliveryFee: order.amountDeliveryFee,
          referredBy: order.referredBy,
          customerType: order.customerType,
          deliveryOption: order.deliveryOption,
          deliveryTrackingNo: order.deliveryTrackingNo,
          isMonthlyOrder: order.isMonthlyOrder,
          packingListPrinted: order.packingListPrinted,
          type: order.type,
        }));
        setRows(orders);
      })
      .catch((error) =>
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer")
      )
      .finally(() => setLoading(false));
  }, [tabStatus]);

  const handleViewClick = (id: GridRowId) => () => {
    navigate(`/admin/bestellings/${id}`, { replace: true });
  };

  const handleSendPackingList = (id: GridRowId) => () => {
    toast.info("Paklys word verwerk...");

    agent.Admin.orderSendPackingListEmail(parseInt(id.toString()))
      .then(() => toast.success("Paklys suksesvol gestuur."))
      .catch((error) => {
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
      });
  };

  const handleDownloadPackingList = (id: GridRowId) => () => {
    //toast.info("Paklys word verwerk...");
    setShowDownloadModal(true);

    agent.Admin.orderDownloadPackingListPDF(parseInt(id.toString()))
      .then((response) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([response], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        console.log("url", url);
        link.setAttribute("download", `Paklys - ${id}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        //read tab or set value to downloaded.
        let item = rows.find((x) => x.id === id);
        item.packingListPrinted = true;
        apiRef.current.updateRows([item]);
      })
      .catch((error) => {
        //toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
        console.log("error", error);
      })
      .finally(() => {
        setShowDownloadModal(false);
      });
  };


  const handleDownloadInvoice = (id: GridRowId) => () => {
    //toast.info("Paklys word verwerk...");
    setShowDownloadModal(true);

    agent.Orders.downloadInvoicePDF(parseInt(id.toString()))
      .then((response) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([response], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        console.log("url", url);
        link.setAttribute("download", `Faktuur - ${id}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

      })
      .catch((error) => {
        //toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
        console.log("error", error);
      })
      .finally(() => {
        setShowDownloadModal(false);
      });
  };

  const handleSetMonthlyOrder = (id: GridRowId) => () => {
    //toast.info("Paklys word verwerk...");

    agent.Admin.orderSetMonthlyOrder(parseInt(id.toString()))
      .then(() => {
        toast.success("Bestelling suksesvol gemerk as maand bestelling.");

        //read tab or set value to downloaded.
        let item = rows.find((x) => x.id === id);
        item.isMonthlyOrder = true;
        apiRef.current.updateRows([item]);
        
      })
      .catch((error) => {
        if (error.response?.data){
        toast.error(error.response.data);
        }
        else if (error){
          toast.error(error)
        }
      });
  };

  const handleSendOrderConfirmation = (id: GridRowId) => () => {
    toast.info("Epos word verwerk...");

    agent.Admin.orderSendConfirmationEmail(parseInt(id.toString()))
      .then(() => toast.success("Epos suksesvol gestuur."))
      .catch((error) =>
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer")
      );
  };

  const handleSendTaxInvoice = (id: GridRowId) => () => {
    toast.info("Epos word verwerk...");

    agent.Admin.orderSendTaxInvoiceEmail(parseInt(id.toString()))
      .then(() => toast.success("Epos suksesvol gestuur."))
      .catch((error) =>
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer")
      );
  };

  const columns: GridColumns = [
    { field: "id", headerName: "Verwysing", width: 80 },
    { field: "customerName", headerName: "Kliënt", width: 250 },
    {
      field: "orderDate",
      headerName: "Datum",
      width: 180,
      // valueGetter: (params) => params.row.orderDate.split("T")[0] + " " + params.row.orderDate.split("T")[1].substring(0, 5),
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }

        const date = new Date(params.value);

        const valueFormatted = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return valueFormatted;
      },
    },
    { field: "type", headerName: "Tiepe", width: 120},
    {
      field: "amountTotal",
      headerName: "Totaal",
      width: 180,
      valueGetter: (params) => "R" + params.row.amountTotal.toFixed(2),
    },
    {
      field: "amountDeliveryFee",
      headerName: "Aflewering Koste",
      width: 180,
      valueGetter: (params) => "R" + params.row.amountDeliveryFee.toFixed(2),
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
    },
    {
      field: "packingListPrinted",
      headerName: "Paklys Afgelaai",
      width: 150,
      valueGetter: (params) => (params.row.packingListPrinted ? "Ja" : "Nee"),
    },
    { field: "customerType", headerName: "Kliënt Groep", width: 250 },
    { field: "referredBy", headerName: "Verwys Deur (Agent)", width: 250 },
    { field: "deliveryOption", headerName: "Aflewering Opsie", width: 250 },
    {
      field: "deliveryTrackingNo",
      headerName: "Opsporings Nommer",
      width: 250,
    },
    {
      field: "isMonthlyOrder",
      headerName: "Maand Bestelling",
      width: 250,
      valueGetter: (params) => (params.row.isMonthlyOrder ? "Ja" : "Nee"),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Opsies",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            className="textPrimary"
            onClick={handleViewClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<LocalShippingIcon />}
            label="Stuur Paklys Epos"
            onClick={handleSendPackingList(id)}
            showInMenu
          />,

          <GridActionsCellItem
            icon={<CloudDownloadIcon />}
            label="Download Paklys (PDF)"
            onClick={handleDownloadPackingList(id)}
            showInMenu
            
          />,

          <GridActionsCellItem
            icon={<CloudDownloadIcon />}
            label="Download Faktuur (PDF)"
            onClick={handleDownloadInvoice(id)}
            showInMenu
            divider
          />,

          <GridActionsCellItem
            icon={<SendIcon />}
            label="Stuur Bestelling Ontvang Epos"
            onClick={handleSendOrderConfirmation(id)}
            showInMenu
          />,

          <GridActionsCellItem
            icon={<EventAvailableIcon />}
            label="Merk as Maand Bestelling"
            onClick={handleSetMonthlyOrder(id)}
            showInMenu
            disabled={row.isMonthlyOrder || row.customerType !== "Agent"}
          />,

          <GridActionsCellItem
          icon={<SendIcon />}
          label="Stuur Belasting Faktuur Epos"
          onClick={handleSendTaxInvoice(id)}
          showInMenu
        />,
        ];
      },
    },
  ];

  return (
    <FixedLayout>
      <Box
        width={1}
        margin={"0 auto"}
        paddingX={2}
        paddingY={{ xs: 2, sm: 2, md: 2 }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ p: 2 }} variant="h5">
            Bestellings Lys
          </Typography>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", padding: 1 }}>
            <Tabs
              value={tabStatus}
              onChange={handleStatusChange}
              aria-label="tabs"
            >
              <Tab label="Benodig Betaling" value={"Benodig Betaling"} />
              <Tab label="Verwerking" value={"Verwerking"} />
              <Tab label="Afgehandel" value={"Afgehandel"} />
              <Tab label="Gekanselleer" value={"Gekanselleer"} />
              <Tab label="Alles" value={"Alles"} />
            </Tabs>
          </Box>
        </Box>

        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  amountDeliveryFee: false,
                  customerType: false,
                  referredBy: false,
                  deliveryOption: false,
                  deliveryTrackingNo: false,
                  isMonthlyOrder: false,
                },
              },
            }}
            rows={rows}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              LoadingOverlay: LinearProgress,
            }}
            loading={loading}
          />
        </Box>
        <Dialog
          open={showDownloadModal}
          onClose={() => setShowDownloadModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography>Versoek is besig om te verwerk.</Typography>

              <LinearProgress />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDownloadModal(false)}>
              Kanselleer
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </FixedLayout>
  );
}
