import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Skeleton } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import agent from "../../api/agent";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import React from "react";
import { toast } from "react-toastify";

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your first name"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  message: yup
    .string()
    .min(10, "Please enter a valid message")
    .max(500, "Please enter a shorter message")
    .required("Boodskap is noodsaaklik."),
});

const FormContact = (): JSX.Element => {
  const [step, setStep] = useState(1);
  const [submitVisible, setSubmitVisible] = useState(false);

  // const recaptchaRef = React.createRef();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  //Store the value when recaptcha is checked.
  function onChange(value) {
    setValue("captcha", value);

    if (value === null) {
      setSubmitVisible(false);
    } else {
      setSubmitVisible(true);
    }
  }

  async function handleSubmitData(data: FieldValues) {
    try {
      //Validation:
      var captcha = getValues("captcha");

      if (captcha === null || captcha === "") {
        toast.error(
          "The reCaptcha validation must be completed, please confirm the reCaptcha then try again to submit. "
        );
        return;
      }

      

      await agent.Forms.formContactSubmit(data);

      setStep(2);
    } catch (error) {
      if (error.Captcha) {
        toast.warning(
          "Captcha validasie was nie suksesvol, probeer asb weer om die validasie te doen en dan die vorm te stuur."
        );
        setSubmitVisible(false);
      }

    }
  }

  if (isSubmitting) {
    return (
      <Box>
        <Box marginBottom={7}>
          <Typography
            variant={"h4"}
            sx={{ fontWeight: 700 }}
            gutterBottom
            align={"center"}
          >
            Stuur ons 'n boodskap
          </Typography>
        </Box>
        <Typography
          variant={"body1"}
          color={"textSecondary"}
          align={"center"}
          sx={{ paddingBottom: 3 }}
        >
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="rectangular" height={200} />
        </Typography>
      </Box>
    );
  }

  if (step === 2) {
    return (
      <Box>
        <Box marginBottom={7}>
          <Typography
            variant={"h4"}
            sx={{ fontWeight: 700 }}
            gutterBottom
            align={"center"}
          >
            Stuur ons 'n boodskap
          </Typography>
        </Box>
        <Typography
          variant={"body1"}
          color={"textSecondary"}
          align={"center"}
          sx={{ paddingBottom: 3 }}
        >
          Baie dankie. Jou boodskap is ontvang, ons sal binne 1-2 werksdae in
          kontak wees.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box marginBottom={7}>
        <Typography
          variant={"h4"}
          sx={{ fontWeight: 700 }}
          gutterBottom
          align={"center"}
        >
          Stuur ons 'n boodskap
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <Box
          component={Grid}
          marginBottom={{ xs: 10, sm: 0 }}
          container
          spacing={4}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              label="Naam"
              variant="outlined"
              name={"name"}
              fullWidth
              {...register("name")}
              error={errors?.name != null}
              // @ts-ignore
              helperText={errors?.name?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="E-Pos"
              variant="outlined"
              name={"email"}
              fullWidth
              {...register("email")}
              error={errors?.email != null}
              // @ts-ignore
              helperText={errors?.email?.message}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              label="Boodskap"
              variant="outlined"
              name={"message"}
              fullWidth
              multiline={true}
              rows={3}
              {...register("message")}
              error={errors?.message != null}
              // @ts-ignore
              helperText={errors?.message?.message}
            />
          </Grid>
          <Grid item xs={1} sm={4}></Grid>
          <Grid item xs={10} sm={4}>
            <input type="hidden" name={"captcha"} {...register("captcha")} />

            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={1} sm={4}></Grid>

          <Grid
            item
            container
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Button
              size={"large"}
              variant={"contained"}
              type={"submit"}
              disabled={!submitVisible}
             
            >
              Stuur
            </Button>
            <Typography
              variant={"subtitle2"}
              color={"textSecondary"}
              sx={{ marginTop: 2, marginBottom: 5 }}
              align={"center"}
            >
              Ons sal binne 1-2 werksdae in kontak wees.
            </Typography>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default FormContact;
