import Box from "@mui/material/Box";

import Main from "layouts/Main";
import Container from "components/Container";

import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Handelshoek } from "models/handelshoek";
import agent from "api/agent";
import { toast } from "react-toastify";

export default function OnsHandelshoeke() {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<Handelshoek[]>([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response: Handelshoek[] =
          await agent.Handelshoek.handelshoekList();
        setList(response);
      } catch (error) {
        toast.error(
          "Aggenee, kon nie die handelshoek lys aflaai nie. Probeer asb weer."
        );
      }

      setLoading(false);
    }

    fetchData();
  }, []);

  const theme = useTheme();

  return (
    <Main>
      <Box>
        <Box
          sx={{
            position: "relative",
            backgroundColor: theme.palette.alternate.main,
            backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
            marginTop: -13,
            paddingTop: 13,
          }}
        >
          <Container>
            <Box>
              <Box
                marginBottom={{ xs: 0, sm: 2 }}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Typography
                  variant="h3"
                  gutterBottom
                  align={"center"}
                  sx={{
                    fontWeight: 900,
                  }}
                >
                  Ons Handelshoeke
                </Typography>
                <Typography
                  variant="h6"
                  component="p"
                  color="text.primary"
                  align={"center"}
                  sx={{ marginBottom: 1 }}
                >
                  Besoek gerus een van ons handelshoeke vir 'n klein bietjie
                  bederf!
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box>
          <Container>
            <Box sx={{ textAlign: "center" }}>
              {loading && <CircularProgress />
              
             }

              {!loading && (
                <Grid container spacing={4}>
                  {list
                    .sort(() => Math.random() - 0.5)
                    .map((item, i) => (
                      <Grid item xs={6} md={4} lg={3} key={i}>
                        <Box
                          // component={"a"}
                          // href={""}
                          display={"block"}
                          width={1}
                          height={1}
                          sx={{
                            textDecoration: "none",
                            transition: "all .2s ease-in-out",
                            "&:hover": {
                              transform: `translateY(-${theme.spacing(1 / 2)})`,
                            },
                          }}
                        >
                          <Box
                            component={Card}
                            width={1}
                            height={1}
                            boxShadow={0}
                            sx={{
                              bgcolor: "transparent",
                              backgroundImage: "none",
                            }}
                          >
                            <CardMedia
                              image={`/uploads/handelshoeke/${item.imageFileName}`}
                              title={item.title}
                              sx={{
                                height: { xs: 280, md: 280 },
                                position: "relative",
                                filter:
                                  theme.palette.mode === "dark"
                                    ? "brightness(0.7)"
                                    : "none",
                              }}
                            />
                            <Box
                              width={"90%"}
                              margin={"0 auto"}
                              display={"flex"}
                              flexDirection={"column"}
                              boxShadow={3}
                              borderRadius={2}
                              bgcolor={"background.paper"}
                              position={"relative"}
                              zIndex={3}
                              sx={{ transform: "translateY(-15px)" }}
                            >
                              <Box
                                component={CardContent}
                                position={"relative"}
                              >
                                <Typography variant={"h6"} gutterBottom>
                                  {item.title}
                                </Typography>
                                <Typography color="text.secondary">
                                  {item.description}
                                </Typography>
                                <Typography
                                  color="text.secondary"
                                  paddingTop={1}
                                >
                                  {item.contact}
                                </Typography>
                              </Box>
                              <Box flexGrow={1} />
                              <Box
                                padding={2}
                                display={"flex"}
                                flexDirection={"column"}
                              >
                                <Box marginBottom={2}>
                                  <Divider />
                                </Box>
                                <Box
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  <Box display={"flex"} alignItems={"center"}>
                                    <Typography color={"text.secondary"}>
                                      {item.province},
                                    </Typography>
                                  </Box>
                                  <Typography color={"text.secondary"}>
                                    {item.country}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              )}
            </Box>
          </Container>
        </Box>
      </Box>
    </Main>
  );
}
