import FixedLayout from "layouts/Fixed";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from "@mui/icons-material/Add";
import {
  Typography,
  Button,
  Box,
  LinearProgress,
  Dialog,
  Divider,
} from "@mui/material";
import { useEffect, useState } from "react";
import agent from "../../api/agent";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import React from "react";
//import ProductQuickViewDialog from "./ProductQuickViewDialog";
import { toast } from "react-toastify";
import { CommissionHierarchy } from "models/commission";
import AddHierarchyForm from "./AddHierarchyForm";
import EditHierarchyForm from "./EditHierarchyForm";

const HierarchyList = (): JSX.Element => {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [loading, setLoading] = useState(true);
  const [newModalOpen, setNewModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editModalId, setEditModalId] = useState(0);

  useEffect(() => {
    agent.Admin.commissionHierarchyList()
      .then((c: CommissionHierarchy[]) => {
        const s: GridRowsProp = c.map((entry) => ({
          id: entry.id,
          userId: entry.userId,
          userName: entry.userName,
          userEmail: entry.userEmail,
          parentUserId: entry.parentUserId,
          parentUserName: entry.parentUserName,
          parentUserEmail: entry.parentUserEmail,
          effectiveDate: entry.effectiveDate,
          level: entry.level,
        }));
        setRows(s);
      })
      .catch((error) => {
        toast.error("Aggenee, dit wou nie laai nie. Probeer asb weer");
      })
      .finally(() => setLoading(false));
  }, []);

  function handleCloseNewModal(reloaddata: boolean) {
    setNewModalOpen(false);

    if (reloaddata) {
      setLoading(true);

      agent.Admin.commissionHierarchyList()
        .then((c: CommissionHierarchy[]) => {
          const s: GridRowsProp = c.map((entry) => ({
            id: entry.id,
            userId: entry.userId,
            userName: entry.userName,
            userEmail: entry.userEmail,
            parentUserId: entry.parentUserId,
            parentUserName: entry.parentUserName,
            parentUserEmail: entry.parentUserEmail,
            effectiveDate: entry.effectiveDate,
            level: entry.level,
          }));
          setRows(s);
        })
        .catch((error) => {
          toast.error("Aggenee, dit wou nie laai nie. Probeer asb weer");
        })
        .finally(() => setLoading(false));
    }
  }

  async function handleDeleteEntry(id: number) {
    //TODO: Add confirmation modal.

    setLoading(true);

    try {
      await agent.Admin.commissionHierarchyDelete(id);

      agent.Admin.commissionHierarchyList()
        .then((c: CommissionHierarchy[]) => {
          const s: GridRowsProp = c.map((entry) => ({
            id: entry.id,
            userId: entry.userId,
            userName: entry.userName,
            userEmail: entry.userEmail,
            parentUserId: entry.parentUserId,
            parentUserName: entry.parentUserName,
            parentUserEmail: entry.parentUserEmail,
            effectiveDate: entry.effectiveDate,
            level: entry.level,
          }));
          setRows(s);
        })
        .catch((error) => {
          toast.error("Aggenee, dit wou nie laai nie. Probeer asb weer");
        });
    } catch (error) {
      toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer");
    } finally {
      setLoading(false);
    }

    // setLoading(false);
  }

  function handleEditEntry(id: number){
    setEditModalOpen(true);
    setEditModalId(id);
  }

  function handleCloseEditModal(reloaddata: boolean) {
    setEditModalOpen(false);

    if (reloaddata) {
      setLoading(true);

      agent.Admin.commissionHierarchyList()
        .then((c: CommissionHierarchy[]) => {
          const s: GridRowsProp = c.map((entry) => ({
            id: entry.id,
            userId: entry.userId,
            userName: entry.userName,
            userEmail: entry.userEmail,
            parentUserId: entry.parentUserId,
            parentUserName: entry.parentUserName,
            parentUserEmail: entry.parentUserEmail,
            effectiveDate: entry.effectiveDate,
            level: entry.level,
          }));
          setRows(s);
        })
        .catch((error) => {
          toast.error("Aggenee, dit wou nie laai nie. Probeer asb weer");
        })
        .finally(() => setLoading(false));
    }
  }

  function EditToolbar() {
    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setNewModalOpen(true)}
        >
          Skep Nuwe
        </Button>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const columns: GridColumns = [
    {
      field: "id",
      headerName: "Id",
      width: 50,
    },
    {
      field: "parentUserName",
      headerName: "Parent Agent Naam",
      width: 200,
    },
    {
      field: "parentUserEmail",
      headerName: "Parent Agent Email",
      width: 200,
    },
    {
      field: "userName",
      headerName: "Sub Gebruiker Naam",
      width: 200,
    },
    {
      field: "userEmail",
      headerName: "Sub Gebruiker E-posadres",
      width: 200,
    },
    {
      field: "effectiveDate",
      headerName: "Effektief Vanaf",
      width: 130,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value == null) {
          return "";
        }

        const date = new Date(params.value);

        const valueFormatted = date.toLocaleDateString("en-ZA", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return valueFormatted;
      },
    },
    {
      field: "level",
      headerName: "Sub Gebruiker Vlak",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Opsies",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          showInMenu
          onClick={(event) => handleDeleteEntry(params.row.id)}
        />,
        <GridActionsCellItem
        icon={<EditIcon />}
        label="Edit"
        showInMenu
        onClick={(event) => handleEditEntry(params.row.id)}
      />,
      ],
    },
  ];

  return (
    <FixedLayout>
      <Box
        width={1}
        margin={"0 auto"}
        paddingX={2}
        paddingY={{ xs: 2, sm: 2, md: 2 }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ p: 2 }} variant="h5">
            Struktuur
          </Typography>
        </Box>

        <div style={{ height: 600, width: "100%" }}>
          {/* {!initialised && <span>Besig om te laai...</span>}
          {initialised && */}
          <DataGrid
            rows={rows}
            columns={columns}
            components={{
              Toolbar: EditToolbar,
              LoadingOverlay: LinearProgress,
            }}
            loading={loading}
          />
        </div>

        {/* {openId && 
        <ProductQuickViewDialog
          open={openId !== null}
          onClose={handleCloseProduct}
          product={openId}
        />} */}

        <Dialog
          onClose={() => setNewModalOpen(false)}
          open={newModalOpen}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <Box paddingY={{ xs: 1, sm: 2 }} paddingX={{ xs: 2, sm: 4 }}>
            <Box
              paddingY={{ xs: 1, sm: 2 }}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography variant={"h5"} fontWeight={700}>
                Voeg by
              </Typography>
              <Box
                component={"svg"}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width={24}
                height={24}
                onClick={() => setNewModalOpen(false)}
                sx={{ cursor: "pointer" }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </Box>
            </Box>
            <Divider />
            <Box paddingY={2}>
              <AddHierarchyForm closeModal={handleCloseNewModal} />
            
            </Box>
          </Box>
        </Dialog>

        {editModalOpen && <EditHierarchyForm closeModal={handleCloseEditModal} id={editModalId} /> }
      </Box>
    </FixedLayout>
  );
};

export default HierarchyList;
