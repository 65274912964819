import { BasketItem, BasketItemVariation } from "models/basket";
import { OrderItem, OrderItemVariation } from "models/order";
import { Product, ProductVariation } from "models/product";

export function getCookie(key: string) {
  const b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export function currencyFormat(amount: number) {
  return "R" + amount.toFixed(2);
}

export interface KeyValuePair {
  key: string;
  value: string;
}

export function slugify(str: string) {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
}

export function toCamel(o) {
  var newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === "object") {
        value = toCamel(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = (
          origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
        ).toString();
        value = o[origKey];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toCamel(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
}

export function addServerErrors<T>(
  errors: { [P in keyof T]?: string[] },
  setError: (
    fieldName: keyof T,
    error: { type: string; message: string }
  ) => void
) {
  return Object.keys(errors).forEach((key) => {
    setError(key as keyof T, {
      type: "server",
      message: errors[key as keyof T]!.join(". "),
    });
  });
}

export function priceCalculatePerOrderItem(item: OrderItem, customerType: string) {
  
  //Check if agent or normal user
  //const agent = item.priceAgent !== -1 && item.priceRetailer === -1;
  //const retailer = item.priceRetailer !== -1 && item.priceAgent === -1;

  let totalprice = 0;

  if (item.price === 0) {
    return "R0.00";
  }
 
  if (customerType === "Agent") {
    totalprice += item.priceAgent;

    item.itemVariations.forEach((variation) => {
      totalprice += variation.priceAgent;
    });

    return "R" + totalprice.toFixed(2);
  }

  if (customerType === "Retailer") {
    totalprice += item.priceRetailer;

    item.itemVariations.forEach((variation) => {
      totalprice += variation.priceRetailer;
    });

    return "R" + totalprice.toFixed(2);
  }

    if (customerType === "Winkel") {
        totalprice += item.priceWinkel;

        item.itemVariations.forEach((variation) => {
            totalprice += variation.priceWinkel;
        });

        return "R" + totalprice.toFixed(2);
    }

  if (item.onSale) {
    totalprice += item.priceSale;
  } else {
    totalprice += item.price;
  }

  item.itemVariations.forEach((variation) => {
    if (variation.onSale) {
      totalprice += variation.priceSale;
    } else {
      totalprice += variation.price;
    }
  });

  return "R" + totalprice.toFixed(2);
}

export function priceCalculatePerItem(item: BasketItem) {
  let totalprice = 0;

  if (item.customerType === "Gewoon" || item.customerType === "Public") {
    if (item.onSale) {
      totalprice += item.priceSale;
    } else {
      totalprice += item.price;
    }

    item.basketItemVariations.forEach((variation) => {
      if (variation.onSale) {
        totalprice += variation.priceSale;
      } else {
        totalprice += variation.price;
      }
    });

    return "R" + totalprice.toFixed(2);
  }

  if (
    item.customerType === "Agent" ||
    item.customerType === "Admin" ||
    item.customerType === "Retailer" ||
      item.customerType === "Bulk" ||
      item.customerType === "Winkel"
  ) {
    totalprice += item.priceCustomer;

    item.basketItemVariations.forEach((variation) => {
      totalprice += variation.priceCustomer;
    });

    return "R" + totalprice.toFixed(2);
  }
}

export function priceCalculatePerItemTotal(item: BasketItem) {
  
  let totalprice = 0;

  if (item.customerType === "Gewoon" || item.customerType === "Public") {
    if (item.onSale) {
      totalprice += item.priceSale;
    } else {
      totalprice += item.price;
    }

    item.basketItemVariations.forEach((variation) => {
      if (variation.onSale) {
        totalprice += variation.priceSale;
      } else {
        totalprice += variation.price;
      }
    });

    return "R" + (totalprice * item.quantity).toFixed(2);
  }

  if (
    item.customerType === "Agent" ||
    item.customerType === "Admin" ||
    item.customerType === "Retailer" ||
      item.customerType === "Bulk" ||
      item.customerType === "Winkel"
  ) {
    totalprice += item.priceCustomer;

    item.basketItemVariations.forEach((variation) => {
      totalprice += variation.priceCustomer;
    });

    return "R" + (totalprice * item.quantity).toFixed(2);
  }

  return <span>Fout! Verw. 1025</span>;
}

export function priceFormatOrderItem(
  variation: OrderItem,
  showMessage: boolean = false,
  customerType: string,
) {

  //Check if agent or normal user
  // const agent = variation.priceAgent !== -1 && variation.priceRetailer === -1;
  // const retailer = variation.priceRetailer !== -1 && variation.priceAgent === -1;

  if (variation.price === 0) {
    return null;
  }

  if (customerType === 'Agent') {
    return (
      <span>
        <del> R{variation.price.toFixed(2)} </del>&nbsp;R{variation.priceAgent.toFixed(2)}
        {showMessage ? " (Agent)" : ""}
      </span>
    );
  }

  if (customerType === 'Retailer') {
    return (
      <span>
        <del> R{variation.price.toFixed(2)} </del>&nbsp;R{variation.priceRetailer.toFixed(2)}
        {showMessage ? " (Retailer)" : ""}
      </span>
    );
    }

    if (customerType === 'Winkel') {
        return (
            <span>
                <del> R{variation.price.toFixed(2)} </del>&nbsp;R{variation.priceWinkel.toFixed(2)}
                {showMessage ? " (Winkel)" : ""}
            </span>
        );
    }

  if (variation.onSale) {
    return (
      <span>
        <del> R{variation.price.toFixed(2)} </del>&nbsp;R{variation.priceSale.toFixed(2)}
        {showMessage ? " (Uitverkoping)" : ""}
      </span>
    );
  }

  return <span>R{variation.price.toFixed(2)}</span>;
}


export function priceFormatOrderItemVariation(
  variation: OrderItemVariation,
  showMessage: boolean = false,
  customerType: string,
) {

  //Check if agent or normal user
  // const agent = variation.priceAgent !== -1 && variation.priceRetailer === -1;
  // const retailer = variation.priceRetailer !== -1 && variation.priceAgent === -1;

  if (variation.price === 0) {
    return null;
  }

  if (customerType === 'Agent') {
    return (
      <span>
        <del> R{variation.price.toFixed(2)} </del>&nbsp;R{variation.priceAgent.toFixed(2)}
        {showMessage ? " (Agent)" : ""}
      </span>
    );
  }

  if (customerType === 'Retailer') {
    return (
      <span>
        <del> R{variation.price.toFixed(2)} </del>&nbsp;R{variation.priceRetailer.toFixed(2)}
        {showMessage ? " (Retailer)" : ""}
      </span>
    );
    }

    if (customerType === 'Winkel') {
        return (
            <span>
                <del> R{variation.price.toFixed(2)} </del>&nbsp;R{variation.priceWinkel.toFixed(2)}
                {showMessage ? " (Winkel)" : ""}
            </span>
        );
    }

  if (variation.onSale) {
    return (
      <span>
        <del> R{variation.price.toFixed(2)} </del>&nbsp;R{variation.priceSale.toFixed(2)}
        {showMessage ? " (Uitverkoping)" : ""}
      </span>
    );
  }

  return <span>R{variation.price.toFixed(2)}</span>;
}


export function BasketItemVariationPriceFormat(
  item: BasketItemVariation,
  showMessage: boolean = true
) {
  if (item.customerType === "Gewoon" || item.customerType === "Public") {
    if (item.onSale) {
      return (
        <span>
          <del> R{item.price.toFixed(2)} </del>&nbsp;R
          {item.priceSale.toFixed(2)}
          {showMessage ? " (Uitverkoping)" : ""}
        </span>
      );
    }

    return <span>R{item.price.toFixed(2)}</span>;
  }

  if (item.customerType === "Agent") {
    return (
      <span>
        <del> R{item.price.toFixed(2)} </del>&nbsp;R
        {item.priceCustomer.toFixed(2)}
        {showMessage ? " (Agent)" : ""}
      </span>
    );
  }

  if (item.customerType === "Retailer" || item.customerType === "Admin") {
    return (
      <span>
        <del> R{item.price.toFixed(2)} </del>&nbsp;R
        {item.priceCustomer.toFixed(2)}
        {showMessage ? " (Retailer)" : ""}
      </span>
    );
    }

    if (item.customerType === "Winkel") {
        return (
            <span>
                <del> R{item.price.toFixed(2)} </del>&nbsp;R
                {item.priceCustomer.toFixed(2)}
                {showMessage ? " (Winkel)" : ""}
            </span>
        );
    }


  if (item.customerType === "Bulk") {
    return <span>R{item.priceCustomer.toFixed(2)}</span>;
  }

  return <span>Fout! Verw. 1024</span>;
}

export function priceFormatv2(
  product: Product, showMessage: boolean = false
) {
  if (product.price === 0) {
    return null;
  }

    if (product.customerType === "Gewoon" || product.customerType === "Public") {
        if (product.onSale) {
      return (
        <span>
              <del> R{product.price.toFixed(2)} </del>&nbsp;R{product.priceSale.toFixed(2)}
          {showMessage ? " (Uitverkoping)" : ""}
        </span>
      );
    }

        return <span>R{product.price.toFixed(2)}</span>;
  }

    if (product.customerType === "Agent") {
    return (
      <span>
            <del> R{product.price.toFixed(2)} </del>&nbsp;R{product.priceCustomer.toFixed(2)}
        {showMessage ? " (Agent)" : ""}
      </span>
    );
  }

    if (product.customerType === "Retailer" || product.customerType === "Admin") {
    return (
      <span>
            <del> R{product.price.toFixed(2)} </del>&nbsp;R{product.priceCustomer.toFixed(2)}
        {showMessage ? " (Retailer)" : ""}
      </span>
    );
    }

    if (product.customerType === "Winkel") {
        return (
            <span>
                <del> R{product.price.toFixed(2)} </del>&nbsp;R{product.priceCustomer.toFixed(2)}
                {showMessage ? " (Winkel)" : ""}
            </span>
        );
    }

    if (product.customerType === "Bulk") {
        return <span>R{product.priceCustomer.toFixed(2)}</span>;
  }

  return <span>Fout! Verw. 1022</span>;
}

export function priceFormatv2BasketItem(
    product: BasketItem, showMessage: boolean = false
) {
    if (product.price === 0) {
        return null;
    }

    if (product.customerType === "Gewoon" || product.customerType === "Public") {
        if (product.onSale) {
            return (
                <span>
                    <del> R{product.price.toFixed(2)} </del>&nbsp;R{product.priceSale.toFixed(2)}
                    {showMessage ? " (Uitverkoping)" : ""}
                </span>
            );
        }

        return <span>R{product.price.toFixed(2)}</span>;
    }

    if (product.customerType === "Agent") {
        return (
            <span>
                <del> R{product.price.toFixed(2)} </del>&nbsp;R{product.priceCustomer.toFixed(2)}
                {showMessage ? " (Agent)" : ""}
            </span>
        );
    }

    if (product.customerType === "Retailer" || product.customerType === "Admin") {
        return (
            <span>
                <del> R{product.price.toFixed(2)} </del>&nbsp;R{product.priceCustomer.toFixed(2)}
                {showMessage ? " (Retailer)" : ""}
            </span>
        );
    }

    if (product.customerType === "Winkel") {
        return (
            <span>
                <del> R{product.price.toFixed(2)} </del>&nbsp;R{product.priceCustomer.toFixed(2)}
                {showMessage ? " (Winkel)" : ""}
            </span>
        );
    }

    if (product.customerType === "Bulk") {
        return <span>R{product.priceCustomer.toFixed(2)}</span>;
    }

    return <span>Fout! Verw. 1022</span>;
}


export function variationPriceFormatv2(
  item: ProductVariation,
  showMessage: boolean = true
) {
  if (item.price === 0) {
    return "";
  }

  if (item.customerType === "Gewoon" || item.customerType === "Public") {
    if (item.onSale) {
      return (
        <span>
          <del> R{item.price.toFixed(2)} </del>&nbsp;R
          {item.priceSale.toFixed(2)} {showMessage ? " (Uitverkoping)" : ""}
        </span>
      );
    }

    return <span>R{item.price.toFixed(2)}</span>;
  }

  if (item.customerType === "Agent") {
    return (
      <span>
        <del> R{item.price.toFixed(2)} </del>&nbsp;R
        {item.priceCustomer.toFixed(2)}
        {showMessage ? " (Agent)" : ""}
      </span>
    );
  }

  if (item.customerType === "Retailer" || item.customerType === "Admin") {
    return (
      <span>
        <del> R{item.price.toFixed(2)} </del>&nbsp;R
        {item.priceCustomer.toFixed(2)}
        {showMessage ? " (Retailer)" : ""}
      </span>
    );
    }

    if (item.customerType === "Winkel") {
        return (
            <span>
                <del> R{item.price.toFixed(2)} </del>&nbsp;R
                {item.priceCustomer.toFixed(2)}
                {showMessage ? " (Winkel)" : ""}
            </span>
        );
    }

  if (item.customerType === "Bulk") {
    return <span>R{item.priceCustomer.toFixed(2)}</span>;
  }

  return <span>Fout! Verw. 1023</span>;
}

export function variationPriceFormatSizev2(
  item: ProductVariation,
  product: Product,
  showMessage: boolean = true
) {
  if (item.customerType === "Gewoon" || item.customerType === "Public") {
    if (item.onSale) {
      return (
        <span>
          <del> +R{item.price.toFixed(2)} </del>&nbsp;+R
          {(
            (product.onSale ? product.priceSale : product.price) +
            item.priceSale
          ).toFixed(2)}
          {" (Uitverkoping) "}
        </span>
      );
    }

    return (
      <>
        <br />
        <span>
          R
          {(
            (product.onSale ? product.priceSale : product.price) + item.price
          ).toFixed(2)}
        </span>
      </>
    );
  }

  if (item.customerType === "Agent") {
    return (
      <span>
        <del> R{(product.price + item.price).toFixed(2)} </del>&nbsp;R
        {(product.priceCustomer + item.priceCustomer).toFixed(2)}
        {" (Agent) "}
      </span>
    );
    }

    if (item.customerType === "Winkel") {
        return (
            <span>
                <del> R{(product.price + item.price).toFixed(2)} </del>&nbsp;R
                {(product.priceCustomer + item.priceCustomer).toFixed(2)}
                {" (Winkel) "}
            </span>
        );
    }

  if (item.customerType === "Retailer" || item.customerType === "Admin") {
    return (
      <span>
        <del> R{(product.price + item.price).toFixed(2)} </del>&nbsp;R
        {(product.priceCustomer + item.priceCustomer).toFixed(2)}
        {" (Retailer) "}
      </span>
    );
  }

  if (item.customerType === "Bulk") {
    return (
      <>
        <br />
        <span>R{(product.price + item.price).toFixed(2)}</span>
      </>
    );
  }

  return <span>Fout! Verw. 1023</span>;
}
